import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PageTitle } from "../../../../../_metronic/layout/core"
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap"
import { KTCard, KTIcon } from "../../../../../_metronic/helpers"
import Select from 'react-select';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css'; // Importa el CSS de Quill
import 'quill-mention/dist/quill.mention.css'; // Importa el CSS de Quill Mention
import { Mention } from 'quill-mention';
import Quill from 'quill';
import './custom.css'
import { getProcessesForDropdown } from "../../../../services/procesos"
import { getSheetsAllByProcessForDropdown } from "../../../../services/fichas"
import { getFieldsBySheetDropdown } from "../../../../services/campos"
import { AlertDropdown } from "./Alert.interface"
import { getAdminUsers } from "../../../../services/users"
import { createAlert, createAlertProcess, getAlertById, updateAlert, updateAlertProcess } from "../../../../services/alerts"
import { showMessage } from "../../../../shared/Alerts"
import { getUnidadesPorId } from "../../../../services/companies"
import { DataUser } from "../../../documentacion/MiFicha/DataUserInterface"


// Registra el módulo Mention en Quill
if (typeof window !== 'undefined' && Quill) {
    Quill.register('modules/mention', Mention);
}

const fichasBreadcrumbs = [
    {
        title: 'Configuración',
        path: '/configuracion/alertas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]



type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AlertEstadoEdit = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [title, setTitle] = useState('Nueva Alerta')
    const [value, onChange] = useState<Value>([new Date(), new Date()]);
    const [selectedEmails, setSelectedEmails] = useState<any>([]);
    const [listProcessValidationError, setListProcessValidationError] = useState(false);
    const [bodyValue, setBodyValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [processList, setProcessList] = useState<any[]>([])
    const [unidadList, setUnidadList] = useState<any[]>([])
    const [fieldRequerid, setFieldRequerid] = useState(false)
    const [fieldList, setFieldList] = useState<any[]>([])
    const [processSelected, setProcessSelected] = useState()
    const [processValue, setProcessValue] = useState()
    const [unidadSelected, setUnidadSelected] = useState()
    const [unidadValue, setUnidadValue] = useState()
    const [estadoSelected, setEstadoSelected] = useState<any>()
    const [estadoValue, setEstadoValue] = useState()
    const [selectedRemitente, setSelectedRemitente] = useState<any>()
    const [remitenteValue, setRemitenteValue] = useState()
    const [sheetValue, setSheetValue] = useState()
    const [processOwners, setProcessOwners] = useState([])
    const [description, setDescription] = useState<string>('')
    const [validationMessage, setValidationMessage] = useState('')
    const [isNewEmailButtonEnable, setNewEmailButtonStatus] = useState(false)
    const [newEmail, setNewEmail] = useState('')
    const [idAlert, setIdAlert] = useState(id)

    const quillRef = useRef<ReactQuill>(null);
    const cursorPositionRef = useRef<number | null>(null);
    const usuarioJSON = localStorage.getItem('userData');
    let usuario: DataUser | null = null;

    if (usuarioJSON !== null) {
        usuario = JSON.parse(usuarioJSON);
    }

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
            width: "400px", // Ancho fijo
        }),
    };

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],        // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];


    const modules = {
        toolbar: toolbarOptions,
    };


    const stateList = [
        { value: 'pendientePorColaborador', label: 'Fichas Pendientes' },
        { value: 'fichaSiendoEditada', label: 'Fichas en Progreso' },
        { value: 'fichaObservada', label: 'Fichas Observadas' },
        { value: 'completadoPorColaborador', label: 'Fichas Completadas' },
        { value: 'aprobadoConPendiente', label: 'Fichas Aprobadas con Pendientes' },
        { value: 'aprobadoPorAdmin', label: 'Fichas Aprobadas' },
        { value: 'historico', label: 'Fichas Historicas' },
        { value: 'pending', label: 'Documentos Pendientes de Firma' },
        { value: 'viewed', label: 'Documentos en Proceso de Firma' },
        { value: 'signed', label: 'Documentos Firmados' },
        { value: 'procesoCerrado', label: 'Procesos Cerrados' },
        { value: 'procesoCerradoConPendiente', label: 'Procesos Cerrados con Pendientes' },
        { value: 'procesoAnulado', label: 'Procesos Anulados' },
    ]

    const remitenteList = [
        { value: 'responsableproceso', label: 'RESPONSABLE PROCESO' },
        { value: 'usuario', label: 'USUARIO' },
    ]

    const etiquetasAdicionales = [
        { value: '[UNIDAD]', label: 'UNIDAD' },
        { value: '[EMPRESA]', label: 'EMPRESA' },
        { value: '[USUARIO]', label: 'USUARIO' },
        { value: '[USUARIOS]', label: 'USUARIOS' }
    ]

    const handleSelectionChange = (range: any) => {
        if (range) {
            cursorPositionRef.current = range.index; // Guarda la posición actual del cursor
        }
    };

    const insertTag = (tag: string) => {
        const quillEditor = quillRef.current?.getEditor();
        if (quillEditor) {
            const position = cursorPositionRef.current ?? 0; // Usa la posición guardada o inserta al inicio
            quillEditor.insertText(position, tag);
            quillEditor.setSelection({ index: position + tag.length, length: 0 });
        }
    };

    useEffect(() => {
        getProcesses()
        fetchUnidades()
        getAdministrativeUsers()
    }, [])

    useEffect(() => {

        if (idAlert && processList && unidadList) {
            setTitle('Editar Alerta')
            getAlert(idAlert)
        }
    }, [processList, unidadList])


    useEffect(() => {
        changeVeryfiField()
    }, [processValue, unidadValue, estadoValue, remitenteValue])

    const getAlert = (id: string) => {
        getAlertById(id)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    const data = response.data
                    setDescription(data.description)
                    setProcessValue(data.processId)
                    setUnidadValue(data.unidad)
                    setEstadoValue(data.sheetState)
                    setRemitenteValue(data.typeSend)
                    setSheetValue(data.sheetId)
                    setBodyValue(data.bodyMessage)
                    setSelectedEmails(data.emails)
                    onChange(data.dateRange)
                    getSheetsByProcess(data.processId)
                    getFieldsBySheet(data.sheetId)

                    setProcessSelected(processList.filter(pro => pro.value === data.processId)?.[0])
                    setUnidadSelected(unidadList.filter(und => und.value === data.unidad)?.[0])
                    setEstadoSelected(stateList.filter(sta => sta.value === data.sheetState)?.[0])
                    setSelectedRemitente(remitenteList.filter(rem => rem.value === data.typeSend)?.[0])

                }
            })
            .catch((error) => console.log(error))
    }

    const goBack = () => {
        navigate('/configuracion/alerta2')
    }


    const handleEmailsChange = (selectedOptions: any) => {
        setSelectedEmails(selectedOptions);
    };

    const getProcesses = () => {
        setLoading(true)
        getProcessesForDropdown()
            .then((response) => {
                if (response.success) {
                    setProcessList(response.data.map((x: any) => ({ label: x.name, value: x.id })))
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchUnidades = async () => {
        try {
            const response = await getUnidadesPorId(usuario?.userId);
            if (response.success) {
                setUnidadList(response.data.map((unidad: any) => ({
                    label: unidad.DesUnid.toUpperCase(),
                    value: unidad.codUnid,
                })));
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getAdministrativeUsers = () => {
        getAdminUsers()
            .then((response) => {
                setProcessOwners(response.data.map((x: any) => ({ label: x.nombre, value: x._id })))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSheetsByProcess = (id: string) => {
        getSheetsAllByProcessForDropdown(id)
            .then((response) => {
                const listFields: any = []
                if (response.success) {
                    response.data.map((dat: any) => {
                        dat.seccion.map((sec: any) => {
                            listFields.push(sec)
                        })
                    })

                    const options: any = listFields.flatMap((section: any) => ({
                        label: section.name,
                        options: section.fields.map((field: any) => ({
                            value: `[${field.id}]`,
                            label: field.name,
                        })),
                    }));
                    setFieldList(options)
                    // setSheetList(response.data)
                }
            })
            .catch((error) => { console.log(error) })
    }

    const getFieldsBySheet = (id: string) => {
        getFieldsBySheetDropdown(id)
            .then((response) => {
                if (response.success) {
                    setFieldList(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleProcessChange = (e: any) => {
        setProcessSelected(e)
        setProcessValue(e.value)
        getSheetsByProcess(e.value)
    }

    const handleUnidadChange = (e: any) => {
        setUnidadSelected(e)
        setUnidadValue(e.value)
    }

    const handleEstadoChange = (e: any) => {
        setEstadoSelected(e)
        setEstadoValue(e.value)
    }

    const handleRemitenteChange = (e: any) => {
        setSelectedRemitente(e)
        setRemitenteValue(e.value)
    }

    const changeVeryfiField = () => {
        if (processValue && unidadValue && estadoValue && remitenteValue) {
            setFieldRequerid(true)
        } else {
            setFieldRequerid(false)
        }
    }

    const handleSheetChange = (e: any) => {
        setSheetValue(e.target.value)
        getFieldsBySheet(e.target.value)
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    useEffect(() => {
        console.log("bodyValue")
        console.log(bodyValue)
    }, [bodyValue])

    const handleSubmit = (e: any) => {

        e.preventDefault()

        const data = {
            description: description,
            processId: processValue,
            unidad: unidadValue,
            sheetState: estadoValue,
            typeSend: remitenteValue,
            type: 'state',
            sheetId: sheetValue,
            filters: [],
            bodyMessage: bodyValue,
            days: [],
            emails: selectedEmails,
            dateRange: value
        }

        if (idAlert) {
            updateAlertProcess(idAlert, data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        } else {
            createAlertProcess(data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        }

    }


    const handleNewEmailInputChange = (e: any) => {
        const enteredEmail = e.target.value
        setNewEmail(enteredEmail)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (enteredEmail.trim() === '') {
            setValidationMessage('')
            setNewEmailButtonStatus(false)
        } else if (!emailRegex.test(enteredEmail)) {
            setValidationMessage('El email no es válido')
            setNewEmailButtonStatus(false)
        } else {
            setValidationMessage('')
            setNewEmailButtonStatus(true)
        }
    };

    const handleNewEmail = () => {
        setSelectedEmails([...selectedEmails, { value: newEmail, label: newEmail }]);
        setNewEmailButtonStatus(false)
        setNewEmail('')
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };


    return (
        <>
            <style >{`
                .custom-tag {
                    background-color: #e0e0e0;
                    padding: 2px 4px;
                    border-radius: 3px;
                    font-weight: bold;
                    color: #333;
                    }
                    .ql-tag {
                    width: auto !important;
                    padding: 0 5px;
                    }
                    .ql-tag::after {
                    content: "#";
                    font-size: 18px;
                    }
                `}</style>

            <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
            <KTCard className="overflow-visible">
                {
                    loading ? <></> : <Form className='pt-12 pb-6' onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col sm>
                                    <Form.Group controlId='formProcess'>
                                        <Form.Label><span className={`required`}>Proceso</span></Form.Label>
                                        <Select
                                            value={processSelected}
                                            //defaultValue={...(processList.filter(proc => proc.value.includes(processSelected)))}
                                            name='process'
                                            options={processList}
                                            classNamePrefix='select'
                                            placeholder='Seleccione proceso'
                                            onChange={handleProcessChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Seleccione un proceso.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group controlId='formProcess'>
                                        <Form.Label><span className={`required`}>Unidad</span></Form.Label>
                                        <Select
                                            value={unidadSelected}
                                            name='unidad'
                                            options={unidadList}
                                            classNamePrefix='select'
                                            placeholder='Seleccione proceso'
                                            onChange={handleUnidadChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Seleccione una unidad.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm>
                                    <Form.Group controlId='formEstado'>
                                        <Form.Label><span className={`required`}>Estado</span></Form.Label>
                                        <Select
                                            value={estadoSelected}
                                            name='estado'
                                            options={stateList}
                                            classNamePrefix='select'
                                            placeholder='Seleccione'
                                            onChange={handleEstadoChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Group controlId='formEstado' className="mt-2">
                                        <Form.Label><span className={`required`}>Remitente</span></Form.Label>
                                        <Select
                                            value={selectedRemitente}
                                            name='estado'
                                            options={remitenteList}
                                            classNamePrefix='select'
                                            placeholder='Seleccione'
                                            onChange={handleRemitenteChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={{ span: 1 }}>
                                    <div className="vl2"></div>
                                </Col>
                                <Col>
                                    <Stack direction="vertical">
                                        <Form.Group controlId='formEmail' className="mt-2">
                                            <Form.Label>Remitente en Copia</Form.Label>
                                            <div className="d-flex flex-row align-items-center">
                                                <Form.Control
                                                    type='text'
                                                    value={newEmail}
                                                    placeholder='Nuevo correo'
                                                    name='value'
                                                    className="w-300px h-40px"
                                                    onChange={handleNewEmailInputChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <button
                                                    type='button'
                                                    className='btn btn-success ms-4'
                                                    disabled={!isNewEmailButtonEnable}
                                                    onClick={() => handleNewEmail()}
                                                >
                                                    <KTIcon iconName='plus' className='fs-2' />
                                                </button>
                                            </div>

                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col>
                                    <Stack direction="vertical">
                                        {validationMessage && <div style={{ color: 'red' }}>{validationMessage}</div>}
                                        <Form.Group controlId='formEmail' className="mt-2">
                                            <Form.Label>Correos</Form.Label>
                                            <Select
                                                value={selectedEmails}
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                isMulti
                                                name='emails'
                                                options={processOwners}
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                                placeholder='Seleccione'
                                                onChange={handleEmailsChange}
                                            />
                                            {listProcessValidationError && <p style={{ color: '#B73A47', marginTop: '10px' }}>Seleccione al menos un email.</p>}
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col sm>
                                    <Form.Group controlId='formDescription'>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={description}
                                            disabled={!fieldRequerid}
                                            required
                                            placeholder='Ingrese un nombre'
                                            name='description'
                                            onChange={handleDescriptionChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Escriba un nombre para la alerta.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3' >
                                <Stack direction="vertical">
                                    <h6>Cuerpo</h6>
                                    <Form.Label>Mensaje</Form.Label>

                                    <Stack direction="horizontal">
                                        <Select
                                            styles={customStyles}
                                            isDisabled={!fieldRequerid}
                                            options={fieldList}
                                            onChange={(selectedOption: any) => {
                                                if (selectedOption) {
                                                    insertTag(selectedOption.value);
                                                }
                                            }}
                                            placeholder="Seleccione etiqueta del proceso"
                                            isSearchable
                                        />
                                        <Select
                                            styles={customStyles}
                                            isDisabled={!fieldRequerid}
                                            className="mx-2"
                                            options={etiquetasAdicionales}
                                            onChange={(selectedOption: any) => {
                                                if (selectedOption) {
                                                    insertTag(selectedOption.value);
                                                }
                                            }}
                                            placeholder="Seleccione etiqueta adicional"
                                            isSearchable
                                        />

                                    </Stack>
                                    <ReactQuill
                                        readOnly={!fieldRequerid}
                                        className="mt-5"
                                        ref={quillRef}
                                        value={bodyValue}
                                        onChange={setBodyValue}
                                        onChangeSelection={handleSelectionChange}
                                        theme="snow"
                                        modules={modules}
                                        placeholder="Escriba un mensaje..."
                                        style={{ height: '150px', marginBottom: '20px' }}
                                    />
                                </Stack>
                            </Row>
                            <Row className="mt-13">
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        variant='light'
                                        className='mx-3'
                                        type='reset'
                                        onClick={() => {
                                            goBack()
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button variant='primary' type='submit'>
                                        {!loading && 'Guardar'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Guardando...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </Button>
                                </div>
                            </Row>
                        </Container>
                    </Form>
                }
            </KTCard>
        </>
    )
}

export { AlertEstadoEdit }