import React, { Fragment, useEffect, useState, useContext } from 'react'
import { getFileId, getFileInPDF, getFileSigned, getFilexIdproceso } from '../../../../services/files'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { downloadFileS3, deleteFile } from '../../../../services/files'
import { showDeleteMessage, showMessage } from '../../../../shared/Alerts'
import { useNavigate } from 'react-router-dom'
import { PartnerContext, initialDetDocument } from '../PartnerContexts'
import { deleteDocumentsBulk, getDocumentsByIdUsuarios } from '../../../../services/documentos'
import OverlayLoading from '../../documento/componente/OverlayLoading'
import { ShowFileModal } from '../show-file-modal/ShowFileModal'
import { handleS3Convert64 } from '../../../generales_functions/files'
import * as XLSX from 'xlsx';
import { formatDateToDMY, formatISODateDetalleToDMYHM } from '../../../../helpers/dateFunctions'
import { Col, Row, Stack } from 'react-bootstrap'
import { downloadMultipleDocumentsKeyName, downloadUnicoDocumentsKeyName } from '../../../../services/documentSent'
import ReactJson from 'react-json-view'
import { useAuth2 } from '../../../authv2/Auth2Provider'


export const ListDocuments = ({ id }) => {

  const { setTree, tree, check, listUnidadProcess, listProcess, setShowFileModal, setViewDocumento, searchDocumento, selectIdUnidad, openCargarDocumentModal, setToUsername,setSearchSend, setIndividualData, setDetalleDocument, searchSend, setSelectIdUnidad } = useContext(PartnerContext)
  const [listProcessAux, setListProcessAux] = useState(listProcess)
  const [listUnidadProcessAux, setListUnidadProcessAux] = useState(listUnidadProcess)
  const { selectedCompanyId,userData} = useAuth2()
  const [selecto, setSelecto] = useState(selectIdUnidad)
  const [selecto1, setSelecto1] = useState("h0")
  const [selecto2, setSelecto2] = useState("hh0")
  const [cargandoFile, setCargandoFile] = useState(false);
  const [treeAux, setTreeAux] = useState(tree)
  const [checkAux, setCheckAux] = useState(check)

  useEffect(() => {
    setSelecto(selectIdUnidad)
  }, [selectIdUnidad])

  useEffect(() => {
    searhFilter()
  }, [searchDocumento])

  useEffect(() => {
    setListProcessAux(listProcess)
  }, [listProcess])

  const searhFilter = () =>{
    if(searchDocumento){
      console.log(searchDocumento)
      const newAgrupacion = {};
      const newDocument = listUnidadProcess
      Object.keys(newDocument).map(unid => {
        Object.keys(newDocument[unid]).map((proces, ind0) => {
          Object.keys(newDocument[unid][proces]).map((key, ind) => {
              Object.keys(newDocument[unid][proces][key]).map((key1, ind) => {
                Object.keys(newDocument[unid][proces][key][key1]).map((key2, ind1) => {
                  newDocument[unid][proces][key][key1][key2].map(key3 => {
                    let entro = false
                    if (String(key3.name).toLowerCase().includes(String(searchDocumento).toLowerCase()) || String(key3.origin).toLowerCase().includes(String(searchDocumento).toLowerCase())) {
                      if (!newAgrupacion[unid]) newAgrupacion[unid] = {}
                      if (!newAgrupacion[unid][proces]) newAgrupacion[unid][proces] = {}
                      if (!newAgrupacion[unid][proces][key]) newAgrupacion[unid][proces][key] = {}
                      if (!newAgrupacion[unid][proces][key][key1]) newAgrupacion[unid][proces][key][key1] = {}
                      if (!newAgrupacion[unid][proces][key][key1][key2]) newAgrupacion[unid][proces][key][key1][key2] = [];

                      newAgrupacion[unid][proces][key][key1][key2].push(key3);

                    }
                  })
                })
              })
          })
        })
      })      
      setListUnidadProcessAux(newAgrupacion)
      // setDocumentoaux(fechasAgrupadas)
    }else{
      // setDocumentsAux(documento)
      setListUnidadProcessAux(listUnidadProcess)
    }

  }
  
  const handleCheckChangeProcess = (selectOpion, unid, proces, checkHandle) => {
    let checked = selectOpion.target.checked
    const auxCheck = checkAux
    const newCheck = auxCheck
    const newDocument = listUnidadProcessAux
    Object.keys(newDocument[unid][proces]).map(key => {
      Object.keys(newDocument[unid][proces][key]).map((key1, ind0) => {
        newCheck[checkHandle] = checked;
        Object.keys(newDocument[unid][proces][key][key1]).map((key2, ind1) => {
          newCheck[checkHandle+"_"+key+"_"+key1] = checked;
          CheckChangeFecha(checked, unid, proces, key, key1, checkHandle+"_"+key+"_"+key1)
        })
      })
    })
    setCheckAux(newCheck)
  }

  const CheckChangeFecha = (selectOpion, unid, proces, año, mes, checkHandle) => {
    let checked = selectOpion
    const auxCheck = checkAux
    const newCheck = auxCheck
    const newDocument = listUnidadProcessAux

    Object.keys(newDocument[unid][proces]).map(key => {
      Object.keys(newDocument[unid][proces][key]).map((key1, ind0) => {
        Object.keys(newDocument[unid][proces][key][key1]).map((key2, ind1) => {
          if (String(checkHandle) === String(`${unid}_${proces}_${key}_${key1}`)) {
            newCheck[checkHandle + "_" + key2] = checked;
            CheckChangeUser(checked, key2, checkHandle + "_" + key2)
          } else {
            newCheck[checkHandle + "_" + key2] = auxCheck[checkHandle + "_" + key2];
          }
        })
      })
    })

    setCheckAux(newCheck)
  }

  const handleCheckChangeFecha = (selectOpion, unid, proces, año, mes, checkHandle) => {
    let checked = selectOpion.target.checked
    const auxCheck = checkAux
    const newCheck = auxCheck
    const newDocument = listUnidadProcessAux

    Object.keys(newDocument[unid][proces]).map(key => {
      Object.keys(newDocument[unid][proces][key]).map((key1, ind0) => {
        if (String(`${año}_${mes}`) === String(`${key}_${key1}`)) {
          newCheck[checkHandle] = checked;
        } else {
          newCheck[checkHandle] = auxCheck[checkHandle];
        }
        Object.keys(newDocument[unid][proces][key][key1]).map((key2, ind1) => {
          if (String(checkHandle) === String(`${unid}_${proces}_${key}_${key1}`)) {
            newCheck[checkHandle + "_" + key2] = checked;
            CheckChangeUser(checked, key2, checkHandle + "_" + key2)
          } else {
            newCheck[checkHandle + "_" + key2] = auxCheck[checkHandle + "_" + key2];
          }
        })
      })
    })

    setCheckAux(newCheck)
  }

  const CheckChangeUser = (selected, user, keycheck) => {
    let checked = selected
    const auxCheck = check
    auxCheck[keycheck] = checked
    setCheckAux(auxCheck)
    const newDocument = listUnidadProcessAux
    const newObject = {};
    Object.keys(newDocument).map(unid => {
      newObject[unid] = {};
      Object.keys(newDocument[unid]).map((proc, ind0) => {
        newObject[unid][proc] = {};
        Object.keys(newDocument[unid][proc]).map((key, ind) => {
          newObject[unid][proc][key] = {};
          Object.keys(newDocument[unid][proc][key]).map((key1, ind) => {
            newObject[unid][proc][key][key1] = {};
            Object.keys(newDocument[unid][proc][key][key1]).map((key2, ind1) => {
              newObject[unid][proc][key][key1][key2] = [];
              if (String(key2) === String(user)) {
                newDocument[unid][proc][key][key1][key2].map((key3) => {
                  key3.checkSelected = checked
                  newObject[unid][proc][key][key1][key2].push(key3)
                })
              } else {
                newDocument[unid][proc][key][key1][key2].map((key3) => {
                  newObject[unid][proc][key][key1][key2].push(key3)
                })
              }
            })
          })
        })
      })
    })
    
    setListUnidadProcessAux(newObject)

  }

  const handleCheckChangeUser = (selectOpion, user, keycheck) => {
    let checked = selectOpion.target.checked
    const auxCheck = checkAux
    auxCheck[keycheck] = checked
    setCheckAux(auxCheck)
    const newDocument = listUnidadProcessAux
    const newObject = {};
    Object.keys(newDocument).map(unid => {
      newObject[unid] = {};
      Object.keys(newDocument[unid]).map((proc, ind0) => {
        newObject[unid][proc] = {};
        Object.keys(newDocument[unid][proc]).map((key, ind) => {
          newObject[unid][proc][key] = {};
          Object.keys(newDocument[unid][proc][key]).map((key1, ind) => {
            newObject[unid][proc][key][key1] = {};
            Object.keys(newDocument[unid][proc][key][key1]).map((key2, ind1) => {
              newObject[unid][proc][key][key1][key2] = [];
              if (String(key2) === String(user)) {
                newDocument[unid][proc][key][key1][key2].map((key3) => {
                  key3.checkSelected = checked
                  newObject[unid][proc][key][key1][key2].push(key3)
                })
              } else {
                newDocument[unid][proc][key][key1][key2].map((key3) => {
                  newObject[unid][proc][key][key1][key2].push(key3)
                })
              }
            })
          })
        })
      })
    })
    setListUnidadProcessAux(newObject)

  }

  const handleCheckChangeOne = (selectOpion, _id) => {
    let checked = selectOpion.target.checked
    const newDocument = listUnidadProcessAux
    const newObject = {};
    Object.keys(newDocument).map(unidad => {
      newObject[unidad] = {};
      Object.keys(newDocument[unidad]).map((proce, ind0) => {
        newObject[unidad][proce] = {};
        Object.keys(newDocument[unidad][proce]).map((key, ind) => {
          newObject[unidad][proce][key] = {};
          Object.keys(newDocument[unidad][proce][key]).map((key1, ind) => {
            newObject[unidad][proce][key][key1] = {};
            Object.keys(newDocument[unidad][proce][key][key1]).map((key2, ind1) => {
              newObject[unidad][proce][key][key1][key2] = [];
              newDocument[unidad][proce][key][key1][key2].map((key3) => {
                if (String(key3._id) === String(_id)) {
                  key3.checkSelected = checked
                }
                newObject[unidad][proce][key][key1][key2].push(key3)
              })
            })
          })
        })
      })
    })
    setListUnidadProcessAux(newObject)
    // setDocumentsAux(newObject)
  }


  const onAñadirNewDocument = (username, idproceso, args, unidad, tree) => {
    const { dni, origin } = args
    console.log(origin, dni, idproceso, username, tree)
    setIndividualData({
      dni,
      origin: '',
      idproceso,
      username,
      unidad: unidad === 'SIN UNIDAD' ? '' : unidad,
      tree
    })
    openCargarDocumentModal()
  }


  const handleDownload = async (keyname, name, key3) => {
    setCargandoFile(true)
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1];
    const partesname = name.split('.');
    let filename = ""
    let exist = false
    partesname.map(par => {
      filename = filename + "." + par
      if (String(extension) === String(par)) {
        exist = true
      }
    })

    const empresa = userData.userCompany.find(x => x.id === selectedCompanyId)?.label ?? ''
    const iniciales = empresa.split(' ').map(x => x[0]).join('').toUpperCase()

    if (!exist) {
      filename = `${iniciales}_${key3.dni}_${filename}.${extension}`
    } else {
      filename = `${iniciales}_${key3.dni}_${filename}`
    }

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a');
          link.href = response.data;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
        setCargandoFile(false)
      })
      .catch((error) => {
        console.log(error)
        setCargandoFile(false)
        showMessage('error', 'Hubo un error', error.message)
      })
    // setCargandoFile(false)
  }

  const handleViewModal = async (id, keyname, name, ext, origin) => {
    setCargandoFile(true)
    const newViewDocument = []
    const newInfoDocument = []
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1];
    const partesname = name.split('.');
    let filename = ""


    if (ext === "pdf" || ext === "docx") {
      let exist = false
      partesname.map((par, i) => {
        i == 0 ? filename += filename + String(par)
          : filename = filename + "." + String(par)
        if (String(extension) === String(par)) {
          exist = true
        }
      })
      if (!exist) {
        filename = filename + ".pdf"
      }
      if (origin === "Envio Documentos") {
        await getFileSigned(id, keyname)
          .then((response) => {
            console.log(response)
            if (response.success) {
              newViewDocument.push({
                uri: response.data.document,
                fileType: 'pdf',
                fileName: filename
              })
              response.data.info.historial.map(det => {
                newInfoDocument.push({ ...det, createdAt: formatISODateDetalleToDMYHM(det.createdAt) })
              })

            } else {
              console.log(response)
            }
          })
          .catch((error) => {
            console.log(error)
          })
        setCargandoFile(false)
        setDetalleDocument(newInfoDocument)
        setViewDocumento(newViewDocument)
        setShowFileModal(true)
      } else {
        await getFileInPDF(keyname)
          .then((response) => {
            console.log(response)
            if (response.success) {
              newViewDocument.push({
                uri: response.data,
                fileType: 'pdf',
                fileName: filename
              })
            } else {
              console.log(response)
            }
          })
          .catch((error) => {
            console.log(error)
          })
        setCargandoFile(false)
        setViewDocumento(newViewDocument)
        setShowFileModal(true)
      }

    } else {
      let exist = false
      partesname.map(par => {
        filename = filename + "." + par
        if (String(extension) === String(par)) {
          exist = true
        }
      })
      if (!exist) {
        filename = filename + "." + ext
      }

      await downloadFileS3(keyname, extension)
        .then((response) => {
          if (response.success) {

            newViewDocument.push({
              uri: response.data,
              fileType: ext,
              fileName: filename
            })
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setCargandoFile(false)
        })
        .catch((error) => {
          console.log(error)
        })
      setCargandoFile(false)
      setViewDocumento(newViewDocument)
      setShowFileModal(true)
    }

    /*  if(result){
      
      setShowFileModal(true)
    }else{
    } */

  }


  const handleSelectTree = (key) => {
    const auxTree = treeAux
    auxTree[key] = !auxTree[key]
    setTreeAux(auxTree)
  }

  const handleDelete = (data) => {
    showDeleteMessage()
    .then((result) => {
      if (result.isConfirmed) {
          setSelectIdUnidad('')
          deleteDocumentsBulk(data.fileId)
            .then((response) => {
              if (response.success) {
                setSearchSend(true)
                showMessage('success', '¡Buen trabajo!', response.message)
                  .then(() => {
                    setTree(treeAux)
                    setSelectIdUnidad(selectIdUnidad)
                  })
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
  }

  // useEffect(()=>{
  //   console.log("listProcess")
  //   console.log(listProcess)
  //   console.log("selecto")
  //   console.log(selecto)    
  //   console.log(listUnidadProcessAux)
  // },[selecto])

  return (
    <>
      {!searchSend && listUnidadProcessAux && listProcessAux.length>0 && (
        <>        
        {
          Object.keys(listUnidadProcessAux[selecto]).map(keyProc => (
            listProcessAux.filter((x) => x._id === keyProc) && 
            <Fragment key={keyProc}>
              <tr style={{height :'20px'}}>
                <td>
                  <div className="form-check form-check-custom form-check-solid form-check-sm m-1">
                    <input
                      className={`form-check-input`}
                      type="checkbox"
                      checked={check[`${selecto}_${keyProc}`]}
                      onChange={(event) => handleCheckChangeProcess(event,selecto,keyProc,`${selecto}_${keyProc}`)}
                      id={`flexRadioLg` + keyProc} />
                  </div>
                </td>
                <td className='text-start ' onClick={() => handleSelectTree(`${selecto}_${keyProc}`)}>
                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-0 fs-6'>
                    {
                      tree[`${selecto}_${keyProc}`] ?
                        <i className="bi bi-chevron-up"></i>
                        :
                        <i className="bi bi-chevron-down"></i>
                    }

                  </a>
                  <a href='# ' className='text-dark fw-bold text-hover-primary mx-3 mb-0 fs-6'>
                    {listProcessAux.filter((x) => x._id === keyProc)?.[0]?.nombre}                   </a>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td className='text-end text-muted fw-semibold'>
                </td>
              </tr>
              {
                Object.keys(listUnidadProcessAux[selecto][keyProc]).map(key => (                  
                  Object.keys(listUnidadProcessAux[selecto][keyProc][key]).map(key1 => ( 
                    tree[`${selecto}_${keyProc}`] && 
                    <Fragment key={`${selecto}_${keyProc}_${key}_${key1}`}>
                        <tr className='p-0'>
                          <td className='p-0 m-0'>
                          </td>
                          <td className='text-end'  >
                            <Row>
                              <Col>
                                <Stack direction='horizontal'>
                                  <div className="form-check form-check-custom form-check-solid form-check-sm m-0">
                                    <input
                                      className={`form-check-input `}
                                      type="checkbox"
                                      checked={check[`${selecto}_${keyProc}_${key}_${key1}`]}
                                      onChange={(event) => handleCheckChangeFecha(event,selecto,keyProc, key, key1,`${selecto}_${keyProc}_${key}_${key1}`)}
                                      id={`flexRadioLg` + key} />
                                  </div>
                                  <a href='# ' onClick={() => handleSelectTree(`${selecto}_${keyProc}_${key}_${key1}`)} className='text-dark fw-bold text-hover-primary mb-0 mx-3 fs-6' >
                                    {
                                      tree[`${selecto}_${keyProc}_${key}_${key1}`] ?
                                        <i className="bi bi-chevron-up"></i>
                                        :
                                        <i className="bi bi-chevron-down"></i>
                                    }
                                  </a>
                                  
                                  <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                    {key} - {key1}
                                  </a>
                                </Stack>
                              </Col>
                            </Row>
                          </td>
                          <td className='text-start'>
                          </td>
                          <td>
                          </td>
                          <td className='text-end text-muted fw-semibold'>
                          </td>
                        </tr>
                        {Object.keys(listUnidadProcessAux[selecto][keyProc][key][key1]).map((key2, ind1) => (
                            tree[`${selecto}_${keyProc}_${key}_${key1}`] && 
                            <Fragment key={`${selecto}_${keyProc}_${key}_${key1}_${key2}`}>
                              <tr className='p-0' style={{height :'20px'}}>
                                <td>
                                </td>
                                <td>
                                  <div className="form-check form-check-custom form-check-solid form-check-sm m-0">
                                    <input
                                      className={`form-check-input mx-8`}
                                      type="checkbox"
                                      checked={check[`${selecto}_${keyProc}_${key}_${key1}_${key2}`]}
                                      onChange={(event) => handleCheckChangeUser(event, key2, `${selecto}_${keyProc}_${key}_${key1}_${key2}`)}
                                      id={`flexRadioLg` + key2} />
                                      
                                  <a href='# ' onClick={() => handleSelectTree(`${selecto}_${keyProc}_${key}_${key1}_${key2}`)} className='text-dark fw-bold text-hover-primary mb-0 fs-6 mx-3'>
                                    {
                                      tree[`${selecto}_${keyProc}_${key}_${key1}_${key2}`] ?
                                        <i className="bi bi-chevron-up"></i>
                                        :
                                        <i className="bi bi-chevron-down"></i>
                                    }

                                  </a>
                                  <a href='# ' className='text-dark fw-bold text-hover-primary mx-0 mb-1 fs-6'>
                                    {key2}
                                  </a>

                                  </div>
                                </td>
                                <td className='text-start ' >
                                </td>
                                <td>
                                </td>
                                <td className='text-end text-muted fw-semibold'>
                                  <button
                                    title='añadir documentos'
                                    onClick={() => {
                                      onAñadirNewDocument(
                                        key2,
                                        keyProc,
                                        listUnidadProcessAux[selecto][keyProc][key][key1][key2].find(x => x.loadId)
                                        ?? listUnidadProcessAux[selecto][keyProc][key][key1][key2][0],
                                        selecto,
                                        tree
                                      )
                                    }}
                                    type='button'
                                    className='btn mt-0 btn-light-success btn-sm mr-10'
                                  >
                                    Agregar <KTIcon iconName='plus' className='fs-3 p-0' />
                                  </button>
                                </td>
                              </tr>
                              {listUnidadProcessAux[selecto][keyProc][key][key1][key2].map((key3, ind1) => (
                                tree[`${selecto}_${keyProc}_${key}_${key1}_${key2}`] &&
                                <Fragment key={key3._id}>
                                  <tr className='p-0' style={{height :'25px'}}>
                                    <td></td>
                                    <td>
                                      <div className="form-check form-check-custom form-check-solid form-check-sm m-0" style={{paddingLeft:"40px"}}>
                                        <input
                                          className={`form-check-input`}
                                          checked={key3.checkSelected}
                                          type="checkbox"
                                          onChange={(event) => handleCheckChangeOne(event, key3._id)}
                                          id={`flexRadioLg` + key} />
                                          <a href='# ' className='text-dark fw-bold text-hover-primary' style={{marginLeft:"80px"}}>
                                            {key3.name}
                                          </a>
                                      </div>
                                    </td>
                                    <td className='text-start p-0' >
                                      
                                      {key3.origin}
                                    </td>
                                    <td>
                                      {formatDateToDMY(key3.createdAt)}
                                    </td>
                                    <td>
                                    </td>
                                    <td className='text-end text-muted fw-semibold'>
                                      <div className='d-flex justify-content-end flex-shrink-0'>
                                          {
                                            key3.typeSelected?.toLowerCase() === 'masivo' && (
                                              <>
                                                <button
                                                  onClick={() => handleDelete(key3)}
                                                  title='Eliminar'
                                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-0'
                                                >
                                                  <KTIcon iconName='trash' className='fs-3 p-0' />
                                                </button>
                                              </>
                                            )
                                          }
                                        {
                                          (key3.ext === "pdf" || key3.ext === "docx" ||
                                            key3.ext === "jpg" || key3.ext === "png" ||
                                            key3.ext === "jpeg") && (
                                            <a
                                              href='#'
                                              onClick={() => handleViewModal(key3._id, key3.keyname, key3.name, key3.ext, key3.origin)}
                                              title='Visualizar'
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-0'
                                            >
                                              <KTIcon iconName='eye' className='fs-3 p-0' />
                                            </a>
                                          )
                                        }
                                        <a
                                          href='#'
                                          onClick={() => handleDownload(key3.keyname, key3.name, key3)}
                                          title='Descargar'
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-0'
                                        >
                                          <KTIcon iconName='cloud-download' className='fs-3 p-0' />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </Fragment>

                              ))}
                            </Fragment>
                          ))}
                      
                    </Fragment>
                  ))
                ))
              }
            </Fragment>
          ))}
            
        </>
      )}

      {(searchSend || cargandoFile) && <OverlayLoading />}
      <ShowFileModal />
    </>
  )
}
