import {useContext} from 'react'

import {SheetEditContext} from '../SheetContexts'
import { AssignmentRequeridoModalFormWrapper } from './AssignmentRequeridoModalFormWrapper'
import { AssignmentRequeridoModalHeader } from './AssignmentRequeridoModalHeader'

const AssignmentRequeridoModal = () => {

  const {isAssignmentRequeridoModalOpen} = useContext(SheetEditContext)

  return (
    <>
      {isAssignmentRequeridoModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered mw-400px'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <AssignmentRequeridoModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <AssignmentRequeridoModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {AssignmentRequeridoModal}
