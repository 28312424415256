import React, {Fragment, useEffect, useState,useContext} from 'react'
import { getFileId, getFileInPDF, getFilexIdproceso} from '../../../../services/files'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {downloadFileS3, deleteFile} from '../../../../services/files'
import {showMessage} from '../../../../shared/Alerts'
import {useNavigate} from 'react-router-dom'
import { PartnerContext } from '../PartnerContexts'
import { getDocumentsByIdUsuarios } from '../../../../services/documentos'
import OverlayLoading from '../../documento/componente/OverlayLoading'
import { ShowFileModal } from '../show-file-modal/ShowFileModal'
import { handleS3Convert64 } from '../../../generales_functions/files'
import * as XLSX from 'xlsx';
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { Col, Row, Stack } from 'react-bootstrap'
import { downloadMultipleDocumentsKeyName, downloadUnicoDocumentsKeyName } from '../../../../services/documentSent'


export const ListDocuments = ({id}) => {
  
  const { setDocuments, setDocumentsAux,setShowFileModal,setViewDocumento,searchDocumento,selectIdProceso,documentsAux, documents, processSelected ,searchTerm,entryDateFrom,
    entryDateTo, searchSend,setSearchSend,listProcessAll,setListProcess, openCargarDocumentModal} = useContext(PartnerContext)
  const [documento, setDocumento] = useState('')
  const [documentoaux, setDocumentoaux] = useState(documentsAux)
  const [selecto, setSelecto] = useState(selectIdProceso)
  const [selecto1, setSelecto1] = useState("h0")
  const [selecto2, setSelecto2] = useState("hh0")
  const [cargandoFile, setCargandoFile] = useState(false);
  const [tree, setTree] = useState({})
  const [check, setCheck] = useState({})

  
  

  useEffect(() => {
    refetchData()
  }, [])

  
  useEffect(() => {
    setSelecto(selectIdProceso)
  }, [selectIdProceso])
  

  useEffect(() => {
    setDocumento(documents)
  }, [documents])

  useEffect(() => {
    searhFilter()
  }, [searchDocumento])

  useEffect(() => {
    if(searchSend){
      setSearchSend(false)
      refetchData()
    }
  }, [searchSend])

  const handleSelecto1 = (opt: any) => {
    if (String(opt) === String(selecto1)) {
      setSelecto1("i0")
    } else {
      setSelecto1(opt)
    }
  }

  const handleSelecto2 = (opt: any) => {
    if (String(opt) === String(selecto2)) {
      setSelecto2("ii0")
    } else {
      setSelecto2(opt)
    }
  }
  const searhFilter = () =>{
    if(searchDocumento){
      const fechasAgrupadas: { [año: number]: { [mes: string]:{ [id_proceso: string]: { [user: string]: any[] }} } } = {};
      Object.keys(documento).map(key => {
        Object.keys(documento[key]).map((key1,ind0) => {            
          Object.keys(documento[key][key1]).map((key_idproceso,ind) => {
            if(String(key_idproceso) === String(selecto)){
              Object.keys(documento[key][key1][selecto]).map((key2,ind1) => {                
                documento[key][key1][selecto][key2].map(key3 => {
                  let entro = false                  
                  if(String(key3.name).toLowerCase().includes(String(searchDocumento).toLowerCase()) || String(key3.origin).toLowerCase().includes(String(searchDocumento).toLowerCase())){
                    if (!fechasAgrupadas[key]) {
                      fechasAgrupadas[key] = {};
                    }
                  
                    // Agregar la fecha al objeto de agrupación
                    if (!fechasAgrupadas[key][key1]) {
                      fechasAgrupadas[key][key1] = {};
                    }
                    if (!fechasAgrupadas[key][key1][key_idproceso]) {
                      fechasAgrupadas[key][key1][key_idproceso] = {}
                    }
                    if (!fechasAgrupadas[key][key1][key_idproceso][key2]) {
                      fechasAgrupadas[key][key1][key_idproceso][key2] = [];
                    }
                    fechasAgrupadas[key][key1][key_idproceso][key2].push(key3);

                  }
                })
              })
            }
          })
        })
      })
      
      setDocumentsAux(fechasAgrupadas)
      setDocumentoaux(fechasAgrupadas)
    }else{
      setDocumentsAux(documento)
      setDocumentoaux(documento)
    }

  }

  
  const refetchData = () => {
    if((entryDateFrom !== '' && entryDateTo !== '') || searchTerm.length > 3){
      const option = {
        fec_ini:entryDateFrom,
        fec_fin:entryDateTo,
        id_proceso:processSelected,
        user_dni_nombre:searchTerm
      }
      
      const processAux = []
      getDocumentsByIdUsuarios(option)
        .then((response) => {
          if (response.success) {
            const newObject = {};
            const newObjectArbol = {};
            const newObjectCheck = {};
            Object.keys(response?.data).map(key => {
              newObject[key] = {};
              Object.keys(response?.data[key]).map((key1,ind0) => { 
                newObjectArbol[key+"_"+key1] = false; 
                newObjectCheck[key+"_"+key1] = false; 
                
                newObject[key][key1] = {};          
                Object.keys(response?.data[key][key1]).map((key_idproceso,ind) => {
                  listProcessAll.map(procAux => {
                    if(String(key_idproceso) === String(procAux._id)){
                      processAux.push(procAux)
                    }                    
                  })

                  newObject[key][key1][key_idproceso] = {};
                  Object.keys(response?.data[key][key1][key_idproceso]).map((key2,ind1) => {
                    newObjectArbol[key+"_"+key1+"_"+key_idproceso+"_"+key2] = false;
                    newObjectCheck[key+"_"+key1+"_"+key_idproceso+"_"+key2] = false;
                     
                    newObject[key][key1][key_idproceso][key2] = []; 
                    response?.data[key][key1][key_idproceso][key2].map((key3) => {
                      key3.checkSelected = false
                      newObject[key][key1][key_idproceso][key2].push(key3)
                    })
                  })
                  
                  
                })
              })
            })
            const resultado = processAux.filter((obj, index, self) =>
              self.findIndex((o) => o._id === obj._id) === index
            );
            setListProcess(resultado)
            setTree(newObjectArbol)
            setCheck(newObjectCheck)
            
            setDocumentoaux(response?.data)
            setDocumentsAux(response?.data)
            setDocuments(response?.data)
          } else {
            console.log(response.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })

    }else{
      console.log ("entryDateFrom")
      console.log (entryDateFrom)
      console.log (entryDateTo)
      console.log (searchTerm.length)
      if((entryDateFrom === '' && entryDateTo === '') && searchTerm.length <= 3){
        showMessage('info', 'Hubo un inconveniente', "Seleccione rango de fecha o buscar por colaborador")
      }else if(entryDateFrom === '' && entryDateTo === ''){
        showMessage('info', 'Hubo un inconveniente', "Seleccione rango de fecha")
      }else if(searchTerm.length <= 3){
        showMessage('info', 'Hubo un inconveniente', "Excribir por lo menos 4 letras para buscar por colaborador")
      }
    }
  }
  
  const handleCheckChangeFecha = (selectOpion,año,mes,idproceso) => { 
    let checked = selectOpion.target.checked
    const auxCheck = check
    const newCheck = {}
    const newDocument  = documentoaux
    Object.keys(newDocument).map(key => {
      Object.keys(newDocument[key]).map((key1,ind0) => {  
        if(String(`${año}_${mes}`) === String(`${key}_${key1}`)){
          newCheck[key+"_"+key1] = checked;           
        }else{
          newCheck[key+"_"+key1] = auxCheck[key+"_"+key1]; 
        }
        Object.keys(newDocument[key][key1]).map((key_idproceso,ind) => {
          Object.keys(newDocument[key][key1][key_idproceso]).map((key2,ind1) => {
            if(String(`${año}_${mes}_${idproceso}`) === String(`${key}_${key1}_${key_idproceso}`)){  
              newCheck[key+"_"+key1+"_"+key_idproceso+"_"+key2] = checked;
              CheckChangeUser(checked,key2,key+"_"+key1+"_"+key_idproceso+"_"+key2)
            }else{
              newCheck[key+"_"+key1+"_"+key_idproceso+"_"+key2] = auxCheck[key+"_"+key1+"_"+key_idproceso+"_"+key2]; 
            }
          })
        })
      })
    })

    setCheck(newCheck)    
  }

  const CheckChangeUser = (selected,user,keycheck) => { 
    let checked = selected
    const auxCheck = check
    auxCheck[keycheck] = checked
    setCheck(auxCheck)
    const newDocument  = documentoaux 
    const newObject = {};
    Object.keys(newDocument).map(key => {
      newObject[key] = {};
      Object.keys(newDocument[key]).map((key1,ind0) => {  
        newObject[key][key1] = {};          
        Object.keys(newDocument[key][key1]).map((key_idproceso,ind) => {
          newObject[key][key1][key_idproceso] = {};
          Object.keys(newDocument[key][key1][key_idproceso]).map((key2,ind1) => { 
            newObject[key][key1][key_idproceso][key2] = []; 
            if(String(key2) === String(user)){
              newDocument[key][key1][key_idproceso][key2].map((key3) => {
                  key3.checkSelected = checked
                newObject[key][key1][key_idproceso][key2].push(key3)
              })
            }else{
              newDocument[key][key1][key_idproceso][key2].map((key3) => {
                newObject[key][key1][key_idproceso][key2].push(key3)
              })
            }
          })
        })
      })
    })
    setDocumentoaux(newObject)
    setDocumentsAux(newObject)
    
  }

  const handleCheckChangeUser = (selectOpion,user,keycheck) => { 
    let checked = selectOpion.target.checked
    const auxCheck = check
    auxCheck[keycheck] = checked
    setCheck(auxCheck)
    const newDocument  = documentoaux 
    const newObject = {};
    Object.keys(newDocument).map(key => {
      newObject[key] = {};
      Object.keys(newDocument[key]).map((key1,ind0) => {  
        newObject[key][key1] = {};          
        Object.keys(newDocument[key][key1]).map((key_idproceso,ind) => {
          newObject[key][key1][key_idproceso] = {};
          Object.keys(newDocument[key][key1][key_idproceso]).map((key2,ind1) => { 
            newObject[key][key1][key_idproceso][key2] = []; 
            if(String(key2) === String(user)){
              newDocument[key][key1][key_idproceso][key2].map((key3) => {
                  key3.checkSelected = checked
                newObject[key][key1][key_idproceso][key2].push(key3)
              })
            }else{
              newDocument[key][key1][key_idproceso][key2].map((key3) => {
                newObject[key][key1][key_idproceso][key2].push(key3)
              })
            }
          })
        })
      })
    })
    setDocumentoaux(newObject)
    setDocumentsAux(newObject)
    
  }

  const handleCheckChangeOne = (selectOpion,_id) => { 
    let checked = selectOpion.target.checked
    const newDocument  = documentoaux 
    const newObject = {};
    Object.keys(newDocument).map(key => {
      newObject[key] = {};
      Object.keys(newDocument[key]).map((key1,ind0) => {  
        newObject[key][key1] = {};          
        Object.keys(newDocument[key][key1]).map((key_idproceso,ind) => {
          newObject[key][key1][key_idproceso] = {};
          Object.keys(newDocument[key][key1][key_idproceso]).map((key2,ind1) => { 
            newObject[key][key1][key_idproceso][key2] = []; 
            newDocument[key][key1][key_idproceso][key2].map((key3) => {
              if(String(key3._id) === String(_id)){
                key3.checkSelected = checked
              }
              newObject[key][key1][key_idproceso][key2].push(key3)
            })
          })
        })
      })
    })
    setDocumentoaux(newObject)
    setDocumentsAux(newObject)
  }


  const onAñadirNewDocument = () => {    
    openCargarDocumentModal()
  }


  const handleDownload = async (keyname,name) => { 
    setCargandoFile(true)   
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1]; 
    const partesname = name.split('.');
    let filename = ""
    let exist = false
    partesname.map(par => {
      filename = filename + "."+par
      if(String(extension) === String(par)){
        exist = true
      }
    })
    if(!exist) {
      filename = filename+"."+extension
    }

    downloadFileS3(keyname,extension)
        .then((response) => {
          if (response.success) {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showMessage('success', '¡Buen trabajo!', response.message)
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setCargandoFile(false)
        })
        .catch((error) => {
          console.log(error)
          setCargandoFile(false)
          showMessage('error', 'Hubo un error', error.message)
        })
  }

  const handleViewModal = async (id,keyname,name,ext) => {
    setCargandoFile(true)
    const newViewDocument = []
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1]; 
    const partesname = name.split('.');
    let filename = ""
    

    if(ext === "pdf" || ext === "docx"){
      let exist = false
      partesname.map((par,i) => {        
        i==0?filename += filename +String(par)
        : filename = filename + "."+String(par) 
        if(String(extension) === String(par)){
          exist = true
        }
      })
      if(!exist) {
        filename = filename+".pdf"
      }

      await getFileInPDF(keyname)
        .then((response) => {
            console.log(response)
            if (response.success) {
                newViewDocument.push({ 
                  uri: response.data,
                  fileType:'pdf',
                  fileName:filename
              })
            } else {
            console.log(response)
            }
        })
        .catch((error) => {
            console.log(error)
        })
        setCargandoFile(false)
        setViewDocumento(newViewDocument)
        setShowFileModal(true)
    }else {      
      let exist = false
      partesname.map(par => {
        filename = filename + "."+par
        if(String(extension) === String(par)){
          exist = true
        }
      })
      if(!exist) {
        filename = filename+"."+ext
      }

      await downloadFileS3(keyname,extension)
        .then((response) => {
          if (response.success) {
            
            newViewDocument.push({ 
              uri: response.data,
              fileType:ext,
              fileName:filename
            })
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setCargandoFile(false)
        })
        .catch((error) => {
          console.log(error)
        })
        setCargandoFile(false)
        setViewDocumento(newViewDocument)
        setShowFileModal(true)
    }
    
    /*  if(result){
      
      setShowFileModal(true)
    }else{
    } */

  }

  
  const handleSelectTree = (key)=>{
    const auxTree = tree
    auxTree[key] = !auxTree[key]
    setTree(auxTree) 
  }

  const handleDelete = (id)=>{
    deleteFile(id)
        .then((response) => {
            if (response.success) {
              refetchData()
              showMessage('success', '¡Buen trabajo!', response.message)
            }

        })
        .catch((error) => {
            console.log(error)
        })
  }


  return (
    <>
      {documentoaux && (
        <>
        {Object.keys(documentoaux).map(key => (
          Object.keys(documentoaux[key]).map((key1,ind0) => (            
            Object.keys(documentoaux[key][key1]).map((key_idproceso,ind) => (
              String(key_idproceso) === String(selecto) && (
                <Fragment key={key}>
                  <tr >
                    <td  className='text-end'  >
                      <Row>
                        <Col>
                          <Stack direction='horizontal'>
                            <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                              <input 
                              className={`form-check-input `}
                              type="checkbox"
                              checked={check[`${key}_${key1}`]}
                              onChange={(event) => handleCheckChangeFecha(event, key,key1,selecto)}
                              id={`flexRadioLg`+key}/>
                            </div>
                            <a href='# ' onClick={() => handleSelectTree(`${key}_${key1}`)} className='text-dark fw-bold text-hover-primary mb-1 mx-2 fs-6' >
                              {
                                tree[`${key}_${key1}`] ? 
                                  <i className="bi bi-chevron-up"></i>                            
                                :
                                  <i className="bi bi-chevron-down"></i>
                              }
                            </a>
                            
                          </Stack>
                        </Col>
                      </Row>
                    </td>
                    <td className='text-start'>                    
                      <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {key} - {key1}
                      </a>
                    </td>
                    <td>
                    </td>
                    <td>
                    </td>
                    <td className='text-end text-muted fw-semibold'>  
                    </td>
                  </tr>
                  
                  {Object.keys(documentoaux[key][key1][selecto]).map((key2,ind1) => ( 
                    tree[`${key}_${key1}`] && <Fragment key={key2}>
                      <tr >
                        <td>
                          <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                            <input 
                            className={`form-check-input`}
                            type="checkbox"
                            checked={check[`${key}_${key1}_${key_idproceso}_${key2}`]}
                            onChange={(event) => handleCheckChangeUser(event, key2,`${key}_${key1}_${key_idproceso}_${key2}`)}
                            id={`flexRadioLg`+key2}/>
                          </div>
                        </td>
                        <td className='text-start ' onClick={() => handleSelectTree(`${key}_${key1}_${key_idproceso}_${key2}`)}> 
                          <a href='# ' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {
                              tree[`${key}_${key1}_${key_idproceso}_${key2}`] ? 
                                <i className="bi bi-chevron-up"></i>                            
                              :
                                <i className="bi bi-chevron-down"></i>
                            }
                            
                          </a>
                          <a href='# ' className='text-dark fw-bold text-hover-primary mx-7 mb-1 fs-6'>
                            {key2}
                          </a>     
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td className='text-end text-muted fw-semibold'>
                          <button title='hacer click para añadir esta Sección' onClick={onAñadirNewDocument} type="button" className="btn mt-2 btn-light-success btn-sm mr-10" >
                            Agregar <KTIcon iconName='plus' className='fs-3' />
                          </button>
                        </td>
                      </tr>
                      {documentoaux[key][key1][selecto][key2].map((key3,ind3) => (
                        tree[`${key}_${key1}_${selecto}_${key2}`] && 
                        <Fragment key={key3._id}>
                          <tr >
                            <td>
                              <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                                <input 
                                className={`form-check-input`}
                                checked={key3.checkSelected}
                                type="checkbox"
                                onChange={(event) => handleCheckChangeOne(event,key3._id)}
                                id={`flexRadioLg`+key}/>
                              </div>
                            </td>
                            <td className='text-start px-6 pa-0'> 
                                {key3.name}
                            </td>
                            <td>
                              {key3.origin}
                            </td>
                            <td>
                            {formatDateToDMY(key3.createdAt)}
                            </td>
                            <td className='text-end text-muted fw-semibold'>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {
                                (key3.ext === "pdf" || key3.ext === "docx" || 
                                key3.ext === "jpg" || key3.ext === "png" || 
                                key3.ext === "jpeg") && (
                                  <a
                                    href='#'
                                    onClick={() => handleViewModal(key3._id,key3.keyname,key3.name,key3.ext)}
                                    title='Visualizar'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTIcon iconName='eye' className='fs-3' />
                                  </a>
                                ) 
                              }
                              <a
                                href='#'
                                onClick={() => handleDownload(key3.keyname,key3.name)}
                                title='Descargar'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTIcon iconName='cloud-download' className='fs-3' />
                              </a>
                              </div>  
                            </td>
                          </tr>
                        </Fragment>
                        
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
            
            ))
            
            


            ))
          ))
        )}

          
        </>
      )}
      
      {cargandoFile && <OverlayLoading />}
        <ShowFileModal />
    </>
  )
}
