import {useContext, useEffect, useState} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {getDocumentsByFile} from '../../../../../services/documentSent'
import {DocumentLoadDetailContext} from '../DocumentLoadDetailContext'
import PDFViewer from './PDFViewer'

const DocumentQuickViewModalForm = () => {
  const {closeDocumentQuickViewModal, quickViewModalData} = useContext(DocumentLoadDetailContext)
  const [documentList, setDocumentList] = useState<any>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const isSingleDocument = documentList.length === 1
  const {id} = useParams()
  console.log('quickViewModalData')
  console.log(quickViewModalData)
  useEffect(() => {
    getDocuments({
      processId: quickViewModalData.idproceso,
      loadId: id,
      fileId: quickViewModalData._id,
    })
    /* if (quickViewModalData.type === 'PLANTILLA') {
      console.log(quickViewModalData, id);
      
    } */

    return () => {}
  }, [])

  const getDocuments = (params: any) => {
    getDocumentsByFile(params)
      .then((result) => {
        setDocumentList(result.data ?? [])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div>
      <Container>
        <Row className='mb-5'>
          <Col sm>
            <div className='d-flex justify-content-between'>
              <div className='d-flex align-items-end'>
                {documentList.length > 0 && <h5>{quickViewModalData.name}</h5>}
              </div>
              {
                /* quickViewModalData.type === 'PDF' && documentList.length > 0 ? <></> :  */
                documentList.length > 0 && <h6>{documentList?.[currentIndex]?.username}</h6>
              }
            </div>
          </Col>
        </Row>

        {documentList.length > 0 ? (
          <Row className='mb-3'>
            <Col sm>
              {
                /* quickViewModalData.type === 'PDF' ? <></> :  */
                <div className='d-flex justify-content-start mb-5'>
                  <Button
                    variant='primary'
                    className='mx-3'
                    type='button'
                    onClick={() => {
                      if (currentIndex > 0) {
                        setCurrentIndex(currentIndex - 1)
                      }
                    }}
                    disabled={isSingleDocument || currentIndex === 0}
                  >
                    Anterior
                  </Button>
                  <Button
                    variant='primary'
                    type='button'
                    onClick={() => {
                      if (currentIndex < documentList.length - 1) {
                        setCurrentIndex(currentIndex + 1)
                      }
                    }}
                    disabled={isSingleDocument || currentIndex === documentList.length - 1}
                  >
                    Siguiente
                  </Button>
                </div>
              }
              {documentList[currentIndex] && (
                <PDFViewer
                  key={documentList[currentIndex]?._id}  
                  keyn={documentList[currentIndex]?.keyname}
                  idn={documentList[currentIndex]?._id}
                  extra={{
                    showSignature: documentList[currentIndex]?.state === 'pending',
                    signCeo: documentList[currentIndex]?.includeSign,
                    idUserSign: documentList[currentIndex]?.signMetadata?.signFrom,
                  }}
                  statepending={documentList[currentIndex]?.state === 'pending'}
                />
              )}

              {/* {quickViewModalData.type === 'PDF'? 
                  <PDFViewer keyn={quickViewModalData.keyname} idn={''} /> 
                  :
                  <PDFViewer keyn={documentList[currentIndex]?.keyname} idn={documentList[currentIndex]?._id} /> 
                  } */}
            </Col>
          </Row>
        ) : (
          <Row className='mb-3'>
            <Col>
              <h2>
                NO HAY DOCMENTOS QUE MOSTRAR, ES POSIBLE QUE SE HAIGAN DESHABILITADO LOS DOCUMENTOS
                PARA TODOS LOS USUARIOS
              </h2>
            </Col>
          </Row>
        )}

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              type='reset'
              onClick={() => {
                closeDocumentQuickViewModal()
              }}
            >
              Cerrar
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export {DocumentQuickViewModalForm}
