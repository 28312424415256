import { Tabs, Tab } from "react-bootstrap"
import { DocumentTrayCustomizableTable } from "./DocumentTrayCustomizableTable"
import { useState } from "react"

const DocumentTrayTabs = () => {

    const [reloadKey, setReloadKey] = useState(0);

    const handleChange = () => {
        setReloadKey(reloadKey + 1)
    }

    return (
        <>
            <Tabs
                defaultActiveKey="pending"
                className="mb-3 custom-tabs"
            >
                <Tab eventKey="pending" title="Documentos Pendientes">
                    <DocumentTrayCustomizableTable key={`pending-${reloadKey}`}  state='pending' onChange={handleChange}/>
                </Tab>
                <Tab eventKey="signed" title="Documentos Firmados">
                    <DocumentTrayCustomizableTable key={`signed-${reloadKey}`} state='signed' onChange={handleChange}/>
                </Tab>
                <Tab eventKey="rejected" title="Documentos Rechazados">
                    <DocumentTrayCustomizableTable key={`rejected-${reloadKey}`} state='rejected' onChange={handleChange}/>
                </Tab>

            </Tabs>
        </>
    )
}

export { DocumentTrayTabs }