import { useContext, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getProcessUsersConUnidad } from '../../../../services/procesos';
import { PartnerContext } from '../PartnerContexts';
import '../component/style.css';
import { ShowCargaExcelModal } from '../show-carga_excel-modal/ShowCargaExcelModal';
import { PartnersTableGeneral } from './../../../generales_component/PartnersTableGeneral';


const ShowUserModalForm = () => {
  const {idProcess,setListUsers, listUsers, dataDni, listDocuments, setShowCargaExcelModal, 
        setShowSelectUserModal,listDocumentsPersonalizado,unidad,setUnidad, setListDocuments} = useContext(PartnerContext)
  const [bodyUser, setBodyUser] = useState<any[]>([])
  const [bodyUserSelect, setBodyUserSelect] = useState<any[]>([])
  const [visible, setVisible] = useState(false);

  useEffect(()=>{
    getUserProcess()
  },[])

  useEffect(()=>{    
    refreshListUsers()
  },[bodyUser])

  useEffect(()=>{
    setSelectUsersList()
  },[dataDni])
  
  const setSelectUsersList = () => {
    console.log(dataDni)
    const arrayNew1 : any[] = []
    const arrayNew2 : any[] = []
    bodyUser.map(user => { 
      let entro = false
      dataDni.map(dni => {
        if(user['dni'] === dni){
          entro = true
        }
      })

      if(entro){
        arrayNew2.push({...user,countDocument:listDocuments.length})
      }else{
        arrayNew1.push(user)
      }
    })    
    setBodyUser(arrayNew1)
    setBodyUserSelect([ ...arrayNew2, ...bodyUserSelect ])

  }

  const getUserProcess = () => {
    getProcessUsersConUnidad(idProcess,unidad)
      .then((response) => {
        if (response.success) {
          if(listUsers.length > 0){
            const filteredArray = response.data.filter((item1:any) => !listUsers.some((item2:any) => item1._id === item2._id));
            setBodyUser(filteredArray)
            setBodyUserSelect(listUsers)
          }else{
            setBodyUser(response.data)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const headerUser = [
    {
      type: 'string',
      field: 'dni',
      label: 'DNI',
    },
    {
      type: 'string',
      field: 'nombre',
      label: 'Nombre',
    },
    {
      type: 'date',
      field: 'createdAt',
      label: 'F.Creación',
    },
    {
      type: 'check',
      field: 'selected',
      label: "Todos",
    }
  ]

  const handleCheckSelected =  (_ids: string[], array1:any[], array2:any[], NormalFlow:boolean ) => {
    const arrayNew1 : any[] = []
    const arrayNew2 : any[] = []
    
    if(NormalFlow){
      array1.map(user => {
        let entro = false
        _ids.map(id => {
          if(user['_id'] === id){
            entro = true
          }
        })
        if(entro){
          arrayNew2.push({...user,countDocument:listDocuments.length})
        }else{
          arrayNew1.push(user)
        }
      })
      setBodyUser(arrayNew1)
      setBodyUserSelect([ ...arrayNew2, ...array2 ])
    }else{
      array2.map(user => {
        let entro = false
        _ids.map(id => {
          if(user['_id'] === id){
            entro = true
          }
        })
        if(entro){
          arrayNew1.push(user)
        }else{
          arrayNew2.push(user)
        }
      })
      setBodyUserSelect(arrayNew2)
      setBodyUser([...arrayNew1, ...array1 ])
    }
  };

  const donwloadPlantilla =  () => {
    const link = document.createElement('a');
    link.style.display = 'none'; 
    link.href = require("./../../../../../assets/Plantilla_Seleccionar_Usuario.xlsx");
    link.download = "Plantilla_Seleccionar_Usuario.xlsx";
    document.body.appendChild(link);
    link.click();                
    document.body.removeChild(link);
  }

  const refreshListUsers = () => {
    // count Configured Document
    let newListUsers = bodyUserSelect.map((user:any) => {
      const ListDocuAux = listDocuments.filter(doc => doc.typeSelected === 'Configurado')      
      return {...user,countDocument:ListDocuAux.length}
    })

    // count Personalizado Document
    if(listDocumentsPersonalizado.children.length>0){
      newListUsers = newListUsers.map(user => {
        let cont = 0 
        listDocumentsPersonalizado.children.map(docPer => {
          if(user.dni === docPer.dni){
            cont++
          }
        })
        return {...user,countDocument:user.countDocument + cont}
      })
    }
    setBodyUserSelect(newListUsers)
  }

  const onAddUsers = () => {
    setListUsers(bodyUserSelect)
    setShowSelectUserModal(false)
    const userDnis = bodyUserSelect.map(user => user.dni)
    console.log(userDnis)
    const withUsersSelected = listDocuments.map(item => {
      if(item.tags) {
        const tags = item.tags.filter((tag: any) => userDnis.includes(tag.user))
        return {
          ...item,
          tags: tags.length > 0 ? tags : null
        }
      }

      return item
    })

    setListDocuments(withUsersSelected)
  }
    
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option:any) => {
    setSelectedOption(option);
  };


  return (
    <>
          <Row className='mb-3 pt-3 px-3' >
              <Row>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-start'>
                      <Form.Label >Selecciona los usuarios que requieres enviarle documentación</Form.Label>
                    </div>
                    <div className='d-flex align-items-end'>                      
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                          Carga masiva de usuarios
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={()=>{ donwloadPlantilla() }} eventKey="option1">Descargar Plantilla</Dropdown.Item>
                          <Dropdown.Item onClick={()=>{ setShowCargaExcelModal(true) }} eventKey="option2">Cargar Plantilla</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>
                  </div>

                </Col>
              </Row>
              <Row>
                <Col>
                  <PartnersTableGeneral title={"Seleccionar Usuarios"} theader={headerUser} tbody={bodyUser} pagination={false} searchVisible={true}  searchHint={"Buscar Usuarios ..."} checksAll={true} onCheckSelected={(_id:string[]) => handleCheckSelected(_id,bodyUser,bodyUserSelect,true)} />
                </Col>
                <Col>
                  <PartnersTableGeneral title={"Usuarios Seleccionados"} theader={headerUser} tbody={bodyUserSelect} pagination={false} searchVisible={true}  searchHint={"Buscar Usuarios ..."}  checksAll={true} onCheckSelected={(_id:string[]) => handleCheckSelected(_id,bodyUser,bodyUserSelect,false)} />
                </Col>
              </Row>
              <Row>
              {/* <Dropdown id={"idKey12"} >
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu className='fs-7'>
                  <Dropdown.Item onClick={()=>{ donwloadPlantilla() }} >Descargar Plantilla</Dropdown.Item>
                  <Dropdown.Item onClick={()=>{ setShowCargaExcelModal(true) }} > Cargar Plantilla</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}

                <div className='d-flex justify-content-end'>
                  <Button
                    variant='light'
                    className='mx-3'
                    onClick={()=>{setShowSelectUserModal(false)}}
                    type='reset'
                  >
                    Cerrar
                  </Button>
                  <Button 
                  variant='primary' 
                  onClick={onAddUsers}
                  type='button'>
                    Aceptar
                  </Button>
                </div>
              </Row>
          </Row>
        < ShowCargaExcelModal />
    </>
  )
}

export { ShowUserModalForm };

