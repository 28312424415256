import { useMemo, useState, useContext, useEffect, Fragment, FC } from 'react'
import { Form, Stack } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../../_metronic/helpers'
import {
  showDeleteMessage,
  showMessage,
  showReactivationMessage,
} from '../../../../../shared/Alerts'
import { UsersListLoading } from '../../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../../apps/user-management/users-list/table/columns/_columns'
import Pagination from '../../../../../shared/Pagination'
import { deletePartner, updatePartner } from '../../../../../services/colaboradores'
import { PartnerContext } from '../../PartnerContexts'
import React from 'react'
import { activarUser, deleteUser, getAllUsersPag } from '../../../../../services/users'
import { activarVista, deleteVista, getAllVistasPag } from '../../../../../services/vista'
import { getAllCompanies, getAllCompaniesByPais } from '../../../../../services/companies'
import { PartnersTableGeneral } from '../../../../generales_component/PartnersTableGeneral'

const headerDataApi1 = [
  {
    type: 'check',
    field: 'check_selected',
    label: "Seleccionar",
  },
  {
    type: 'string',
    field: 'id',
    label: 'Cod',
  },
  {
    type: 'string',
    field: 'name',
    label: 'Nombre',
  }
]

interface MyComponentProps {
  tbody:{[key: string]: any;}[];
  onCheckSelect: (Ids: string[]) => void;
}

const AsignacionTablaTable1 :FC<MyComponentProps> = (props) => {
  const {tbody,onCheckSelect} = props
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableBodyProps } = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [listDataApi1, setListDataApi1] = useState<any[]>(tbody)
  const [checkedItemApi1, setCheckedItemApi1] = useState<any[]>([]);
  const { searchTerm, setVistaData, setEdit, reloadTable, setReloadTable} = useContext(PartnerContext)

  useEffect(() => {
    actualizarFiles()
  }, [checkedItemApi1]);

  useEffect(() => {
    setCheckedItemApi1([])
    setListDataApi1(tbody)
  }, [tbody]);
  
  useEffect(() => {
    actualizarFiles()
  }, [checkedItemApi1]);
  
  const actualizarFiles  = () => {
    const newArray : any[]= listDataApi1.map((r:any) => {
      let exist = false
      checkedItemApi1.map(_id => { 
        if(String(r._id) === String(_id)){
          exist = true;
        }
      })
      if(exist){
        return {...r , check_selected:true }
      }else{
        return {...r , check_selected:false }
      }      
    })
    setListDataApi1(newArray)
  }

  const handleCheckSelectedApi =  (_ids: string[],check? :boolean ) => {
    const arraySelected : string[] = []
    checkedItemApi1.map(id => {
      arraySelected.push(id)
    })

    if(_ids.length === 1){
      if(arraySelected.includes(_ids[0])){
        const indiceAEliminar = arraySelected.indexOf(_ids[0]);
        arraySelected.splice(indiceAEliminar, 1);
        setCheckedItemApi1(arraySelected)
        onCheckSelect(arraySelected)
      }else{
        arraySelected.push(_ids[0])
        setCheckedItemApi1(arraySelected)
        onCheckSelect(arraySelected)
      }
    }else{
      const arrayBody : any[] = []
      if(check){
        setCheckedItemApi1(_ids)
        onCheckSelect(_ids)
      }else{
        setCheckedItemApi1([])
        onCheckSelect(_ids)
      }
    }
  }


  return (
    <>
      <KTCardBody className='py-4'>
          <PartnersTableGeneral 
            key={"table_general1"}
            title={"Datos Unidad Api"} 
            searchVisible={true}
            searchCol={8}
            searchHint={"Buscar Unidad ..."}
            theaderVisible={true} 
            theader={headerDataApi1}
            tbody={listDataApi1}
            tMaxHeight={"600px"}
            onCheckSelected={(_id:string[],check?:boolean) => handleCheckSelectedApi(_id,check)}
            classTable='table-rounded table-striped border' />
      </KTCardBody>
    </>
  )
}

export { AsignacionTablaTable1 }
