import { Fragment, useContext, useEffect, useState } from "react"
import { Button, Col, Dropdown, Form, OverlayTrigger, Row, Stack, Tooltip } from "react-bootstrap"
import { KTCard, KTCardBody, KTIcon } from "../../../../../_metronic/helpers"

import { getAllFindTabla } from "../../../../services/tablas"
import { asignarEmpresas, asignarUnidadByEmpresa, desasignarEmpresas, desasignarUnidadByEmpresa, getAllCompaniesByPais, getUnidadesByEmpresaAsignadas, getUnidadesEmpresas } from "../../../../services/companies"
import { showMessage } from "../../../../shared/Alerts"
import { AsignacionTablaTable1 } from "./Proceso/AsignacionTablaTable1"
import { AsignacionTablaTable2 } from "./Proceso/AsignacionTablaTable2"
import Select from 'react-select'
import { useLocation } from "react-router-dom"
import { useAuth2 } from "../../../authv2/Auth2Provider"
import { DataUser } from "../../../documentacion/MiFicha/DataUserInterface"
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from "../../../../services/menu"
import { getProceso, getColaboradorProcessOfUnidad } from "../../../../services/procesos"
import { addWSeleccionAsignacionProceso } from "../../../../services/wseleccion"


const DocumentTrayCustom2 = () => {
    const [listDataProceso, setListDataProceso] = useState<any[]>([]);
    const [listDataApi1Aux, setListDataApi1Aux] = useState<any[]>([]);
    const [idProceso, setIdProceso] = useState('')
    const [unidad, setUnidad] = useState('')
    const [idProceso2, setIdProceso2] = useState('')
    const [unidad2, setUnidad2] = useState('')
    const [lblUnidad2, setLblUnidad2] = useState('')
    

    const [listDataApi1, setListDataApi1] = useState<any[]>([]);
    const [checkedItem1, setCheckedItem1] = useState<any[]>([]);
    const [listDataApi2, setListDataApi2] = useState<any[]>([])
    const [checkedItem2, setCheckedItem2] = useState<any[]>([]);
    const [processList, setProcessList] = useState<any[]>([])
    const [unitList, setUnitList] = useState<any[]>([])
    const [processList2, setProcessList2] = useState<any[]>([])
    const [unitList2, setUnitList2] = useState<any[]>([])
    const [actualizarData,setActualizarData] = useState(false)
    const location = useLocation()
    const { selectedRole, selectedCompanyId } = useAuth2()
    const [permissions, setPermissions] = useState<string[]>([])
    const usuarioJSON = localStorage.getItem('userData')

    let usuario: DataUser | null = null

    if (usuarioJSON !== null) {
        usuario = JSON.parse(usuarioJSON)
    }
    
    useEffect(() => {
        const fetchPermissions = async () => {
            const response = await fetchMenuActionsByRole(selectedRole)
            if (response.success) {
            const currentPath = location.pathname
            console.log("currentpath"+ JSON.stringify(currentPath))
            const subMenuResponse = await fetchSubMenuByUrl(currentPath)
            if (subMenuResponse.success) {
                console.log("PERMISOS"+ JSON.stringify(subMenuResponse))
                const subMenuId = subMenuResponse.data.id_hijo
                const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
                setPermissions(permissionsForSubMenu)
            }
            }
        }

        fetchPermissions()
    }, [selectedRole])


    useEffect(()=>{
        // refetchDataProcesos()
        const options = {
            responsables: usuario?.userId,
            estado: "A"
        }
        Promise.all([
            getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId)),
            getProceso(1, 100, options),
        ]).then((responses) => {
            if (responses[0].success && responses[1].success && responses[1].data.length>0) {
                const misUnids = responses[0].data[0].unidad.filter((u: any) => u.selected)
                setUnitList([                    
                    {label: "Seleccione Proceso", value: ""},
                    ...misUnids.map((unidad: any) => ({
                        label: unidad.DesUnid.toUpperCase(),
                        value: unidad.codUnidad
                    }))
                ])
                setProcessList([
                    {label: "Seleccione Proceso", value: ""},
                    ...responses[1].data.map((x: any) => ({label: x.nombre, value: x._id}))
                ])

                setUnitList2([                    
                    {label: "Seleccione Proceso", value: ""},
                    ...misUnids.map((unidad: any) => ({
                        label: unidad.DesUnid.toUpperCase(),
                        value: unidad.codUnidad
                    }))
                ])
                setProcessList2([
                    {label: "Seleccione Proceso", value: ""},
                    ...responses[1].data.map((x: any) => ({label: x.nombre, value: x._id}))
                ])
            }
        });
    },[])
    
    useEffect(()=>{
        setActualizarData(true)
        if(listDataApi1Aux.length>0 && listDataApi2.length>0 ){
            const filteredArray:any[] = listDataApi1Aux.filter(item1 =>
                !listDataApi2.some(item2 => item2._id === item1._id)
            );
            setActualizarData(false)
            setListDataApi1(filteredArray);
        }else if(listDataApi1Aux.length>0 && actualizarData){
            setActualizarData(false)
            setListDataApi1(listDataApi1Aux);

        }
    },[listDataApi1Aux,listDataApi2])

    const refetchDataProcesos = async () => {
        await getAllCompaniesByPais()
            .then((response) => {
                if (response.success) {
                    const DataNew = response?.data.map((ele:any) => {
                        return {...ele,check_selected:false,_id:String(ele.idEmpresaInterna)}
                    })
                    
                    const EmpresasNew = response?.data.map((ele:any) => ({ 
                        label: ele.nombre,
                        value: ele.idEmpresaInterna
                    }))
                    setListDataProceso(EmpresasNew)

                } else {
                    // console.log(response)
                }
            })
            .catch((error) => {
                console.log(error)
        })
    }


    useEffect(()=>{
        setListDataApi1Aux([])
        getColaboradorProcessOfUnidad(idProceso,unidad,usuario?.userId)
        .then((response) => {
            if (response.success) {
                setListDataApi1Aux(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })

    },[idProceso,unidad])

    useEffect(()=>{
        getColaboradorProcessOfUnidad(idProceso,unidad,usuario?.userId)
        .then((response) => {
            if (response.success) {
                setListDataApi1Aux(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },[])

    useEffect(()=>{
        if(idProceso2 || unidad2){
            setListDataApi2([])
            getColaboradorProcessOfUnidad(idProceso2,unidad2,usuario?.userId)
            .then((response) => {
                if (response.success) {
                    setListDataApi2(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }else {
            setListDataApi2([])
        }

    },[idProceso2,unidad2])
    
    const handleProcessChange = (e: any) => {
        setIdProceso(e.value)
    }

    const handleUnitChange = (e: any) => {
        setUnidad(e.value)
    }

    const handleProcessChange2 = (e: any) => {
        setIdProceso2(e.value)
    }
    
    const handleUnitChange2 = (e: any) => {
        setUnidad2(e.value)
        setLblUnidad2(e.label)
    }

    const refetchDataUnidad1 = async () => {
        /* await getAllFindTabla('Unidades',id_empresa)
            .then((response) => {
            if (response.success) {
                const DataNew1 = response?.data.map((ele:any) => {
                return {...ele,check_selected:false,_id:String(ele.id)}
                })
                setListDataApi1Aux(DataNew1)
            } else {
                // console.log(response)
            }
            })
            .catch((error) => {
            console.log(error)
            }) */

        await getColaboradorProcessOfUnidad(idProceso,unidad,usuario?.userId)
        .then((response) => {
            if (response.success) {
                setListDataApi1Aux(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    
    const refetchDataUnidad2 = async () => {
        setListDataApi2([])
        await getColaboradorProcessOfUnidad(idProceso2,unidad2,usuario?.userId)
        .then((response) => {
            if (response.success) {
                setListDataApi2(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    
    /* const handleDesasignar = async () =>{
        await desasignarUnidadByEmpresa({
            id_proceso:idProceso2,
            unidad:unidad2,
            ids_unidad:checkedItem2
        } )
        .then(res => {
            if(res.success){
                refetchDataUnidad1(selectEmpresa)
                refetchDataUnidad2(selectEmpresa)
                showMessage("success","Se Desasigno Correctamente","todo correcto")
            }
        })
        .catch(er => console.log(er))
    } */
    
    const handleAsignar = async () =>{
        /* console.log({
            id_proceso:idProceso2,
            unidad:unidad2,
            lblunidad:unidad2,
            ids_users:checkedItem1
        }) */
        await addWSeleccionAsignacionProceso(
            {
                id_proceso:idProceso,
                unidad:unidad2,
                lblunidad:lblUnidad2,
                ids_users:checkedItem1
            },
            'POST',
            idProceso2,
            "existing",
            null,
        )
        .then(res => {
            if(res.success){
                refetchDataUnidad1()
                refetchDataUnidad2()
                showMessage("success","Se Asigno Correctamente","todo correcto")
            }
    
        })
        .catch(er => console.log(er))
    }

    return (
        <>
            <KTCard>
                <KTCardBody> 
                    <Row className='mb-3'>
                        <Col xs={5} lg={5} xl={5} sm>    
                            <KTCard>                                
                                <Row>
                                    <Col xs={10} lg={4} xl={3} sm className='d-flex align-self-end justify-content-start'>
                                        <Stack direction='horizontal' gap={3} style={{marginLeft:"30px"}} >
                                            <div className='d-flex align-items-center'>
                                                <Stack direction='vertical' gap={1}>
                                                    <span className='fw-bold'>Seleccione Proceso:</span>
                                                    <Select
                                                        styles={{
                                                            control: (provided) => ({
                                                            ...provided,
                                                            width:'250px',
                                                            fontSize: '13px',
                                                            minHeight: '42px', // Reducción del alto
                                                            height: '42px',
                                                            }),
                                                        }}
                                                        placeholder='Seleccione una Proceso'
                                                        options={processList}
                                                        onChange={handleProcessChange}
                                                    />
                                                </Stack>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Stack direction='vertical' gap={1}>
                                                    <span className='fw-bold'>Seleccione Unidad:</span>
                                                    <Select
                                                        styles={{
                                                            control: (provided) => ({
                                                            ...provided,
                                                            width:'250px',
                                                            fontSize: '13px',
                                                            minHeight: '42px', // Reducción del alto
                                                            height: '42px',
                                                            }),
                                                        }}
                                                        placeholder='Seleccione una Unidad'
                                                        options={unitList}
                                                        onChange={handleUnitChange}
                                                    />
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <AsignacionTablaTable1 tbody={listDataApi1} onCheckSelect={(_id:string[]) => setCheckedItem1(_id)}  />
                            </KTCard>
                        </Col>
                        <Col className='d-flex flex-column ' xs={2} lg={2} xl={2} sm> 
                            <Button disabled={idProceso2 ? false : true} className='btn btn-primary' style={{marginTop:"100px"}} onClick={handleAsignar} >
                                Asignar 
                                <KTIcon iconName='arrow-right' className='fs-2 mx-3' />
                            </Button>
                            
                            {/* <Button className='btn btn-secondary mt-3' onClick={handleDesasignar} >
                                <KTIcon iconName='arrow-left' className='fs-2 mx-3' />
                                Desasignar 
                            </Button> */}
                        </Col>
                        <Col xs={5} lg={5} xl={5}  sm>
                            <KTCard>
                                <Row>
                                    <Col xs={10} lg={4} xl={3} sm className='d-flex align-self-end justify-content-start'>
                                        <Stack direction='horizontal' gap={3} style={{marginLeft:"30px"}} >
                                            <div className='d-flex align-items-center'>
                                                <Stack direction='vertical' gap={1}>
                                                    <span className='fw-bold'>Seleccione Proceso:</span>
                                                    <Select
                                                        styles={{
                                                            control: (provided) => ({
                                                            ...provided,
                                                            width:'250px',
                                                            fontSize: '13px',
                                                            minHeight: '42px', // Reducción del alto
                                                            height: '42px',
                                                            }),
                                                        }}
                                                        placeholder='Seleccione una Proceso'
                                                        options={processList2}
                                                        onChange={handleProcessChange2}
                                                    />
                                                </Stack>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Stack direction='vertical' gap={1}>
                                                    <span className='fw-bold'>Seleccione Unidad:</span>
                                                    <Select
                                                        styles={{
                                                            control: (provided) => ({
                                                            ...provided,
                                                            width:'250px',
                                                            fontSize: '13px',
                                                            minHeight: '42px', // Reducción del alto
                                                            height: '42px',
                                                            }),
                                                        }}
                                                        placeholder='Seleccione una Unidad'
                                                        options={unitList2}
                                                        onChange={handleUnitChange2}
                                                    />
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <AsignacionTablaTable2 tbody={listDataApi2} onCheckSelect={(_id:string[]) => setCheckedItem2(_id)} />
                            </KTCard>
                        </Col>
                    </Row>
                </KTCardBody>            
            </KTCard>
        </>
        
    )
}

export { DocumentTrayCustom2 }