import { createContext } from 'react'

interface AlertsContextProps {
    processId: string,
    setProcessId: (id: string) => void,
    searchTerm: string,
    setSearchTerm: (term: string) => void,
}

const AlertsContext = createContext<AlertsContextProps>({
    processId: '',
    setProcessId: () => {},
    searchTerm: '',
    setSearchTerm: () => {}
})

export { AlertsContext }