import {Fragment, useContext, useEffect, useState} from 'react'
import {Stack} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {getAllMisRegistroProcesos} from '../../../../services/registroFicha'
import Pagination from '../../../../shared/Pagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {DataUser} from '../../MiFicha/DataUserInterface'
import {PartnerContext} from '../BandejaProcesoContexts'
import { useQuery } from 'react-query'

const iconsData = [
  {
    iconName: 'time',
    title: 'PENDIENTE',
    stateName: 'pendientePorColaborador',
    color: '#3498db', // Blue
  },
  {
    iconName: 'check-square',
    title: 'COMPLETADO',
    stateName: 'completadoPorColaborador',
    color: '#9b59b6', // Purple
  },
  {
    iconName: 'verify',
    title: 'APROBADO CON PENDIENTE',
    stateName: 'aprobadoConPendiente',
    color: '#f39c12', // Orange
  },
  {
    iconName: 'security-user',
    title: 'APROBADO',
    stateName: 'aprobadoPorAdmin',
    color: '#2ecc71', // Green
  },
  {
    iconName: 'security-user',
    title: 'HISTORICO',
    stateName: 'historico',
    color: '#2ecccc', // Green
  },
  {
    iconName: 'notepad-edit',
    title: 'EN PROCESO',
    stateName: 'fichaSiendoEditada',
    color: '#000000', // Purple
  },
  {
    iconName: 'exclamation-triangle', // Adjust the icon name
    title: 'ANULADO',
    stateName: 'procesoAnulado',
    color: '#e74c3c', // Red
  },
  {
    iconName: 'eye',
    title: 'OBSERVADO',
    stateName: 'fichaObservada',
    color: '#d35400',
  },
  {
    iconName: 'exclamation-triangle',
    title: 'CERRADO',
    stateName: 'procesoCerrado',
    color: '#8A2BE2  ',
  },
  {
    iconName: 'exclamation-triangle',
    title: 'CERRADO CON PENDIENTES',
    stateName: 'procesoCerradoConPendiente',
    color: '#B22222',
  },
]

const PartnersTable = () => {
  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  // const [isLoading, setLoadingStatus] = useState(false)
  // const [totalPages, setTotalPages] = useState(1)
  // const [lista, setLista] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [permissions, setPermissions] = useState<string[]>([])
  const {selectedRole} = useAuth2()
  const location = useLocation()
  const idusuario = usuario?.userId

  const {activeOnly, state, searchTerm, entryDateFrom, entryDateTo} = useContext(PartnerContext)

  const {data: response, isLoading, refetch} = useQuery({
    queryKey: ['mis-procesos-bandeja', idusuario, activeOnly, state, searchTerm, currentPage],
    queryFn: ({queryKey}) => {
      const [, idusuario, activeOnly, state, searchTerm, currentPage] = queryKey
      return getAllMisRegistroProcesos({
        id: idusuario,
        activeOnly,
        sheetStates: state,
        searchQuery: searchTerm,
        currentPage,
        limitAux: 10,
      })
    }
  })

  const lista = response?.data || []
  const totalPages = response?.totalPages ?? 1

  const navigate = useNavigate()
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  // useEffect(() => {
  //   setLoadingStatus(true)
  //   refetchData({
  //     id: idusuario,
  //     activeOnly: activeOnly,
  //     sheetStates: state,
  //     searchQuery: searchTerm,
  //     currentPage: currentPage,
  //     limitAux: 10,
  //   })
  // }, [])

  // const refetchData = (options: any) => {
  //   setLoadingStatus(true)

  //   getAllMisRegistroProcesos(options)
  //     .then((response) => {
  //       if (response.success) {
  //         setLoadingStatus(false)
  //         setLista(response?.data)
  //         setTotalPages(response?.totalPages)
  //       } else {
  //         console.log(response)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    refetch()
  }

  const handleUpdate = (id: any) => {
    navigate(`/documentos/proceso/edit/${id}`)
  }

  const handleView = (id: any) => {
    navigate(`/documentos/proceso/view/${id}`)
  }

  const otrosProcesos = lista.filter((item: any) => item.estadoFicha !== 'procesoAnulado')

  return (
    <>
      <KTCardBody className='py-4 min-vh-100'>
        <div className='table-responsive min-vh-100'>
          <table className='table align-middle table-row-bordered fs-6 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                <th className='p-3'>Proceso</th>
                <th className='text-start'>Fecha de Asignacion</th>
                <th className='text-start'>Cargo</th>
                <th className='text-start'>Estado Proceso</th>
                <th className='text-end p-3'>Opciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {otrosProcesos.length > 0 ? (
                otrosProcesos?.map((item: any, index: any) => (
                  <Fragment key={item.idRegistroFicha}>
                    <tr>
                      <td>{item.idproceso.nombre}</td>
                      <td className='text-start min-w-100px'>
                        {formatDateToDMY(item.createdAt)}
                      </td>
                      <td className='text-start min-w-100px'>{item.cargo}</td>
                      <td className='text-start min-w-100px'>
                        <span
                          style={{
                            backgroundColor: `${
                              iconsData.find(
                                (e) =>
                                  e.stateName ===
                                  (item.isClosed ? item.estadoProceso : item.estadoFicha)
                              )?.color
                            }`,
                            color: 'white',
                            padding: '0.2em 0.5em',
                            borderRadius: '0.25rem',
                            marginRight: '0.5em',
                            fontSize: '0.8em',
                          }}
                        >
                          {
                            iconsData.find(
                              (e) =>
                                e.stateName ===
                                (item.isClosed ? item.estadoProceso : item.estadoFicha)
                            )?.title
                          }
                        </span>
                      </td>
                      <td className='text-end d-flex align-self-end justify-content-end'>
                        <Stack direction='horizontal' gap={1}>
                          {permissions.includes('Detalle') && !item.estadoFicha.includes('null') && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='Visualizar ficha'
                              onClick={() => handleView(item.idRegistroFicha)}
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </button>
                          )}

                          {permissions.includes('Actualizar') &&
                            !item.estadoFicha.includes('null') &&
                            ((item.estadoFicha === 'pendientePorColaborador' ||
                            item.estadoFicha === 'aprobadoConPendiente' ||
                            item.estadoFicha === 'fichaObservada' ||
                            item.estadoFicha === 'fichaSiendoEditada' ||
                            item.estadoFicha === 'historico' ||
                            item.estadoFicha === 'aprobadoPorAdmin') &&
                            (item.estadoProceso !== 'procesoCerradoConPendiente' &&
                              item.estadoProceso !== 'procesoAnulado' &&
                              item.estadoProceso !== 'procesoCerrado'
                            ) ? (
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                title='Editar ficha'
                                onClick={() => handleUpdate(item.idRegistroFicha)}
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </button>
                            ) : (
                              <></>
                            ))}
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {PartnersTable}
