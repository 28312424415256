import {createContext} from 'react'
import {Doc} from '../../enviodocumento/enviodocumentInterface'

interface DocumentLoadDetailContextProps {
  isDocumentDetailModalOpen: boolean
  openDocumentDetailModal: () => void
  closeDocumentDetailModal: () => void
  isDocumentQuickViewModalOpen: boolean
  openDocumentQuickViewModal: () => void
  closeDocumentQuickViewModal: () => void
  quickViewModalData: any
  setQuickViewModalData: (value: any) => void
  data: any
  showFileModal: boolean
  setShowFileModal: (value: boolean) => void
  viewDocumento: Doc
  setViewDocumento: (value: Doc) => void
  selectedPlantillaId: string
  setSelectedPlantillaId: (value: string) => void
  viewDocumentoArray: Doc[]
  setViewDocumentoArray: React.Dispatch<React.SetStateAction<Doc[]>>
  showFile2Modal: boolean
  setShowFile2Modal: (value: boolean) => void
  loadingNewPdf: boolean
  setLoadingNewPdf: (value: boolean) => void
}

const DocumentLoadDetailContext = createContext<DocumentLoadDetailContextProps>({
  isDocumentDetailModalOpen: false,
  openDocumentDetailModal: () => {},
  closeDocumentDetailModal: () => {},
  isDocumentQuickViewModalOpen: false,
  openDocumentQuickViewModal: () => {},
  closeDocumentQuickViewModal: () => {},
  quickViewModalData: {},
  setQuickViewModalData: (value) => {},
  data: {},
  showFileModal: false,
  setShowFileModal: (value) => {},
  viewDocumento: {uri: '', fileType: '', fileName: '', keyName: ''},
  setViewDocumento: (value) => {},
  selectedPlantillaId: '',
  setSelectedPlantillaId: (value) => {},
  viewDocumentoArray: [],
  setViewDocumentoArray: (value) => {},
  showFile2Modal: false,
  setShowFile2Modal: (value) => {},
  loadingNewPdf: false,
  setLoadingNewPdf: (value) => {},
})

export {DocumentLoadDetailContext}
