/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {KTIcon} from '../../_metronic/helpers'
import {useDebounce} from 'use-debounce'
import {Form} from 'react-bootstrap'

const TableSearch = ({searchTermDef,onSearch,onSearchDocument,typeBusqueda,placeholderSearch, design = false, className = '', clearValue = false, setClearValue = () => {}}: any) => {
  const [searchTerm, setSearchTerm] = useState(searchTermDef ?? '')
  const [searchDocument, setSearchDocument] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300) // Debounce the search term
  const [debouncedSearchDocument] = useDebounce(searchDocument, 300) // Debounce the search documento

  useEffect(() => {
    // Trigger the search callback with the debounced search term
    onSearch(debouncedSearchTerm) // Pass page 1 for initial search
  }, [debouncedSearchTerm, onSearch])

  useEffect(() => {
    // Trigger the search callback with the debounced search term
    onSearch(debouncedSearchDocument) // Pass page 1 for initial search
  }, [debouncedSearchDocument, onSearchDocument])

  useEffect(() => {
    if (clearValue) {
      setSearchTerm('')
      setSearchDocument('')
      setClearValue(false)
    } 
  }, [clearValue])
  
  return (
    <>
      {design ? (
        <Form.Control
          type='text'
          className={'form-control-sm  ' + className}
          placeholder={placeholderSearch}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        typeBusqueda === "Proceso" ?
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                
                className='form-control form-control-sm form-control-solid w-250px ps-14'
                placeholder='Buscar...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        :
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-sm form-control-solid w-250px ps-14'
                placeholder='Buscar...'
                value={searchDocument}
                onChange={(e) => setSearchDocument(e.target.value)}
              />
            </div>
          </div>

      )}
    </>
  )
}

export {TableSearch}
