export interface Folder {
  name: string
  description: string
  state: string
}

export interface Proceso {
  _id:string
  nombre: string
  codigo: string
  descripcion: string
  estado:string
}

export interface Field {  
  _id: string;
  titulo: string;
  tag: string;
  visible?:boolean;
  editable?:boolean;
}

export interface Section {
  _id:string;
  dinamico:boolean;
  nombre: string;
  descripcion: string;
  campos: Field[];
}

export interface Sheet {
  _id:string;
  ruta: {path:string,link:string}|null;
  nombre: string;
  descripcion: string;
  seccion: Section[];
}


export interface ProcessSheet {
  _id:string;
  idproceso: string;
  fichas: Sheet[];
}


export interface ListTags {
  label:string;
  fields: Tag[];
}

export interface Tag {
  label:string;
  tags:string;
  identifier: string;
  type: string;
}


export interface ListFieldsExport {
  label:string;
  fields: FieldsExport[];
}

export interface FieldsExport {
  label:string;
  check:boolean;
  identifier: string;
  type: string;
  obligatorio: boolean;
  assignedTo: string;
}

export interface ListFieldsMigracion {
  label:string;
  fields: FieldsMigracion[];
}

export interface FieldsMigracion {
  label:string;
  check:boolean;
  identifier: string;
  type: string;
  obligatorio: boolean;
}

export interface ListFieldsIngracion {
  label:string;
  fields: FieldsIngracion[];
}

export interface FieldsIngracion {
  label:string;
  check:boolean;
  identifier: string;
  type: string;
  obligatorio: boolean;
}

export interface GroupAprobacion  {
  idgrupoaprobacion : string,
  grupoaprobacion : string,
  cantidadaprobaciones : number,
  firma : boolean
}

export interface Niveles {
  envio_usuario:boolean;
  notificacion_usuario:boolean;
  detalle: GroupAprobacion;
}

export interface ProcessFiles {
  _id:string;
  idproceso:string;
  name:string;
  number_aprobacion:number,
  file:string;
  filename: string;
  keyname: string;  
  type: string;
  type_document:string;
  detalle_niveles:Niveles[];
}

export const initialProcessFiles = { 
  _id:'',
  idproceso:'',
  name: '',
  file: '',
  filename: '',
  keyname: '',
  number_aprobacion:0 ,
  type:'PLANTILLA',
  type_document:'KIT DE INGRESO',
  detalle_niveles:[] 
}