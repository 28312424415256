import {useContext} from 'react'
import {PartnerContext} from '../PartnerContexts'
import {AddUserModalContent} from './AddUserContent'
import {AddUserModalHeader} from './AddUserModalHeader'

const AddUserModal = () => {
  const {addUserModal} = useContext(PartnerContext)

  return (
    <>
      {addUserModal && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <AddUserModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <AddUserModalContent />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {AddUserModal}
