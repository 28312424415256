import { useState } from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import { PartnerContext} from './PartnerContexts'
import {SolicitudesListHeader} from './components/SolicitudesListHeader'
import {SolicitudesTable} from './components/SolicitudesTable'
import { Campos, DataTabla, Ficha, ProcesoFichas, Seccion, Tabla } from '../../documentacion/MiFicha/FIchaInterface'
import { InfoModalForm } from './show-info-modal/InfoModalForm'
import { InfoModal } from './show-info-modal/InfoModal'
import { InfoModalCandidato } from './show-info-candidato/InfoModalCandidato' 



const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/solicitudes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SolicitudesPage = () => {

  const [activeOnly, setActiveOnly] = useState("PENDIENTE")
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState()
  const [entryDateTo, setEntryDateTo] = useState()
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false)
  const [isInfoModalCandidatoOpen, setIsInfoModalCandidatoOpen] = useState<boolean>(false)
  const [infoData, setInfoData] = useState<{}>({})
  const [updateUser, setUpdateUser] = useState(false)

  return (
    <>
      <PartnerContext.Provider
      value={{
        activeOnly,
        setActiveOnly,
        state,
        setState,
        searchTerm,
        setSearchTerm,
        entryDateFrom,
        setEntryDateFrom,
        entryDateTo,
        setEntryDateTo,
        isInfoModalOpen,
        setIsInfoModalOpen,
        isInfoModalCandidatoOpen,
        setIsInfoModalCandidatoOpen,
        infoData, 
        setInfoData,
        updateUser,
        setUpdateUser
      }}>
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Solicitudes</PageTitle>
        <KTCard>
          <SolicitudesListHeader />
          <SolicitudesTable />
        </KTCard>
        <InfoModal />
        <InfoModalCandidato/>
      </PartnerContext.Provider>
    </>
  )
}

export {SolicitudesPage}
