import { Tabs, Tab, Row, Col, Button } from "react-bootstrap"
import { WNominaTrayCustom1 } from "./WNominaTrayCustom1"
import { useState } from "react"
import { WNominaTrayCustom2 } from "./WNominaTrayCustom2";
import { KTCard } from "../../../../../_metronic/helpers";
import { InfoModal } from "./show-info-modal/InfoModal";

const WNominaTrayTabs = () => {

    const [reloadKey, setReloadKey] = useState(0);

    const handleChange = () => {
        setReloadKey(reloadKey + 1)
    }

    return (
        <>
        <KTCard>
            <Tabs
                defaultActiveKey="pending"
                className="mb-3"
            >
                <Tab eventKey="pending" title="Campos a Exportar">
                    <WNominaTrayCustom1 />
                </Tab>
                <Tab eventKey="signed" title="Servicios Externos">
                    <WNominaTrayCustom2 />
                </Tab>

            </Tabs>
        </KTCard>
        <InfoModal />
        </>
    )
}

export { WNominaTrayTabs }