import {Fragment, useContext, useEffect} from 'react'
import {Form, Stack} from 'react-bootstrap'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {getAllCompaniesByPais} from '../../../../services/companies'
import {activarUser, deleteUser} from '../../../../services/users'
import {showDeleteMessage, showMessage, showReactivationMessage} from '../../../../shared/Alerts'
import {ModernPagination} from '../../../../shared/ModernPagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {TableBusiness} from '../../../generales_component/table/TableBusiness'
import {PartnerContext} from '../PartnerContexts'

const UsersTable = () => {
  const {selectedCompanyId} = useAuth2()

  const {
    activeOnly,
    state,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    updateUser,
    setUpdateUser,
    setUserData,
    openEditUserModal,
    enterprices,
    setEnterprices,
    currentPage,
    setCurrentPage,
    userEmpresa,
    setUserEmpresa,
    lista,
    setLoadingStatus,
    isLoading,
    totalPages,
    fetchAllUsers,
  } = useContext(PartnerContext)

  useEffect(() => {
    setLoadingStatus(true)
    getAllCompaniesByPais().then((data) => {
      setEnterprices(data.data)
    })
  }, [])

  useEffect(() => {
    if (updateUser) {
      setUpdateUser(false)
      setLoadingStatus(true)
      fetchAllUsers()
    }
  }, [updateUser])

  useEffect(() => {
    setLoadingStatus(true)
    fetchAllUsers()
  }, [activeOnly, state, entryDateFrom, entryDateTo, currentPage, searchTerm])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            fetchAllUsers()
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        activarUser(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            fetchAllUsers()
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (userData: any) => {
    setUserData(userData)
    openEditUserModal()
  }

  const showInComboBox = (rols: string[]) => {
    return rols.length > 1
  }

  const getEnterpricesName = (ids: string[]): {nombre: string; id: string}[] => {
    return enterprices
      .filter((enterprice) => ids.includes(enterprice._id))
      .map((e) => ({nombre: e.nombre, id: e._id}))
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <TableBusiness
            checkbox={{include: false}}
            headers={[
              {key: 'dni', value: 'DNI'},
              {key: 'nombres', value: 'Nombres'},
              {key: 'apellidos', value: 'Apellidos'},
              {key: 'email', value: 'Correo'},
              {key: 'empresa', value: 'Empresa'},
              {key: 'roles', value: 'Roles'},
              {key: 'actions', value: 'Opciones'}
            ]}
          >
            {lista.length > 0 ? (
              lista?.map((item: any) => {
                const rolesByEmpresa = item.roles?.find(
                  (rol: any) => rol.empresa === userEmpresa[item.dni]
                )
                return (
                  <Fragment key={item._id}>
                    <tr>
                      <td className='text-start p-2'>{item.dni}</td>
                      <td className='text-start min-w-200px p-2'>
                        {item.nombres ? item.nombres : ''}
                      </td>
                      <td className='text-start min-w-200px p-2'>
                        {item.apellido_paterno ? item.apellido_paterno : ''}{' '}
                        {item.apellido_materno ? item.apellido_materno : ''}
                      </td>
                      <td className='text-start min-w-200px p-2'>{item.email}</td>
                      <td className='text-start min-w-200px p-2'>
                        {showInComboBox(item.empresa) ? (
                          <Form.Select
                            onChange={(e) => {
                              setUserEmpresa((prev) => ({...prev, [item.dni]: e.target.value}))
                            }}
                          >
                            {getEnterpricesName(item.empresa).map((e) => (
                              <option selected={e.id === selectedCompanyId} key={e.id} value={e.id}>
                                {e.nombre}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          getEnterpricesName(item.empresa)[0].nombre
                        )}
                      </td>
                      <td className='text-start min-w-150px p-1'>
                        {rolesByEmpresa?.rol?.length > 0 ? (
                          <Form.Select>
                            {rolesByEmpresa?.rol?.map((rol: any) => (
                              <option key={rol.codigo_perfil}>{rol.nombre_perfil}</option>
                            ))}
                          </Form.Select>
                        ) : (
                          'Sin roles en la empresa seleccionada'
                        )}
                      </td>
                      <td className='text-start d-flex align-self-end justify-content-end p-1'>
                        <Stack direction='horizontal' gap={1}>
                          <a
                            href='#up'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            title='Editar'
                            onClick={() => handleActualizar(item)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </a>
                          {item.estado ? (
                            <a
                              href='#del'
                              className='btn btn-icon btn-bg-light btn-light-danger btn-sm'
                              title='Desactivar usuario'
                              onClick={() => handleDelete(item._id)}
                            >
                              <KTIcon iconName='cross' className='fs-3' />
                            </a>
                          ) : (
                            <a
                              href='#rea'
                              className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                              title='Activar usuario'
                              onClick={() => handleReactivation(item._id)}
                            >
                              <KTIcon iconName='check' className='fs-3' />
                            </a>
                          )}
                        </Stack>
                      </td>
                    </tr>
                  </Fragment>
                )
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </TableBusiness>
        </div>

        <ModernPagination
          currentPage={currentPage}
          totalPages={totalPages}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />

        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {UsersTable}
