import { useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { KTIcon } from '../../../../../_metronic/helpers'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from "../../../../services/menu"
import { TableSearch } from "../../../../shared/TableSearch"
import { useAuth2 } from "../../../authv2/Auth2Provider"
import { AlertsContext } from "../AlertsContexts"
import { getProcessesForDropdown } from "../../../../services/procesos"
import Select from 'react-select';

const AlertsTableHeader = () => {

    const location = useLocation()
    const currentPath = location.pathname
    const { setSearchTerm, setProcessId, processId } = useContext(AlertsContext)
    const [processList, setProcessList] = useState<any[]>([])

    const relativeRoute = `${currentPath}/create`

    const handleSearch = (e: string) => {
        setSearchTerm(e)
    }
    const { selectedRole } = useAuth2()
    const [permissions, setPermissions] = useState<string[]>([])
    useEffect(() => {
      const fetchPermissions = async () => {
        const response = await fetchMenuActionsByRole(selectedRole)
        if (response.success) {
          const currentPath = location.pathname
          const subMenuResponse = await fetchSubMenuByUrl(currentPath)
          if (subMenuResponse.success) {
            const subMenuId = subMenuResponse.data.id_hijo
            const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
            setPermissions(permissionsForSubMenu)
          }
        }
      }
  
      fetchPermissions()
    }, [selectedRole])

    const getProcesses = () => {
        getProcessesForDropdown()
            .then((response) => {
            if (response.success) {
                setProcessList(response.data)
            }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getProcesses()
    }, [])

    const processListOptions = [{label: 'Todos', value: ''}].concat(processList.map((process: any) => ({
        label: process.name,
        value: process.id,
    })))

    const handleProcessChange = (e: any) => {
        setProcessId(e.value)
    }
  
    return (
      <>
        <div className='mx-8 mt-8 mb-6'>
          <Row>
            <Col>
              <span className='fw-bold'>Proceso:</span>
              <Select
                menuPortalTarget={document.body}
                menuPosition='fixed'
                options={processListOptions}
                onChange={handleProcessChange}
              />
            </Col>
            <Col>
              <span className='fw-bold'>Nombre:</span>
              <TableSearch
                className='same-height'
                onSearch={handleSearch}
                design={true}
                placeholderSearch={'Buscar por nombre'}
              />
            </Col>
            <Col className='d-flex align-self-end justify-content-end'>
              <Link to='/configuracion/alertas/create'>
                {permissions.includes('Agregar Alerta') && (
                  <button type='button' className='btn btn-success'>
                    <KTIcon iconName='plus' className='fs-2' />
                    Alerta
                  </button>
                )}
              </Link>
            </Col>
          </Row>
        </div>
      </>
    )
}

export { AlertsTableHeader }
