import {Col, Container, Form, Row, Stack} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useContext, useEffect, useState} from 'react'
import {PartnerContext} from '../PartnerContexts'
import {useDebounce} from 'use-debounce'
import {TableSearch} from '../../../../shared/TableSearch'
import { Link, useLocation } from 'react-router-dom'

const PartnersListHeader = () => {
  const location = useLocation()
  const currentPath = location.pathname
  const relativeRoute = `${currentPath}/create`
  const { setSearchTerm, setSearchDocument} = useContext(PartnerContext)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  const handleSearchDocument = (value: string) => {
    setSearchDocument(value)
  }


  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row className='mb-4'>
        <Col>
          <Stack direction='vertical' className='h-100'>
              <span className='fw-bold'>Buscar Proceso</span>
              <TableSearch className='same-height' onSearch={handleSearch} design={true} />
          </Stack>
        </Col>

        <Col>
          <Stack direction='vertical' className='h-100'>
              <span className='fw-bold'>Buscar Documento</span>
              <TableSearch className='same-height' onSearch={handleSearch} design={true} />
          </Stack>
        </Col>
          <Col className='d-flex align-self-end justify-content-end'>
            <Stack direction='horizontal' gap={2}>
              {/* <Link to={relativeRoute}>
                <button type='button' className='btn btn-primary'>
                  <KTIcon iconName='plus' className='fs-2' />
                  Nuevo
                </button>
              </Link> */}
              {/* <button type='button' className='btn btn-light'>
                <KTIcon iconName='tablet-delete' className='fs-2' />
                Nuevo
              </button> */}
            </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export {PartnersListHeader}
