import React, {useContext, useEffect, useState} from 'react'
import {Accordion, Button, Col, Form, Row} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {getAllCompaniesByPais} from '../../../../services/companies'
import {createUser, getRoles} from '../../../../services/users'
import {showMessage, showValidatePassword} from '../../../../shared/Alerts'
import {useMyCompanies} from '../../../../shared/hooks/useMyCompanies'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {GlobalOverlayContext} from '../../../generales_component/GlobalOverlayProvider'
import {PartnerContext} from '../PartnerContexts'

type Option = {
  value: string
  label: string
}

type RolesByEnterprice = {
  rol: string[]
  empresa: string
}

type FormValues = {
  nombres: {value: string; error: string}
  apellido_paterno: {value: string; error: string}
  apellido_materno: {value: string; error: string}
  email: {value: string; error: string}
  empresa: {value: string[]; error: string}
  dni: {value: string; error: string}
  roles: {value: RolesByEnterprice[]; error: string}
}

const ROLE_FILTER = 'ADMIN'
const ROLE_TOP = 'SUPERADMIN'

const AddUserModalContent: React.FC = () => {
  const {closeAddUserModal, fetchAllUsers} = useContext(PartnerContext)
  const {setGlobalOverlayLoading} = useContext(GlobalOverlayContext)
  const [formValues, setFormValues] = useState<FormValues>({
    nombres: {value: '', error: ''},
    apellido_paterno: {value: '', error: ''},
    apellido_materno: {value: '', error: ''},
    email: {value: '', error: ''},
    empresa: {value: [], error: ''},
    dni: {value: '', error: ''},
    roles: {value: [], error: ''},
  })
  const [enterpricesSelected, setEnterpricesSelected] = useState<Option[]>([])
  const [listRoles, setListRoles] = useState<Option[]>()
  const [enterprices, setEnterprices] = useState<any[]>([])
  const [rolsByEnterpriceSelected, setRolsByEnterpriceSelected] = useState<
    Record<string, Record<string, any>[]>
  >({})
  const [keyError, setKeyError] = useState<{message: string; key: string} | null>(null)
  const {selectedRole} = useAuth2()

  const {myCompaniesOptions, rolsByCompany} = useMyCompanies()

  const companiesOptions =
    selectedRole === ROLE_TOP
      ? enterprices.map((e) => ({value: e._id, label: e.nombre}))
      : myCompaniesOptions.filter((company: any) => {
          const rols: string[] = rolsByCompany.find((x) => x.empresa === company.value)?.rol ?? []

          return rols.includes(ROLE_FILTER)
        })

  const listRolesFilter =
    selectedRole === ROLE_TOP
      ? listRoles
      : listRoles?.filter((roles: any) => ![ROLE_FILTER, ROLE_TOP].includes(roles.value))

  const handleEnterpriceChange = (e: MultiValue<Option>) => {
    setRolsByEnterpriceSelected((prev) => {
      const companyIds = e.map((x) => x.value)
      const rBe = companyIds.reduce((acc: any, companyId) => {
        if (!prev[companyId]) {
          acc[companyId] = []
        } else {
          acc[companyId] = prev[companyId]
        }
        return acc
      }, {})
      return rBe
    })

    setEnterpricesSelected(e.map((x) => x))
    setFormValues((prev) => ({
      ...prev,
      empresa: {value: e.map((x) => x.value), error: ''},
    }))
  }

  const handleRoleByEnterpriceChange = (e: MultiValue<Option>, empresa: string) => {
    setRolsByEnterpriceSelected((prev) => ({
      ...prev,
      [empresa]: e.map((x) => x),
    }))

    setFormValues((prev) => {
      const roles = prev.roles.value.filter((x) => x.empresa !== empresa)
      const newRoles = {empresa: empresa, rol: e.map((x) => x.value)}

      return {
        ...prev,
        roles: {value: [...roles, newRoles], error: ''},
      }
    })
  }

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: {value: e.target.value, error: ''},
    }))
  }

  const obtenerRoles = () => {
    getRoles()
      .then((response) => {
        console.log(response)
        if (response.success) {
          const rolesAll =
            response.data?.map((x) => ({
              label: x.nombre_perfil,
              value: x.rol,
            })) ?? []
          setListRoles(rolesAll)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error ?? '')
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const validateForm = () => {
    let isInvalid = false
    const {nombres, apellido_paterno, apellido_materno, email, empresa, dni, roles} = formValues

    if (!dni.value.match(/^[\w\W\s\S]+$/)) {
      setFormValues((prev) => ({
        ...prev,
        dni: {value: dni.value, error: 'Ingrese un valor valido'},
      }))
      isInvalid = true
    }

    if (!nombres.value.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+$/)) {
      setFormValues((prev) => ({
        ...prev,
        nombres: {value: nombres.value, error: 'Solo debe contener letras'},
      }))
      isInvalid = true
    }

    if (!apellido_paterno.value.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+$/)) {
      setFormValues((prev) => ({
        ...prev,
        apellido_paterno: {value: apellido_paterno.value, error: 'Solo debe contener letras'},
      }))
      isInvalid = true
    }

    if (!apellido_materno.value.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]+$/)) {
      setFormValues((prev) => ({
        ...prev,
        apellido_materno: {value: apellido_materno.value, error: 'Solo debe contener letras'},
      }))
      isInvalid = true
    }

    if (
      !email.value.match(
        /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9._%+-]+@[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9.-]+\.[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ]{2,}$/
      )
    ) {
      setFormValues((prev) => ({
        ...prev,
        email: {value: email.value, error: 'Ingrese un correo electrónico válido'},
      }))
      isInvalid = true
    }

    if (empresa.value.length === 0) {
      setFormValues((prev) => ({
        ...prev,
        empresa: {value: empresa.value, error: 'Seleccione al menos una empresa'},
      }))
      isInvalid = true
    }

    const allHasRoles = roles.value.every((rbe) => rbe.rol.length > 0)
    const sameLength = empresa.value.length === roles.value.length

    if (empresa.value.length > 0 && (!sameLength || !allHasRoles)) {
      setFormValues((prev) => ({
        ...prev,
        roles: {value: roles.value, error: 'Cada empresa debe tener al menos un rol'},
      }))
      isInvalid = true
    }

    return isInvalid
  }

  const handleSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      showMessage('error', 'Error en el formulario', 'Por favor, corrija los errores')
      return
    }

    const {nombres, apellido_paterno, apellido_materno, email, empresa, dni, roles} = formValues

    const data = {
      nombres: nombres.value.trim().toLocaleUpperCase(),
      apellido_paterno: apellido_paterno.value.trim().toLocaleUpperCase(),
      apellido_materno: apellido_materno.value.trim().toLocaleUpperCase(),
      email: email.value.trim(),
      empresa: empresa.value,
      dni: dni.value.trim(),
      roles: roles.value,
      password: '',
      type_user: 'USER',
    }

    showValidatePassword().then(async (result) => {
      if (result.isConfirmed) {
        data.password = result.value
        setGlobalOverlayLoading(true)
        createUser(data)
          .then((response) => {
            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message)
              closeAddUserModal()
              fetchAllUsers()
            } else {
              setKeyError({
                message: response.message,
                key: response.key,
              })
              // showMessage('error', 'Hubo un error', response.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => setGlobalOverlayLoading(false))
      }
    })
  }

  useEffect(() => {
    getAllCompaniesByPais().then((data) => {
      if (data.success) {
        setEnterprices(data.data)
      }
    })
    obtenerRoles()
  }, [])

  return (
    <>
      <section className='py-4'>
        <Row>
          <Col>
            <Form.Label>Nro. Documento</Form.Label>
            <Form.Control
              className={keyError?.key === 'DNI' ? 'is-invalid' : ''}
              type='text'
              placeholder='Ingrese Nro. Documento'
              name='dni'
              value={formValues.dni.value}
              onChange={handleInputChange}
              isInvalid={keyError?.key === 'DNI'}
            />
            <Form.Control.Feedback type='invalid' className='text-danger'>
              {keyError?.message}
            </Form.Control.Feedback>
            {formValues.dni.error && <p className='text-danger'>{formValues.dni.error}</p>}
          </Col>
          <Col>
            <Form.Label>Nombres</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ingrese nombres'
              name='nombres'
              value={formValues.nombres.value}
              onChange={handleInputChange}
            />
            {formValues.nombres.error && <p className='text-danger'>{formValues.nombres.error}</p>}
          </Col>
        </Row>

        <Row className='my-4'>
          <Col>
            <Form.Label>Apellido Paterno</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ingrese Apellido Paterno'
              name='apellido_paterno'
              value={formValues.apellido_paterno.value}
              onChange={handleInputChange}
            />
            {formValues.apellido_paterno.error && (
              <p className='text-danger'>{formValues.apellido_paterno.error}</p>
            )}
          </Col>
          <Col>
            <Form.Label>Apellido Materno</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ingrese Apellido Materno'
              name='apellido_materno'
              value={formValues.apellido_materno.value}
              onChange={handleInputChange}
            />
            {formValues.apellido_materno.error && (
              <p className='text-danger'>{formValues.apellido_materno.error}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label>Email</Form.Label>
            <Form.Control
              className={keyError?.key === 'EMAIL' ? 'is-invalid' : ''}
              type='text'
              placeholder='Ingrese Email'
              name='email'
              value={formValues.email.value}
              onChange={handleInputChange}
              isInvalid={keyError?.key === 'EMAIL'}
            />
            <Form.Control.Feedback type='invalid' className='text-danger'>
              {keyError?.message}
            </Form.Control.Feedback>
            {formValues.email.error && <p className='text-danger'>{formValues.email.error}</p>}
          </Col>
          <Col>
            <Form.Label>Empresas</Form.Label>
            <Select
              isMulti
              name='enterprices'
              className='basic-multi-select'
              classNamePrefix='select'
              placeholder='Seleccione'
              onChange={handleEnterpriceChange}
              options={companiesOptions}
            />
            {formValues.empresa.error && <p className='text-danger'>{formValues.empresa.error}</p>}
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col>
            <Accordion>
              {enterpricesSelected.map((enterprice) => (
                <Accordion.Item eventKey={enterprice.value} key={enterprice.value}>
                  <Accordion.Header>{enterprice.label}</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group className='mb-3'>
                      <Form.Label>Roles</Form.Label>
                      <Select
                        value={rolsByEnterpriceSelected[enterprice.value] ?? []}
                        isMulti
                        name='managers'
                        options={listRolesFilter}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        placeholder='Seleccione'
                        onChange={(e) =>
                          handleRoleByEnterpriceChange(e as MultiValue<Option>, enterprice.value)
                        }
                      />
                      {rolsByEnterpriceSelected[enterprice.value]?.length === 0 && (
                        <p style={{color: '#B73A47', marginTop: '10px'}}>
                          Seleccione al menos un rol.
                        </p>
                      )}
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          {formValues.roles.error && <p className='text-danger'>{formValues.roles.error}</p>}
        </Row>

        <Row className='my-4'>
          <Col className='d-flex justify-content-end'>
            <Button variant='primary' size='sm' onClick={handleSubmit}>
              Agregar usuario
            </Button>
          </Col>
        </Row>
      </section>
    </>
  )
}

export {AddUserModalContent}
