import {useContext} from 'react'

import { InfoModalFormWrapper } from './InfoModalFormWrapper'
import { InfoModalHeader } from './InfoModalHeader'
import { PartnerContext } from '../../PartnerContexts'

const InfoModal = () => {

  const {isInfoModalOpen} = useContext(PartnerContext)

  return (
    <>
      {isInfoModalOpen && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-lg'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <InfoModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <InfoModalFormWrapper />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {InfoModal}
