import React from 'react'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {UsersBody} from './components/UsersBody'
import {UsersHeader} from './components/UsersHeader'
import {UsuariosPageContextProvider} from './context/UsersPageContext'

interface Props {}

const breadCrumbs = [
  {
    title: 'Seguimiento',
    path: '/seguimiento/colaboradores',
    isSeparator: false,
    isActive: false,
  },
]

const UsersPageAdmin: React.FC<Props> = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Colaboradores</PageTitle>
      <KTCard>
        <KTCardBody>
          <UsuariosPageContextProvider>
            <UsersHeader />
            <UsersBody />
          </UsuariosPageContextProvider>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {UsersPageAdmin}
