import { fetchConToken } from '../helpers/fetch'

const url = 'proceso/'

const procesos = async (nombre = '', page, limit) => {
  const resp = await fetchConToken(
    `proceso?nombre=${nombre}&page=${page}&limit=${limit}`,
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProceso = async (currentPage, numberData, options) => {
  const queryString = new URLSearchParams(options).toString()
  const resp = await fetchConToken(
    `proceso/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  )

  const body = await resp.json()

  return body
}

const getProcesosByCliente = async (currentPage, numberData, options) => {
  const queryString = new URLSearchParams(options).toString()
  const resp = await fetchConToken(
    `proceso/getclient/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  )

  const body = await resp.json()

  return body
}

const getProcesoColaborador = async (currentPage, numberData, options) => {
  const queryString = new URLSearchParams(options).toString()
  const resp = await fetchConToken(
    `proceso/colaborador/${currentPage}/${numberData}?${queryString}`,
    {},
    'GET'
  )

  const body = await resp.json()

  return body
}


const getFichasProceso = async (idproceso) => {
  const resp = await fetchConToken(
    `${url}fichasxproceso/`,
    {
      idproceso: idproceso,
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProcesoFichas = async (idproceso, idusuario) => {
  const resp = await fetchConToken(
    `${url}fichasxallxproceso/`,
    {
      idproceso: idproceso,
      idusuario: idusuario
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProcessSheetTags = async (idproceso) => {
  const resp = await fetchConToken(
    `${url}tagsxfichaxproceso/`,
    {
      idproceso: idproceso,
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}


const getProcesoDocument = async (idproceso, idusuario) => {
  const resp = await fetchConToken(
    `${url}documentosxallxproceso/`,
    {
      idproceso: idproceso,
      idusuario: idusuario
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProcesoDocumentByClient = async (idproceso, codUnid, idusuario) => {
  const resp = await fetchConToken(
    `${url}all_documents_by_client/`,
    {
      idproceso,
      idusuario,
      codUnid
    },
    'POST'
  )
  const body = await resp.json()

  return body
}

const getProcessUsers = async (idproceso) => {
  const resp = await fetchConToken(
    `${url}usersxprocess/`,
    {
      idproceso: idproceso,
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProcessUsersConUnidad = async (idproceso, unidad) => {
  const resp = await fetchConToken(
    `${url}usersxprocess/`,
    {
      idproceso: idproceso,
      unidad: unidad
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getColaboradorProcessOfUnidad = async (idproceso, unidad, responsables) => {
  const resp = await fetchConToken(
    `${url}colaboradortoProcessOfUnidad/`,
    {
      idproceso,
      unidad,
      responsables
    },
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const nuevoProceso = async (data) => {
  const resp = await fetchConToken(
    `${url}add`,
    data,
    'POST'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const eliminarproceso = async (id) => {
  const resp = await fetchConToken(
    `${url}${id}`,
    {},
    'DELETE'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const actualizarproceso = async (data, id) => {
  const resp = await fetchConToken(
    `${url}editar/${id}`,
    data,
    'PUT'
  )
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getProcessById = async (id) => {
  const resp = await fetchConToken(
    `${url}${id}`,
    {},
    'GET'
  )
  const body = await resp.json()

  return body
}

const updateProcess = async (data) => {
  const resp = await fetchConToken(
    `${url}update_process`,
    data,
    'PUT'
  )
  const body = await resp.json()

  return body
}

const getProcessesForDropdown = async () => {
  const resp = await fetchConToken(
    `${url}processesDropdown`,
    {},
    'POST'
  )

  const body = await resp.json()

  return body
}

const getProcessLikePartner = async () => {
  const resp = await fetchConToken(`${url}like/partner`, {}, 'POST')

  return await resp.json()
}

export {
  actualizarproceso,
  eliminarproceso,
  getColaboradorProcessOfUnidad,
  getFichasProceso,
  getProceso,
  getProcesoColaborador,
  getProcesoDocument,
  getProcesoDocumentByClient,
  getProcesoFichas,
  getProcesosByCliente,
  getProcessById,
  getProcessesForDropdown,
  getProcessLikePartner,
  getProcessSheetTags,
  getProcessUsers,
  getProcessUsersConUnidad,
  nuevoProceso,
  procesos,
  updateProcess
}

