import { useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { KTIcon } from '../../../../../_metronic/helpers'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from "../../../../services/menu"
import { TableSearch } from "../../../../shared/TableSearch"
import { useAuth2 } from "../../../authv2/Auth2Provider"
import { Alerts2Context } from "../Alerts2Contexts"
import Select from 'react-select';
import { getProcessesForDropdown } from "../../../../services/procesos"

const AlertsTableHeader = () => {

    const location = useLocation()
    const currentPath = location.pathname
    const { setSearchTerm } = useContext(Alerts2Context)

    const relativeRoute = `${currentPath}/create`

    const handleSearch = (e: string) => {
        setSearchTerm(e)
    }
    const { selectedRole } = useAuth2()
    const [permissions, setPermissions] = useState<string[]>([])
    const [processList, setProcessList] = useState<any[]>([])
    const [processSelected, setProcessSelected] = useState()

    useEffect(() => {
        const fetchPermissions = async () => {
            const response = await fetchMenuActionsByRole(selectedRole)
            if (response.success) {
                const currentPath = location.pathname
                const subMenuResponse = await fetchSubMenuByUrl(currentPath)
                if (subMenuResponse.success) {
                    const subMenuId = subMenuResponse.data.id_hijo
                    const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
                    setPermissions(permissionsForSubMenu)
                }
            }
        }

        fetchPermissions()
    }, [selectedRole])

    useEffect(() => {
        getProcesses()
    }, [])

    const handleProcessChange = (e: any) => {
        setProcessSelected(e)
        /* setProcessValue(e.value)
        getSheetsByProcess(e.value) */
    }

    const getProcesses = () => {
        getProcessesForDropdown()
            .then((response) => {
                if (response.success) {
                    setProcessList(response.data.map((x: any) => ({ label: x.name, value: x.id })))
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    return (
        <>
            <div className='mx-8 mt-8 mb-6'>
                <Row>
                    <Col>
                        <span className='fw-bold'>Proceso:</span>
                        <Select
                            value={processSelected}
                            name='process'
                            options={processList}
                            classNamePrefix='select'
                            placeholder='Seleccione proceso'
                            onChange={handleProcessChange}
                        />
                    </Col>
                    <Col>
                        <span className='fw-bold'>Nombre:</span>
                        <TableSearch className='same-height'onSearch={handleSearch} design={true} placeholderSearch={'Buscar por nombre'} />
                    </Col>
                    <Col className='d-flex align-self-end justify-content-end'>
                        <Link to={relativeRoute}>
                            {permissions.includes('Agregar Alerta') && (
                                <button type='button' className='btn btn-success'>
                                    <KTIcon iconName='plus' className='fs-2' />
                                    Alerta
                                </button>
                            )}
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export { AlertsTableHeader }
