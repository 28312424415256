import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth2 } from '../../../../../app/modules/authv2/Auth2Provider';
import { fetchMenuDataByRole } from '../../../../../app/services/menu';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';

interface Menu {
  id_padre?: string;
  id_hijo?: string;
  titulo: string;
  tipo?: string;
  url?: string;
  icono: string;
  fontIcon?: string;
  order: number;
  hasBullet?: boolean;
  acciones?: string[];
  hijos?: Menu[];
}

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { selectedRole } = useAuth2();
  const [menuData, setMenuData] = useState<Menu[]>([]);

  useEffect(() => {
    const getMenuData = async () => {
      const response = await fetchMenuDataByRole(selectedRole);
      if (response.success) {
        setMenuData(response.data);
      }
    };
    getMenuData();
  }, [selectedRole]);

  const renderMenuItems = (menus: Menu[]) => {
    return menus.map((menu, idx) => (
      menu.hijos && menu.hijos.length > 0 ? (
        <SidebarMenuItemWithSub
          key={(menu.id_hijo ?? '') + (menu.id_padre ?? '') + idx.toString(2)}
          to={menu.url || '#'}
          title={menu.titulo}
          icon={menu.icono}
          fontIcon={menu.fontIcon || 'bi-layers'}
          hasBullet={!menu.icono} 
        >
          {renderMenuItems(menu.hijos)}
        </SidebarMenuItemWithSub>
      ) : (
        <SidebarMenuItem
          key={menu.id_hijo || menu.id_padre}
          to={menu.url || '#'}
          title={menu.titulo}
          icon={menu.icono}
          fontIcon={menu.fontIcon || 'bi-layers'}
          hasBullet={!menu.icono} 
        />
      )
    ));
  };

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Opciones
          </span>
        </div>
      </div>
      {menuData.length > 0 ? renderMenuItems(menuData) : <div>No menu items available</div>}
    </>
  );
};

export { SidebarMenuMain };

