import {useEffect, useMemo, useState} from 'react'
import {useAuth2} from '../../modules/authv2/Auth2Provider'
import {getRolsByCompany} from '../../services/users'

export const useMyCompanies = () => {
  const [myCompanies, setMyCompanies] = useState<string[]>([])
  const [rolsByCompany, setRolsByCompany] = useState<Record<string, any[]>[]>([])
  const {userData} = useAuth2()
  const myCompaniesOptions = useMemo(() => {
    const companies =
      userData?.userCompany
        ?.filter((x: {id: string; label: string}) => myCompanies.includes(x.id))
        ?.map((x: {id: string; label: string}) => ({value: x.id, label: x.label})) ?? []

    return companies
  }, [myCompanies])

  useEffect(() => {
    getRolsByCompany().then((res) => {
      if (res.success) {
        console.log(res.data)
        setMyCompanies(res?.data?.empresa ?? [])
        setRolsByCompany(res?.data?.roles ?? [])
      }
    })
  }, [])

  return {
    myCompaniesOptions,
    myCompanies,
    rolsByCompany,
  }
}
