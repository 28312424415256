import React, {useContext} from 'react'
import {Form} from 'react-bootstrap'
import {ModernPagination} from '../../../../shared/ModernPagination'
import {TableBusiness} from '../../../generales_component/table/TableBusiness'
import {UsuariosPageContext} from '../context/UsersPageContext'

const typeUserMap = {
  USER: 'Usuario',
  PARTNER: 'Colaborador',
  POSTULANT: 'Postulante',
}

const UsersBody: React.FC = () => {
  const {
    users,
    currentPage,
    setCurrentPage,
    totalPages,
    isLoading,
    hints,
    enterprices,
    optionsSelected,
  } = useContext(UsuariosPageContext)

  const getEnterpricesName = (ids: string[]): {nombre: string; id: string}[] => {
    return enterprices
      .filter((enterprice) => ids.includes(enterprice._id))
      .map((e) => ({nombre: e.nombre, id: e._id}))
  }

  const showInComboBox = (rols: string[]) => {
    return rols.length > 1
  }

  return (
    <>
      <div className='table-responsive mt-4'>
        <TableBusiness
          checkbox={{include: false}}
          headers={[
            {key: 'dni', value: 'Nro Documento'},
            // {key: 'nombres', value: 'Nombres'},
            {key: 'fullname', value: 'Nombres y Apellidos'},
            {key: 'correo', value: 'Correo Personal'},
            {key: 'corporateEmail', value: 'Correo Corporativo'},
            {key: 'type_user', value: 'Tipo'},
            {key: 'fechaIngreso', value: 'Fecha Ingreso'},
            {key: 'empresa', value: 'Empresa'},
            {key: 'estado', value: 'Estado'},
          ]}
        >
          {users.map((user) => (
            <tr key={user._id}>
              <td className='text-start min-w-100px p-1 text-uppercase'>{user.dni}</td>
              {/* <td className='text-start min-w-200px p-1'>{user.nombres}</td> */}
              <td className='text-start min-w-250px p-1 text-uppercase'>{`${user.nombres} ${user.apellido_paterno} ${user.apellido_materno}`}</td>
              <td className='text-start min-w-200px p-1 text-uppercase'>{user.email}</td>
              <td className='text-start min-w-200px p-1 text-uppercase'>
                {user?.corporateEmail ?? 'No registrado'}
              </td>
              <td className='text/start min-w-150px p-1 text-uppercase'>
                {typeUserMap[user.type_user as keyof typeof typeUserMap] ?? 'No identificado'}
              </td>
              <td className='text-start min-w-150px p-1'>
                {new Date(user.createdAt).toLocaleDateString()}
              </td>
              <td className='text-start min-w-200px p-1'>
                {showInComboBox(user.empresa) ? (
                  <Form.Select>
                    {getEnterpricesName(user.empresa)?.map((e) => (
                      <option selected={optionsSelected.empresa === e.id} key={e.id} value={e.id}>
                        {e.nombre}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  getEnterpricesName(user.empresa)[0]?.nombre
                )}
              </td>
              <td className='text-uppercase'>{user.estado ? 'Activo' : 'Inactivo'}</td>
            </tr>
          ))}
        </TableBusiness>
      </div>
      <section className='d-flex justify-content-between align-items-center mt-4'>
        <div>
          <p className='m-0 fw-bold border border-dark p-2 rounded-2'>{hints} registros</p>
        </div>
        <ModernPagination
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          totalPages={totalPages}
          isLoading={isLoading}
        />
      </section>
    </>
  )
}
export {UsersBody}
