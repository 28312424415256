import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import TagsListGroup from './EditFileGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { PartnerContext } from '../PartnerContexts'
import { getProcessSheetTags } from '../../../../services/procesos'
import { Field, initialProcessFiles, ListTags, ProcessFiles, ProcessSheet, Section, Sheet, Tag } from '../documentInterface'
import DropzoneArchivoUnico from '../componente/DropzoneArchivoUnico'
import { ConverDocx, addFileProcess } from '../../../../services/files'
import { showInfoHtml, showMessage } from '../../../../shared/Alerts'
import OverlayLoading from '../componente/OverlayLoading'


const EditFileModalForm = () => {
  const {closeEditFileModal,setUpdateFile,fileData} = useContext(PartnerContext)
  const [loading, setLoading] = useState(false)
  const [processFile, setProcessFile] = useState<ProcessFiles>(fileData)
  const [acceptExt, setAcceptExt] = useState('.docx'); 
  const [shouldClear, setShouldClear] = useState(false);
  const [cargandoFile, setCargandoFile] = useState(false);

  useEffect(()=>{
    setProcessFile(fileData)
    if(fileData.type === 'PLANTILLA'){
      setAcceptExt('.docx')
    }else{
      setAcceptExt('.pdf')
    }
  },[fileData])

  const handleSaveProcessFile = () => {
    setLoading(true)
    addFileProcess(processFile)
        .then((response) => {
          if (response.success) {
            limpiarCampos()
            showMessage('success', '¡Buen trabajo!', response.message)
            setUpdateFile(true)
            closeEditFileModal()
          } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          limpiarCampos()
          setLoading(false)
          showMessage('error', 'Hubo un error', error.message)
        })
  }

  const limpiarCampos = ()=>{    
    setProcessFile(initialProcessFiles)
    setAcceptExt('.docx')            
    setShouldClear(true);
  }


  const handleDocumentSelect = (e: any) => {
    const val = e.target.value
    if(val === 'PLANTILLA'){
      setAcceptExt('.docx')
    }else{
      setAcceptExt('.pdf')
    }
    
    setProcessFile({...processFile,type:val})
  }

  const handleDocumentTypeSelect = (e: any) => {
    const val = e.target.value    
    setProcessFile({...processFile,type_document:val})
  }

  const fileToBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        resolve(reader.result);
      };
  
      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleClearComplete = () => {
    setShouldClear(false);
  };

  const handleChangeDocument = (e: any) => {
    setProcessFile({...processFile, [e.target.name]: e.target.value})
  }

  const handleFileUnicoChange = async (file:  File|null)  => {
    const selectedFile = file;
    setCargandoFile(true)
    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase();
      const base64 = await fileToBase64(selectedFile);
      
      if (fileName.endsWith('.pdf')) {   
        setCargandoFile(true)     
        //setSelectedFile(selectedFile);
      }else if(fileName.endsWith('.docx') || fileName.endsWith('.doc')){
        //setSelectedFile(selectedFile);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('idproceso', processFile.idproceso);
        const response = await ConverDocx(formData)
        if (response.success) {
          console.log(response)
          let codigoHTML = ''
          if(!Object.keys(response.data).length ){
            codigoHTML += `No se encontraron <b>Etiquetas</b> en la plantilla `;
          }else{
            codigoHTML += `Se encontraron en la plantilla lo siguiente <b>Etiquetas</b><br/>
                                <div class="centrar-tabla" >
                                <table border="1">
                                <thead>
                                    <tr>
                                        <th>Etiqueta</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody> `;
            for (const key of Object.keys(response.data)) {            
              codigoHTML += '<tr><td>' + key.replace(/\\/g, '')  + '</td><td>'+response.data[key]+'</td></tr>';
            }
            codigoHTML += `</tbody> </table> </div>`
          }
          showInfoHtml("info",response.message,codigoHTML)
          setCargandoFile(false)
        } else {
          setCargandoFile(false)
          console.error('Error al descargar el archivo');
        }
        console.log('Archivo Word seleccionado:', selectedFile);

      } else {
        setCargandoFile(false)
        alert('Por favor, seleccione un archivo PDF válido.');
        //e.target.value = null;
      }
      
      setProcessFile({...processFile,file:String(base64),filename:fileName})
    }
    setCargandoFile(false)
    
  };

  return (
    <>
      <Form >
        <Container>
          <Row className='custom-row mb-3 pt-3 px-3' >
            <Form >
              <Col sm>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{ fontSize: '16px', fontWeight:'bold' }} >Agregar Documento</Form.Label>
                  </div>
                </div>
              </Col>
              <Row className='mb-3'>
                <Col sm>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Ingresa nombre de documento</Form.Label>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Ingrese un nombre'
                      name='name'
                      value={processFile.name}
                      onChange={handleChangeDocument}
                      
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3' >
                <Col sm>
                  <Form.Group controlId='formNombre'>
                      <Form.Label>Tipo de documento</Form.Label>
                      <Form.Control
                        as='select'
                        name='type'
                        value={processFile.type_document}
                        className={'form-select form-select-solid'}
                        onChange={handleDocumentTypeSelect}
                        required
                      >
                        {/* <option value=''>Seleccione un campo</option>   */}                      
                        <option key={1} value={`KIT DE INGRESO`}>{`KIT DE INGRESO`}</option>
                        <option key={2} value={`DOCUMENTOS GENERALES`}>{`DOCUMENTOS GENERALES`}</option>
                      </Form.Control>
                    </Form.Group>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Formato de documento</Form.Label>
                    <Form.Control
                      as='select'
                      name='type'
                      value={processFile.type}
                      className={'form-select form-select-solid'}
                      onChange={handleDocumentSelect}
                      required
                    >
                      {/* <option value=''>Seleccione un campo</option>   */}                      
                      <option key={1} value={`PLANTILLA`}>{`PLANTILLA`}</option>
                      <option key={2} value={`PDF`}>{`PDF`}</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm>
                <DropzoneArchivoUnico 
                  key={processFile._id} 
                  onSelected={(files) => handleFileUnicoChange(files?files:null)} 
                  shouldClear={shouldClear}
                  onClear={handleClearComplete}
                  acceptType={acceptExt}
                  infoArchivo= {{url:processFile.file ,name:processFile.filename}}
                  idx={processFile._id} />
                  {cargandoFile && <OverlayLoading />}
                </Col>
              </Row>
              <Row>
                <div className='d-flex justify-content-end'>
                  <Button
                    variant='light'
                    className='mx-3'
                    type='reset'
                    onClick={() => {
                      closeEditFileModal()
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button variant='primary' type='button' onClick={()=>{handleSaveProcessFile()}}>
                    {!loading && 'Actualizar Archivo'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Subiendo...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>

        
      </Form>
    </>
  )
}

export {EditFileModalForm}
