import {FC, Suspense, lazy, useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MenuTestPage} from '../pages/MenuTestPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {fetchConToken} from '../helpers/fetch'
import {useAuth2} from '../modules/authv2/Auth2Provider'

import {Error404} from '../modules/errors/components/Error404'
import {fetchAllowedUrls} from '../services/menu'
import {ErrorsLayoutCus} from './ErrorLayoutCus'
import {routes} from './routes'

interface MyComponentProps {
  userRol: string
}

const PrivateRoutes: FC<MyComponentProps> = (props) => {
  const {userRol} = props

  const UsuarioPage = lazy(() => import('../modules/configuracion/usuario/UsuarioPage'))
  const ProcesosPage = lazy(() => import('../modules/configuracion/procesos/ProcesosPage'))
  const CompaniesPage = lazy(() => import('../modules/maintenances/companies/CompaniesPage'))
  const FichasPage = lazy(() => import('../modules/configuracion/fichas/FichasPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const PerfilPage = lazy(() => import('../modules/documentacion/Perfil/PerfilPage'))
  const FichaPage = lazy(() => import('../modules/documentacion/MiFicha/FichaPage'))
  const ConfigurePlantilla = lazy(() => import('../modules/configuracion/documento/plantilla'))

  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const location = useLocation()

  const {userData, setUserData} = useAuth2()
  const [allowedUrls, setAllowedUrls] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  const isUrlAllowed = (url: string): boolean => {
    return allowedUrls.includes(url)
  }
  const decodeJWT = (token: string) => {
    try {
      const base64Url = token.split('.')[1] // Extraer el payload
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') // Convertir a Base64 estándar
      const decodedData = JSON.parse(atob(base64)) // Decodificar Base64 y convertir a objeto
      return decodedData
    } catch (error) {
      console.error('Error al decodificar el JWT', error)
      return null
    }
  }
  useEffect(() => {
    const fetchUrls = async () => {
      try {
        const result = await fetchAllowedUrls(userRol)
        if (result.success) {
          setAllowedUrls(result.data)
        } else {
          console.error(result.message)
        }
      } catch (err) {
        console.error('Error fetching allowed URLs', err)
      } finally {
        setLoading(false)
      }
    }

    fetchUrls()
  }, [userRol])

  useEffect(() => {
    const isAuthenticated =
      (Object.keys(userData).length === 0 && userData.constructor === Object) || !userData.userId
        ? false
        : true

    if (!isAuthenticated) {
      if (location.pathname === '/dashboard') {
        // console.log("has entrado punto principal")
        const searchParams = new URLSearchParams(location.search)
        const token = searchParams.get('token')
        if (token) {
          // console.log("bien tienes token prosigues")
          const decodedToken = decodeJWT(token)
          localStorage.setItem('urlApi', decodedToken.urlApi)
          localStorage.setItem('urlLogout', decodedToken.urlLogout)
          localStorage.setItem('authToken', token)
          searchParams.delete('token')
          const newUrl = `${location.pathname}`
          window.history.replaceState({}, '', newUrl)

          fetchProfile(token)
            .then((response) => {
              // console.log("se obtuvieron datos del api")
              if (response.userId) {
                // console.log("se encontro informacin")
                localStorage.setItem('userData', JSON.stringify(response))
                setUserData(response)
                window.location.reload()
              } else {
                // console.log("No se encontro datos el token o usuario no tiene permiso")
                localStorage.clear()
                window.location.replace(String(localStorage.getItem('urlLogout')))
              }
            })
            .catch((error) => {
              console.log(error)
              localStorage.clear()
              window.location.replace(String(localStorage.getItem('urlLogout')))
            })
        } else {
          // console.log("no hay token para validar")
          localStorage.clear()
          window.location.replace(String(localStorage.getItem('urlLogout')))
        }
      } else {
        // console.log("estas entrando a wdocs y no es por la parte principal ni estas logueado")
        localStorage.clear()
        window.location.replace(String(localStorage.getItem('urlLogout')))
      }
    }
  }, [userData])

  const fetchProfile = async (token: string) => {
    const url = 'auth/profile'
    const resp = await fetchConToken(url, {}, 'GET', token)
    const body = await resp.json()

    return body
  }

  if (loading) {
    // Renderizar un indicador de carga o un componente vacío mientras se cargan las URLs permitidas
    return <div>Loading...</div>
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/configuracion/procesos' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
      </Route>

      {/* alternativa a agregar manualmente que se renderiza segun el rol */}
      <Route element={<MasterLayout />}>
        {routes.map((parent) => {
          const allowed = parent.children.filter((route) => isUrlAllowed(route.keypath!))
          // console.log(allowed)
          return (
            <Route path={parent.path} {...(parent?.props ?? {})} key={parent.path}>
              {allowed.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <SuspensedView>
                        <route.Component {...(route?.props ?? {})} />
                      </SuspensedView>
                    }
                  />
                )
              })}
            </Route>
          )
        })}
        <Route
          path='*'
          element={
            <ErrorsLayoutCus>
              <Error404 />
            </ErrorsLayoutCus>
          }
        />
      </Route>

      {/* {userRol === 'POST' ? (
        <Route element={<MasterLayout />}>
          {isUrlAllowed('documentos/proceso/*') && (
            <Route
              path='documentos/proceso/*'
              element={
                <SuspensedView>
                  <BandejaProcesoPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/procesos/view/:id'
            element={
              <SuspensedView>
                <PartnerEdit />
              </SuspensedView>
            }
          />
          <Route
            path='documentos/proceso/edit/:id'
            element={
              <SuspensedView>
                <ProcessEdit editable={true} />
              </SuspensedView>
            }
          />
          {isUrlAllowed('documentos/bandeja/*') && (
            <Route
              path='documentos/bandeja/*'
              element={
                <SuspensedView>
                  <DocumentTrayPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/kit_documentos/*') && (
            <Route
              path='documentos/kit_documentos/*'
              element={
                <SuspensedView>
                  <KitDocumentos />
                </SuspensedView>
              }
            />
          )}

          {isUrlAllowed('mi-legajo/*') && (
            <Route
              path='/mi-legajo'
              element={
                <SuspensedView>
                  <MiLegajoPage />
                </SuspensedView>
              }
            />
          )}

          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : userRol === 'SUPER' ? (
        <Route element={<MasterLayout />}>
          <Route
            path='configuracion/alertas/create'
            element={
              <SuspensedView>
                <AlertEdit />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/documentos_por_cliente/*') && (
            <Route
              path='configuracion/documentos_por_cliente/*'
              element={
                <SuspensedView>
                  <DocumentoClientePage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='configuracion/documentos_por_cliente/edit/:id/:idCliente'
            element={
              <SuspensedView>
                <DocumentClienteEdit />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/config_documentos/*') && (
            <Route
              path='configuracion/config_documentos/*'
              element={
                <SuspensedView>
                  <ConfigDocumentos />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/kit_documentos/*') && (
            <Route
              path='documentos/kit_documentos/*'
              element={
                <SuspensedView>
                  <KitDocumentos />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/procesos/*') && (
            <Route
              path='configuracion/procesos/*'
              element={
                <SuspensedView>
                  <ProcesosPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/wnomina/*') && (
            <Route
              path='configuracion/wnomina/*'
              element={
                <SuspensedView>
                  <WNominaPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/fichas/*') && (
            <Route
              path='configuracion/fichas/*'
              element={
                <SuspensedView>
                  <FichasPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/colaboradores/*') && (
            <Route
              path='seguimiento/colaboradores/*'
              element={
                <SuspensedView>
                  <UsersPageAdmin />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/procesos/*') && (
            <Route
              path='seguimiento/procesos/*'
              element={
                <SuspensedView>
                  <PartnersPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/solicitudes/*') && (
            <Route
              path='seguimiento/solicitudes/*'
              element={
                <SuspensedView>
                  <SolicitudesPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/documentos/*') && (
            <Route
              path='seguimiento/documentos/*'
              element={
                <SuspensedView>
                  <DocumentsPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/alertas/*') && (
            <Route
              path='configuracion/alertas/*'
              element={
                <SuspensedView>
                  <AlertsPages />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('reportes/documentos/*') && (
            <Route
              path='reportes/documentos/*'
              element={
                <SuspensedView>
                  <DocumentsReportPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('reportes/usuarios/*') && (
            <Route
              path='reportes/usuarios/*'
              element={
                <SuspensedView>
                  <UsersReportPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/documentos/detalle/:id'
            element={
              <SuspensedView>
                <DocumentDetail />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/historialdocumentos/*') && (
            <Route
              path='seguimiento/historialdocumentos/*'
              element={
                <SuspensedView>
                  <HistorialDocumentsPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/historialdocumentos/detalle/:id'
            element={
              <SuspensedView>
                <HistorialDocumentDetail />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/legajo/*') && (
            <Route
              path='seguimiento/legajo/*'
              element={
                <SuspensedView>
                  <LegajoDocumentsPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/fiscalizador/documento/*'
            element={
              <SuspensedView>
                <FiscalizadorLegajoDocumentsPage />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/cpd-documentos/*') && (
            <Route
              path='seguimiento/cpd-documentos/*'
              element={
                <SuspensedView>
                  <DocumentsCPDPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/cpd-documentos/detalle/:id'
            element={
              <SuspensedView>
                <DocumentCPDDetail />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/observacion/:id/:namecolaborador/:observacion'
            element={
              <SuspensedView>
                <ColaboradorObservacion />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/validar/*') && (
            <Route
              path='configuracion/validar/*'
              element={
                <SuspensedView>
                  <ValidationPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/procesos/validar/actions/:id/:namecolaborador'
            element={
              <SuspensedView>
                <ValidationActions />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/edit/:id'
            element={
              <SuspensedView>
                <PartnerEdit editable={true} />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/view/:id'
            element={
              <SuspensedView>
                <PartnerEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/fichas/create'
            element={
              <SuspensedView>
                <SheetEdit />
              </SuspensedView>
            }
          />
         
            path='seguimiento/asignacionproceso/*'
            element={
              <SuspensedView>
                <AsignacionProcesoPage />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/enviodocumentos/*') && (
            <Route
              path='/configuracion/enviodocumentos'
              element={
                <SuspensedView>
                  <EnvioDocument />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='/configuracion/enviodocumentos/:id'
            element={
              <SuspensedView>
                <EnvioDocument />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/config_documentos/edit/:id'
            element={
              <SuspensedView>
                <DocumentEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/documentos/view/:id'
            element={
              <SuspensedView>
                <DocumentView />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/documentos/view/platilla/:idfile/:iduser'
            element={
              <SuspensedView>
                <DocumentViewPlantilla />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/fichas/edit/:id/:shouldExecuteModal'
            element={
              <SuspensedView>
                <SheetEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/alertas/edit/:id'
            element={
              <SuspensedView>
                <AlertEdit />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/plantilla/*') && (
            <Route
              path='configuracion/plantilla/*'
              element={
                <SuspensedView>
                  <ConfigurePlantilla />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/perfil/*') && (
            <Route
              path='documentos/perfil/*'
              element={
                <SuspensedView>
                  <PerfilPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/ficha/*') && (
            <Route
              path='documentos/ficha/*'
              element={
                <SuspensedView>
                  <FichaPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='documentos/proceso/edit/:id'
            element={
              <SuspensedView>
                <ProcessEdit editable={true} />
              </SuspensedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : userRol === 'ADMIN' ||
        userRol === 'CLIENTE' ||
        userRol === 'APROBADOR' ||
        userRol === 'SUPERADMIN' ||
        userRol === 'GEST' ||
        userRol === 'WDOCS' ? (
        <Route element={<MasterLayout />}>
          {isUrlAllowed('configuracion/documentos_por_cliente/*') && (
            <Route
              path='configuracion/documentos_por_cliente/*'
              element={
                <SuspensedView>
                  <DocumentoClientePage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='configuracion/documentos_por_cliente/edit/:id/:idCliente'
            element={
              <SuspensedView>
                <DocumentClienteEdit />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/configuracion/usuario/*'
            element={
              <SuspensedView>
                <UsuarioPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:id'
            element={
              <SuspensedView>
                <Ficha />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/configuracion/usuario/pages/fichas/plantilla/Formulario/:id'
            element={
              <SuspensedView>
                <Formulario />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/config_documentos/*') && (
            <Route
              path='configuracion/config_documentos/*'
              element={
                <SuspensedView>
                  <ConfigDocumentos />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/kit_documentos/*') && (
            <Route
              path='documentos/kit_documentos/*'
              element={
                <SuspensedView>
                  <KitDocumentos />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/procesos/*') && (
            <Route
              path='configuracion/procesos/*'
              element={
                <SuspensedView>
                  <ProcesosPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/wnomina/*') && (
            <Route
              path='configuracion/wnomina/*'
              element={
                <SuspensedView>
                  <WNominaPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('mantenimiento/empresas/*') && (
            <Route
              path='mantenimiento/empresas/*'
              element={
                <SuspensedView>
                  <CompaniesPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/fichas/*') && (
            <Route
              path='configuracion/fichas/*'
              element={
                <SuspensedView>
                  <FichasPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/procesos/*') && (
            <Route
              path='seguimiento/procesos/*'
              element={
                <SuspensedView>
                  <PartnersPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/solicitudes/*') && (
            <Route
              path='seguimiento/solicitudes/*'
              element={
                <SuspensedView>
                  <SolicitudesPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/documentos/*') && (
            <Route
              path='seguimiento/documentos/*'
              element={
                <SuspensedView>
                  <DocumentsPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/alta/*') && (
            <Route
              path='seguimiento/alta/*'
              element={
                <SuspensedView>
                  <AltaPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/alertas/*') && (
            <Route
              path='configuracion/alertas/*'
              element={
                <SuspensedView>
                  <AlertsPages />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('configuracion/alerta2/*') && (
            <Route
              path='configuracion/alerta2/*'
              element={
                <SuspensedView>
                  <Alerts2Pages />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('reportes/documentos/*') && (
            <Route
              path='reportes/documentos/*'
              element={
                <SuspensedView>
                  <DocumentsReportPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('reportes/usuarios/*') && (
            <Route
              path='reportes/usuarios/*'
              element={
                <SuspensedView>
                  <UsersReportPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguimiento/colaboradores/*') && (
            <Route
              path='seguimiento/colaboradores/*'
              element={
                <SuspensedView>
                  <UsersPageAdmin />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='configuracion/alertas/create'
            element={
              <SuspensedView>
                <AlertEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/alerta2/create'
            element={
              <SuspensedView>
                <Alert2Edit />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/documentos/detalle/:id'
            element={
              <SuspensedView>
                <DocumentDetail />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/historialdocumentos/*') && (
            <Route
              path='seguimiento/historialdocumentos/*'
              element={
                <SuspensedView>
                  <HistorialDocumentsPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/historialdocumentos/detalle/:id'
            element={
              <SuspensedView>
                <HistorialDocumentDetail />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/legajo/*') && (
            <Route
              path='seguimiento/legajo/*'
              element={
                <SuspensedView>
                  <LegajoDocumentsPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/fiscalizador/documento/*'
            element={
              <SuspensedView>
                <FiscalizadorLegajoDocumentsPage />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguimiento/cpd-documentos/*') && (
            <Route
              path='seguimiento/cpd-documentos/*'
              element={
                <SuspensedView>
                  <DocumentsCPDPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='seguimiento/cpd-documentos/detalle/:id'
            element={
              <SuspensedView>
                <DocumentCPDDetail />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/observacion/:id/:namecolaborador/:observacion'
            element={
              <SuspensedView>
                <ColaboradorObservacion />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/validar/*'
            element={
              <SuspensedView>
                <ValidationPage />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/validar/actions/:id/:namecolaborador'
            element={
              <SuspensedView>
                <ValidationActions />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/edit/:id'
            element={
              <SuspensedView>
                <PartnerEdit editable={true} />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/procesos/view/:id'
            element={
              <SuspensedView>
                <PartnerEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/fichas/create'
            element={
              <SuspensedView>
                <SheetEdit />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/enviodocumentos/*') && (
            <Route
              path='/configuracion/enviodocumentos'
              element={
                <SuspensedView>
                  <EnvioDocument />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='/configuracion/enviodocumentos/:id'
            element={
              <SuspensedView>
                <EnvioDocument />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/config_documentos/edit/:id'
            element={
              <SuspensedView>
                <DocumentEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/documentos/view/:id'
            element={
              <SuspensedView>
                <DocumentView />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/documentos/view/platilla/:idfile/:iduser'
            element={
              <SuspensedView>
                <DocumentViewPlantilla />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/fichas/edit/:id/:shouldExecuteModal'
            element={
              <SuspensedView>
                <SheetEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/alertas/edit/:id'
            element={
              <SuspensedView>
                <AlertEdit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/alerta2/edit/:id'
            element={
              <SuspensedView>
                <Alert2Edit />
              </SuspensedView>
            }
          />
          <Route
            path='configuracion/plantilla/*'
            element={
              <SuspensedView>
                <ConfigurePlantilla />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          {isUrlAllowed('configuracion/enviodocumentos/*') && (
            <Route
              path='documentos/perfil/*'
              element={
                <SuspensedView>
                  <PerfilPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('documentos/ficha/*') && (
            <Route
              path='documentos/ficha/*'
              element={
                <SuspensedView>
                  <FichaPage />
                </SuspensedView>
              }
            />
          )}
          <Route
            path='documentos/proceso/edit/:id'
            element={
              <SuspensedView>
                <ProcessEdit editable={true} />
              </SuspensedView>
            }
          />
          {isUrlAllowed('seguridad/user/*') && (
            <Route
              path='seguridad/user/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/asignacionunidad/*') && (
            <Route
              path='seguridad/asignacionunidad/*'
              element={
                <SuspensedView>
                  <AginacionUnidadPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/permiso/*') && (
            <Route
              path='seguridad/permiso/*'
              element={
                <SuspensedView>
                  <PermisoPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/perfil/*') && (
            <Route
              path='seguridad/perfil/*'
              element={
                <SuspensedView>
                  <PerfilPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/vistasadicionales/*') && (
            <Route
              path='seguridad/vistasadicionales/*'
              element={
                <SuspensedView>
                  <VistasAdicionalesPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/creacionmenu/*') && (
            <Route
              path='seguridad/creacionmenu/*'
              element={
                <SuspensedView>
                  <AddMenuPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/asignacionmenu/*') && (
            <Route
              path='seguridad/asignacionmenu/*'
              element={
                <SuspensedView>
                  <AsignacionMenuPage />
                </SuspensedView>
              }
            />
          )}
          {isUrlAllowed('seguridad/asignaciontabla/*') && (
            <Route
              path='seguridad/asignaciontabla/*'
              element={
                <SuspensedView>
                  <AsignacionTablaPage />
                </SuspensedView>
              }
            />
          )}
          
          <Route
            path='seguimiento/asignacionproceso/*'
            element={
              <SuspensedView>
                <AsignacionProcesoPage />
              </SuspensedView>
            }
          />
          <Route
            path='seguimiento/grupoaprobacion/*'
            element={
              <SuspensedView>
                <GrupoAprobacionPage />
              </SuspensedView>
            }
          />
          <Route
            path='documentos/bandeja/*'
            element={
              <SuspensedView>
                <DocumentTrayPage />
              </SuspensedView>
            }
          />
          
          <Route
            path='inspector/reportes/documentos/*'
            element={
              <SuspensedView>
                <InspectorDocumentsReportPage />
              </SuspensedView>
            }
          />
         
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : userRol === 'INSPECTOR' ? (
        <Route element={<MasterLayout />}>
          <Route
            path='inspector/reportes/documentos/*'
            element={
              <SuspensedView>
                <InspectorDocumentsReportPage />
              </SuspensedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : (
        <Route path='*' element={<Navigate to='/error/401' />} />
      )} */}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
