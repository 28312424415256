import React, {lazy} from 'react'
import {DocumentEdit} from '../modules/configuracion/documento/document-edit/DocumentEdit'
import {SheetEdit} from '../modules/configuracion/fichas/sheet-edit/SheetEdit'
import Ficha from '../modules/configuracion/usuario/pages/fichas/plantilla/Ficha'
import Formulario from '../modules/configuracion/usuario/pages/fichas/plantilla/Formulario'

import DocumentView from '../modules/configuracion/documento/document-edit/ViewDocument'
import DocumentViewPlantilla from '../modules/configuracion/documento/document-edit/ViewDocumentPlantilla'
import ConfigDocumentos from '../modules/configuracion/documento/documento'
import EnvioDocument from '../modules/configuracion/enviodocumento/enviodocumento'

import {DocumentsCPDPage} from '../modules/configuracion/administracion-documentos-cpd/DocumentsCPDPage'
import {DocumentsPage} from '../modules/configuracion/administracion-documentos/DocumentsPage'

import {DocumentCPDDetail} from '../modules/configuracion/administracion-documentos-cpd/document-detail/DocumentDetail'
import {DocumentDetail} from '../modules/configuracion/administracion-documentos/document-detail/DocumentDetail'

import {PartnersPage} from '../modules/configuracion/colaboradores/PartnersPage'
import {ColaboradorObservacion} from '../modules/configuracion/colaboradores/components/observacion/ColaboradorObservacion'
import {PartnerEdit} from '../modules/configuracion/colaboradores/partner-edit/PartnerEdit'
import {HistorialDocumentsPage} from '../modules/configuracion/historial-documentos/HistorialDocumentsPage'
import {HistorialDocumentDetail} from '../modules/configuracion/historial-documentos/document-detail/HistorialDocumentDetail'
import {SolicitudesPage} from '../modules/configuracion/solicitudes/SolicitudesPage'
import {ValidationPage} from '../modules/configuracion/validacion/ValidationPage'
import {ValidationActions} from '../modules/configuracion/validacion/validation-actions/ValidationActions'

import {AlertsPages} from '../modules/configuracion/alerts/AlertsPage'
import {AlertEdit} from '../modules/configuracion/alerts/alert-edit/AlertEdit'
import {Alerts2Pages} from '../modules/configuracion/alerts2/Alerts2Page'
import {Alert2Edit} from '../modules/configuracion/alerts2/alert-edit/Alert2Edit'

import {DocumentoClientePage} from '../modules/configuracion/documentoCliente/DocumentoClientePage'
import {DocumentClienteEdit} from '../modules/configuracion/documentoCliente/document-edit/DocumentEdit'
import {FiscalizadorLegajoDocumentsPage} from '../modules/configuracion/fiscalizador-legajo/FiscalizadorLegajoDocumentsPage'
import {LegajoDocumentsPage} from '../modules/configuracion/legajo/LegajoDocumentsPage'
import {WNominaPage} from '../modules/configuracion/wnomina/WNominaPage'
import {BandejaProcesoPage} from '../modules/documentacion/bandeja-proceso/BandejaProcesoPage'
import {ProcessEdit} from '../modules/documentacion/bandeja-proceso/process-edit/ProcessEdit'
import {DocumentTrayPage} from '../modules/documentacion/document-tray/DocumentTrayPage'
import KitDocumentos from '../modules/documentacion/documento/documento'
import {MiLegajoPage} from '../modules/documentacion/mi-legajo/MiLegajoPage'
import {DocumentsReportPage} from '../modules/reports/documents-report/DocumentsReportPage'
import {InspectorDocumentsReportPage} from '../modules/reports/inspector-documents-report/InspectorDocumentsReportPage'
import {UsersReportPage} from '../modules/reports/users-report/UsersReportPage'
import AltaPage from '../modules/seguimientos/alta/Alta'
import {AsignacionProcesoPage} from '../modules/seguimientos/asignacion-procesos/AsignacionProcesoPage'
import {UsersPageAdmin} from '../modules/seguimientos/usuarios/UsersPageAdmin'
import {AsignacionMenuPage} from '../modules/seguridad/asignacion-menus/AsignacionMenuPage'
import {AsignacionTablaPage} from '../modules/seguridad/asignacion-tabla/AsignacionTablaPage'
import {AginacionUnidadPage} from '../modules/seguridad/asignacion-unidad/UsersPage'
import {AddMenuPage} from '../modules/seguridad/creacionmenu/AddMenuPage'
import {GrupoAprobacionPage} from '../modules/seguridad/grupoaprobacion/GrupoAprobacionPage'
import {PerfilPage} from '../modules/seguridad/perfil/PerfilPage'
import {PermisoPage} from '../modules/seguridad/permiso/PermisoPage'
import {UsersPage} from '../modules/seguridad/user/UsersPage'
import {VistasAdicionalesPage} from '../modules/seguridad/vistasadicionales/VistasAdicionalesPage'
const UsuarioPage = lazy(() => import('../modules/configuracion/usuario/UsuarioPage'))
const ProcesosPage = lazy(() => import('../modules/configuracion/procesos/ProcesosPage'))
const CompaniesPage = lazy(() => import('../modules/maintenances/companies/CompaniesPage'))
const FichasPage = lazy(() => import('../modules/configuracion/fichas/FichasPage'))
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
const FichaPage = lazy(() => import('../modules/documentacion/MiFicha/FichaPage'))
const ConfigurePlantilla = lazy(() => import('../modules/configuracion/documento/plantilla'))
const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

type Route = {
  path: string
  keypath?: string
  Component: React.FC
  props?: Record<string, any>
}

type RouteParent = {
  path: string
  children: Route[]
  Component?: React.FC
  props?: Record<string, any>
}

export const routesOld: Route[] = [
  {path: 'configuracion/enviodocumentos/*', Component: EnvioDocument},
  {path: 'configuracion/enviodocumentos/:id', Component: EnvioDocument},
  {
    path: 'configuracion/fichas/edit/:id/:shouldExecuteModal',
    Component: SheetEdit,
  },
  {path: 'configuracion/documentos_por_cliente/*', Component: DocumentoClientePage},
  {
    path: 'configuracion/documentos_por_cliente/edit/:id/:idCliente',
    Component: DocumentClienteEdit,
  },
  {
    path: 'configuracion/alerta2/*',
    Component: Alerts2Pages,
  },
  {
    path: 'configuracion/alertas/create',
    Component: AlertEdit,
  },
  {
    path: 'configuracion/alerta2/create',
    Component: Alert2Edit,
  },
  {
    path: 'configuracion/validar/*',
    Component: ValidationPage,
  },
  {
    path: 'configuracion/fichas/create',
    Component: SheetEdit,
  },
  {
    path: 'configuracion/enviodocumentos',
    Component: EnvioDocument,
  },
  {
    path: 'configuracion/config_documentos/edit/:id',
    Component: DocumentEdit,
  },
  {
    path: 'configuracion/documentos/view/:id',
    Component: DocumentView,
  },
  {
    path: 'configuracion/documentos/view/platilla/:idfile/:iduser',
    Component: DocumentViewPlantilla,
  },
  {
    path: 'configuracion/alertas/edit/:id',
    Component: AlertEdit,
  },
  {
    path: 'configuracion/alerta2/edit/:id',
    Component: Alert2Edit,
  },
  {
    path: 'configuracion/plantilla/*',
    Component: ConfigurePlantilla,
  },
  {path: 'configuracion/config_documentos/*', Component: ConfigDocumentos},
  {path: 'configuracion/procesos/*', Component: ProcesosPage},
  {path: 'configuracion/wnomina/*', Component: WNominaPage},
  {path: 'configuracion/fichas/*', Component: FichasPage},
  {path: 'configuracion/alertas/*', Component: AlertsPages},

  // colab
  {path: 'documentos/proceso/*', Component: BandejaProcesoPage},
  {
    path: 'documentos/proceso/edit/:id',
    Component: ProcessEdit,
  },
  {path: 'documentos/bandeja/*', Component: DocumentTrayPage},
  {path: 'documentos/kit_documentos/*', Component: KitDocumentos},
  {
    path: 'documentos/perfil/*',
    Component: PerfilPage,
  },

  {
    path: 'documentos/ficha/*',
    Component: FichaPage,
  },
  {path: 'mi-legajo/*', Component: MiLegajoPage},
  //colab end

  // seguimiento
  {
    path: 'seguimiento/procesos/*',
    Component: PartnersPage,
  },
  {
    path: 'seguimiento/alta/*',
    Component: AltaPage,
  },
  {
    path: 'seguimiento/colaboradores/*',
    Component: UsersPageAdmin,
  },
  {
    path: 'seguimiento/grupoaprobacion/*',
    Component: GrupoAprobacionPage,
  },
  {path: 'seguimiento/solicitudes/*', Component: SolicitudesPage},
  {path: 'seguimiento/documentos/*', Component: DocumentsPage},
  {path: 'seguimiento/documentos/detalle/:id', Component: DocumentDetail},
  {path: 'seguimiento/historialdocumentos/*', Component: HistorialDocumentsPage},
  {path: 'seguimiento/historialdocumentos/detalle/:id', Component: HistorialDocumentDetail},
  {path: 'seguimiento/legajo/*', Component: LegajoDocumentsPage},
  {path: 'seguimiento/fiscalizador/documento/*', Component: FiscalizadorLegajoDocumentsPage},
  {path: 'seguimiento/cpd-documentos/*', Component: DocumentsCPDPage},
  {path: 'seguimiento/cpd-documentos/detalle/:id', Component: DocumentCPDDetail},
  {
    path: 'seguimiento/procesos/observacion/:id/:namecolaborador/:observacion',
    Component: ColaboradorObservacion,
  },
  {path: 'seguimiento/procesos/validar/actions/:id/:namecolaborador', Component: ValidationActions},
  {path: 'seguimiento/procesos/edit/:id', Component: PartnerEdit, props: {editable: true}},
  {path: 'seguimiento/procesos/view/:id', Component: PartnerEdit},
  {path: 'seguimiento/asignacionproceso/*', Component: AsignacionProcesoPage},
  // end seguimiento

  // seguridad
  {
    path: 'mantenimiento/empresas/*',
    Component: CompaniesPage,
  },

  {
    path: 'seguridad/user/*',
    Component: UsersPage,
  },
  {
    path: 'seguridad/asignacionunidad/*',
    Component: AginacionUnidadPage,
  },
  {
    path: 'seguridad/permiso/*',
    Component: PermisoPage,
  },
  {
    path: 'seguridad/perfil/*',
    Component: PerfilPage,
  },
  {
    path: 'seguridad/vistasadicionales/*',
    Component: VistasAdicionalesPage,
  },
  {
    path: 'seguridad/creacionmenu/*',
    Component: AddMenuPage,
  },
  {
    path: 'seguridad/asignacionmenu/*',
    Component: AsignacionMenuPage,
  },
  {
    path: 'seguridad/asignaciontabla/*',
    Component: AsignacionTablaPage,
  },

  // end seguridad

  // ??
  {path: 'apps/chat/*', Component: ChatPage},
  {path: 'apps/user-management/*', Component: UsersPage},
  {path: 'crafted/pages/profile/*', Component: ProfilePage},
  {path: 'crafted/pages/configuracion/usuario/*', Component: UsuarioPage},
  {path: 'crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:id', Component: Ficha},
  {
    path: 'crafted/pages/configuracion/usuario/pages/fichas/plantilla/Formulario/:id',
    Component: Formulario,
  },
  {
    path: 'crafted/pages/wizards/*',
    Component: WizardsPage,
  },
  // ??

  //reportes
  {
    path: 'inspector/reportes/documentos/*',
    Component: InspectorDocumentsReportPage,
  },
  {path: 'reportes/documentos/*', Component: DocumentsReportPage},
  {path: 'reportes/usuarios/*', Component: UsersReportPage},
]

export const routes: RouteParent[] = [
  {
    path: '/configuracion',
    children: [
      // PROCESOS
      {path: 'procesos', keypath: 'configuracion/procesos/*', Component: ProcesosPage},

      // FICHAS
      {path: 'fichas', keypath: 'configuracion/fichas/*', Component: FichasPage},
      {
        path: 'fichas/edit/:id/:shouldExecuteModal',
        keypath: 'configuracion/fichas/*',
        Component: SheetEdit,
      },
      {
        path: 'fichas/create',
        keypath: 'configuracion/fichas/*',
        Component: SheetEdit,
      },

      // WNOMINA
      {path: 'wnomina', keypath: 'configuracion/wnomina/*', Component: WNominaPage},

      // DOCUMENTOS
      {
        path: 'config_documentos',
        keypath: 'configuracion/config_documentos/*',
        Component: ConfigDocumentos,
      },
      {
        path: 'config_documentos/edit/:id',
        keypath: 'configuracion/config_documentos/*',
        Component: DocumentEdit,
      },
      {
        path: 'documentos/view/:id',
        keypath: 'configuracion/config_documentos/*',
        Component: DocumentView,
      },
      {
        path: 'documentos/view/platilla/:idfile/:iduser',
        keypath: 'configuracion/config_documentos/*',
        Component: DocumentViewPlantilla,
      },

      {
        path: 'plantilla',
        keypath: 'configuracion/config_documentos/*',
        Component: ConfigurePlantilla,
      },

      // DOCUMENTOS POR CLIENTE
      {
        path: 'documentos_por_cliente',
        keypath: 'configuracion/documentos_por_cliente/*',
        Component: DocumentoClientePage,
      },
      {
        path: 'documentos_por_cliente/edit/:id/:idCliente',
        keypath: 'configuracion/documentos_por_cliente/*',
        Component: DocumentClienteEdit,
      },

      // ENVIAR DOCS
      {
        path: 'enviodocumentos',
        keypath: 'configuracion/enviodocumentos/*',
        Component: EnvioDocument,
      },
      {
        path: 'enviodocumentos/:id',
        keypath: 'configuracion/enviodocumentos/*',
        Component: EnvioDocument,
      },
      {
        path: 'enviodocumentos',
        keypath: 'configuracion/enviodocumentos',
        Component: EnvioDocument,
      },

      // ALERTAS
      {path: 'alertas', keypath: 'configuracion/alertas/*', Component: AlertsPages},
      {
        path: 'alertas/create',
        keypath: 'configuracion/alertas/*',
        Component: AlertEdit,
      },
      {
        path: 'alertas/edit/:id',
        keypath: 'configuracion/alertas/*',
        Component: AlertEdit,
      },

      // ALERTAS POR ESTADO
      {
        path: 'alerta2',
        keypath: 'configuracion/alerta2/*',
        Component: Alerts2Pages,
      },
      {
        path: 'alerta2/edit/:id',
        keypath: 'configuracion/alerta2/*',
        Component: Alert2Edit,
      },
      {
        path: 'alerta2/create',
        keypath: 'configuracion/alerta2/*',
        Component: Alert2Edit,
      },
      // end

      {
        path: 'validar',
        keypath: 'configuracion/validar/*',
        Component: ValidationPage,
      },
    ],
  },
  {
    path: '/seguimiento',
    children: [
      // PROCESOS
      {
        path: 'procesos',
        keypath: 'seguimiento/procesos/*',
        Component: PartnersPage,
      },
      {
        path: 'procesos/observacion/:id/:namecolaborador/:observacion',
        keypath: 'seguimiento/procesos/*',
        Component: ColaboradorObservacion,
      },
      {
        path: 'procesos/validar/actions/:id/:namecolaborador',
        keypath: 'seguimiento/procesos/*',
        Component: ValidationActions,
      },
      {
        path: 'procesos/edit/:id',
        keypath: 'seguimiento/procesos/*',
        Component: PartnerEdit,
        props: {editable: true},
      },
      {
        path: 'procesos/view/:id',
        keypath: 'seguimiento/procesos/*',
        Component: PartnerEdit,
      },

      // ALTA
      {
        path: 'alta',
        keypath: 'seguimiento/alta/*',
        Component: AltaPage,
      },

      // COLABORADORES
      {
        path: 'colaboradores',
        keypath: 'seguimiento/colaboradores/*',
        Component: UsersPageAdmin,
      },

      // GRUPOAPROBACION
      {
        path: 'grupoaprobacion',
        keypath: 'seguimiento/grupoaprobacion/*',
        Component: GrupoAprobacionPage,
      },

      // SOLICITUDES
      {path: 'solicitudes', keypath: 'seguimiento/solicitudes/*', Component: SolicitudesPage},

      // DOCUMENTOS
      {path: 'documentos', keypath: 'seguimiento/documentos/*', Component: DocumentsPage},
      {
        path: 'documentos/detalle/:id',
        keypath: 'seguimiento/documentos/*',
        Component: DocumentDetail,
      },

      // HISTORIAL DOCUMENTOS
      {
        path: 'historialdocumentos',
        keypath: 'seguimiento/historialdocumentos/*',
        Component: HistorialDocumentsPage,
      },
      {
        path: 'historialdocumentos/detalle/:id',
        keypath: 'seguimiento/historialdocumentos/*',
        Component: HistorialDocumentDetail,
      },

      // LEGAJO
      {path: 'legajo', keypath: 'seguimiento/legajo/*', Component: LegajoDocumentsPage},

      //FIZCALIZADOR
      {
        path: 'fiscalizador/documento',
        keypath: 'seguimiento/fiscalizador/documento/*',
        Component: FiscalizadorLegajoDocumentsPage,
      },

      // CPD DOCUMENTOS
      {
        path: 'cpd-documentos',
        keypath: 'seguimiento/cpd-documentos/*',
        Component: DocumentsCPDPage,
      },
      {
        path: 'cpd-documentos/detalle/:id',
        keypath: 'seguimiento/cpd-documentos/*',
        Component: DocumentCPDDetail,
      },

      // ASIGNACION
      {
        path: 'asignacionproceso',
        keypath: 'seguimiento/asignacionproceso/*',
        Component: AsignacionProcesoPage,
      },
    ],
  },
  {
    path: '/reportes',
    children: [
      {path: 'documentos', keypath: 'reportes/documentos/*', Component: DocumentsReportPage},
      {path: 'usuarios', keypath: 'reportes/usuarios/*', Component: UsersReportPage},
    ],
  },
  {
    path: '/seguridad',
    children: [
      {
        path: 'user',
        keypath: 'seguridad/user/*',
        Component: UsersPage,
      },
      {
        path: 'asignacionunidad',
        keypath: 'seguridad/asignacionunidad/*',
        Component: AginacionUnidadPage,
      },
      {
        path: 'permiso',
        keypath: 'seguridad/permiso/*',
        Component: PermisoPage,
      },
      {
        path: 'perfil',
        keypath: 'seguridad/perfil/*',
        Component: PerfilPage,
      },
      {
        path: 'vistasadicionales',
        keypath: 'seguridad/vistasadicionales/*',
        Component: VistasAdicionalesPage,
      },
      {
        path: 'creacionmenu',
        keypath: 'seguridad/creacionmenu/*',
        Component: AddMenuPage,
      },
      {
        path: 'asignacionmenu',
        keypath: 'seguridad/asignacionmenu/*',
        Component: AsignacionMenuPage,
      },
      {
        path: 'asignaciontabla',
        keypath: 'seguridad/asignaciontabla/*',
        Component: AsignacionTablaPage,
      },
    ],
  },
  {
    path: '/mantenimiento',
    children: [
      {
        path: 'empresas',
        keypath: 'mantenimiento/empresas/*',
        Component: CompaniesPage,
      },
    ],
  },
  {
    path: 'documentos',
    children: [
      {path: 'proceso', keypath: 'documentos/proceso/*', Component: BandejaProcesoPage},
      {path: 'proceso/view/:id', keypath: 'documentos/proceso/*', Component: ProcessEdit},
      {
        path: 'proceso/edit/:id',
        keypath: 'documentos/proceso/*',
        props: {editable: true},
        Component: ProcessEdit,
      },
      {path: 'bandeja', keypath: 'documentos/bandeja/*', Component: DocumentTrayPage},
      {path: 'kit_documentos', keypath: 'documentos/kit_documentos/*', Component: KitDocumentos},
      {
        path: 'perfil',
        keypath: 'documentos/perfil/*',
        Component: PerfilPage,
      },
      {
        path: 'ficha',
        keypath: 'documentos/ficha/*',
        Component: FichaPage,
      },
    ],
  },
  {
    path: 'mi-legajo',
    // Component: MiLegajoPage,
    children: [{path: '', keypath: 'mi-legajo/*', Component: MiLegajoPage}],
  },
  {
    path: '/inspector',
    children: [
      {
        path: 'reportes/documentos',
        keypath: 'inspector/reportes/documentos/*',
        Component: InspectorDocumentsReportPage,
      },
    ],
  },
]
