import { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { createAction, updateAction, deleteAction, createHuerfano, createOrUpdateMenu, deleteMenu, fetchMenuData, updateMenu } from '../../../../services/menu';
import { showMessage } from '../../../../shared/Alerts';
import { PartnerContext } from '../PartnerContexts';
import { Menu } from './MenuInterface';
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import { string } from 'yup';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const DataMenu: Menu = {
  _id: '',
  titulo: '',
  url: '',
  icono: '',
  tipo: 'submenu',
  order: 0,
  id_padre: '',
  id_hijo: '',
  hijos: [],
  acciones: []
};

const MenuForm = () => {
  const { menuData, setMenuData, setReloadTable, edit, setEdit } = useContext(PartnerContext);
  const [menu, setMenu] = useState<Menu>(DataMenu);
  const [isOrphan, setIsOrphan] = useState<boolean>(false);
  const [nameAccion, setNameAccion] = useState<string>('');
  const [isAccion, setIsAccion] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (menuData) {
      setMenu(menuData);
      setIsOrphan(menuData.id_padre === '');
      setIsAccion(menuData.tipo === 'accion');
    } else {
      setMenu(DataMenu);
      setIsAccion(false);
      console.log(2,DataMenu)
    }
  }, [menuData, refreshKey]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setMenu({ ...menu, [name]: value });

    if (name === 'tipo') {
      setIsAccion(value === 'accion');
    }
  };
  
  const handleChangeAccion = (index: number, newValue: string) => {
    setMenu((prevMenu) => ({
      ...prevMenu,
      acciones: prevMenu.acciones.map((accion, i) =>
        i === index ? newValue : accion
      ),
    }));
  };

  const handleChangeNameAccion = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNameAccion(value);
  };

  const clearForm = () => {
    setEdit(false);
    setMenuData(DataMenu);
    setIsOrphan(false);
    setIsAccion(false);
  };

  const handleOrphanClick = () => {
    setIsOrphan(true);
    setIsAccion(false);
    setMenu({
      ...DataMenu,
      tipo: '',
      titulo: '',
      icono: '',
      order: 0,
    });
  };

  const handleNewAction = async () => {
    const response = await createAction({...menu,name_accion:nameAccion});

    if (response.success) {
      showMessage('success', '¡Buen trabajo!', 'Acción creada correctamente');
      reloadMenuData(); // Recargar datos del menú
      clearForm();
    } else {
      showMessage('error', 'Hubo un error', response.message);
    }
  };
  const eliminarMenu = async () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Deseas eliminar este menú/submenú?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Eliminando...',
          text: 'Por favor espere',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        try {
          const response = await deleteMenu(menu);

          if (response.success) {
            showMessage('success', '¡Buen trabajo!', 'Menú eliminado correctamente');
            reloadMenuData(); 
            clearForm();
          } else {
            showMessage('error', 'Hubo un error', response.message);
          }
        } catch (error) {
          console.error('Error deleting menu', error);
          showMessage('error', 'Hubo un error', 'Hubo un error al eliminar el menú');
        }
      }
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isOrphan) {
      saveOrphanMenu();
    } 
    else {
      saveMenu();
    }

    
  };

  const saveMenu = async () => {
    Swal.fire({
      title: 'Guardando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createOrUpdateMenu(menu);

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Menú guardado correctamente');
        reloadMenuData(); 
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving menu', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar el menú');
    }
  };

  const newAction = async () =>{
    const response = await createAction({...menu,name_accion:nameAccion});

        if (response.success) {
          showMessage('success', '¡Buen trabajo!', 'Acción actualizada correctamente');
          reloadMenuData(); // Recargar datos del menú
          clearForm();
        } else {
          showMessage('error', 'Hubo un error', response.message);
        }
  }

  const saveAction = async () => {
    Swal.fire({
      title: 'Guardando Acción...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createAction(menu);

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Acción guardada correctamente');
        reloadMenuData(); 
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving action', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar la acción');
    }
  };

  const saveOrphanMenu = async () => {
    Swal.fire({
      title: 'Guardando Huérfano...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const response = await createHuerfano({ ...menu, id_padre: '' });

      if (response.success) {
        showMessage('success', '¡Buen trabajo!', 'Menú huérfano guardado correctamente');
        reloadMenuData(); 
        clearForm();
      } else {
        showMessage('error', 'Hubo un error', response.message);
      }
    } catch (error) {
      console.error('Error saving orphan menu', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al guardar el menú huérfano');
    }
  };

  const actualizarMenu = async () => {
    Swal.fire({
      title: 'Actualizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  
    try {
      
        const response = await updateMenu(menu);
  
        if (response.success) {
          showMessage('success', '¡Menú actualizado!', 'El menú se ha actualizado correctamente.');
        } else {
          showMessage('error', 'Hubo un error', response.message);
        }
      
        reloadMenuData(); 
        clearForm();
    } catch (error) {
      console.error('Error actualizando menú', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al actualizar el menú');
    }
  };
  
  const actualizarAccion = async (index: number, newValue: string) => {
    try {
      const oldName = menu.acciones[index];
  
      if (!oldName) {
        console.log('No hay acción para actualizar en el índice:', index);
        return;
      }
  
      console.log('actualizar', oldName, newValue);
  
      const response = await updateAction({
        ...menu,
        index, 
        new_name_accion: newValue, 
      });
  
      if (response.success) {
        showMessage('success', '¡Acción actualizada!', 'La acción se ha actualizado correctamente.');
  
        // Actualizar el estado del menú localmente
        const nuevasAcciones = [...menu.acciones];
        nuevasAcciones[index] = newValue;
        setMenu({ ...menu, acciones: nuevasAcciones });
      } else {
        showMessage('error', 'Error', response.message);
      }
  
    } catch (error) {
      console.error('Error actualizando menú', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al actualizar el menú');
    }
  };
  
  const handleRedirect = () => {
    window.open ("https://preview.keenthemes.com/metronic8/react/docs/icons/fontawesome","blank"); 
  };

  const eliminarAccion = async (index: number) => {
    try {
      const accionAEliminar = menu.acciones[index]; 
  
      const response = await deleteAction({
        ...menu,
        index
      });
  
      if (response.success) {
        showMessage('success', '¡Acción eliminada!', 'La acción se ha eliminado correctamente.');
  
        const nuevasAcciones = [...menu.acciones];
        nuevasAcciones.splice(index, 1);
        setMenu({ ...menu, acciones: nuevasAcciones }); 
      } else {
        showMessage('error', 'Error', response.message);
      }
    } catch (error) {
      console.error('Error eliminando acción', error);
      showMessage('error', 'Hubo un error', 'Hubo un error al eliminar la acción');
    }
  };

  const reloadMenuData = async () => {
    try {
      const response = await fetchMenuData();
      if (response.success) {
        setMenuData(response.data); 
      setRefreshKey((prev) => prev + 1)
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };

  return (
    <Container>
      <div className='card'>
        <div className="card-header">
          <h3 className="card-title">Datos</h3>
          {!isOrphan &&(
            
            <Col sm="auto" className="mb-2 ms-auto p-5">
            <Button
              type='button'
              variant='primary'
              onClick={handleOrphanClick}
            >
              Nuevo Menú
            </Button>
            </Col>
            
          )}
          
        </div>
        <Container>
          <Form onSubmit={handleSubmit}>
            {!isOrphan && menu.id_padre && (
              <Row className='mb-3 pt-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formParent'>
                    <Form.Label>Padre</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'
                      value={menu.id_padre || ''}
                      readOnly
                      style={{ backgroundColor: '#f0f0f0' }}
                      name='id_padre'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!isOrphan && (
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formId'>
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-sm'
                      value={menu.id_hijo || menu._id || ''}
                      readOnly
                      style={{ backgroundColor: '#f0f0f0' }}
                      name='_id'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className='mb-3 px-3 p-3'>
              <Col sm className='mb-2'>
                <Form.Group controlId='formTitle'>
                  <Form.Label>{isOrphan || !menu.id_padre ? 'Nombre Menú' : 'Nombre Submenú'}</Form.Label>
                  <Form.Control
                    type='text'
                    className={`form-control-sm ${
                            isAccion? 'disabled' : ''
                          }`}
                    required
                    value={menu.titulo || ''}
                    placeholder='Ingrese el nombre del menú'
                    onChange={handleChange}
                    name='titulo'
                  />
                  
                </Form.Group>
              </Col>
            </Row>
            {!isOrphan && (
              <>
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                <h3 className="card-title">{menu.id_hijo ? 'Detalle Submenú' :'Detalle Menú'}</h3>
                </Col>
              </Row>
              <Row className='mb-3 px-3'>
                    <Col sm className='mb-2'>
                      <Form.Group controlId='formUrl'>
                        <Form.Label>Url</Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-sm'
                          required
                          value={menu.url || ''}
                          placeholder='Ingrese la URL del menú'
                          onChange={handleChange}
                          name='url'
                        />
                      </Form.Group>
                    </Col>
                    <Col sm className='mb-2'>
                      <Form.Group controlId='formOrder'>
                        <Form.Label>Orden</Form.Label>
                        <Form.Control
                          type='number'
                          className='form-control-sm'
                          required
                          value={menu.order || 0}
                          placeholder='Ingrese el orden del menú'
                          onChange={handleChange}
                          name='order'
                        />
                      </Form.Group>
                    </Col>
                    <Col sm className='mb-2'>
                    <Form.Group controlId='formIcon'>
                      <Form.Label>Icono
                      <i
                      className={`fas fa-exclamation-circle ms-2 fs-5 text-primary`}
                      data-tooltip-id={`my-tooltip-1`}
                      onClick={handleRedirect}
                      style={{ cursor: "pointer" }}
                      ></i>
                        <ReactTooltip
                        id={`my-tooltip-1`}
                        place='bottom'
                        variant={`info`}
                        content={`Lista de iconos`}
                        />
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type='text'
                          className='form-control-sm flex-grow-1'
                          value={menu.icono || ''}
                          placeholder='Ingrese el icono del menú'
                          onChange={handleChange}
                          name='icono'
                        />
                        <KTIcon iconName={menu.icono || 'file'} className="fs-1 pt-1" />
                      </div>
                    </Form.Group>

                    </Col> 
              </Row>
              <Row className='mb-3 px-3'>

              {menu._id &&(
                  <>    
                  <div className='d-flex justify-content-end'>
                    {!menu.id_hijo&&(
                    <Button variant='success' type='submit'>
                      Guardar como nuevo
                    </Button>
                    )}
                    <Button
                    className='mx-2'
                    variant='warning'
                    type='button'
                    onClick={actualizarMenu}
                    >
                    Actualizar
                    </Button>
                    <Button
                      className='mx-2'
                      variant='danger'
                      type='button'
                      onClick={eliminarMenu}
                    >
                      Eliminar
                    </Button>
                    <hr></hr>
                  </div>
                  </>
                  )}
              {menu.id_hijo&&(
                <>
                <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                <h3 className="card-title">Detalle Acciones</h3>
                </Col>
              </Row>
                <Col sm className="mb-2">
                        <Form.Group controlId="formUrl">
                          <Form.Label>Nueva acción</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control-sm"
                            value={nameAccion}
                            placeholder="Ingrese Nombre de la acción"
                            onChange={handleChangeNameAccion}
                            name="nameAccion"
                          />
                        </Form.Group>
                </Col>
                <Col sm="auto" className="mb-2 d-flex align-items-end">
                        <Button variant="success" type="button" onClick={newAction}>
                          Agregar
                        </Button>
                </Col>
                <KTCardBody className='py-4'>
          <div className='table-responsive'>
          <h3 className="text-lg font-semibold">Acciones Disponibles</h3>
          <table className='table align-middle table-row-bordered fs-6 table-hover'>
            <thead>
              <tr className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white">
                <th className="text-start p-2">Nombre</th>
                <th className="text-end p-2">Acciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {menu.acciones.map((accion, index) => (
              <tr key={index}>
                 <td className="p-2">
              <input
                type="text"
                value={accion}
                className="form-control"
                onChange={(e) => handleChangeAccion(index, e.target.value)}
              />
            </td>
                <td className='text-start d-flex align-self-end justify-content-end p-1'>
                        <Stack direction='horizontal' gap={1}>
                            <a
                              href='#up'
                              className='btn btn-icon btn-bg-light btn-light-success btn-sm'
                              title='Editar'
                              onClick={() => actualizarAccion(index, menu.acciones[index])} 
                            >
                              <KTIcon iconName='check' className='fs-3' />
                            </a>
                            <a
                              href='#del'
                              className='btn btn-icon btn-bg-light btn-light-danger btn-sm'
                              title='Desactivar usuario'
                              onClick={() => eliminarAccion(index)}
                              >
                              <KTIcon iconName='cross' className='fs-3' />
                            </a>
                         
                        </Stack>
                      </td>
              </tr>
            ))}
            </tbody>
          </table>
          </div>
              </KTCardBody>
                </>
              )}     

              </Row>
              
              </>
            )}
            {isOrphan && (
              <Row className='mb-3 px-3'>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formIcon'>
                  <Form.Label>Icono
                      <i
                      className={`fas fa-exclamation-circle ms-2 fs-5 text-primary`}
                      data-tooltip-id={`my-tooltip-1`}
                      onClick={handleRedirect}
                      style={{ cursor: "pointer" }}
                      ></i>
                        <ReactTooltip
                        id={`my-tooltip-1`}
                        place='bottom'
                        variant={`info`}
                        content={`Lista de iconos`}
                        />
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type='text'
                          className='form-control-sm flex-grow-1'
                          value={menu.icono || ''}
                          placeholder='Ingrese el icono del menú'
                          onChange={handleChange}
                          name='icono'
                        />
                        <KTIcon iconName={menu.icono || 'file'} className="fs-1 pt-1" />
                      </div>
                  </Form.Group>
                </Col>
                <Col sm className='mb-2'>
                  <Form.Group controlId='formOrder'>
                    <Form.Label>Orden</Form.Label>
                    <Form.Control
                      type='number'
                      className='form-control-sm'
                      required
                      value={menu.order || 0}
                      placeholder='Ingrese el orden del menú'
                      onChange={handleChange}
                      name='order'
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col className='p-3'>
                <div className='d-flex justify-content-end'>
                  {isOrphan && (
                    <Button variant='success' type='submit'>
                      Guardar
                    </Button>
                  )}
                  
                  
                </div>
              </Col>
            </Row>


        
          </Form>
        </Container>
      </div>
    </Container>
  );
};

export { MenuForm };

