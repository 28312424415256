import {useState} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAllUsersPag} from '../../../services/users'
import {Enterprice, PartnerContext} from './PartnerContexts'
import {AddUserModal} from './add-user-modal/AddUserModal'
import {BulkAddUserModal} from './bulk-add-user-modal/BulkAddUserModal'
import {UsersListHeader} from './components/UsersListHeader'
import {UsersTable} from './components/UsersTable'
import {EditUserModal} from './show-edit-user-modal/EditUserModal'

const historialDocumentsBreadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  const [activeOnly, setActiveOnly] = useState(true)
  const [state, setState] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [entryDateFrom, setEntryDateFrom] = useState()
  const [entryDateTo, setEntryDateTo] = useState()
  const [idRegistroFicha, setIdRegistroFicha] = useState('')
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const [userData, setUserData] = useState<{}>({})
  const [updateUser, setUpdateUser] = useState(false)
  const [enterprices, setEnterprices] = useState<Enterprice[]>([])
  const [addUserModal, setAddUserModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [userEmpresa, setUserEmpresa] = useState<Record<string, string>>({})
  const [lista, setLista] = useState<any[]>([])
  const [isLoading, setLoadingStatus] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [bulkAddUserModal, setBulkAddUserModal] = useState(false)

  const openEditUserModal = () => {
    setIsEditUserModalOpen(true)
  }

  const closeEditUserModal = () => {
    setIsEditUserModalOpen(false)
  }

  const openAddUserModal = () => {
    setAddUserModal(true)
  }

  const closeAddUserModal = () => {
    setAddUserModal(false)
  }

  const openBulkAddUserModal = () => {
    setBulkAddUserModal(true)
  }

  const closeBulkAddUserModal = () => {
    setBulkAddUserModal(false)
  }

  const fetchAllUsers = async () => {
    setLoadingStatus(true)

    const response = await getAllUsersPag(currentPage, 10, {
      activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom,
      entryDateTo,
    })

    setLoadingStatus(false)
    const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages
    setTotalPages(auxTotalPage)
    setLista(response?.data)

    const ue = response?.data.reduce((acc: any, user: any) => {
      acc[user.dni] = user.empresa[0]
      return acc
    }, {})

    setUserEmpresa(ue)

    return response
  }

  return (
    <>
      <PartnerContext.Provider
        value={{
          activeOnly,
          setActiveOnly,
          state,
          setState,
          searchTerm,
          setSearchTerm,
          entryDateFrom,
          setEntryDateFrom,
          entryDateTo,
          setEntryDateTo,
          isEditUserModalOpen,
          openEditUserModal,
          closeEditUserModal,
          userData,
          setUserData,
          updateUser,
          setUpdateUser,
          enterprices,
          setEnterprices,
          addUserModal,
          openAddUserModal,
          closeAddUserModal,
          fetchAllUsers,
          setCurrentPage,
          currentPage,
          userEmpresa,
          setUserEmpresa,
          lista,
          setLista,
          isLoading,
          setLoadingStatus,
          totalPages,
          setTotalPages,
          bulkAddUserModal,
          openBulkAddUserModal,
          closeBulkAddUserModal,
        }}
      >
        <PageTitle breadcrumbs={historialDocumentsBreadcrumbs}>Usuarios</PageTitle>
        <KTCard>
          <UsersListHeader />
          <UsersTable />
        </KTCard>
        <EditUserModal />
        <AddUserModal />
        <BulkAddUserModal />
      </PartnerContext.Provider>
    </>
  )
}

export {UsersPage}
