import { useState } from "react"
import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { Alerts2Context } from "./Alerts2Contexts"
import { AlertsTable } from "./main/AlertsTable"
import { AlertsTableHeader } from "./main/AlertsTableHeader"

const alertsBreadcrumbs = [
    {
        title: 'Configuración',
        path: '/configuracion/alertas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const Alerts2Pages = () => {

    const [searchTerm, setSearchTerm] = useState('')

    return (
        <Alerts2Context.Provider value={{searchTerm, setSearchTerm}}>
            <PageTitle breadcrumbs={alertsBreadcrumbs}>Alertas</PageTitle>
            <KTCard>
                <AlertsTableHeader />
                <AlertsTable />
            </KTCard>
        </Alerts2Context.Provider>
    )
}

export { Alerts2Pages }