import {createContext} from 'react'
import {Field, Section, Sheet} from './Sheet'

export interface Table_Item {
  _id: string
  label: string
}

export interface Type {
  _id: string
  nombre: string
  identificador: string
}

interface SheetContextProps {
  searchTerm: string
  setSearchTerm: (value: string) => void
}

interface SheetEditContextProps {
  previewMode: "Colaborador" | "Administrador"; 
  setPreviewMode: (mode: "Colaborador" | "Administrador") => void; 
  isSectionModalOpen: boolean
  openSectionModal: () => void
  closeSectionModal: () => void
  isFieldModalOpen: boolean
  openFieldModal: () => void
  closeFieldModal: () => void
  isAssignmentModalOpen: boolean
  openAssignmentModal: () => void
  closeAssignmentModal: () => void
  isAssignmentRequeridoModalOpen: boolean
  openAssignmentRequeridoModal: () => void
  closeAssignmentRequeridoModal: () => void
  isPreviewModalOpen: boolean
  openPreviewModal: (mode: "Colaborador" | "Administrador") => void
  closePreviewModal: () => void
  sheetData: Sheet
  setData: (value: Sheet) => void
  sectionData: Section
  setSectionData: (value: Section) => void
  fieldData: Field
  setFieldData: (value: Field) => void,
  tables: Table_Item[]
  setTables: (value: Table_Item[]) => void
  types: Type[]
  setTypes: (value: Type[]) => void
}

const SheetContext = createContext<SheetContextProps>({
  searchTerm: '',
  setSearchTerm: (value) => {},
})

const SheetEditContext = createContext<SheetEditContextProps>({
  previewMode: "Administrador",
  setPreviewMode: () => {},
  isSectionModalOpen: false,
  openSectionModal: () => {},
  closeSectionModal: () => {},
  isFieldModalOpen: false,
  openFieldModal: () => {},
  closeFieldModal: () => {},
  isAssignmentModalOpen: false,
  openAssignmentModal: () => {},
  closeAssignmentModal: () => {},
  isAssignmentRequeridoModalOpen: false,
  openAssignmentRequeridoModal: () => {},
  closeAssignmentRequeridoModal: () => {},
  isPreviewModalOpen: false,
  openPreviewModal: () => {},
  closePreviewModal: () => {},
  sheetData: {
    name: '',
    type: '',
    description: '',
    sections: [],
  },
  setData: (value) => {},
  sectionData: {
    id: '',
    name: '',
    description: '',
    dynamic: false,
    fields: [],
    filter: false
  },
  setSectionData: (value) => {},
  fieldData: {
    id: '',
    name: '',
    tag: '',
    isDependent: false,
    responseType: '',
    comment: '',
    visible: true,
    editable: false,
    required: false,
    actualizable:false,
    collectionName: '',
    options: [],
    tablaData: [],
    type: [],
    assignedTo: '',
    dependentField: '',
    dependentFieldResponse:'',
    variable: false
  },
  setFieldData: (value) => {},
  tables: [],
  setTables: (value) => {},
  types: [],
  setTypes: (value) => {},
})

export {SheetContext, SheetEditContext}
