import React, {useEffect, useState} from 'react'
import {ListGroup, Badge, Form} from 'react-bootstrap'

const AssigmentListGroup = ({onChange, elementSelected}: any) => {
  const [searchQuery, setSearchQuery] = useState('')
  // Sample data for the list items
  // const listItems = [
  //   { id: 1, text: 'Item 1', badges: ['Badge A', 'Badge B'] },
  //   { id: 2, text: 'Item 2', badges: ['Badge C'] },
  //   { id: 3, text: 'Item 3', badges: [] },
  //   { id: 4, text: 'Item 4', badges: ['Badge D'] },
  //   { id: 5, text: 'Item 5', badges: ['Badge E'] },
  //   { id: 6, text: 'Item 6', badges: ['Badge F', 'Badge G'] },
  //   { id: 7, text: 'Item 7', badges: [] },
  //   { id: 8, text: 'Item 8', badges: ['Badge H'] },
  //   { id: 9, text: 'Item 9', badges: [] },
  //   { id: 10, text: 'Item 10', badges: ['Badge I'] },
  //   { id: 11, text: 'Item 11', badges: [] },
  //   { id: 12, text: 'Item 12', badges: [] },
  //   { id: 13, text: 'Item 13', badges: [] },
  //   { id: 14, text: 'Item 14', badges: ['Badge J'] },
  //   // Add more sample items here...
  // ];

  // const inputList = [
  //   "BeneficiosList",
  //   "TipoMoneda",
  //   "MontoTotal",
  //   "SCTR",
  //   "SeguroMedico",
  //   "SeguroVidaLey",
  //   "Otros",
  //   "HT_DominioCorreo",
  //   "HT_ListaDistribucion",
  //   "HT_EsReemplazo",
  //   "HT_JustCompraSAP",
  //   "OtrosRQList",
  //   "CL_RazonSocial",
  //   "CL_Gerencia",
  //   "CL_Area",
  //   "CL_SubArea",
  //   "CentroCosto",
  //   "RQ_BusinessPartner",
  //   "RQ_Solicitante",
  //   "RQ_Puesto",
  //   "RQ_JefeInmediato",
  //   "RQ_RespRenovContrato",
  //   "RQ_OperSistControlTiempos",
  //   "RQ_AprobVacaciones",
  //   "RQ_ConfProceso",
  //   "RQ_TipoConvocatoria",
  //   "RQ_MotivoSolicitud",
  //   "RQ_TipoContrato",
  //   "RQ_AprobIncrHC",
  //   "RQ_ModContrato",
  //   "RQ_EsRenovable",
  //   "RQ_TipoJornada",
  //   "RQ_NumeroVacantes",
  //   "PR_ApellidosNombres",
  //   "PR_Dni",
  //   "PR_Puesto",
  //   "PR_Activo",
  //   "PR_FechaCese",
  //   "PR_SueldoBase",
  //   "PR_ValeAlimentos",
  //   "PR_Movilidad",
  //   "PR_Variable",
  //   "PR_TotalRemuneracion",
  //   "PS_TipoPuesto",
  //   "PS_CalfPuesto",
  //   "PS_PeriodoPuesto",
  //   "PS_Departamento",
  //   "PS_Sede",
  //   "EF_TiempoExp",
  //   "EF_ExpRequerida",
  //   "EF_RubPreferencia",
  //   "EF_MisionPuesto",
  //   "EF_ObjetivoPuesto",
  //   "EF_Funciones",
  //   "EF_PropValor",
  //   "ComepentenciaExpFun",
  //   "HT_InfSistemas",
  //   "CandidatosSeleccionados"
  // ];

  const inputList = [
    {
      label: 'INFORMACIÓN CLIENTE',
      fields: [
        {
          label: 'Razón social',
          identifier: 'CL_RazonSocial',
          type: 'string',
        },
        {
          label: 'Gerencia',
          identifier: 'CL_Gerencia',
          type: 'string',
        },
        {
          label: 'Área',
          identifier: 'CL_Area',
          type: 'string',
        },
        {
          label: 'Subárea',
          identifier: 'CL_SubArea',
          type: 'string',
        },
      ],
    },
    {
      label: 'INFORMACIÓN GENERAL DE REQUERIMIENTO',
      fields: [
        {
          label: 'Business Partner',
          identifier: 'RQ_BusinessPartner',
          type: 'string',
        },
        {
          label: 'Solicitante',
          identifier: 'RQ_Solicitante',
          type: 'null',
        },
        {
          label: 'Puesto',
          identifier: 'RQ_Puesto',
          type: 'string',
        },
        {
          label: 'Jefe Inmediato',
          identifier: 'RQ_JefeInmediato',
          type: 'string',
        },
        {
          label: 'Responsable Renovación Contrato',
          identifier: 'RQ_RespRenovContrato',
          type: 'string',
        },
        {
          label: 'Operador de Sistemas de Control de Tiempos',
          identifier: 'RQ_OperSistControlTiempos',
          type: 'string',
        },
        {
          label: 'Aprobador de Vacaciones',
          identifier: 'RQ_AprobVacaciones',
          type: 'string',
        },
        {
          label: 'Confidencialidad del Proceso',
          identifier: 'RQ_ConfProceso',
          type: 'string',
        },
        {
          label: 'Tipo de Convocatoria',
          identifier: 'RQ_TipoConvocatoria',
          type: 'string',
        },
        {
          label: 'Motivo de Solicitud',
          identifier: 'RQ_MotivoSolicitud',
          type: 'string',
        },
        {
          label: 'Tipo de Contrato',
          identifier: 'RQ_TipoContrato',
          type: 'string',
        },
        {
          label: 'Aprobación Incremento de HC',
          identifier: 'RQ_AprobIncrHC',
          type: 'string',
        },
        {
          label: 'Modalidad de Contrato',
          identifier: 'RQ_ModContrato',
          type: 'string',
        },
        {
          label: 'Es Renovable',
          identifier: 'RQ_EsRenovable',
          type: 'string',
        },
        {
          label: 'Tipo de Jornada',
          identifier: 'RQ_TipoJornada',
          type: 'string',
        },
        {
          label: 'Número de Vacantes',
          identifier: 'RQ_NumeroVacantes',
          type: 'number',
        },
        {
          label: 'Detalle Ubicación',
          identifier: 'RQ_DetalleUbicacion',
          type: 'string',
        },
        {
          label: 'Canal',
          identifier: 'RQ_Canal',
          type: 'string',
        },
        {
          label: 'Categoria',
          identifier: 'RQ_Categoria',
          type: 'string',
        },
      ],
    },
    {
      label: 'INFORMACIÓN PERSONA A REEMPLAZAR',
      fields: [
        {
          label: 'Apellidos y Nombres',
          identifier: 'PR_ApellidosNombres',
          type: 'string',
        },
        {
          label: 'DNI',
          identifier: 'PR_Dni',
          type: 'string',
        },
        {
          label: 'Puesto',
          identifier: 'PR_Puesto',
          type: 'string',
        },
        {
          label: 'Activo',
          identifier: 'PR_Activo',
          type: 'string',
        },
        {
          label: 'Fecha de Cese',
          identifier: 'PR_FechaCese',
          type: 'string',
        },
        {
          label: 'Sueldo Base',
          identifier: 'PR_SueldoBase',
          type: 'number',
        },
        {
          label: 'Vale Alimentos',
          identifier: 'PR_ValeAlimentos',
          type: 'number',
        },
        {
          label: 'Movilidad',
          identifier: 'PR_Movilidad',
          type: 'number',
        },
        {
          label: 'Variable',
          identifier: 'PR_Variable',
          type: 'number',
        },
        {
          label: 'Total Remuneración',
          identifier: 'PR_TotalRemuneracion',
          type: 'number',
        },
      ],
    },
    {
      label: 'INFORMACIÓN SOBRE PUESTO',
      fields: [
        {
          label: 'Tipo de Puesto',
          identifier: 'PS_TipoPuesto',
          type: 'string',
        },
        {
          label: 'Calificación del Puesto',
          identifier: 'PS_CalfPuesto',
          type: 'string',
        },
        {
          label: 'Período de Puesto',
          identifier: 'PS_PeriodoPuesto',
          type: 'string',
        },
        {
          label: 'Departamento',
          identifier: 'PS_Departamento',
          type: 'string',
        },
        {
          label: 'Sede',
          identifier: 'PS_Sede',
          type: 'string',
        },
      ],
    },
    {
      label: 'EXPERIENCIA Y FUNCIONES',
      fields: [
        {
          label: 'Tiempo de Experiencia',
          identifier: 'EF_TiempoExp',
          type: 'string',
        },
        {
          label: 'Experiencia Requerida',
          identifier: 'EF_ExpRequerida',
          type: 'string',
        },
        {
          label: 'Rubro de Preferencia',
          identifier: 'EF_RubPreferencia',
          type: 'string',
        },
        {
          label: 'Misión del Puesto',
          identifier: 'EF_MisionPuesto',
          type: 'string',
        },
        {
          label: 'Objetivo del Puesto',
          identifier: 'EF_ObjetivoPuesto',
          type: 'string',
        },
        {
          label: 'Funciones',
          identifier: 'EF_Funciones',
          type: 'string',
        },
        {
          label: 'Propuesta de Valor',
          identifier: 'EF_PropValor',
          type: 'string',
        },
        {
          label: 'Competencias',
          identifier: 'ComepentenciaExpFun',
          type: 'object',
        },
      ],
    },
    {
      label: 'CANDIDATOS SELECCIONADOS',
      identifier: 'CandidatosSeleccionados',
      type: 'array',
      fields: [
        {
          label: 'Codigo Usuario',
          identifier: 'CS_Codigo',
          type: 'string',
        },
        {
          label: 'Codigo de Requerimiento',
          identifier: 'CS_CodigoRQ',
          type: 'string',
        },
        {
          label: 'Apellidos Paterno',
          identifier: 'CS_ApPaterno',
          type: 'string',
        },
        {
          label: 'Email Coorporativo',
          identifier: 'CS_EmailCoorporativo',
          type: 'string',
        },
        {
          label: 'Apellidos Materno',
          identifier: 'CS_ApMaterno',
          type: 'string',
        },
        {
          label: 'Nombres',
          identifier: 'CS_Nombres',
          type: 'string',
        },
        {
          label: 'Tipo de Documento',
          identifier: 'CS_TipoDocumento',
          type: 'string',
        },
        {
          label: 'Documento',
          identifier: 'CS_Documento',
          type: 'string',
        },
        {
          label: 'Sexo',
          identifier: 'CS_Sexo',
          type: 'string',
        },
        {
          label: 'Estado Civil',
          identifier: 'CS_EstadoCivil',
          type: 'string',
        },
        {
          label: 'Teléfono Fijo',
          identifier: 'CS_TelfFijo',
          type: 'string',
        },
        {
          label: 'Teléfono Celular',
          identifier: 'CS_TelfCelular',
          type: 'string',
        },
        {
          label: 'Nivel Académico',
          identifier: 'CS_NivAcademico',
          type: 'string',
        },
        {
          label: 'Institución',
          identifier: 'CS_Institucion',
          type: 'string',
        },
        {
          label: 'Carrera',
          identifier: 'CS_Carrera',
          type: 'string',
        },
        {
          label: 'Año de Egreso',
          identifier: 'CS_AñoEgreso',
          type: 'string',
        },
        {
          label: 'Email Personal',
          identifier: 'CS_EmailPersonal',
          type: 'string',
        },
        {
          label: 'Dirección Domicilio',
          identifier: 'CS_DirecDomicilio',
          type: 'string',
        },
        {
          label: 'Departamento',
          identifier: 'CS_Departamento',
          type: 'string',
        },
        {
          label: 'Provincia',
          identifier: 'CS_Provincia',
          type: 'string',
        },
        {
          label: 'Distrito',
          identifier: 'CS_Distrito',
          type: 'string',
        },
        {
          label: 'Nacionalidad',
          identifier: 'CS_Nacionalidad',
          type: 'string',
        },
        {
          label: 'Fecha Nacimiento',
          identifier: 'CS_FecNacimiento',
          type: 'string',
        },
        {
          label: 'Planilla Interna',
          identifier: 'CS_PlanillaInterna',
          type: 'string',
        },
        {
          label: 'Fecha Inicio Contrato',
          identifier: 'CS_FechaIniContrato',
          type: 'string',
        },
        {
          label: 'Fecha Fin Contrato',
          identifier: 'CS_FechaFinContrato',
          type: 'string',
        },
      ],
    },
    {
      label: 'CENTRO DE COSTO',
      fields: [
        {
          label: 'Centro de Costo',
          identifier: 'CentroCosto',
          type: 'object',
        },
      ],
    },
    {
      label: 'BENEFICIOS Y REMUNERACIÓN',
      fields: [
        {
          label: 'Beneficios y Remuneración',
          identifier: 'BeneficiosRemuneracion',
          type: 'object',
        },
      ],
    },
    {
      label: 'HERRAMIENTAS DE TRABAJO',
      fields: [
        {
          label: 'Herramientas de Trabajo',
          identifier: 'HerramientasTrabajo',
          type: 'object',
        },
      ],
    },
    {
      label: 'INFORMACIÓN SISTEMAS',
      fields: [
        {
          label: 'Información Sistemas',
          identifier: 'HT_InfSistemas',
          type: 'object',
        },
      ],
    }
  ]

  const [selectedItemId, setSelectedItemId] = useState('')

  useEffect(() => {
    if (elementSelected !== '') {
      setSelectedItemId(elementSelected)
    }
  }, [])

  const handleItemClick = (itemId: any) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId)
    onChange(itemId)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  // Set a maximum height for the ListGroup container
  const maxListHeight = '300px'

  return (
    <>
      <Form.Group>
        <Form.Control
          type='text'
          placeholder='Buscar...'
          value={searchQuery}
          onChange={handleSearchChange}
          className='my-2'
        />
      </Form.Group>
      <div style={{maxHeight: maxListHeight, overflowY: 'scroll'}}>
        {inputList.map((group) => {
          const filteredFields = group.fields.filter((item) =>
            item.label.toLowerCase().includes(searchQuery.toLowerCase())
          )

          if (filteredFields.length === 0) {
            return null
          }

          return (
            <>
              <p className='my-3 ms-2 fw-bold'>{group.label}</p>
              <ListGroup>
                {filteredFields.map((item) => (
                  <ListGroup.Item
                    key={item.identifier}
                    active={item.identifier === selectedItemId}
                    onClick={() => handleItemClick(item.identifier)}
                    style={{cursor: 'pointer'}}
                  >
                    {item.label}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )
        })}
      </div>
    </>
  )
}

export default AssigmentListGroup
