import React from "react"
import { Fragment, useContext, useState } from "react"
import { Button, Form, Stack } from "react-bootstrap"
import { Api, External, initExternalData, PartnerContext } from "../../PartnerContexts"
import { crearExternalData } from "../../../../../services/external"
import { showMessage } from "../../../../../shared/Alerts"
import { KTIcon } from "../../../../../../_metronic/helpers"
import { TableBusiness } from "../../../../generales_component/table/TableBusiness"
import ReactJson from "react-json-view"



interface MYProps {

}

const CreateServicio : React.FC<MYProps> = ()=>{
    const {setReloadServiceExternal,setInfoData,setIsInfoModalOpen}=useContext(PartnerContext)
    const [dataServicio,setDataServicio] = useState<External>(initExternalData)
    const [loading, setLoading] = useState(false)
    const [nameApi, setNameApi] = useState("")
    const [urlApi, setUrlApi] = useState("")
    const [bodyApi, setBodyApi] = useState({ key: "value" });
    const [arrayApi, setArrayApi] = useState<Api[]>([]);
    const [editButton, setEditButton] = useState(false);


    const handleEdit = (edit:any) => {
        if (edit.updated_src) {
            setBodyApi(edit.updated_src);
        }
    };

    const handleChange = (e: any) => {
        /* if(editButton){
            if(e.target.name === "nombre_perfil") setPerfil({...perfil, [e.target.name]: e.target.value})
        }else { */
            setDataServicio({...dataServicio, [e.target.name]: e.target.value})
        //}
    }

    const addApi = () => {
        setArrayApi([...arrayApi,{name:nameApi,url:urlApi,body:bodyApi}])
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (dataServicio._id === "") {
            setLoading(true)
            crearExternalData({...dataServicio,api:arrayApi})
            .then((response) => {
                if (response.success) {  
                showMessage("success","Agregado",response.message)
                setDataServicio(initExternalData)
                setNameApi("")
                setUrlApi("")
                setBodyApi({ key: "value" })
                setArrayApi([])
                setReloadServiceExternal(true)
                setLoading(false)
                } else {
                setLoading(false)
                console.log(response)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
        }else{
            /* setLoading(true)
            updateRolData(perfil._id,perfil)
            .then((response) => {
                if (response.success) {
                setReloadTable(true)
                showMessage("success","Actualizado",response.message)
                setPerfilData(DataPerfil)
                setEdit(false)
                setLoading(false)
                } else {
                setLoading(false)
                console.log(response)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            }) */
        }
    }

    const handleShowDetalle = (show:boolean,userData: any) => {
        setInfoData(userData)
        setIsInfoModalOpen(show)
    }

    const clear = ()=>{
        // setEdit(false)
        setDataServicio(initExternalData)
    }

    return (
        <>
        
            <Form onSubmit={handleSubmit}>
                <div className="card card-custom">
                    <div className="card-body card-scroll h-600px"> 
                        <div className="card-header">
                            <h3 className="card-title">Crear Servicio</h3>
                        </div>                  
                        <Form.Group controlId='formNombre'>
                            <Form.Label>Ingrese Nombre Servicio</Form.Label>
                            <Form.Control
                            type='text'
                            className='form-control-sm'                      
                            required
                            value={dataServicio.service}
                            placeholder='Ingrese Nombre'
                            onChange={handleChange}  
                            name='service'
                            />
                        </Form.Group>
                        <Form.Group controlId='formApi' className="mt-3">
                            <Form.Label>Ingrese Detalle Api (Opcional)</Form.Label>
                            <Stack direction='horizontal' gap={1}>
                            <Form.Control
                                type='text'
                                className='form-control-sm'                      
                                required
                                value={nameApi}
                                placeholder='Ingrese Nombre de API'
                                onChange={(e)=>{setNameApi(e.target.value)}}  
                                name='api'
                                />
                            </Stack>
                            
                            <Form.Control
                                type='text'
                                className='form-control-sm mt-2'                      
                                required
                                value={urlApi}
                                placeholder='Ingrese URL del API'
                                onChange={(e)=>{setUrlApi(e.target.value)}} 
                                name='api'
                                />

                            <ReactJson
                                style={{marginTop:"10px"}}
                                src={bodyApi}
                                onEdit={handleEdit}
                                onAdd={handleEdit}
                                onDelete={handleEdit}
                                theme="monokai"
                                />
                            
                            <a
                                href='#'
                                className='btn   btn-primary btn-sm mt-2'
                                title='Añadir Api'
                                onClick={() => addApi()}
                                >
                                    Añadir a la tabla
                                <KTIcon iconName='plus' className='fs-3 mx-3' />
                            </a>
                        </Form.Group>
                        <div className='table-responsive mt-5'>
                            <TableBusiness
                                checkbox={{
                                include: false
                                }}
                                headers={[
                                {key: 'nombre', value: 'Nombre'},
                                {key: 'url', value: 'Url'},
                                {key: 'actions', value: 'Acciones'},
                                ]}
                            >
                                {arrayApi.length > 0 ? (
                                arrayApi?.map((item: any) => (
                                    <Fragment key={item.name}>
                                    <tr>
                                        <td className='text-start min-w-100px p-0'><span className="mx-1">{item.name}</span></td>
                                        <td className='text-start min-w-100px p-0'><span className="mx-1">{item.url}</span></td>
                                        <td className='text-end d-flex align-self-end justify-content-end p-0'>
                                        <Stack direction='horizontal' gap={1}>
                                            <a
                                                href='#up'
                                                className='btn btn-icon btn-bg-light btn-light-primary btn-sm mx-2'
                                                title='Ver Detalle'
                                                onClick={() => handleShowDetalle(true, item.body)}
                                            >
                                                <KTIcon iconName='eye' className='fs-3' />
                                            </a>
                                            <a
                                                href='#up'
                                                className='btn btn-icon btn-bg-light btn-light-danger btn-sm mx-2'
                                                title='Eliminar'
                                                // onClick={() => handleShowDetalle(true, item.info)}
                                            >
                                                <KTIcon iconName='cross' className='fs-3' />
                                            </a>
                                        </Stack>
                                        </td>
                                    </tr>
                                    </Fragment>
                                ))
                                ) : (
                                <tr>
                                    <td colSpan={5}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        No matching records found
                                    </div>
                                    </td>
                                </tr>
                                )}
                            </TableBusiness>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className='d-flex justify-content-end'>
                            {!loading && editButton &&                       
                            <Button className='mx-2' variant='light' 
                            type='reset' 
                            onClick={() => {
                                clear()
                            }}>
                                Cancelar
                            </Button>
                            }
                            <Button variant='success' type='submit'>
                            {!loading && !editButton && 'Registrar'}
                            {!loading && editButton && 'Actualizar'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Subiendo...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CreateServicio