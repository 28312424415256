import {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {SheetContext, SheetEditContext} from '../SheetContexts'

const AssignmentRequeridoModalHeader = () => {
  const {closeAssignmentRequeridoModal, fieldData} = useContext(SheetEditContext)
  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle('Agregar')
    if (fieldData.id.length > 0) setTitle('Editar')
  }, [])

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Vincular con Datos Requeridos</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        onClick={() => closeAssignmentRequeridoModal()}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AssignmentRequeridoModalHeader}
