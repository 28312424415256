import React from 'react'

const WeekDaysType = {
  L: 'Lunes',
  M: 'Martes',
  X: 'Miércoles',
  J: 'Jueves',
  V: 'Viernes',
  S: 'Sábado',
  D: 'Domingo',
}

export const WeekDaysRow: React.FC<{enabledDays: string[]}> = ({enabledDays}) => {
  const days = Object.keys(WeekDaysType) as Array<keyof typeof WeekDaysType>

  return (
    <div style={{display: 'flex'}}>
      {days.map((day, index) => (
        <div
          key={index}
          style={{
            padding: '10px',
            backgroundColor: enabledDays.includes(day) ? 'lightgreen' : 'lightcoral',
            margin: '2px',
            textAlign: 'center',
            width: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
						cursor: 'pointer',
          }}
					title={WeekDaysType[day]}
        >
          {day}
        </div>
      ))}
    </div>
  )
}
