import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import {useEffect, useState} from 'react'
import {Button, Col, Container, Form, Row, Stack} from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {createAlert, getAlertById, updateAlert} from '../../../../services/alerts'
import {getFieldsBySheetDropdown} from '../../../../services/campos'
import {getSheetsByProcessForDropdown} from '../../../../services/fichas'
import {getProcessesForDropdown} from '../../../../services/procesos'
import {getAdminUsers} from '../../../../services/users'
import {showMessage} from '../../../../shared/Alerts'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import {AlertDropdown} from './Alert.interface'
import './custom.css'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/configuracion/alertas',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const allStateOptions = [
  {
    value: 'pendientePorColaborador',
    label: 'Fichas Pendientes',
    state: 'sheet',
  },
  {
    value: 'fichaSiendoEditada',
    label: 'Fichas en Progreso',
    state: 'sheet',
  },
  {
    value: 'fichaObservada',
    label: 'Fichas Observadas',
    state: 'sheet',
  },
  {
    value: 'completadoPorColaborador',
    label: 'Fichas Completadas',
    state: 'sheet',
  },
  {
    value: 'aprobadoConPendiente',
    label: 'Fichas Aprobadas con Pendientes',
    state: 'sheet',
  },
  {
    value: 'aprobadoPorAdmin',
    label: 'Fichas Aprobadas',
    state: 'sheet',
  },
  {
    value: 'historico',
    label: 'Fichas Historicas',
    state: 'sheet',
  },
  {
    value: 'pending',
    label: 'Documentos Pendientes de Firma',
    state: 'document',
  },
  {
    value: 'viewed',
    label: 'Documentos en Proceso de Firma',
    state: 'document',
  },
  {
    value: 'signed',
    label: 'Documentos Firmados',
    state: 'document',
  },
  {
    value: 'procesoCerrado',
    label: 'Procesos Cerrados',
    state: 'process',
  },
  {
    value: 'procesoCerradoConPendiente',
    label: 'Procesos Cerrados con Pendientes',
    state: 'process',
  },
  {
    value: 'procesoAnulado',
    label: 'Procesos Anulados',
    state: 'process',
  },
]

const hours = new Array(24).fill(0).map((_, i) => {
  const value = i.toString().padStart(2, '0')
  return {value, label: value}
})
const minutes = new Array(12).fill(0).map((_, i) => {
  const value = (i * 5).toString().padStart(2, '0')
  return {value, label: value}
})

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

const frequencyOptions = [
  {value: 'daily', label: 'Diaria'},
  // {value: 'weekly', label: 'Semanal'},
  {value: 'custom', label: 'Personalizado'},
]

const AlertFichaEdit = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [title, setTitle] = useState('Nueva Alerta')
  // const [value, onChange] = useState<Value>([new Date(), new Date()])
  const [selectedDays, setSelectedDays] = useState<any>([])
  const [selectedEmails, setSelectedEmails] = useState<any>([])
  const [listProcessValidationError, setListProcessValidationError] = useState(false)
  const [bodyValue, setBodyValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [processList, setProcessList] = useState<AlertDropdown[]>([])
  const [sheetList, setSheetList] = useState<AlertDropdown[]>([])
  const [fieldList, setFieldList] = useState<AlertDropdown[]>([])
  const [processValue, setProcessValue] = useState()
  const [sheetValue, setSheetValue] = useState()
  const [processOwners, setProcessOwners] = useState([])
  const [description, setDescription] = useState<string>('')
  const [filters, setFilters] = useState<any>([])
  const [validationMessage, setValidationMessage] = useState('')
  const [isNewEmailButtonEnable, setNewEmailButtonStatus] = useState(false)
  const [newEmail, setNewEmail] = useState('')

  const [frequency, setFrequency] = useState<string>('daily')
  const [hour, setHour] = useState<string>('00')
  const [minute, setMinute] = useState<string>('00')
  const [unidadId, setUnidadId] = useState<string>('')
  const [titular, setTitular] = useState('')
  const [titularTest, setTitularTest] = useState('')
  const [sheetState, setSheetState] = useState<string>('fichaSiendoEditada')
  const [typeSend, setTypeSend] = useState<'usuario' | 'titular' | 'responsableproceso'>('usuario')
  const [typeState, setTypeState] = useState<string>('sheet')
  const [adminsSelected, setAdminsSelected] = useState<any>([])
  const [processOwnersEmails, setProcessOwnersEmails] = useState<any>([])

  const {toOptions} = useUnidades()

  const dayList = [
    {value: 'monday', label: 'Lunes'},
    {value: 'tuesday', label: 'Martes'},
    {value: 'wednesday', label: 'Miércoles'},
    {value: 'thursday', label: 'Jueves'},
    {value: 'friday', label: 'Viernes'},
    {value: 'saturday', label: 'Sábado'},
    {value: 'sunday', label: 'Domingo'},
  ]

  const sheetListOptions = sheetList.map((sheet) => ({label: sheet.name, value: sheet.id}))
  const processListOptions = processList.map((process) => ({label: process.name, value: process.id}))

  useEffect(() => {
    if (id) {
      setTitle('Editar Alerta')
      getAlert(id)
    }

    getProcesses()
    getAdministrativeUsers()
  }, [])

  useEffect(() => {
    if (processOwnersEmails.length > 0) {
      setAdminsSelected(
        typeSend === 'responsableproceso'
          ? processOwnersEmails.filter((x: any) => titular.split(', ').includes(x.value))
          : []
      )
    }
  }, [processOwnersEmails])

  const getAlert = (id: string) => {
    getAlertById(id)
      .then((response) => {
        console.log(response)
        if (response.success) {
          const data = response.data
          setDescription(data.description)
          setProcessValue(data.processId)
          setSheetValue(data.sheetId)
          setFilters(data.filters)
          setBodyValue(data.bodyMessage)
          setSelectedDays(data.days)
          setSelectedEmails(data.emails)

          setFrequency(data.frequency ?? 'daily')
          setHour(data.hour ?? '00')
          setMinute(data.minute ?? '00')
          setUnidadId(data.unidad ?? '')
          setSheetState(data.sheetState ?? 'fichaSiendoEditada')
          setTypeSend(data.typeSend ?? 'usuario')
          setTitular(data.titular ?? '')
          setTypeState(data.typeState ?? 'sheet')
          //   onChange(data.dateRange)
          getSheetsByProcess(data.processId)
          getFieldsBySheet(data.sheetId)
        }
      })
      .catch((error) => console.log(error))
  }

  const goBack = () => {
    navigate('/configuracion/alertas')
  }

  const handleDaysChange = (selectedOptions: any) => {
    setSelectedDays(selectedOptions)
    setListProcessValidationError(selectedOptions.length === 0)
  }

  const handleEmailsChange = (selectedOptions: any) => {
    setSelectedEmails(selectedOptions)
  }

  const getProcesses = () => {
    setLoading(true)
    getProcessesForDropdown()
      .then((response) => {
        if (response.success) {
          setProcessList(response.data)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAdministrativeUsers = () => {
    getAdminUsers()
      .then((response) => {
        setProcessOwners(response.data.map((x: any) => ({label: x.nombre, value: x._id})))
        setProcessOwnersEmails(response.data.map((x: any) => ({label: x.nombre, value: x.email})))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSheetsByProcess = (id: string) => {
    getSheetsByProcessForDropdown(id)
      .then((response) => {
        if (response.success) {
          setSheetList(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getFieldsBySheet = (id: string) => {
    getFieldsBySheetDropdown(id)
      .then((response) => {
        if (response.success) {
          setFieldList(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleProcessChange = (e: any) => {
    setProcessValue(e.value)
    getSheetsByProcess(e.value)
  }

  const handleSheetChange = (e: any) => {
    setSheetValue(e.value)
    // getFieldsBySheet(e.value)
  }

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!unidadId || !processValue || !sheetValue) {
      showMessage(
        'error',
        'Necesita completar campos',
        'Debe seleccionar una unidad, un proceso y una ficha'
      )
      return
    }

    if(frequency === 'custom' && selectedDays.length === 0) {
      showMessage(
        'error',
        'Necesita seleccionar días',
        'Debe seleccionar al menos un día'
      )
      return
    }

    if (typeSend === 'responsableproceso' && adminsSelected.length === 0) {
      showMessage(
        'error',
        'Necesita selecionar responsables',
        'Debe seleccionar al menos un responsable'
      )
      return
    }

    if (typeSend === 'titular' && titular === '') {
      showMessage('error', 'Faltan campos obligatorios', 'Debe agregar un titular')
      return
    }

    if (selectedEmails.length === 0) {
      showMessage(
        'error',
        'Necesita selecionar correos',
        'Debe seleccionar al menos un email en copia'
      )
      return
    }

    const data = {
      description: description,
      processId: processValue,
      sheetId: sheetValue,
      filters: filters,
      bodyMessage: bodyValue,
      days: selectedDays,
      emails: selectedEmails,
      frequency,
      hour,
      minute,
      type: 'sheet',
      unidad: unidadId,
      sheetState,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      typeSend,
      titular,
      typeState,
    }

    if (id) {
      updateAlert(id, data)
        .then((response) => {
          if (response.success) {
            showMessage('success', '¡Buen trabajo!', response.message)
            goBack()
          } else {
            showMessage('error', 'Hubo un error', response.error)
          }
        })
        .catch((error) => {
          showMessage('error', 'Hubo un error', error.message)
        })
    } else {
      createAlert(data)
        .then((response) => {
          if (response.success) {
            showMessage('success', '¡Buen trabajo!', response.message)
            goBack()
          } else {
            showMessage('error', 'Hubo un error', response.error)
          }
        })
        .catch((error) => {
          showMessage('error', 'Hubo un error', error.message)
        })
    }
  }

  const handleFilterListChange = (e: any) => {
    setFilters(e)
  }

  const handleNewEmailInputChange = (e: any) => {
    const enteredEmail = e.target.value
    setNewEmail(enteredEmail)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (enteredEmail.trim() === '') {
      setValidationMessage('')
      setNewEmailButtonStatus(false)
    } else if (!emailRegex.test(enteredEmail)) {
      setValidationMessage('El email no es válido')
      setNewEmailButtonStatus(false)
    } else {
      setValidationMessage('')
      setNewEmailButtonStatus(true)
    }
  }

  const handleNewEmail = () => {
    setSelectedEmails([...selectedEmails, {value: newEmail, label: newEmail}])
    setNewEmailButtonStatus(false)
    setNewEmail('')
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleFrequencyChange = (option: any) => {
    setFrequency(option.value)

    if (option.value !== 'custom') {
      setSelectedDays([])
    }
  }

  const handleUnidadChange = (e: any) => {
    setUnidadId(e.value)
  }

  const handleTitularChange = (e: any) => {
    const enteredEmail = e.target.value
    setTitular(enteredEmail)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (enteredEmail.trim() === '') {
      setTitularTest('')
    } else if (!emailRegex.test(enteredEmail)) {
      setTitularTest('El email no es válido')
    } else {
      setTitularTest('')
    }
  }

  const handleAdminsChange = (value: any) => {
    setAdminsSelected(value)
    setTitular(value.map((x: any) => x.value).join(', '))
  }

  return (
    <>
      <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
      <KTCard className='overflow-visible'>
        {loading ? (
          <></>
        ) : (
          <Form className='pt-12 pb-6' onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col sm>
                  <Form.Group controlId='formDescription'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type='text'
                      value={description}
                      required
                      placeholder='Ingrese un nombre'
                      name='description'
                      onChange={handleDescriptionChange}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Escriba un nombre para la alerta.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId='unidadId'>
                    <Form.Label>Unidad</Form.Label>
                    <Select
                      options={toOptions()}
                      onChange={handleUnidadChange}
                      value={toOptions().find((option) => option.value === unidadId)}
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId='formProcess'>
                    <Form.Label>Proceso</Form.Label>

                    <Select
                      options={processListOptions}
                      value={processListOptions.find((option) => option.value === processValue)}
                      onChange={handleProcessChange}
                    />

                    {/* <Form.Control
                      as='select'
                      required
                      name='process'
                      value={processValue}
                      onChange={handleProcessChange}
                    >
                      <option value=''>Seleccione un proceso</option>
                      {processList.map((process) => (
                        <option value={process.id} key={process.id}>
                          {process.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Seleccione un proceso.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId='formDocument'>
                    <Form.Label>Ficha</Form.Label>
                    <Select
                      options={sheetListOptions}
                      value={sheetListOptions.find((option) => option.value === sheetValue)}
                      onChange={handleSheetChange}
                    />

                    {/* <Form.Control
                      as='select'
                      required
                      name='document'
                      value={sheetValue}
                      onChange={handleSheetChange}
                    >
                      <option value=''>Seleccione una ficha</option>
                      {sheetList.map((sheet) => (
                        <option value={sheet.id} key={sheet.id}>
                          {sheet.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      Seleccione un documento.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId='stateSheet'>
                    <Form.Label>Estado</Form.Label>
                    <Select
                      options={allStateOptions}
                      value={allStateOptions.find((option) => option.value === sheetState)}
                      onChange={(e) => {
                        setSheetState(e?.value ?? 'fichaSiendoEditada')
                        setTypeState(e?.state ?? 'sheet')
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* <FilterList list={fieldList} data={filters} onChange={handleFilterListChange} /> */}

              <Row className='mt-10'>
                <Col>
                  <Stack direction='vertical'>
                    <h6>Horario</h6>
                    <div>
                      <Form.Label>Frecuencia</Form.Label>
                      <Select
                        options={frequencyOptions}
                        value={frequencyOptions.find((option) => option.value === frequency)}
                        onChange={handleFrequencyChange}
                      />
                    </div>

                    {frequency === 'custom' && (
                      <Form.Group controlId='formDays' className='mt-3'>
                        <Form.Label>Días</Form.Label>
                        <Select
                          value={selectedDays}
                          menuPortalTarget={document.body}
                          menuPosition='fixed'
                          isMulti
                          name='days'
                          options={dayList}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          placeholder='Seleccione'
                          onChange={handleDaysChange}
                        />
                        {listProcessValidationError && (
                          <p style={{color: '#B73A47', marginTop: '10px'}}>
                            Seleccione al menos un día.
                          </p>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group className='my-3'>
                      <Row>
                        <Col>
                          <Form.Label htmlFor='hour'>Hora</Form.Label>
                          <Select
                            id='hour'
                            options={hours}
                            placeholder='0-23'
                            value={hours.find((option) => option.value === hour)}
                            onChange={(e) => setHour(e?.value ?? '00')}
                          />
                        </Col>
                        <Col>
                          <Form.Label htmlFor='minute'>Minuto</Form.Label>
                          <Select
                            id='minute'
                            // type='number'
                            // min='0'
                            // max='59'
                            options={minutes}
                            placeholder='0-55'
                            value={minutes.find((option) => option.value === minute)}
                            onChange={(e) => setMinute(e?.value ?? '00')}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Stack>
                </Col>
                <Col lg={{span: 1}}>
                  <div className='vl'></div>
                </Col>
                <Col>
                  <Stack direction='vertical'>
                    <Form.Group className='mb-4 d-flex flex-column gap-2' controlId='type_group'>
                      <Form.Label>Opciones de envio de correo</Form.Label>
                      <Form.Check
                        inline
                        type='radio'
                        name='type_group1'
                        label='Enviar correo a usuarios'
                        id='usuario'
                        checked={typeSend === 'usuario'}
                        onChange={() => {
                          setTypeSend('usuario')
                          setTitular('')
                          setAdminsSelected([])
                        }}
                      />
                      <Form.Check
                        inline
                        type='radio'
                        name='type_group1'
                        label='Enviar correo solo al titular'
                        id='titular'
                        checked={typeSend === 'titular'}
                        onChange={() => {
                          setTypeSend('titular')
                          setAdminsSelected([])
                          if (typeSend === 'responsableproceso') {
                            setTitular('')
                          }
                        }}
                      />

                      <Form.Check
                        inline
                        type='radio'
                        name='type_group1'
                        label='Enviar correo a responsables'
                        id='responsableproceso'
                        checked={typeSend === 'responsableproceso'}
                        onChange={() => setTypeSend('responsableproceso')}
                      />
                    </Form.Group>

                    {typeSend === 'titular' && (
                      <Form.Group className='mb-4'>
                        <Form.Label>Titular</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Correo del titular'
                          name='principal'
                          className='w-300px'
                          onChange={handleTitularChange}
                          value={titular}
                        />
                        {titularTest && <div style={{color: 'red'}}>{titularTest}</div>}
                      </Form.Group>
                    )}

                    {typeSend === 'responsableproceso' && (
                      <Form.Group className='mb-4'>
                        <Form.Label>Responsables</Form.Label>
                        <Select
                          value={adminsSelected}
                          menuPosition='fixed'
                          isMulti
                          name='emails-admins'
                          options={processOwnersEmails}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          placeholder='Seleccione'
                          onChange={handleAdminsChange}
                        />
                      </Form.Group>
                    )}

                    <h6>Adicionales en copia</h6>
                    <div className='d-flex flex-row align-items-center'>
                      <Form.Control
                        type='text'
                        value={newEmail}
                        placeholder='Nuevo correo'
                        name='value'
                        className='w-300px'
                        onChange={handleNewEmailInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      <button
                        type='button'
                        className='btn btn-success ms-4'
                        disabled={!isNewEmailButtonEnable}
                        onClick={() => handleNewEmail()}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                      </button>
                    </div>
                    {validationMessage && <div style={{color: 'red'}}>{validationMessage}</div>}
                    <Form.Group controlId='formEmail' className='mt-2'>
                      <Form.Label>Correos</Form.Label>
                      <Select
                        value={selectedEmails}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                        isMulti
                        name='emails'
                        options={processOwners}
                        className='basic-multi-select'
                        classNamePrefix='select'
                        placeholder='Seleccione'
                        onChange={handleEmailsChange}
                      />
                      {listProcessValidationError && (
                        <p style={{color: '#B73A47', marginTop: '10px'}}>
                          Seleccione al menos un email.
                        </p>
                      )}
                    </Form.Group>
                  </Stack>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Stack direction='vertical'>
                  <h6>Cuerpo</h6>
                  <Form.Label>Mensaje</Form.Label>
                  <ReactQuill
                    theme='snow'
                    value={bodyValue}
                    onChange={setBodyValue}
                    placeholder='Escriba un mensaje...'
                    style={{height: '150px', marginBottom: '20px'}}
                  />
                </Stack>
              </Row>
              <Row className='mt-13'>
                <div className='d-flex justify-content-end'>
                  <Button
                    variant='light'
                    className='mx-3'
                    type='reset'
                    onClick={() => {
                      goBack()
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button variant='primary' type='submit'>
                    {!loading && 'Guardar configuración'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Guardando...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Container>
          </Form>
        )}
      </KTCard>
    </>
  )
}

export {AlertFichaEdit}
