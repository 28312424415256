import {FormEventHandler, useContext, useEffect, useState} from 'react'
import {Button, Form, FormGroup, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Select from 'react-select'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {v4} from 'uuid'
import {get_Tables} from '../../../../services/campos'
import {tipos} from '../../../../services/tipos'
import {transformDependentType} from '../../../../shared/functions'
import {Field} from '../Sheet'
import {SheetEditContext} from '../SheetContexts'
import {DraggableTags} from './DraggableTags'

interface Type {
  _id: string
  nombre: string
  identificador: string
}

interface Table_Item {
  _id: string
  label: string
}

interface FieldExtended extends Field {
  isMultiple?: boolean
}

const FieldEditModalForm = () => {
  const {
    closeFieldModal,
    sheetData,
    setData,
    fieldData,
    setFieldData,
    sectionData,
    setSectionData,
    tables,
    setTables,
    types,
    setTypes,
  } = useContext(SheetEditContext)
  const [formData, setFormData] = useState<FieldExtended>({
    id: v4(),
    name: '',
    tag: '',
    isDependent: false,
    responseType: '',
    comment: '',
    visible: true,
    editable: false,
    required: false,
    actualizable: false,
    collectionName: '',
    options: [],
    tablaData: [],
    type: [],
    assignedTo: '',
    dependentField: '',
    dependentFieldResponse: '',
    isMultiple: false,
    variable: false,
  })

  // const [types, setTypes] = useState<Type[]>([])
  // const [tables, setTables] = useState<Table_Item[]>([])
  const [validated, setValidated] = useState(false)
  // const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [groupedOptions, setGroupedOptions] = useState<any>([])
  const [dependentFieldResponses, setDependentFieldResponses] = useState<any>([])
  const [sameNameError, setSameNameError] = useState<string | null>(null)

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.target.value) {
      event.preventDefault() // Prevent the default Enter key behavior (e.g., form submission)
      event.stopPropagation() // Stop the event propagation to prevent the modal from closing
    }
  }

  const handleTypeahead = (e: any) => {
    console.log(e)
    setFormData({
      ...formData,
      options: e,
    })
  }

  const CustomSelections = () => (
    // <Typeahead
    //   allowNew
    //   id='custom-selections'
    //   multiple
    //   newSelectionPrefix='Añade un nuevo elemento: '
    //   options={[]}
    //   defaultSelected={formData.options}
    //   placeholder='Escribe...'
    //   emptyLabel='Añade un nuevo elemento: '
    //   onKeyDown={handleKeyDown}
    //   onChange={handleTypeahead}
    // />
    <DraggableTags data={formData.options} onChange={handleTypeahead} />
  )

  const ListDB = () => (
    <FormGroup>
      <Form.Control
        as='select'
        name='collectionName'
        value={formData.collectionName}
        onChange={handleChange}
        placeholder='Seleccione el nombre de la tabla'
        size='sm'
        required
      >
        <option value=''>Seleccione una opción</option>
        {tables.map((table, index) => (
          <option key={index} value={table.label}>
            {table.label}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type='invalid'>Seleccione una coleccion.</Form.Control.Feedback>
    </FormGroup>
  )

  useEffect(() => {
    if (groupedOptions) {
      const concatenatedOptions = groupedOptions.reduce(
        (accumulator: any, current: any) => {
          return {options: accumulator.options.concat(current.options)}
        },
        {options: []}
      ).options

      const filteredResponses = concatenatedOptions.filter(
        (option: any) => option.value === formData.dependentField
      )

      const dependentFieldResponses = filteredResponses.map((x: any) => x.responses)
      const isArray = Array.isArray(transformDependentType(formData.dependentFieldResponse ?? ''))
      setFormData({...formData, isMultiple: isArray})
      setDependentFieldResponses(dependentFieldResponses)
    }
  }, [formData.dependentField, groupedOptions])

  useEffect(() => {
    console.log('sheetData')
    console.log(sheetData)
  }, [sheetData])

  useEffect(() => {
    // getTypes()
    // getTables()
    console.log(fieldData)
    getDependentData()
    if (fieldData.id.length > 0) {
      console.log(fieldData)
      setFormData(fieldData)
    } else {
      setFormData({
        id: v4(),
        name: '',
        tag: '',
        isDependent: false,
        responseType: '',
        comment: '',
        visible: true,
        editable: false,
        required: false,
        actualizable: false,
        collectionName: '',
        options: [],
        tablaData: [],
        type: [],
        assignedTo: '',
        dependentField: '',
        dependentFieldResponse: '',
        variable: false,
      })
    }
  }, [])

  // useEffect(() => {
  //   if (types.length > 0 && formData.responseType === '') {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       responseType: types[0]._id,
  //     }))
  //   }
  // }, [types])

  // useEffect(() => {
  //   if (tables.length > 0 && formData.collectionName === '') {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       responseType: tables[0].label,
  //     }))
  //   }
  // }, [tables])

  const getTypes = () => {
    tipos()
      .then((response) => {
        setTypes(response.data)
      })
      .catch((error) => console.log(error))
  }

  const getTables = () => {
    get_Tables()
      .then((response) => {
        setTables(response.data)
      })
      .catch((error) => console.log(error))
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'name') {
      setSameNameError(null)
      const tag = '{' + e.target.value.toUpperCase().replace(/\s/g, '_') + '}'
      formData.tag = tag
    }
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleChangeType = (e: any) => {
    const value = e.target.value

    const typeMapping: Record<string, {identificador: string; nombre: string}> = {
      '647c0d9f0b0325fc1a8215e1': {identificador: 'signature', nombre: 'Firma'},
      '647c0dc80b0325fc1a8215e7': {identificador: 'checkbox', nombre: 'checkbox'},
      '65947a4c7f8736eb2f0eb1f2': {identificador: 'table', nombre: 'Tabla'},
      '647c0dac0b0325fc1a8215e3': {identificador: 'textbox', nombre: 'Caja de Texto'},
      '64c04d0b8cb0dec07003b8d4': {
        identificador: 'dropdownlist_db',
        nombre: 'Lista desplegable BD',
      },
      '64c130a897b632fef82c6148': {identificador: 'number', nombre: 'Numero'},
      '647c0db70b0325fc1a8215e5': {identificador: 'dropdownlist', nombre: 'Lista Desplegable'},
      '647c0dce0b0325fc1a8215e9': {identificador: 'documentupload', nombre: 'carga de documentos'},
      '64c0457aaf503f7339f2ae84': {identificador: 'date', nombre: 'Fecha'},
      '64d26fa57bb026db053ee488': {identificador: 'picture', nombre: 'Imagen'},
    }

    // Buscamos si `value` está en el mapeo
    const selectedType = typeMapping[value]

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
      type: selectedType ? [{...selectedType, _id: value}] : [], // Si no hay coincidencia, se asigna un array vacío
    }))
  }

  const validateForm: FormEventHandler<HTMLFormElement> = (e) => {
    const form = e.currentTarget

    const sectionFields = sectionData.fields
    const hasSameName = sectionFields?.some(
      (x) => x.name?.toLowerCase() === formData.name?.toLowerCase() && formData.id !== x.id
    )

    if (!form.checkValidity()) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (hasSameName) {
      setSameNameError('El nombre se esta repitiendo en esta sección')
      e.preventDefault()
      e.stopPropagation()
      setValidated(false)
      return
    }

    if (form.checkValidity() && !hasSameName) {
      setSameNameError(null)
      handleSubmit(e)
    }

    setValidated(true)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (fieldData.id.length > 0) {
      console.log('fieldData')
      console.log(fieldData)
      setData({
        ...sheetData,
        sections: (sheetData.sections || []).map((x) =>
          x.id === sectionData.id
            ? {
                ...sectionData,
                fields: (sectionData.fields || []).map((y) => {
                  if (y.id === fieldData.id) {
                    console.log(formData)
                  }
                  return y.id === fieldData.id ? formData : y
                }),
              }
            : x
        ),
      })
    } else {
      setData({
        ...sheetData,
        sections: (sheetData.sections || []).map((x) =>
          x.id === sectionData.id
            ? {...sectionData, fields: [...(sectionData.fields || []), formData]}
            : x
        ),
      })
    }

    closeFieldModal()
  }

  const handleDependent = (event: any) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        isDependent: event.target.checked,
        required: false,
        dependentField: '',
        dependentFieldResponse: '',
      })
    } else {
      setFormData({...formData, isDependent: event.target.checked})
    }

    getDependentData()
  }

  const handleChangeMultiple = (e: any) => {
    setFormData({...formData, isMultiple: e.target.checked})
  }

  const getDependentData = () => {
    const output = sheetData.sections
      ?.map((section) => {
        if (!section.fields || section.fields.length === 0) return null

        const fieldsWithOptions = section.fields.map((field) => {
          const {id, name, options} = field
          const optionsArray = options?.map((option) => ({
            value: option.value,
            label: option.label,
            id: option.id,
          }))
          return {id, label: name, options: optionsArray}
        })

        return {id: section.id, label: section.name, options: fieldsWithOptions.filter(Boolean)}
      })
      .filter(Boolean)

    const dependentFields = output?.map((x) => ({
      label: x?.label,
      options: x?.options?.map((y) => ({
        value: y?.id,
        label: y?.label,
        responses: y.options?.map((response) => ({
          value: response.id,
          label: response.label,
        })),
      })),
    }))
    console.log(dependentFields)
    setGroupedOptions(dependentFields)
  }

  const handleDependentSelect = (e: any) => {
    if (groupedOptions) {
      const concatenatedOptions = groupedOptions.reduce((accumulator: any, current: any) => {
        return accumulator.concat(current.options)
      }, [])

      const filteredResponses = concatenatedOptions.filter(
        (option: any) => option.value === e.target.value
      )

      const dependentFieldResponses = filteredResponses.map((x: any) => x.responses)
      setDependentFieldResponses(dependentFieldResponses)
    }

    handleChange(e)
  }

  const handleRequiredCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setFormData({
        ...formData,
        required: e.target.checked,
        visible: e.target.checked,
        isDependent: false,
        dependentField: '',
        dependentFieldResponse: '',
      })
    } else {
      setFormData({...formData, required: e.target.checked})
    }
  }

  const handleActualizableCheckboxChange = (e: any) => {
    setFormData({...formData, actualizable: e.target.checked})
    /* if (e.target.checked) {
      setFormData({ ...formData, required: e.target.checked, visible: e.target.checked, isDependent: false, dependentField: '', dependentFieldResponse: '' })
    } else {
      setFormData({ ...formData, required: e.target.checked })
    } */
  }

  const handleVisibleCheckboxChange = (e: any) => {
    if (formData.required) {
      setFormData({...formData, visible: e.target.checked, required: e.target.checked})
    } else {
      setFormData({...formData, visible: e.target.checked})
    }
  }

  const handleChangeMulti = (e: any) => {
    const values = e.map((e: any) => e.label)
    setFormData({...formData, dependentFieldResponse: values.join('@')})
  }

  return (
    <>
      <Form validated={validated} onSubmit={validateForm} noValidate>
        <Form.Group className='mb-3' controlId='formNombre'>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type='text'
            required
            placeholder='Ingrese un nombre'
            name='name'
            value={formData.name}
            onChange={handleChange}
            className='same-height'
            isInvalid={!!sameNameError}
          />
          <Form.Control.Feedback type='invalid'>
            {sameNameError ? sameNameError : 'Escriba un nombre para el campo.'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Check // prettier-ignore
          type='checkbox'
          id='dependentCheckbox'
          label='Dependiente'
          className='mb-3'
          checked={formData.isDependent}
          onChange={(event) => handleDependent(event)}
        />

        {formData.isDependent ? (
          <>
            <Form.Group className='mb-3' controlId='formCampoDependiente'>
              <Form.Label>Campo Dependiente</Form.Label>
              <Form.Control
                as='select'
                name='dependentField'
                value={formData.dependentField}
                onChange={handleDependentSelect}
                required
              >
                <option value=''>Seleccione un campo</option>
                {groupedOptions.map((section: any, index: number) => (
                  <optgroup key={index} label={section.label}>
                    {section.options.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formRespuestaCampoDependiente'>
              <Form.Check
                type='checkbox'
                id='multipleCheckbox'
                label='Múltiple'
                className='mb-3'
                checked={formData.isMultiple ?? false}
                onChange={(event) => handleChangeMultiple(event)}
              />

              <Form.Label>Respuesta de Campo Dependiente</Form.Label>
              {formData.isMultiple === true ? (
                <Select
                  options={dependentFieldResponses[0]?.map((x: any) => ({
                    label: x.label,
                    value: x.label,
                  }))}
                  isMulti
                  onChange={handleChangeMulti}
                  defaultValue={
                    formData.dependentFieldResponse
                      ? formData.dependentFieldResponse
                          .split('@')
                          .map((x: any) => ({label: x, value: x}))
                      : null
                  }
                />
              ) : (
                <Form.Control
                  as='select'
                  name='dependentFieldResponse'
                  value={formData.dependentFieldResponse}
                  onChange={handleChange}
                >
                  <option value=''>Seleccione un campo</option>
                  {dependentFieldResponses
                    ? dependentFieldResponses[0]?.map((response: any) => (
                        <option key={response.value} value={response.label}>
                          {response.label}
                        </option>
                      ))
                    : null}
                </Form.Control>
              )}
            </Form.Group>
          </>
        ) : null}

        <Form.Group className='mb-3' controlId='formTipoRespuesta'>
          <Form.Label>Tipo de Respuesta</Form.Label>
          <Form.Control
            as='select'
            name='responseType'
            value={formData.responseType}
            onChange={handleChangeType}
            className={'form-select form-select-solid}'}
            required
          >
            <option value=''>Seleccione una opción</option>
            {types.map((type, index) => (
              <option key={index} value={type._id}>
                {type.nombre
                  .split(' ')
                  .map((word, index) => {
                    const excepciones = ['de', 'y', 'o', 'a', 'en', 'con', 'para', 'por']
                    return excepciones.includes(word.toLowerCase()) && index !== 0
                      ? word.toLowerCase()
                      : word.charAt(0).toUpperCase() + word.slice(1)
                  })
                  .join(' ')}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type='invalid'>
            Seleccione un tipo de respuesta para el campo.
          </Form.Control.Feedback>
        </Form.Group>

        <div className='mb-3'>
          {types.find((type) => type.identificador === 'dropdownlist')?._id ===
          formData.responseType
            ? CustomSelections()
            : null}
          {types.find((type) => type.identificador === 'dropdownlist_db')?._id ===
          formData.responseType
            ? ListDB()
            : null}
          {types.find((type) => type.identificador === 'checkbox')?._id === formData.responseType
            ? CustomSelections()
            : null}
        </div>

        <Form.Group className='mb-3' controlId='formComentario'>
          <Form.Label>
            Comentario
            <i
              className={'fas fa-exclamation-circle ms-2 fs-5 text-primary'}
              data-tooltip-id={'my-tooltip-1'}
            ></i>
            <ReactTooltip
              id={'my-tooltip-1'}
              place='bottom'
              variant={'info'}
              content={'Breve descripción del campo'}
            />
          </Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese un comentario'
            name='comment'
            value={formData.comment}
            onChange={handleChange}
            className='same-height'
          />
        </Form.Group>

        <div className='form-check mb-3'>
          <input
            type='checkbox'
            id='visibleCheckbox'
            // label='Visible'
            className='form-check-input'
            checked={formData.visible}
            onChange={(event) => handleVisibleCheckboxChange(event)}
          />
          <label htmlFor='visibleCheckbox'>Visible</label>
        </div>

        <div className='form-check mb-3'>
          <input
            type='checkbox'
            id='editableCheckbox'
            // label='Editable'
            className='form-check-input'
            checked={formData.editable}
            onChange={(event) => setFormData({...formData, editable: event.target.checked})}
          />
          <label htmlFor='editableCheckbox'>Editable</label>
        </div>

        <div className='form-check mb-3'>
          <input
            type='checkbox'
            id='requiredCheckbox'
            // label='Obligatorio'
            className='form-check-input'
            checked={formData.required}
            onChange={(event) => handleRequiredCheckboxChange(event)}
          />
          <label htmlFor='requiredCheckbox'>Obligatorio</label>
        </div>

        <div className='form-check mb-3'>
          <input
            type='checkbox'
            id='variableCheckbox'
            // label='Replicable'
            className='form-check-input'
            checked={formData.variable}
            onChange={(e) => {
              setFormData({...formData, variable: e.target.checked})
            }}
          />
          <label htmlFor='variableCheckbox'>Replicable</label>
        </div>
        {/* <Form.Check
          type='checkbox'
          id='actualizableCheckbox'
          label='Actualizable'
          className='mb-3'
          checked={formData.actualizable}
          onChange={(event) => handleActualizableCheckboxChange(event)}
        /> */}

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                closeFieldModal()
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export {FieldEditModalForm}
