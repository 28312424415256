import React, {Fragment, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Dropdown, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTable} from 'react-table'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import GetToken from '../../../../config/ApiClassToken'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {
  changeStatusPartner,
  closeProcess,
  deletePartner,
  getAllPartners,
} from '../../../../services/colaboradores'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {changeStatusFicha} from '../../../../services/registroFicha'
import {
  showCancellationMessage,
  showChangeStatusMessage,
  showCloseProcessMessage,
  showMessage,
  showReactivationMessage,
} from '../../../../shared/Alerts'
import {ModernPagination} from '../../../../shared/ModernPagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useQueryResponseData} from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import {usersColumns} from '../../../apps/user-management/users-list/table/columns/_columns'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PartnerContext} from '../PartnerContexts'

const PartnersTable = () => {
  const access_token = GetToken()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableBodyProps} = useTable({
    columns,
    data,
  })

  // const [isLoading, setLoadingStatus] = useState(false)
  // const [lista, setLista] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState({columnName: '', sortDirection: ''})

  const {
    filtroData,
    filtrosKeys,
    query,
    activeOnly,
    state,
    searchTerm,
    entryDateFrom,
    entryDateTo,
    processId,
    unidadId,
    documentState,
    setHints,
    processData,
  } = useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'time',
      title: 'PENDIENTE',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'COMPLETADO',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'APROBADO CON PENDIENTE',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'APROBADO',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'HISTORICO',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'EN PROGRESO',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    {
      iconName: 'exclamation-triangle', // Adjust the icon name
      title: 'ANULADO',
      stateName: 'procesoAnulado',
      color: '#e74c3c', // Red
    },
    {
      iconName: 'eye',
      title: 'OBSERVADA',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
    {
      iconName: 'exclamation-triangle',
      title: 'CERRADO',
      stateName: 'procesoCerrado',
      color: '#8A2BE2  ',
    },
    {
      iconName: 'exclamation-triangle',
      title: 'CERRADO CON PENDIENTES',
      stateName: 'procesoCerradoConPendiente',
      color: '#B22222',
    },
    {
      iconName: 'exclamation-triangle',
      title: 'PENDIENTES DE FIRMA',
      stateName: 'documentosPendientesDeFirma',
      color: '#cd27bf',
    },
    {
      iconName: 'exclamation-triangle',
      title: 'EN PROCESO DE FIRMA',
      stateName: 'documentosEnProcesoDeFirma',
      color: '#f56335',
    },
    {
      iconName: 'exclamation-triangle',
      title: 'FIRMADOS',
      stateName: 'documentosFirmados',
      color: '#A56331',
    },
  ]
  const location = useLocation()
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'get-all-partners',
      activeOnly,
      state,
      searchTerm,
      entryDateFrom,
      entryDateTo,
      currentPage,
      processId,
      unidadId,
      sortBy,
      query,
      documentState,
      access_token,
    ],
    queryFn: ({queryKey}) => {
      const [
        ,
        activeOnly,
        state,
        searchTerm,
        entryDateFrom,
        entryDateTo,
        currentPage,
        processId,
        unidadId,
        sortBy,
        query,
        documentState,
        access_token,
      ] = queryKey
      return getAllPartners(
        {
          activeOnly,
          sheetStates: state,
          searchQuery: searchTerm,
          entryDateFrom,
          entryDateTo,
          currentPage,
          limitAux: 10,
          processId,
          unidadId,
          sortBy: sortBy.columnName,
          sortOrder: sortBy.sortDirection,
          match: query?.match ?? '',
          campo: query?.campo ?? '',
          values: query?.values ?? '',
          documentState,
        },
        access_token
      )
    },
    onSuccess: (data: any) => {
      setHints(data?.totalItems ?? 0)
      setTotalPages(data?.totalPages ?? 1)
      return data
    },
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
  })

  const lista = response?.data ?? []
  // const totalPages = response?.totalPages ?? 1

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        console.log('currentpath' + JSON.stringify(currentPath))
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          console.log('PERMISOS' + JSON.stringify(subMenuResponse))
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  // useEffect(() => {
  //   fetchData()
  //   console.log('unidadId')
  //   console.log(unidadId)
  // }, [
  //   activeOnly,
  //   state,
  //   searchTerm,
  //   entryDateFrom,
  //   entryDateTo,
  //   currentPage,
  //   processId,
  //   unidadId,
  //   sortBy,
  //   query,
  //   documentState,
  // ])

  // const fetchData = useCallback(() => {
  //   if (processId && unidadId) {
  //     setLoadingStatus(true)
  //     getAllPartners(
  //       {
  //         activeOnly,
  //         sheetStates: state,
  //         searchQuery: searchTerm,
  //         entryDateFrom,
  //         entryDateTo,
  //         currentPage,
  //         limitAux: 10,
  //         processId,
  //         unidadId,
  //         sortBy: sortBy.columnName,
  //         sortOrder: sortBy.sortDirection,
  //         match: query?.match ?? '',
  //         campo: query?.campo ?? '',
  //         values: query?.values ?? '',
  //         documentState,
  //       },
  //       access_token
  //     )
  //       .then((response) => {
  //         if (response.success) {
  //           setLoadingStatus(false)
  //           console.log(response?.data)
  //           setLista(response?.data)
  //           setTotalPages(response?.totalPages)
  //           setHints(response?.totalItems ?? 0)
  //         } else {
  //           console.log(response)
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }
  // }, [
  //   activeOnly,
  //   state,
  //   searchTerm,
  //   entryDateFrom,
  //   entryDateTo,
  //   currentPage,
  //   processId,
  //   unidadId,
  //   sortBy,
  //   query,
  //   documentState,
  // ])

  const handleObservar = (id: any, namecolaborador: any, observacion: any) => {
    navigate(`/seguimiento/procesos/observacion/${id}/${namecolaborador}/${observacion}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/procesos/validar/actions/${id}/${namecolaborador}`)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCancellation = (idRegistroFicha: string, id: string) => {
    showCancellationMessage().then((result) => {
      if (result.isConfirmed) {
        deletePartner(id)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error)
          })

        changeStatusRegistroFicha(idRegistroFicha, 'procesoAnulado')
      }
    })
  }

  const handleCloseProcess = (payload: {
    partnerId: string
    processStatus: string
    idRegistroFicha: string
  }) => {
    try {
      const keys = {
        procesoCerrado: 'Cerrar proceso',
        procesoCerradoConPendiente: 'Cerrar proceso con pendientes',
      }

      showCloseProcessMessage(keys[payload.processStatus as keyof typeof keys] ?? 'Cerrar').then(
        async (result) => {
          if (result.isConfirmed) {
            const response = await closeProcess({
              partnerId: payload.partnerId,
              processStatus: payload.processStatus,
              extraMessage: result.value,
            })

            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message)
              refetch()
            } else {
              showMessage('error', 'Hubo un error', '')
            }
          }
        }
      )
    } catch (err) {
      const error = err as Error
      showMessage('error', 'Hubo un error', error.message)
    }
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then(async (result) => {
      if (result.isConfirmed) {
        const [p1, p2] = await Promise.all([
          changeStatusFicha(id, {estado: 'completadoPorColaborador'}),
          changeStatusPartner(id, 'completadoPorColaborador'),
        ])

        if (p1.success && p2.success) {
          showMessage('success', '¡Buen trabajo!', p1.message)
          refetch()
        }
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, {estado})
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleUpdate = (id: any) => {
    navigate(`/seguimiento/procesos/edit/${id}`)
  }

  const handleView = (id: any) => {
    window.open(`/seguimiento/procesos/view/${id}`, '_blank')
  }

  const shouldCloseProcess = useCallback((docs: any[]) => {
    const isAllDocsSigned = docs.every((doc) => doc.state === 'signed')
    const havePendings = docs.filter((doc) => doc.state === 'pending').length !== 0
    const someDocIsViewedOrSigned = docs.some((doc) => doc.state === 'signed' || doc.isVisualized)
    return {
      havePendings,
      closeProcess: isAllDocsSigned,
      closeProcessWithPending: isAllDocsSigned ? false : someDocIsViewedOrSigned,
    }
  }, [])

  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({onClick}: any, ref: any) => (
    <a
      ref={ref}
      href='#f'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))

  const handleSort = (columnName: string, sortDirection: string) => {
    console.log('Sorting column:', columnName, 'Direction:', sortDirection)
    setSortBy({columnName, sortDirection})
    console.log('SortBy state updated:', sortBy)
  }

  const countDocumentsByState = (docs: any[]) => {
    return docs.reduce(
      (acc, doc) => {
        if (doc.state === 'signed') {
          acc.signed++
        } else if (doc.state === 'pending') {
          if (doc.isVisualised) {
            acc.viewed++
          } else {
            acc.pending++
          }
        } else if (doc.state === 'rejected') {
          acc.rejected++
        }

        return acc
      },
      {
        signed: 0,
        pending: 0,
        viewed: 0,
        rejected: 0,
      }
    )
  }

  const changeStatusMessage = async (idFicha: string, status: string) => {
    const response = await showChangeStatusMessage()

    if (response.isConfirmed) {
      changeStatusRegistroFicha(idFicha, status)
    }
  }

  const process = processData?.find((x) => x.value === processId) ?? null
  const changeHeader = process ? !process.isOnboarding && !process.isPostulacion : false
  const labelHeader = changeHeader ? 'Fec. Asignación' : 'Fec. Ingreso'

  return (
    <>
      <KTCardBody className='py-4 min-vh-100'>
        <div className='table-responsive min-vh-100'>
          <table className='table align-middle table-row-bordered fs-6 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                {filtrosKeys.map((filtro) => (
                  <td key={filtro} className='text-start min-w-100px'>
                    {filtro.toUpperCase()}
                  </td>
                ))}
                <td className='p-3'>DNI</td>
                <SortableTableHeader
                  label='Nombres'
                  columnName='nombres'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Apellidos'
                  columnName='apellidos'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label={labelHeader}
                  columnName='fechaIngreso'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <td className='text-start'>Cargo</td>
                <td className='text-start'>Estado Proceso</td>
                {/* <th className='text-start'>Estado Ficha</th> */}
                {/* {['aprobadoPorAdmin', 'aprobadoConPendiente'].includes(state) && ( */}
                <td className='text-start'>Resumen documentos</td>
                {/* )} */}
                <td className='text-end p-3'>Opciones</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => {
                  const countStatesDocs = countDocumentsByState(item.documentSent)
                  const enableCloseProcess = shouldCloseProcess(item.documentSent)
                  const haveDocs = item?.documentSent?.length > 0
                  const shouldDocState =
                    ['aprobadoPorAdmin', 'aprobadoConPendiente'].includes(item.estadoFicha) &&
                    haveDocs
                  const stateDocs =
                    !enableCloseProcess.closeProcess && !enableCloseProcess.closeProcessWithPending
                      ? 'pending'
                      : enableCloseProcess.closeProcess
                      ? 'signed'
                      : enableCloseProcess.closeProcessWithPending
                      ? 'viewed'
                      : undefined
                  const isClosed = [
                    'procesoCerrado',
                    'procesoCerradoConPendiente',
                    'procesoAnulado',
                  ].includes(item.estadoProceso)

                  return (
                    <Fragment key={item._id}>
                      <tr>
                        {filtrosKeys.length > 0 &&
                          filtrosKeys.map((filtro) => {
                            const data = filtroData.find((f) => f.campo === filtro)
                            let value: any = {}
                            console.log(data)
                            if (data?.isUnidad === true) {
                              value.name = item?.unidad?.DesUnid || 'Vacio'
                            } else {
                              value =
                                data &&
                                data.values &&
                                data.values.find(
                                  (v) => v.id === (item.filtro ? item.filtro[filtro] : '')
                                )
                            }

                            const text = value
                              ? value.name
                              : item.filtro
                              ? item.filtro[filtro] || 'Vacio'
                              : 'No aplica'

                            return (
                              <th key={filtro} className='text-start min-w-150px p-1'>
                                {text}
                              </th>
                            )
                          })}
                        <td className='p-2'>{item.dni}</td>
                        <td className='text-start min-w-250px min-h-57px p-3'>
                          {item.nombres?.toUpperCase()}
                        </td>

                        <td className='text-start min-w-250px p-3'>
                          {item.apellidos?.toUpperCase()}
                        </td>

                        <td className='text-start min-w-200px p-3'>
                          {formatDateToDMY(item.fechaIngreso)}
                        </td>

                        <td className='text-start min-w-250px p-3'>{item.cargo?.toUpperCase()}</td>

                        <td className='text-start min-w-150px p-3'>
                          {/* {shouldDocState ? (
                            <span
                              style={{
                                backgroundColor: `${
                                  iconsData.find(
                                    (e) =>
                                      e.stateName === (isClosed ? item.estadoProceso : stateDocs)
                                  )?.color
                                }`,
                                color: 'white',
                                padding: '0.2em 0.5em',
                                borderRadius: '0.25rem',
                                marginRight: '0.5em',
                                fontSize: '0.8em',
                              }}
                            >
                              {iconsData
                                .find(
                                  (e) => e.stateName === (isClosed ? item.estadoProceso : stateDocs)
                                )
                                ?.title?.toUpperCase()}
                            </span>
                          ) : (
                            <span
                              style={{
                                backgroundColor: `${
                                  iconsData.find(
                                    (e) =>
                                      e.stateName ===
                                      (isClosed ? item.estadoProceso : item.estadoFicha)
                                  )?.color
                                }`,
                                color: 'white',
                                padding: '0.2em 0.5em',
                                borderRadius: '0.25rem',
                                marginRight: '0.5em',
                                fontSize: '0.8em',
                              }}
                            >
                              {iconsData
                                .find((e) => e.stateName === item.estadoFicha)
                                ?.title?.toUpperCase()}
                            </span>
                          )} */}

                          <span
                            style={{
                              backgroundColor: `${
                                iconsData.find(
                                  (e) =>
                                    e.stateName ===
                                    (isClosed ? item.estadoProceso : item.estadoFicha)
                                )?.color
                              }`,
                              color: 'white',
                              padding: '0.2em 0.5em',
                              borderRadius: '0.25rem',
                              marginRight: '0.5em',
                              fontSize: '0.8em',
                            }}
                          >
                            {iconsData
                              .find(
                                (e) =>
                                  e.stateName === (isClosed ? item.estadoProceso : item.estadoFicha)
                              )
                              ?.title?.toUpperCase()}
                          </span>
                        </td>

                        <td className='text-start min-w-100px p-3'>
                          <div className='d-flex gap-1 align-items-center'>
                            <OverlayTrigger
                              placement='left'
                              overlay={
                                <Tooltip id='pending-tooltip'>
                                  Documentos pendientes de firma
                                </Tooltip>
                              }
                            >
                              <span
                                style={{
                                  backgroundColor: '#f39c12',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {countStatesDocs.pending + countStatesDocs.viewed}
                              </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement='left'
                              overlay={<Tooltip id='pending-tooltip'>Documentos firmados</Tooltip>}
                            >
                              <span
                                style={{
                                  backgroundColor: '#2ecc71',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {countStatesDocs.signed}
                              </span>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement='left'
                              overlay={
                                <Tooltip id='pending-tooltip'>Documentos rechazados</Tooltip>
                              }
                            >
                              <span
                                style={{
                                  backgroundColor: '#e74c3c',
                                  color: 'white',
                                  padding: '0.2em 0.5em',
                                  borderRadius: '0.25rem',
                                  marginRight: '0.5em',
                                  fontSize: '0.8em',
                                  width: '4em',
                                  display: 'inline-block',
                                  textAlign: 'center',
                                }}
                              >
                                {countStatesDocs.rejected}
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>

                        <td className='min-w-150px text-end d-flex align-self-end justify-content-end p-1'>
                          <Stack direction='horizontal' gap={1}>
                            {permissions.includes('Visualizar Ficha') &&
                              !item.estadoFicha.includes('null') && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  title='Visualizar ficha'
                                  onClick={() => handleView(item.idRegistroFicha)}
                                >
                                  <KTIcon iconName='eye' className='fs-3' />
                                </button>
                              )}

                            {permissions.includes('Editar Ficha') &&
                              !item.estadoFicha.includes('null') &&
                              (item.estadoFicha === 'completadoPorColaborador' ||
                                item.estadoFicha === 'aprobadoPorAdmin' ||
                                item.estadoFicha === 'historico' ||
                                item.estadoFicha === 'aprobadoConPendiente') && (
                                <a
                                  href='#edit'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  title='Editar ficha'
                                  onClick={() => handleUpdate(item.idRegistroFicha)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </a>
                              )}
                            {permissions.includes('Opciones Ficha') &&
                              !item.estadoFicha.includes('null') && (
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu className='fs-7'>
                                    <Dropdown.Item onClick={() => handleView(item.idRegistroFicha)}>
                                      Visualizar Ficha
                                    </Dropdown.Item>
                                    {item.estadoFicha !== 'pendientePorColaborador' &&
                                      item.estadoFicha !== 'fichaSiendoEditada' &&
                                      item.estadoFicha !== 'completadoPorColaborador' && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleObservar(
                                              item.idRegistroFicha,
                                              `${item.apellidos} ${item.nombres}`,
                                              'true'
                                            )
                                          }
                                        >
                                          Observaciones
                                        </Dropdown.Item>
                                      )}

                                    {item.estadoFicha === 'completadoPorColaborador' && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleValidation(
                                            item.idRegistroFicha,
                                            `${item.apellidos} ${item.nombres}`
                                          )
                                        }
                                      >
                                        Validar Enrolamiento
                                      </Dropdown.Item>
                                    )}

                                    {item.estadoFicha !== 'procesoAnulado' && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleCancellation(item.idRegistroFicha, item._id)
                                        }
                                      >
                                        Anular Proceso
                                      </Dropdown.Item>
                                    )}

                                    {[
                                      'procesoAnulado',
                                      'procesoCerrado',
                                      'procesoCerradoConPendiente',
                                    ].includes(item.estadoProceso) && (
                                      <Dropdown.Item
                                        onClick={() => handleReactivation(item.idRegistroFicha)}
                                      >
                                        Reactivar Proceso
                                      </Dropdown.Item>
                                    )}

                                    {['aprobadoPorAdmin', 'aprobadoConPendiente'].includes(
                                      item.estadoFicha
                                    ) &&
                                      stateDocs === 'pending' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            changeStatusMessage(
                                              item.idRegistroFicha,
                                              'documentosPendientesDeFirma'
                                            )
                                          }}
                                        >
                                          Pendientes de Firma
                                        </Dropdown.Item>
                                      )}

                                    {[
                                      'aprobadoPorAdmin',
                                      'aprobadoConPendiente',
                                      'documentosPendientesDeFirma',
                                    ].includes(item.estadoFicha) &&
                                      ![
                                        'procesoAnulado',
                                        'procesoCerrado',
                                        'procesoCerradoConPendiente',
                                      ].includes(item.estadoProceso) &&
                                      stateDocs === 'viewed' && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            changeStatusMessage(
                                              item.idRegistroFicha,
                                              'documentosEnProcesoDeFirma'
                                            )
                                          }}
                                        >
                                          En Proceso de Firma
                                        </Dropdown.Item>
                                      )}

                                    {[
                                      'aprobadoPorAdmin',
                                      'aprobadoConPendiente',
                                      'documentosPendientesDeFirma',
                                      'documentosEnProcesoDeFirma',
                                    ].includes(item.estadoFicha) &&
                                      ![
                                        'procesoAnulado',
                                        'procesoCerrado',
                                        'procesoCerradoConPendiente',
                                      ].includes(item.estadoProceso) &&
                                      (stateDocs === 'signed' ||
                                        !enableCloseProcess.havePendings) && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            changeStatusMessage(
                                              item.idRegistroFicha,
                                              'documentosFirmados'
                                            )
                                          }}
                                        >
                                          Documentos Firmados
                                        </Dropdown.Item>
                                      )}

                                    {['documentosEnProcesoDeFirma'].includes(item.estadoFicha) &&
                                      ![
                                        'procesoAnulado',
                                        'procesoCerrado',
                                        'procesoCerradoConPendiente',
                                      ].includes(item.estadoProceso) && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleCloseProcess({
                                              partnerId: item._id,
                                              processStatus: 'procesoCerradoConPendiente',
                                              idRegistroFicha: item.idRegistroFicha,
                                            })
                                          }
                                        >
                                          Cerrar proceso con pendientes
                                        </Dropdown.Item>
                                      )}

                                    {(['documentosFirmados'].includes(item.estadoFicha) ||
                                      item.estadoProceso === 'procesoCerradoConPendiente') &&
                                      !['procesoAnulado', 'procesoCerrado'].includes(
                                        item.estadoProceso
                                      ) && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleCloseProcess({
                                              partnerId: item._id,
                                              processStatus: 'procesoCerrado',
                                              idRegistroFicha: item.idRegistroFicha,
                                            })
                                          }
                                        >
                                          Cerrar proceso
                                        </Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </Stack>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <section className='d-flex justify-content-end align-items-center mt-4'>
          <ModernPagination
            totalPages={totalPages}
            currentPage={currentPage}
            isLoading={isLoading}
            onPageChange={handlePageChange}
          />
        </section>
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {PartnersTable}
