import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { PartnersTableGeneral } from '../../../generales_component/PartnersTableGeneral';
import { getProceso, getProcessUsers } from '../../../../services/procesos';
import { PartnerContext } from '../PartnerContexts'
import { array } from 'yup'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Doc } from '../enviodocumentInterface';
import { isObject } from 'formik';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';


const ShowCargaExcelModalForm = () => {
  const {setShowCargaExcelModal, dataDni, setDataDni } = useContext(PartnerContext)
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    readFile()
  },[file])

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0] || null);
  }

  const readFile = () => {
    if (file) {
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.onload = async (e:any) => {
        const arrayBuffer = e.target.result;
        await workbook.xlsx.load(arrayBuffer);
        // Supongamos que el archivo Excel tiene una sola hoja
        const worksheet = workbook.getWorksheet(1);
        // Accede a los datos de la hoja y procesa según tus necesidades
        const datDNI :string[] = []
        worksheet.eachRow((row, rowNumber) => {
          row.eachCell((cell, colNumber) => {
            if(rowNumber>1){
              datDNI.push(String(cell.text))
            }
            //console.log(`Fila ${rowNumber}, Columna ${colNumber}, Valor: ${cell.text}`);
          });
        });
        setDataDni(datDNI)
      };
      reader.readAsArrayBuffer(file);
      setShowCargaExcelModal(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
        "application/xlsx": [".xlsx"],
    },
    maxFiles: 1
  });

  return(
    <>
        <Container className="mt-5">
            <Row>
                <Col sm={12} >
                  <div className='' >
                    { (
                      <div {...getRootProps()} className={`elevator-with-shadow dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        <i className="bi bi-cloud-upload fs-4x"></i>
                        {isDragActive ? <p>Arrastra y suelta los archivos aquí...</p> : <p>Arrastra archivos aquí o haz clic para seleccionar</p>
                        }
                        <button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary">Seleccionar</button>
                      </div>
                    )}
                  </div>
                </Col>
                
            </Row>
        </Container>
    </>
)
}

export {ShowCargaExcelModalForm}
