import {useContext, useState} from 'react'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import Collapsible from 'react-collapsible'
import {CollapsibleFieldItem} from './CollapsibleFieldItem'
import {SheetEditContext} from '../SheetContexts'

// Fake data generator
const getItems = (count: number) =>
  Array.from({length: count}, (_, k) => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }))

// A little function to help with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#c7fff0' : '#EFEFEF',
  padding: grid,
  width: '100%',
  borderRadius: 10,
})

const DraggableFieldList = ({sectionItem,permisos}: any) => {
  const [items, setItems] = useState(() => getItems(4))
  const {sheetData, setData} = useContext(SheetEditContext)

  const section = (sheetData.sections || []).find((n) => n.id === sectionItem.id)
  const fields = section ? section.fields : []

  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(fields || [], result.source.index, result.destination.index)

    setData({
      ...sheetData,
      sections: sheetData?.sections?.map((n) => {
        if (n.id === sectionItem.id) {
          return { ...n, fields: updatedItems };
        } else {
          return n; // Return the original element if the condition is not met
        }
      }),
    });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {(fields || []).map((item, index) => (
              <CollapsibleFieldItem key={item.id} item={item} index={index} sectionItem={sectionItem} permisos={permisos} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export {DraggableFieldList}
