import React, { useEffect, useState } from 'react';
import { downloadFileS3, getFileInPDF, getFileSigned } from '../../../../../services/files';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Col, Row, Spinner } from 'react-bootstrap';

import './PDFViewer.css';
import { Doc, PDFViewerProps,detDocument } from '../Document.interface';
import { formatISODateDetalleToDMYHM } from '../../../../../helpers/dateFunctions';
import { KTIcon } from '../../../../../../_metronic/helpers';

const PDFViewer: React.FC<PDFViewerProps> = ({ keyn = '' ,idn = '', extra = {}, statepending=false }) => {
  const [pdfData, setPdfData] = useState<Doc[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [detalleDocument, setDetalleDocument] = useState<any[]>([])

  const fetchPdfData = () => {

    if(keyn && idn && !statepending){
      getFileSigned(idn,keyn)
      .then((response) => {
        if (response.success) {
          const newInfoDocument:any[] = []
          const doc = [
            {
              uri: response.data.document,
              fileType: 'pdf',
              fileName: 'test',
            },
          ]
          response.data.info.historial.map((det:any) => {
            newInfoDocument.push({...det,createdAt:formatISODateDetalleToDMYHM(det.createdAt)})
          })
          setDetalleDocument(newInfoDocument)
          setPdfData(doc)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
    }else{
      getFileInPDF(keyn,extra)
      .then((response) => {
        if (response.success) {

          const doc = [
            {
              uri: response.data,
              fileType: 'pdf',
              fileName: 'test',
            },
          ];

          setPdfData(doc)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
    }
    
  };

  useEffect(() => {
    fetchPdfData();
  }, [keyn,idn]);

  return (
    <div className="">
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando...</span>
          </Spinner>
        </div>
      ) : (
        <>
        
          { detalleDocument.length > 0 ?
            <Row>
              <Col className='pdf-viewer-container' sm={9} >
                <DocViewer
                  documents={pdfData}
                  pluginRenderers={DocViewerRenderers}
                  style={{ height: 850 }}
                  theme={{
                    primary: "#ffffff",
                    secondary: "#5C5C9C",
                    tertiary: "#5296d899",
                    textPrimary: "#ffffff",
                    textSecondary: "#5296d8",
                    textTertiary: "#00000099",
                    disableThemeScrollbar: false,
                  }}
                  config={{ header: { disableHeader: true } }}
                />
              </Col>
              <Col sm={3} >
                { detalleDocument[detalleDocument.length - 1].status === 'signed' && 
                    <div className="card card-custom card-stretch-50 shadow ">
                        <div className="card-header px-5" style={{minHeight:"50px"}}>
                            <h5 className="card-title">Detalle de la Firma</h5>
                        </div>
                        { detalleDocument.map(det => (
                            det.status === 'signed'? 
                                <div className="card-body px-2">
                                    <div className='d-flex align-items-center flex-grow-1'>
                                        <div className='symbol symbol-30px '>
                                            <div className="symbol-label fs-5 fw-bold bg-primary text-inverse-primary mx-2">{"MS"}</div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <a className='text-gray-800 text-hover-primary fs-7 fw-bold'>
                                            {det.name}
                                            </a>

                                            <span className='text-gray-400 fs-8 fw-semibold'>{det.email}</span>
                                        </div>
                                    </div>                                
                                    <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                        <a className='link-primary fw-bold'>                                    
                                        Fecha/Hora: {' '}
                                        </a>
                                        { det.createdAt }
                                    </div>                               
                                    <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                        <a className='link-primary fw-bold'>                                    
                                        Token: {' '}
                                        </a>
                                        { det.token }
                                    </div>                               
                                    <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                        <a className='link-primary fw-bold'>                                    
                                        IP: {' '}
                                        </a>
                                        { det.ip }
                                    </div>                               
                                    <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                        <a className='link-primary fw-bold'>                                    
                                        User agent: {' '}
                                        </a>
                                        { det.userAgent }
                                    </div>
                                </div>
                            : ''
                        ))

                        }
                    </div>
                }
                

                { detalleDocument.length > 0 && (
                    <div className="card card-custom card-stretch-50 shadow mt-10">
                        <div className="card-header" style={{minHeight:"40px"}}>
                            <h5 className="card-title">Historial</h5>
                        </div>                        
                        <div className="card-body px-2">
                            {detalleDocument.map(det => (        
                                <div className='d-flex align-items-center bg-light-primary rounded p-2 mb-3'>
                                    <span className=' text-primary'>
                                        <KTIcon iconName='document' className='text-primary fs-1 me-3' />
                                    </span>
                                    <div className='flex-grow-1 me-2'>
                                        <a  className='fw-bold text-gray-800 text-hover-primary fs-7'>
                                        {det.motivo} {det.status === 'send'?det.email:det.name}
                                        </a>
                                        <span className='text-muted fw-semibold d-block fs-7'>{ det.createdAt }</span>
                                    </div>
                                </div>
                                
                            ))}
                        </div>
                    
                    </div>
                
                )}
                
            </Col>
            
            </Row> 
            : 
              <Row >
                <Col className='pdf-viewer-container' sm={12} >
                <DocViewer
                  documents={pdfData}
                  pluginRenderers={DocViewerRenderers}
                  style={{ height: 850 }}
                  theme={{
                    primary: "#ffffff",
                    secondary: "#5C5C9C",
                    tertiary: "#5296d899",
                    textPrimary: "#ffffff",
                    textSecondary: "#5296d8",
                    textTertiary: "#00000099",
                    disableThemeScrollbar: false,
                  }}
                  config={{ header: { disableHeader: true } }}
                />
                </Col>
              </Row>
          }
          
        </>
        
      )}
    </div>
  );
};

export default PDFViewer;
