import {Col, Container, Form, Row, Stack} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useContext, useEffect, useState} from 'react'
import {PartnerContext} from '../PartnerContexts'
import {useDebounce} from 'use-debounce'
import {TableSearch} from '../../../../shared/TableSearch'
import { generateExcel, generateSimplifiedExcel } from '../../../../services/fichas'
import Select from 'react-select';
import {  getAllPartnersSelect } from '../../../../services/colaboradores'
import { number } from 'yup'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import { downloadMultipleDocumentsIds, downloadMultipleDocumentsKeyName } from '../../../../services/documentSent'
import { showMessage } from '../../../../shared/Alerts'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'

const LegajoDocumentsListHeader = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  
  const [listProcessValidationError, setListProcessValidationError] = useState(false)
  

  const {setActiveOnly,setSearchTerm,setSearchDocumento, documentsAux, setEntryDateFrom, setEntryDateTo, listProcessAll, setProcessSelected, setSearchSend} =
    useContext(PartnerContext)
    
  const [listUser, setProcessOwners] = useState([])
  const [listProceso, setListProceso] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [documents, setDocuments] = useState<{ [año: number]: { [mes: string]:{ [id_proceso: string]: { [user: string]: any[] }} } }>(documentsAux)
  const [cargandoFile, setCargandoFile] = useState(false);

    useEffect(()=>{
      getUsersSelect()
    },[])

    useEffect(()=>{
      setDocuments(documentsAux)
    },[documentsAux])

    useEffect(()=>{
      actualizarProceso()
    },[listProcessAll])


    

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }


  const handleExport = async (id: any) => {

    const documentExport: { [fecha: string]:{  [user: string]: {  [info: string]: string[]  }  }  } = {};
    Object.keys(documents).map((key:any) => {
      Object.keys(documents[key]).map((key1:any,ind0) => {            
        Object.keys(documents[key][key1]).map((key_idproceso:any,ind) => {
          Object.keys(documents[key][key1][key_idproceso]).map((key2:any,ind1) => {                
            documents[key][key1][key_idproceso][key2].map(key3 => {
              if(key3.checkSelected){
                if (!documentExport[`${key}_${key1}`]) {
                  documentExport[`${key}_${key1}`] = {};
                }
                if (!documentExport[`${key}_${key1}`][`${key2}`]) {
                  documentExport[`${key}_${key1}`][`${key2}`] = {};
                  documentExport[`${key}_${key1}`][`${key2}`]['keyname'] = [];
                  documentExport[`${key}_${key1}`][`${key2}`]['user']  = [];
                  documentExport[`${key}_${key1}`][`${key2}`]['date']  = [];
                }

                documentExport[`${key}_${key1}`][`${key2}`]['keyname'].push(key3.keyname)
                documentExport[`${key}_${key1}`][`${key2}`]['user'].push(key2)
                documentExport[`${key}_${key1}`][`${key2}`]['date'].push(key3.createdAt)
              }
            })
          })
        })
      })
    })


    Object.keys(documentExport).map(async (key:any) => {
      Object.keys(documentExport[key]).map(async (key1:any,ind0) => {
          setCargandoFile(true)
          await downloadMultipleDocumentsKeyName({data:documentExport[key][key1]})
            .then((response) => {
              setCargandoFile(false)
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.download = `${key}_${key1}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    showMessage('success', '¡Buen trabajo!', response.message)
                } else {
                    console.log(response)
                    showMessage('error', 'Hubo un error', response.error)
                }
            })
            .catch((error) => {
              setCargandoFile(false)
                console.log(error)
                showMessage('error', 'Hubo un error', error.message)
            })
      }) 
    })

  }

  const getUsersSelect = () => {
    getAllPartnersSelect({})
      .then((response) => {
        setProcessOwners(response.data.map((x: any) => ({ label: x.dni+' - '+x.apellidos+' '+x.nombres+' ', value: x.id_user })))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const actualizarProceso = () => {
    setListProceso(listProcessAll?.map((x: any) => ({ label: x.nombre, value: x._id })))
  }

  const handleSelectChange = (e: any) => {
    setProcessSelected(e.map((x: any) => x.value))
    // setFormData({ ...formData, managers: e.map((x: any) => x.value) })
    processOwnersValidation(e.map((x: any) => x.value))
  }

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setListProcessValidationError(true)
    } else {
      setListProcessValidationError(false)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setSearchDocumento(event.target.value)    
  }


  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          <Col sm="5" className='mb-3' >
            <Stack direction='vertical'>
              <span className='fw-bold'>Fecha de Ingreso</span>
              <Stack className='mt-2' direction='horizontal' gap={3}>
                <span className='fw-bold'>Inicio </span>
                  <Form.Control
                    type='date'
                    size='sm'
                    placeholder='Inicio'
                    onChange={(e) => setEntryDateFrom(e.target.value)}
                  />
                  <span className='fw-bold'>Fin </span>
                  <Form.Control
                    type='date'
                    placeholder='Fin'
                    size='sm'
                    onChange={(e) => setEntryDateTo(e.target.value)}
                  />
              </Stack>
            </Stack>
          </Col>
          <Col sm="3">
              <Form.Label>Colaboradores</Form.Label>
            <TableSearch placeholderSearch="Buscar por DNI o Nombres" typeBusqueda="Proceso" onSearch={handleSearch} design={true} />
          </Col>
          <Col sm="3">
            <Form.Group className='mb-3' controlId='formResponsables'>
              <Form.Label>Procesos</Form.Label>
              <Select
                // defaultValue={listUser?.filter((x: any) => formData?.managers?.includes(x.value))}
                isMulti
                styles={{control: (provided) => ({
                  ...provided,
                  fontSize: '12px',
                  minHeight: '34px', // Reducción del alto
                  height: '34px', 
                })}}
                name='managers'
                options={listProceso}
                className='basic-multi-select'
                classNamePrefix='select'
                placeholder='Seleccione'
                onChange={handleSelectChange}
              />
              {listProcessValidationError && <p style={{ color: '#B73A47', marginTop: '10px' }}>Seleccione al menos un processo.</p>}
            </Form.Group>
          </Col>

          <Col sm="1" className='pt-8'>
              <button type='button' className='btn btn-success btn-sm' onClick={()=>{setSearchSend(true)}}>
                <KTIcon iconName='filter-search' className='fs-2' />
                
              </button>
          </Col>
        </Row>
        <Row>          
          <Col className='mb-3' >
            <span className='fw-bold'>Buscar Documento</span>
              <Form.Control
                size='sm'
                type='text'
                placeholder="buscar por nombre del documento"
                value={searchQuery}
                onChange={handleSearchChange}
                className='form-control-sm my-2 '
              />
          </Col>
          <Col className='mb-3' >          
          </Col>
          <Col className='mb-5 d-flex align-self-end justify-content-end' >            
              <button type='button' className='btn btn-primary btn-sm' onClick={handleExport}>
                <KTIcon iconName='file-down' className='fs-2' />
                Descargar Archivos
              </button>    
          </Col>
        </Row>
      </div>
      
      {cargandoFile && <OverlayLoading />}
    </>
  )
}

export {LegajoDocumentsListHeader}
