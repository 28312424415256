import { Switch } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { fetchMenuData, fetchMenuDataByRoleMenu, updateMenuRole } from '../../../../services/menu';
import { PartnerContext } from '../PartnerContexts';
import { WorkingDocs } from '../../../../types-module/WDocsNS';
import { getRoles } from '../../../../services/rol';
import { getAllCompanies, getUnidadesEmpresas } from '../../../../services/companies';
import {DataUser} from '../../../documentacion/MiFicha/DataUserInterface'
import {useAuth2} from '../../../authv2/Auth2Provider'


interface SubMenu {
  id_padre: string;
  id_hijo: string;
  titulo: string;
  url: string;
  fontIcon: string;
  order: number;
  acciones: string[];
  hasRole: boolean;
}

interface Menu {
  _id: string;
  titulo: string;
  icono: string;
  order: number;
  hijos: SubMenu[];
  hasRole: boolean;
}

const MenuAsignacionForm = () => {
  const { reloadTable } = useContext(PartnerContext)
  const [menus, setMenus] = useState<Menu[]>([])
  const [role, setRole] = useState('ADMIN')
  const [empresa, setEmpresa] = useState('')
  const [unidad, setUnidad] = useState('')
  const [empresaId, setEmpresaId] = useState('')

  const [allRoles, setAllRoles] = useState<WorkingDocs.Role[]>([])
  const [allEmpresas, setAllEmpresas] = useState<WorkingDocs.Company[]>([])
  const [allUnidades, setAllUnidades] = useState<WorkingDocs.UnidadCompany[]>([])

  const {selectedCompanyId, userData} = useAuth2()
  const userId = userData.userId

  useEffect(() => {
    fetchMenus()
    getRoles().then((res) => {
      if (res.success) {
        setAllRoles(res?.data ?? [])
      }
    })
    getAllCompanies('',1,10).then((res)=>
    {
      if(res.success){
        setAllEmpresas(res?.data??[])
      }
    })
    
  }, [role,reloadTable]);


  useEffect(() => {
    fetchMenus()
    if (empresaId) {
      getUnidadesEmpresas([String(empresaId)], userId).then((res) => {
        if (res.success) {
          const unidades = res.data.flatMap((empresa: any) => empresa.unidad ?? []);
          setAllUnidades(unidades);
        }
      });
    }
  }, [empresaId, reloadTable]);

  useEffect(() =>{
    fetchMenus()
  },[unidad, reloadTable]);

  
  const fetchMenus = async () => {
    try {
      const menuResponse = await fetchMenuData();
      const roleMenuResponse = await fetchMenuDataByRoleMenu(role, empresaId ,unidad);

      if (menuResponse.success && roleMenuResponse.success) {
        const roleMenus = roleMenuResponse.data;
        const roleSubMenuIds = roleMenus.flatMap((menu: Menu) =>
          menu.hijos.map((subMenu: SubMenu) => subMenu.id_hijo)
        );

        const updatedMenus = menuResponse.data.map((menu: Menu) => {
          const hasRole = roleMenus.some((rm: any) => rm._id === menu._id);
          return {
            ...menu,
            hasRole,
            hijos: menu.hijos
              ? menu.hijos.map((subMenu: SubMenu) => ({
                  ...subMenu,
                  hasRole: roleSubMenuIds.includes(subMenu.id_hijo),
                }))
              : [],
          };
        });
        setMenus(updatedMenus);
      }
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  };


  const handlePermissionToggle = async (menuId: string, subMenuId: string | null, isPermitted: boolean) => {
    try {
      const payload = {
        role,
        empresaId,
        unidad,
        menuId,
        subMenuId,
        isPermitted,
      };
      const response = await updateMenuRole(payload);
      if (response.success) {
        setMenus((prevMenus) =>
          prevMenus.map((menu) =>
            menu._id === menuId
              ? {
                  ...menu,
                  hasRole: subMenuId ? menu.hasRole : isPermitted,
                  hijos: menu.hijos
                    ? subMenuId
                      ? menu.hijos.map((subMenu) =>
                          subMenu.id_hijo === subMenuId ? { ...subMenu, hasRole: isPermitted } : subMenu
                        )
                      : menu.hijos.map((subMenu) => ({ ...subMenu, hasRole: isPermitted }))
                    : [],
                }
              : menu
          )
        );
      } else {
        console.error('Error in handlePermissionToggle:', response.message);
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
    }
  };

  return (
    <Container>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Gestión de Permisos de Menús</h3>
        </div>
        <div className='card-body'>
          <Form.Group controlId='roleSelect' style={{width: '200px', marginBottom: '20px'}}>
            <Form.Label>Seleccione el Rol</Form.Label>
            <Form.Control
              as='select'
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{fontSize: '14px'}}
            >
              {allRoles.map((rol: any) => (
                <option key={rol.rol} value={rol.rol}>
                  {rol.nombre_perfil}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='empresaSelect' style={{width: '200px', marginBottom: '20px'}}>
            <Form.Label>Seleccione la Empresa</Form.Label>
            <Form.Control
              as='select'
              value={empresaId}
              onChange={(e) => setEmpresaId(e.target.value)}
              style={{fontSize: '14px'}}
            >
              <option value = ''>
              Predeterminado
            </option>
              {allEmpresas.map((empresa: any) => (
                <option key={empresa._id} value={empresa._id}>
                  {empresa.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='unidadSelect' style={{width: '200px', marginBottom: '20px'}}>
            <Form.Label>Seleccione la Unidad</Form.Label>
            <Form.Control
              as='select'
              value={unidad}
              onChange={(e) => setUnidad(e.target.value)}
              style={{fontSize: '14px'}}
            >
            <option value = ''>
              Predeterminado
            </option>
                {allUnidades.map((unidad: any) => (
                  <option key={unidad.codUnidad} value={unidad.codUnidad}>
                    {unidad.DesUnid}
                  </option>
                ))}

            </Form.Control>
          </Form.Group> 
          <div style={{border: '1px solid #ddd', borderRadius: '4px', padding: '10px'}}>
            {menus.map((menu: Menu) => (
              <div key={menu._id} style={{marginBottom: '20px'}}>
                <div
                  style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                >
                  <h5>{menu.titulo}</h5>
                  <Switch
                    checked={menu.hasRole}
                    onChange={(e: any) => handlePermissionToggle(menu._id, null, e.target.checked)}
                    color='primary'
                  />
                </div>
                {menu.hijos &&
                  menu.hijos.length > 0 &&
                  menu.hijos.map((subMenu: SubMenu) => (
                    <div
                      key={subMenu.id_hijo}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      <span>{subMenu.titulo}</span>
                      <Switch
                        checked={subMenu.hasRole}
                        onChange={(e: any) =>
                          handlePermissionToggle(menu._id, subMenu.id_hijo, e.target.checked)
                        }
                        color='primary'
                        disabled={!menu.hasRole}
                      />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
};

export default MenuAsignacionForm;
