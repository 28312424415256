import { createContext } from "react";

interface Alerts2ContextProps {
    searchTerm: string,
    setSearchTerm: (term: string) => void,
}

const Alerts2Context = createContext<Alerts2ContextProps>({
    searchTerm: '',
    setSearchTerm: () => {}
})

export { Alerts2Context }