import React from 'react'
import {Card} from 'react-bootstrap'

interface Props {
  className?: string
  children: React.ReactNode
  title: React.ReactNode
  description?: React.ReactNode
  showHeader?: boolean
  styleBody?: React.CSSProperties
}

const Widget: React.FC<Props> = ({
  styleBody,
  className,
  children,
  title,
  description,
  showHeader = true,
}) => {
  return (
    <>
      <Card className={className}>
        {showHeader ? (
          <Card.Header className='align-items-center border-0 mt-4 w-100'>
            <div className='card-title align-items-start flex-column w-100 m-0'>
              {title}
              <div className='text-muted fw-semibold fs-7 w-100'>{description}</div>
            </div>

            {/* <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 />
          </div> */}
          </Card.Header>
        ) : null}
        <Card.Body className='pt-5' style={styleBody}>
          {children}
        </Card.Body>
      </Card>
    </>
  )
}

export {Widget}
