import {fetchConToken} from '../helpers/fetch'
import { WorkingDocs } from '../types-module/WDocsNS'

const url = 'empresa/'

type ResponseAPI<T> = {
  data?: T | null
  success: boolean
  error?: string
  message?: string
}

const getAllCompanies = async (
  nombre = '',
  page: number,
  limit: number
): Promise<ResponseAPI<WorkingDocs.Company[]>> => {
  // const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(`${url}getAll`, {}, 'GET')

  const body = await resp.json()

  return body
}

const getUnidadesEmpresas = async (empresaIds: string[], userId: string) => {
  const queryString = new URLSearchParams({
    empresaIds: empresaIds.join(','),
    userId: userId,
  }).toString()
  /* console.log("querystring: " + queryString); */

  const resp = await fetchConToken(`${url}unidades?${queryString}`, {}, 'GET')

  const body = await resp.json()
  return body
}

const getUnidadesByEmpresaAsignadas = async (empresaId: string) => {
  const queryString = new URLSearchParams({
    empresaId: empresaId
  }).toString()
  /* console.log("querystring: " + queryString); */

  const resp = await fetchConToken(`${url}unidadesbyempresa?${queryString}`, {}, 'GET')

  const body = await resp.json()
  return body
}

const getUnidadesEmpresasInspector = async (empresaIds: string[]) => {
  const queryString = new URLSearchParams({empresaIds: empresaIds.join(',')}).toString()
  /* console.log("querystring: " + queryString); */

  const resp = await fetchConToken(`${url}unidadesall?${queryString}`, {}, 'GET')

  const body = await resp.json()
  return body
}

const asignarEmpresas = async (ids: any) => {
  const resp = await fetchConToken(`${url}asignar/empresas`, ids, 'PUT')
  const body = await resp.json()

  return body
}

const asignarUnidadByEmpresa = async (params: any) => {
  const resp = await fetchConToken(`${url}asignar/unidad_by_empresa`, params, 'PUT')
  const body = await resp.json()

  return body
}

const desasignarEmpresas = async (ids: any) => {
  const resp = await fetchConToken(`${url}desasignar/empresas`, ids, 'PUT')
  const body = await resp.json()

  return body
}

const desasignarUnidadByEmpresa = async (params: any) => {
  const resp = await fetchConToken(`${url}desasignar/unidad_by_empresa`, params, 'PUT')
  const body = await resp.json()

  return body
}

const getUnidades = async () => {
  const resp = await fetchConToken(`${url}all_unidades`, {}, 'GET')
  const body = await resp.json()
  return body
}

const getUnidadesPorId = async (userId: any) => {
  const resp = await fetchConToken(`${url}unidadesporid/${userId}`, {}, 'GET')
  const body = await resp.json()
  return body
}

const getAllCompaniesByPais = async () => {
  // const queryString = new URLSearchParams(options).toString();

  const resp = await fetchConToken(`${url}getAllByPais`, {}, 'GET')

  const body = await resp.json()

  return body
}

const getCompany = async () => {
  const resp = await fetchConToken(`${url}getEmpresa`, {}, 'GET')

  return await resp.json()
}

const getCompanyById = async (id: string): Promise<{
  success: boolean;
  data: null | WorkingDocs.Company
}> => {
  const resp = await fetchConToken(`${url}${id}`, {}, 'GET')

  return await resp.json()
}

const createCompany = async (data: any) => {
  const resp = await fetchConToken(`${url}create`, data, 'POST')
  const body = await resp.json()
  return body
}

const updateCompany = async (data: any) => {
  const resp = await fetchConToken(`${url}update`, data, 'PUT')
  const body = await resp.json()
  return body
}

const softDeleteCompany = async (id: string) => {
  const resp = await fetchConToken(`${url}delete/${id}`, {}, 'DELETE')
  const body = await resp.json()
  return body
}

const getUnidadesLikePartner = async () => {
  const resp = await fetchConToken(`${url}unidades/partner`, {}, 'GET')

  return await resp.json()
}

export {
  asignarEmpresas,
  asignarUnidadByEmpresa,
  createCompany,
  desasignarEmpresas,
  desasignarUnidadByEmpresa,
  getAllCompanies,
  getAllCompaniesByPais,
  getCompany,
  getCompanyById,
  getUnidades,
  getUnidadesEmpresas,
  getUnidadesEmpresasInspector,
  getUnidadesByEmpresaAsignadas,
  getUnidadesPorId,
  updateCompany,
  softDeleteCompany,
  getUnidadesLikePartner
}
