import {useEffect, useRef, useState} from 'react'
import {Button, Container, Form} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {getRoles, getRolsByCompany} from '../../services/users'
import {useAuth2} from '../authv2/Auth2Provider'
import Select from 'react-select';

const CompanySelectorModalForm = ({userData, onClose}: any) => {
  const [selectedCompany, setSelectedCompany] = useState<string>(userData.userCompany[0]?.id)
  const [selectedRoleId, setSelectedRoleId] = useState<string>(userData.userRole[0])
  const {setSelectedCompanyId, setSelectedRole, selectedRole, selectedCompanyId} = useAuth2()
  const [listRoles, setListRoles] = useState<any[]>([])
  const navigate = useNavigate()
  const [rolsByCompany, setRolsByCompany] = useState<Record<string, any> | null>(null)

  const roles = rolsByCompany?.roles?.find((rol: any) => rol.empresa === selectedCompany)?.rol ?? []
  const companies = userData?.userCompany?.filter(
    (x: any) => (rolsByCompany?.empresa ?? []).includes(x.id)
  ) ?? []

  useEffect(() => {
    obtenerRoles()

    getRolsByCompany().then((res) => {
      if (res.success) {
        setRolsByCompany(res.data)
      }
    })

    if (selectedCompanyId && selectedCompanyId !== '') {
      setSelectedCompany(selectedCompanyId)
    }

    if (selectedRole && selectedRole !== '') {
      setSelectedRoleId(selectedRole)
    }
  }, [])

  useEffect(() => {
    if (roles[0]) {
      setSelectedRoleId(roles[0])
    }
  }, [selectedCompany])

  const handleCompanyChange = (e: any) => {
    setSelectedCompany(e.target.value)
  }

  const handleRoleChange = (e: any) => {
    setSelectedRoleId(e.target.value)
  }

  const obtenerRoles = () => {
    getRoles()
      .then((response: any) => {
        if (response.success) {
          const rolesAll = response.data?.map((x: any) => ({label: x.nombre_perfil, value: x.rol}))
          setListRoles(rolesAll)
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setSelectedCompanyId(selectedCompany)
    setSelectedRole(selectedRoleId)

    navigate('/dashboard')

    // update userData roles

    const userDataRAW = localStorage.getItem('userData') ?? '{}'
    const userData = JSON.parse(userDataRAW)
    userData.userRole = roles
    localStorage.setItem('userData', JSON.stringify(userData))

    onClose()
  }

  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <div>
      <Container ref={containerRef}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='formCompany'>
            <Form.Label>Empresa</Form.Label>
            <Form.Select value={selectedCompany} onChange={handleCompanyChange}>
              {companies.map((company: any) => (
                <option value={company.id} key={company.id}>
                  {company.label}
                </option>
              ))}
            </Form.Select>
            {/* <Form.Text className='text-muted'>
              Seleccione la empresa y haga clic en 'Aceptar'.
            </Form.Text> */}
          </Form.Group>

          <Form.Group className='mb-3' controlId='formRole'>
            <Form.Label>Perfil</Form.Label>

            <Form.Select value={selectedRoleId} onChange={handleRoleChange}>
              {roles?.map((role: any) => (
                <option value={role} key={role}>
                  {listRoles.length > 0
                    ? listRoles?.find((lis) => String(lis.value) === String(role)).label
                    : ''}
                </option>
              ))}
            </Form.Select>
            {/* <Form.Text className='text-muted'>
              Seleccione su perfil y haga clic en 'Aceptar'.
            </Form.Text> */}
          </Form.Group>

          <div className='d-flex justify-content-end'>
            <Button variant='primary' type='submit' className='p-3'>
              Aceptar
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export {CompanySelectorModalForm}
