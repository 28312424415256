import { useMemo, useState, useContext, useEffect, Fragment } from "react"
import { Dropdown } from "react-bootstrap"
import Pagination from '../../../../shared/Pagination'
import { useNavigate } from "react-router-dom"
import { useTable } from "react-table"
import { KTCardBody } from "../../../../../_metronic/helpers"
import { getSheets, deleteCompleteSheet, cloneCompleteSheet, ficha, busquedaficha } from "../../../../services/fichas"
import { showDeleteMessage, showMessage, showCloneMessage } from "../../../../shared/Alerts"
import { UsersListLoading } from "../../../apps/user-management/users-list/components/loading/UsersListLoading"
import { useQueryResponseData } from "../../../apps/user-management/users-list/core/QueryResponseProvider"
import { usersColumns } from "../../../apps/user-management/users-list/table/columns/_columns"
import { SheetContext } from "../../fichas/SheetContexts"
import { ListaSecciones } from "../../usuario/pages/secciones/ListaSecciones"
import { getPartnersForValidation } from "../../../../services/colaboradores"
import { formatDateToDMY } from "../../../../helpers/dateFunctions"


export const ValidationTable = (props: any) => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState([])
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  const {searchTerm} = useContext(SheetContext)
  const navigate = useNavigate()

  useEffect(() => {
    setLoadingStatus(true)
    refetchData(currentPage)
  }, [])

  useEffect(() => {
    setLoadingStatus(true)
    refetchData(currentPage)
  }, [searchTerm])

  const refetchData = (page: number) => {
    setLoadingStatus(true)

    getPartnersForValidation()
      .then((response) => {
        if (response.success) {
          console.log(response)
          setLoadingStatus(false)
          setLista(response?.data)
          // setTotalPages(response.totalPages)
          //MenuComponent.reinitialization()
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    refetchData(page)
  }

  /*   const handleSelecto = (opt) => {
    if (opt === selecto) {
      setSelecto(0)
    } else {
      setSelecto(opt)
    }
  } */

  const handleSelecto = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:${opt}`)
  }

  const handleEliminar = (id: any) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteCompleteSheet(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData(currentPage)
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleClone = (id: string) => {
    showCloneMessage().then((result) => {
      if (result.isConfirmed) {
        cloneCompleteSheet(id, result.value)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData(currentPage)
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (id: any) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setActualizar(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setTimeout(() => {
      setMactualizar(!mactualizar)
    }, 1000)
  }

  const handleNuevaSeccion = (idnuevo: any) => {
    setMnuevo(!mnuevo)
    setNuevo(idnuevo)
  }

  const handleSearch = (e: any) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      console.log('llego aqui mira')
      e.preventDefault()
      e.target.blur()
    }
    const valorbuscado = e.target.value
    if (valorbuscado?.length > 2) {
      if (valorbuscado) {
        busquedaficha(valorbuscado).then((response) => {
          if (response.success) {
            setLista(response?.data)
          } else {
            refetchData(currentPage)
          }
        })
      } else {
        refetchData(currentPage)
      }
    } else {
      refetchData(currentPage)
    }
  }

  const handleViewForm = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Formulario/:${opt}`)
  }

  const handleUpdate = (id: any) => {
    navigate(`/configuracion/fichas/edit/${id}`)
  }

  const handleView = (id: any) => {
    navigate(`/seguimiento/procesos/validar/actions/${id}`)
  }

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className=''>DNI</th>
                <th className='text-end'>Usuario</th>
                <th className='text-end'>Correo</th>
                <th className='text-end'>Fec. Creación</th>
                <th className='text-end'>Acciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <td>{item.dni}</td>
                      <td className='text-end min-w-100px'>{`${item.nombres} ${item.apellidos}`}</td>

                      <td className='text-end min-w-100px'>{item.correo}</td>

                      <td className='text-end min-w-100px'>{formatDateToDMY(item.createdAt)}</td>

                      <td className='text-end'>
                        <button className='btn btn-sm btn-success' onClick={() => handleView(item._id)}>
                          Validar
                        </button>
                      </td>
                    </tr>
                    {item._id === selecto ? (
                      <ListaSecciones
                        id={selecto}
                        mnuevo={mnuevo}
                        setMnuevo={setMnuevo}
                        nuevo={nuevo}
                      />
                    ) : null}
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}
