import { useContext, useEffect, useState } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import { SheetEditContext } from '../SheetContexts'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import AssigmentListGroup from './AssigmentListGroup'
import CheckboxTree from 'react-checkbox-tree'
import { v4 } from 'uuid'
import { Field } from '../Sheet'
import { getExternalById, getExternals } from '../../../../services/external'


const AssignmentRequeridoModalForm = () => {
  const { closeAssignmentRequeridoModal, fieldData, setFieldData, sheetData, setData, sectionData } = useContext(SheetEditContext)
  const [idSelectService, setIdSelectService] = useState("")
  const [dataInput, setDataInput] = useState([])
  // const [formData, setFormData] = useState<Field>({
  //   id: v4(),
  //   name: '',
  //   isDependent: false,
  //   responseType: '',
  //   comment: '',
  //   visible: true,
  //   editable: false,
  //   required: false,
  //   collectionName: '',
  //   options: [],
  //   assignedTo: ''
  // })

  // useEffect(() => {

  //   console.log(fieldData)

  //   if (fieldData.id.length > 0) {
  //     setFormData(fieldData)
  //     console.log(formData)
  //   } 
  // }, [])

  useEffect(() => {
    refetchData()
  }, [])
  useEffect(() => {
    if (idSelectService) getExternalId(idSelectService)
  }, [idSelectService])

  const refetchData = async () => {
    await getExternals({ typedata: "NAME_SERVICES" })
      .then((response) => {
        if (response.success) {
          const SeriviceRequerida = response.data.filter((da: any) => da.service == "Data Requerida Nuevos")?.[0]
          setIdSelectService(SeriviceRequerida._id)
        } else {
          console.log(response.msg)
        }
      })
  }

  const getExternalId = async (id: string) => {
    await getExternalById(id)
      .then((response) => {
        console.log("response")
        console.log(response)
        setDataInput(response.data.fields)
        /* setDataServiceExternal(response.data)
        setExternalData(response.data)
        if (response.data.api && response.data.api.length > 0) {
          setArrayApi(response.data.api)
        }
        setEdit(false) */
      })
      .catch((error) => console.log(error))
  }

  const handleListChange = (name: string) => {
    setFieldData({ ...fieldData, assignedTo: name })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    setData({
      ...sheetData,
      sections: (sheetData.sections || []).map((x) =>
        x.id === sectionData.id
          ? {
            ...sectionData,
            fields: (sectionData.fields || []).map((y) =>
              y.id === fieldData.id ? fieldData : y
            ),
          }
          : x
      ),
    })
    closeAssignmentRequeridoModal()
  }

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='formServicio'>
          <Form.Label>Servicio</Form.Label>
          <Form.Control as='select' name='type' className={'form-select form-select-solid}'}          >
            <option value='independent'>Datos Requeridos</option>
            {/* <option value='dependent'>Dependiente</option> */}
          </Form.Control>
        </Form.Group>

        <Row className='mb-3'>
          <AssigmentListGroup elementSelected={fieldData.assignedTo} onChange={(name: string) => handleListChange(name)} dataInput={dataInput} />
          {/* <CheckboxTree
            nodes={transformedData}
            checked={[]} // An array of checked node values (if any)
            expanded={[]} // An array of expanded node values (if any)
            onCheck={(checked) => console.log('Checked nodes: ', checked)}
            onExpand={(expanded) => console.log('Expanded nodes: ', expanded)}
          /> */}
        </Row>

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                closeAssignmentRequeridoModal()
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export { AssignmentRequeridoModalForm }
