import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import { PageDataProvider } from './core'
import { reInitMenu } from '../helpers'
import { ToolbarWrapper } from './components/toolbar'
import { CompanySelectorModal } from '../../app/modules/company-selector-modal/CompanySelectorModal'
import { PoliticaYPrivacidadSelectorModal } from '../../app/modules/politicayprivacidad-selector-modal/PoliticaYPrivacidadSelectorModal'
import './styles.css';

const MasterLayout = () => {
  const location = useLocation()
  const [userData, setUserData] = useState<any>([])
  // const [isCompanyModalOpen, setCompanyModalStatus] = useState<boolean>(true)
  // const [selectedCompany, setSelectedCompany] = useState<any>()

  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem('userData') || '')
  //   const selectedCompanyId = localStorage.getItem('selectedCompanyId')

  //   console.log(selectedCompany)

  //   if (userData) {
  //     setUserData(userData)
  //   }

  //   if(selectedCompanyId){
  //     setSelectedCompany(selectedCompanyId)
  //   }

  // }, [])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  // const handleModalClose = () => {
  //   setCompanyModalStatus(false)
  // }

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
        {/* {
          (userData?.userCompany?.length > 1
            ||
            userData?.userRole?.length > 1
          )
          &&
          !selectedCompany 
          &&
          <CompanySelectorModal isCompanySelecteroModalOpen={isCompanyModalOpen} userData={userData} onClose={handleModalClose} />
        } */}
        <CompanySelectorModal />
        <PoliticaYPrivacidadSelectorModal />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
