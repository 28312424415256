import React from 'react'
import {Accordion} from 'react-bootstrap'
import {ProcesoFichas} from '../FIchaInterface'
import {DynamicSection} from './sections/DynamicSection'
import {Section} from './sections/Section'

interface PartnerViewerProps {
  data: ProcesoFichas[]
  activeTab: number
}

const PartnerViewer: React.FC<PartnerViewerProps> = ({data, activeTab}) => {
  //   const [info, setInfo] = useState<any>()

  //   const {selectedRole} = useAuth2()

  //   useEffect(() => {
  //     if (data[activeTab]) {
  //       generateSections(data[activeTab])
  //       console.log(data[activeTab])
  //     }
  //   }, [data, activeTab])

  //   const generateSections = (procesoFicha: ProcesoFichas) => {
  //     const items = []

  //     for (const seccion of procesoFicha.fichas[0].seccion) {
  //       if (!seccion.dinamico) {
  //         items.push(
  //           generateFields(seccion.campos).length > 0 && (
  //             <Accordion.Item eventKey={seccion._id} key={seccion._id}>
  //               <Accordion.Header>
  //                 <span className='fw-bold'>{seccion.nombre?.toUpperCase()}</span>
  //               </Accordion.Header>
  //               <Accordion.Body>
  //                 <div className='d-flex flex-wrap mx-5'>{generateFields(seccion.campos)}</div>
  //               </Accordion.Body>
  //             </Accordion.Item>
  //           )
  //         )
  //       } else {
  //         items.push(
  //           seccion.secciones.length > 0 && (
  //             <Accordion.Item eventKey={seccion._id} key={seccion._id}>
  //               <Accordion.Header>
  //                 <span className='fw-bold'>{seccion.nombre}</span>
  //               </Accordion.Header>
  //               <Accordion.Body>
  //                 <Accordion alwaysOpen>
  //                   {seccion.secciones.map(
  //                     (seccion) =>
  //                       generateFields(seccion.data).length > 0 && (
  //                         <Accordion.Item
  //                           eventKey={seccion.indice.toString()}
  //                           key={seccion.indice.toString()}
  //                         >
  //                           <Accordion.Header>Sección</Accordion.Header>
  //                           <Accordion.Body>
  //                             <div className='d-flex flex-wrap mx-5'>
  //                               {generateFields(seccion.data)}
  //                             </div>
  //                           </Accordion.Body>
  //                         </Accordion.Item>
  //                       )
  //                   )}
  //                 </Accordion>
  //               </Accordion.Body>
  //             </Accordion.Item>
  //           )
  //         )
  //       }
  //     }

  //     setInfo(items)
  //   }

  //   const extractS3KeyName = (url: string) => {
  //     return url
  //       .split('/')
  //       .filter((n, i) => i > 3)
  //       .join('/')
  //   }

  //   const generateFields = (fields: Campos[]) => {
  //     const items = []

  //     for (const field of fields) {
  //       if (selectedRole !== 'POST' || field.visible === true) {
  //         switch (field.tipo[0].nombre) {
  //           case 'carga de documentos':
  //             items.push(
  //               <div className='flex-fill mx-5' key={field._id}>
  //                 <div>
  //                   <strong>{field.titulo}:</strong>
  //                 </div>
  //                 <ul>
  //                   {field.infoFiles.map((value, i) => (
  //                     <li key={i}>
  //                       <a href='#!' onClick={() => handleDownload(value.url, value.name)}>
  //                         {value.name}
  //                       </a>
  //                     </li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             )
  //             break
  //           case 'Imagen':
  //             items.push(
  //               <div className='flex-fill mx-5' key={field._id}>
  //                 <div>
  //                   <strong>{field.titulo}:</strong>
  //                 </div>
  //                 <ul>
  //                   {field.infoFiles.map((value, i) => (
  //                     <li key={i}>
  //                       <a href='#!' onClick={() => handleDownload(value.url, value.name)}>
  //                         {value.name}
  //                       </a>
  //                     </li>
  //                   ))}
  //                 </ul>
  //               </div>
  //             )
  //             break
  //           case 'Tabla':
  //             items.push(
  //               <div className='flex-fill mx-5' key={field._id}>
  //                 <div>
  //                   <strong>{field.titulo}:</strong>
  //                 </div>
  //                 <JsonToTableViewer data={field.objectValue} />
  //               </div>
  //             )
  //             break
  //           case 'Lista desplegable BD':
  //             items.push(
  //               <div className='flex-fill mx-5' key={field._id}>
  //                 <p>
  //                   <strong>{field.titulo.toLocaleUpperCase()}:</strong>{' '}
  //                   {field.selectOpion?.label.toLocaleUpperCase()}
  //                 </p>
  //               </div>
  //             )
  //             break
  //           default:
  //             items.push(
  //               <div className='flex-fill' key={field._id}>
  //                 <p>
  //                   <strong>{field.titulo.toLocaleUpperCase()}:</strong>{' '}
  //                   {field.values.toLocaleUpperCase()}
  //                 </p>
  //               </div>
  //             )
  //         }
  //       }
  //     }

  //     return items
  //   }

  const secciones = data[activeTab]?.fichas[0].seccion
  return (
    <>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        {secciones?.map((seccion) => (
          <React.Fragment key={seccion._id}>
            {seccion.dinamico ? (
              <DynamicSection
                title={seccion.nombre}
                eventKey={seccion._id}
                secciones={seccion.secciones}
              />
            ) : (
              <Section title={seccion.nombre} eventKey={seccion._id} campos={seccion.campos} />
            )}
          </React.Fragment>
        ))}
      </Accordion>
    </>
  )
}

export {PartnerViewer}
