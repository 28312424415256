import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button, Container, Col} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { PartnersTableGeneral } from '../../../generales_component/PartnersTableGeneral';
import { getProceso, getProcessUsers } from '../../../../services/procesos';
import { PartnerContext } from '../PartnerContexts'
import { array } from 'yup'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { isObject } from 'formik';
import { Doc } from '../../enviodocumento/enviodocumentInterface';
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import clsx from 'clsx';

const userAvatarClass = 'symbol-25px symbol-md-30px'
const ShowFileModalForm = () => {
    const {viewDocumento,detalleDocument } = useContext(PartnerContext)
    const [docs, setDocs] = useState<Doc[]>([])

    useEffect(() => {
        console.log("viewDocumento")
        console.log(viewDocumento)
        setDocs(viewDocumento)
    }, [viewDocumento])

    return(
        <>
            <Container>
                <Row>
                    {
                        detalleDocument.length > 0?
                            <>
                                <Col sm={9} >
                                    {
                                        docs.length > 0 && (
                                            
                                            docs[0].fileType.toLowerCase() === "pdf"?
                                                <DocViewer 
                                                    documents={docs} 
                                                    pluginRenderers={DocViewerRenderers} 
                                                    style={{height:650}}
                                                />
                                            
                                            :
                                                <>
                                                    <div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img
                                                            src={docs[0].uri}
                                                            alt={docs[0].fileName}
                                                            style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}
                                                        />
                                                    </div>
                                                </>
                                        )
                                    }
                                </Col>
                                
                                <Col sm={3} >
                                    { detalleDocument[detalleDocument.length - 1].status === 'signed' && 
                                        <div className="card card-custom card-stretch-50 shadow ">
                                            <div className="card-header px-5" style={{minHeight:"50px"}}>
                                                <h5 className="card-title">Detalle de la Firma</h5>
                                            </div>
                                            { detalleDocument.map(det => (
                                                det.status === 'signed'? 
                                                    <div className="card-body px-2">
                                                        <div className='d-flex align-items-center flex-grow-1'>
                                                            <div className='symbol symbol-30px '>
                                                                <div className="symbol-label fs-5 fw-bold bg-primary text-inverse-primary mx-2">{"MS"}</div>
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <a className='text-gray-800 text-hover-primary fs-7 fw-bold'>
                                                                {det.name}
                                                                </a>

                                                                <span className='text-gray-400 fs-8 fw-semibold'>{det.email}</span>
                                                            </div>
                                                        </div>                                
                                                        <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                                            <a className='link-primary fw-bold'>                                    
                                                            Fecha/Hora: {' '}
                                                            </a>
                                                            { det.createdAt }
                                                        </div>                               
                                                        <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                                            <a className='link-primary fw-bold'>                                    
                                                            Token: {' '}
                                                            </a>
                                                            { det.token }
                                                        </div>                               
                                                        <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                                            <a className='link-primary fw-bold'>                                    
                                                            IP: {' '}
                                                            </a>
                                                            { det.ip }
                                                        </div>                               
                                                        <div className='text-muted text-muted fs-7 px-4 mt-2'>
                                                            <a className='link-primary fw-bold'>                                    
                                                            User agent: {' '}
                                                            </a>
                                                            { det.userAgent }
                                                        </div>
                                                    </div>
                                                : ''
                                            ))

                                            }
                                        </div>
                                    }
                                    

                                    { detalleDocument.length > 0 && (
                                        <div className="card card-custom card-stretch-50 shadow mt-10">
                                            <div className="card-header" style={{minHeight:"40px"}}>
                                                <h5 className="card-title">Historial</h5>
                                            </div>                        
                                            <div className="card-body px-2">
                                                {detalleDocument.map(det => (        
                                                    <div className='d-flex align-items-center bg-light-primary rounded p-2 mb-3'>
                                                        <span className=' text-info'>
                                                            <KTIcon iconName='document' className='text-primary fs-1 me-3' />
                                                        </span>
                                                        <div className='flex-grow-1 me-2'>
                                                            <a className='fw-bold text-gray-800 text-hover-primary fs-7'>
                                                            {det.motivo} {det.status === 'send'?det.email:det.name}
                                                            </a>
                                                            <span className='text-muted fw-semibold d-block fs-7'>{ det.createdAt }</span>
                                                        </div>
                                                    </div>
                                                    
                                                ))}
                                            </div>
                                        
                                        </div>
                                    
                                    )}
                                    
                                </Col>
                            </>
                        :
                        <>
                            <Col sm={12} >
                                {
                                    docs.length > 0 && (
                                        
                                        docs[0].fileType === "pdf"?
                                            <DocViewer 
                                                documents={docs} 
                                                pluginRenderers={DocViewerRenderers} 
                                                style={{height:650}}
                                            />
                                        
                                        :
                                            <>
                                                <div style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img
                                                        src={docs[0].uri}
                                                        alt={docs[0].fileName}
                                                        style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}
                                                    />
                                                </div>
                                            </>
                                    )
                                }
                            </Col>
                        </>
                    }
                    
                    
                </Row>
            </Container>
        </>
    )
}

export {ShowFileModalForm}
