import React, { useState, FC , useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Link, useLocation} from 'react-router-dom'
import Select from 'react-select'
import SignaturePad from './SignatureCanvas'
import Datetime from 'react-datetime';
import { useDropzone } from 'react-dropzone';
import 'react-datetime/css/react-datetime.css';
import { KTSVG } from '../../../../../_metronic/helpers';
import './styles.css';
import {nuevoRegistroFicha,nuevoRegistroFichaTotal} from '../../../../services/registroFicha'
import {getProcesoFichas} from '../../../../services/procesos'

import DropzoneImagen from './Dropzone'
import DropzoneImagenUnico from './DropzoneUnico'
import DropzoneArchivo from './DropzoneArchivo'
import TextToStyleGenerator from './StyleFont'
import Base64FileViewer from './Base64FileViewer'
import { KTIcon } from '../../../../../_metronic/helpers'
import { campos } from '../../../../services/campos';
import { Campos, DataTabla, Ficha, Historial, Observacion, ProcesoFichas, Seccion, Tabla, tablaData } from '../FIchaInterface';
import { showMessage, showResponseObservacionMessage } from '../../../../shared/Alerts';
import { number } from 'yup'
import { DataUser } from '../DataUserInterface'
import {Tooltip as ReactTooltip } from 'react-tooltip';
import { useAuth2 } from '../../../authv2/Auth2Provider'


interface MyComponentProps {
  seccion: Seccion;
  estatusFicha:string;
  onUpdate: (updatedData: any) => void;
}

const CardComponent: FC<MyComponentProps> = (props) => {
  const {seccion,onUpdate,estatusFicha} = props
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  
  const { selectedRole } = useAuth2()
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  const rolusuario = selectedRole

  const [formData, setFormData] = useState(seccion);
  const [activeTab, setActiveTab] = useState('panel_graficar');
  const [shouldClearFirma, setShouldClearFirma] = useState(false);
  const [isJumping, setIsJumping] = useState(false);
  
  useEffect(() => {
    // Esta función se ejecutará después de cada actualización de formData
    onUpdate(formData);
  }, [formData]);
  
  const handleJumpClick = () => {
    setIsJumping(true);
    setTimeout(() => {
      setIsJumping(false);
    }, 1000); // Después de 1 segundo, deja de saltar
  };

  const handleClearFirmaComplete = () => {
    setShouldClearFirma(false);
  };

  const removeFirma = (index:any) => {
    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos];
      newCampo[index] = {
        ...newCampo[index],
        'values': ""
      };
      return { ...prevFormData, campos: newCampo };
    })
    handleTabChange('panel_graficar')
  }

  const handleTabChange = (tabId:any) => {
    setShouldClearFirma(true);
    setActiveTab(tabId)
  }


  const fileToBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        resolve(reader.result);
      };
  
      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'));
      };

      reader.readAsDataURL(file);
    });
  };

  // Función para convertir un array de archivos a Base64
  const filesToBase64 = async (files:any) => {
    const base64Array = [];

    for (const file of files) {
      try {
        const base64 = await fileToBase64(file);
        base64Array.push(base64);
      } catch (error) {
        console.error(error);
      }
    }

    return base64Array;
  };

  const handleFilesSelected =  (files:  File[], index: number,infoArchivo: {url:string,name:string,size:string}[]) => {
    const infoFiless:{url:string; name: string;size: string;}[] = []
    const newValuesFiles:string[] = []
    let hasObservado = false
    let observacion:Observacion[] = []

    infoArchivo.map(res =>{
      if(res.url !== ""){
        infoFiless.push(res)
        newValuesFiles.push(res.url)
      }
    })

    files.forEach(res => {
      infoFiless.push({
        'url':"",
        'name':res.name,
        'size':String(res.size)
      })
    })

    

    filesToBase64(files)
    .then((base64Array) => {
      setFormData((prevFormData) => {
        const newCampo = [...prevFormData.campos];
        const valuesFiles = base64Array.filter((file) => typeof file === 'string') as string[]        
        valuesFiles.map( res => {
          newValuesFiles.push(res)
        })

        if(estatusFicha === "fichaObservada"){
          formData.campos[index].observacion?.map(obs => {
            observacion.push(obs)
          })
          observacion[Number(observacion?.length) - 1].data_correguida = ""
          observacion[Number(observacion?.length) - 1].estado_abierto = false
          if(formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]?.data_observada !== newValuesFiles.join(';')){
            hasObservado = false
          }else{
            console.log("no entro")
            hasObservado = true
          }
        }

        newCampo[index] = {
          ...newCampo[index],
          'infoFiles':infoFiless,
          'Files':files,
          'valuesFiles': newValuesFiles,
          'observado':hasObservado,
          'observacion':observacion,
        };
        return { ...prevFormData, campos: newCampo };
      });
    })
    .catch((error) => console.error(error));
    // console.log(arrayFile)
    
  };

  const handleFirmaSelected =  (firma:  string, index: number) => {
    let hasObservado = false
    let observacion:Observacion[] = []
    if(estatusFicha === "fichaObservada"){
      formData.campos[index].observacion?.map(obs => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = ""
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if(formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]?.data_observada !== firma){
        hasObservado = false
      }else{
        hasObservado = true
      }
    }

    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos];
      newCampo[index] = {
        ...newCampo[index],
        'values': firma,
        'observado':hasObservado,
        'observacion':observacion,
      };
      return { ...prevFormData, campos: newCampo };
    });
    
  };
  

  const handleInputChangeSelect = (selectOpion: any,index:any) => {

    let fieldValue = selectOpion
    let hasError = false
    let hasObservado = false
    let observacion:Observacion[] = []


    if(formData.campos[index].obligatorio && !formData.campos[index].selectOpion  && fieldValue === "") hasError = true

    if(estatusFicha === "fichaObservada"){
      formData.campos[index].observacion?.map(obs => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = fieldValue.label
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if(formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]?.data_observada !== fieldValue.label){
        hasObservado = false
      }else{
        hasObservado = true
      }
    }

    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos];
      newCampo[index] = {
        ...newCampo[index],
        'hasError':hasError,
        selectOpion:fieldValue,
        'observado':hasObservado,
        'observacion':observacion,
        'tablaData':newCampo[index].tablaData.map((tab:tablaData) => ({
          ...tab
        }))
      };
      return { ...prevFormData, campos: newCampo };
    });

  };

  const handleInputChange = (event: any,index:any) => {    

    const { name, value, type } = event.target;
    let fieldValue = value
    let hasError = false
    let hasObservado = false
    let observacion:Observacion[] = []
    
    if(type === 'checkbox'){
      fieldValue = ""
      formData.campos[index].opciones?.map(opc => {
        opc.value = opc.label === name ? !opc.value : opc.value
        if(opc.value) fieldValue = fieldValue === ""? opc.label : fieldValue + ","+opc.label
      })
    }else if(type === 'select-one'){
      formData.campos[index].opciones?.map(opc => {
        opc.value = opc.label === value?true:false
      })
    }else if(type === 'radio'){
      formData.campos[index].opciones?.map(opc => {
        opc.value = opc.label === value?true:false
      })
    }

    if(estatusFicha === "fichaObservada"){
      formData.campos[index].observacion?.map(obs => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = fieldValue
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if(formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]?.data_observada !== fieldValue){
        hasObservado = false
      }else{
        hasObservado = true
      }
    }


    if(formData.campos[index].obligatorio && !formData.campos[index].values && fieldValue === "") hasError = true
    
    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos];
      if(estatusFicha === "fichaObservada"){
        newCampo[index] = {
          ...newCampo[index],
          'values': fieldValue,
          'hasError':hasError,
          'observado':hasObservado,
          'observacion':observacion
        };
      }else{
        newCampo[index] = {
          ...newCampo[index],
          'values': fieldValue,
          'hasError':hasError,
          'observado':hasObservado
        };
      }
      return { ...prevFormData, campos: newCampo };
    })
  };

  const handleInputChange2 = (event: any,ind:any,index:any) => {    

    const { name, value, type } = event.target;
    let fieldValue = value
    let hasError = false
    
    if(type === 'checkbox'){
      fieldValue = ""
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map(opc => {
        opc.value = opc.label === name ? !opc.value : opc.value;
        if (opc.value) fieldValue = fieldValue === "" ? opc.label : fieldValue + "," + opc.label;
      });
    }else if(type === 'select-one'){
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map(opc => {
        opc.value = opc.label === value?true:false
      })
    }else if(type === 'radio'){
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map(opc => {
        opc.value = opc.label === value?true:false
      })
    }


    //if(formData.campos[index].obligatorio && !formData.campos[index].values && fieldValue === "") hasError = true
    
    setFormData((prevFormData) => {
      const secciones : Tabla[] = prevFormData.secciones || [];
      const seccionInd = secciones[ind] || { data: [] };
      const data = seccionInd.data || [];
      const newSeccion: DataTabla[] = [...data];
      newSeccion[index] = {
        ...newSeccion[index],
        'values': fieldValue,
        'hasError':hasError
      };
      secciones[ind] = { ...seccionInd, data: newSeccion };
      return { ...prevFormData, secciones: secciones };
    })
  };


  const btnRef = useRef<HTMLButtonElement | null>(null);

  const onClick = () => {
    if(formData.dinamico === false){
      if(onValidarCampos() === true){
        btnRef.current?.setAttribute('data-kt-indicator', 'on');
        const data = {
          id_user_creador:idusuario,
          procesoficha:[{
            _id:"64ad89b1eeb1435188a4b2cc",
            idproceso: "647c0d730b0325fc1a8215de",
            fichas: [
              {
                  _id: "647c0e1f0b0325fc1a8215f5",
                  nombre: "Ficha Empleado",
                  seccion: [formData],
              }
            ],
          }],          
        }
        

        nuevoRegistroFicha(data)
          .then((response) => {
            if (response?.success) {
              btnRef.current?.removeAttribute("data-kt-indicator");
              showMessage('success', '¡Buen trabajo!', response.message)      
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnRef.current?.removeAttribute("data-kt-indicator");
            console.log(error)
          })
      }      
    } else {
      if(formData.secciones.length > 0){
        btnRef.current?.setAttribute('data-kt-indicator', 'on');
        const data = {
          id_user_creador:idusuario,
          procesoficha:[{
            _id:"64ad89b1eeb1435188a4b2cc",
            idproceso: "647c0d730b0325fc1a8215de",
            fichas: [
              {
                  _id: "647c0e1f0b0325fc1a8215f5",
                  nombre: "Ficha Empleado",
                  seccion: [formData],
              }
            ],
          }],
          
        }
        

        nuevoRegistroFicha(data)
          .then((response) => {
            if (response?.success) {
              btnRef.current?.removeAttribute("data-kt-indicator");
              showMessage('success', '¡Buen trabajo!', response.message)      
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnRef.current?.removeAttribute("data-kt-indicator");
            console.log(error)
          })
      }else{
        showMessage('error', '¡Hubo un error!', "Debe dar click en el más, para añadir la seccion")
      }
      
    }
    

  };

  const onValidarCampos = (): boolean => {
    let result = true;

    const updatedFormData = formData.campos.map((cam) => {
      if (cam.obligatorio && !cam.values && cam.valuesFiles.length === 0 && cam.selectOpion === null) {
        cam.hasError = true;
        result = false;
      } else {
        cam.hasError = false;
      }
      return cam;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      campos: updatedFormData,
    }));
    return result
  };

  const onAñadirTabla = () => {
    
    if(onValidarCampos() === true){
      setFormData((prevFormData) => {
        const seccion = prevFormData.secciones
        const newData : DataTabla[] = []
        prevFormData.campos.forEach(cam => {
          newData.push({
            _id:cam._id,
            values:cam.values,
            valuesAux:cam.values,
            objectValue: cam.objectValue,
            valuesFiles:cam.valuesFiles,
            infoFiles:cam.infoFiles,
            selectOpion:cam.selectOpion,
            Files:cam.Files,
            estado:cam.estado,
            opciones:cam.opciones,
            idseccion:cam.idseccion,
            idtipo: cam.idtipo,
            titulo: cam.titulo,
            obligatorio: cam.obligatorio,
            actualizable: cam.actualizable,
            hasError: cam.hasError,
            observacion: cam.observacion,
            observado: cam.observado,
            observadoAux: cam.observadoAux,
            dependiente: cam.dependiente,
            campoDependiente: cam.campoDependiente,
            respuestaCampoDependiente: cam.respuestaCampoDependiente,
            mostrar: cam.mostrar,
            orden: cam.orden,
            tabla: cam.tabla,
            tablaData: cam.tablaData,
            editable: cam.editable,
            comentario: cam.comentario,
            visible: cam.visible,
            tipo:cam.tipo
          })
        });
        seccion?.push({indice:seccion.length+1,data:newData})
        
        return { ...prevFormData, secciones: seccion };
      });
      
      VaciarCampos()
    }
  };

  
  const VaciarCampos = () => {
    setFormData((prevFormData) => {
      prevFormData.campos.forEach(cam => {
        cam.values = ""
        cam.selectOpion = null
      });
      return { ...prevFormData};
    })
  }

  const handleRemoveSeccion = (item : any) => {
    
      setFormData((prevFormData) => {
        const newSeccion: Tabla[] = []
        let con = 0;
        prevFormData.secciones.forEach((secc, idx) => {
          if(idx !== item ){
            con++;
            newSeccion.push({indice:con,data:secc.data})  
          }        
        });
        
        return { ...prevFormData, secciones: newSeccion };
      });
      
  }

  
  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  

  return (
    <div className={`row g-5 g-xxl-0 
              ${ estatusFicha === 'completadoPorColaborador' || estatusFicha === 'aprobadoPorAdmin' ? 'disabled' : '' }
              `}  >
        <div className={`card card-xxl-stretch mb-5 mb-xxl-8 ${seccion.dinamico ?"bg-opacity-10 bg-primary":""} ${isMinimized ? 'minimized' : ''}`}>
          <div className={`card-header border-0 pt-2  ` }>
            <h3 className={`card-title align-items-start flex-column ${seccion.dinamico ?"text-white":""}` }>
              <span className='card-label fw-bold fs-3 mb-1'>{seccion.nombre}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>{seccion.descripcion}</span>
            </h3>
            <div>
            {( seccion.dinamico ?  
              // <button ref={btnRef} onClick={onAñadirTabla} type="button" className="btn  add-btn btn-light-primary btn-sm mr-10" >
              //   <KTIcon iconName='plus' className='fs-3' />
              // </button>
              <></>
              : ""
            )}
              
            </div>
            <div className='card-toolbar'>
              <ul className='nav'>
                <li className='nav-item'>
                  {isMinimized ?
                    <>
                      <a onClick={handleToggleMinimize}  href="#" className="btn btn-icon btn-light btn-sm">
                        <i className="bi bi-chevron-down"></i>
                      </a>
                    </>
                    :
                    <>
                      <a onClick={handleToggleMinimize}  href="#" className="btn btn-icon btn-light btn-sm">
                        <i className="bi bi-chevron-up"></i>
                      </a>
                    </>
                  }
                </li>
              </ul>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='tab-content'>
              {/* begin::Tap pane */}
              <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>                
                <div className={` grid-container` }>                  
                  {seccion.campos.map((dat, idx) => (
                      (((dat.visible && dat.mostrar) || (rolusuario === "SUPER"))?
                        <div className={`mb-2 ${dat.tipo[0].nombre === "carga de documentos" ? "grid-item-Documento":"grid-item" }`} key={idx} >
                          <label className={`form-label`}>
                            <span className={`${dat.obligatorio ? 'required' : ''}`}>{dat.titulo}</span> 
                            {(formData.campos[idx].observado ?
                              <>
                                <i
                                  className={`fas fa-exclamation-circle ms-2 fs-7 ${estatusFicha === 'aprobadoConPendiente'? 'text-warning' : 'text-danger'}  jumping`}
                                  data-tooltip-id={`my-tooltip-${formData.campos[idx]._id}`}
                                >
                                </i>
                                <ReactTooltip
                                  id={`my-tooltip-${formData.campos[idx]._id}`}
                                  place="bottom"
                                  variant={`${estatusFicha === 'aprobadoConPendiente'? 'warning' : 'error'}`}
                                  content={ String(formData.campos[idx].observacion?.[Number(formData.campos[idx].observacion?.length) - 1]?.value) }
                                />
                              </>
                            :
                              ""
                            )}
                            
                          </label>
                          <br></br>
                            {(  dat.tipo[0].nombre === "Caja de Texto"?
                            <input
                            type='text'
                            className={`form-control form-control-sm form-control-solid 
                                      ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}                            
                            name={dat.tipo[0].nombre}
                            style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}
                            // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux)   ? true : false }
                            disabled = {true}
                            value={formData.campos[idx].values}
                            onChange={(event) => handleInputChange(event, idx)}
                            placeholder='' />
                            
                          
                          :  dat.tipo[0].nombre === "Lista Desplegable"?
                          <select 
                          className={`form-select form-select-solid ${(!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? 'disabled' : ''}   ${formData.campos[idx].hasError ? 'is-invalid' : ''}`} 
                          aria-label="Select example"
                          style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}
                          // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? true : false }
                          disabled = {true}
                          name={dat.tipo[0].nombre}
                          value={formData.campos[idx].values}                      
                          onChange={(event) => handleInputChange(event, idx)}>
                            <option value="">Seleccionar</option>
                            {dat.opciones?.map(opc => (       
                              <option value={opc.label} > { String(opc.label) }</option>
                            ))}
                            
                          </select>
                          
                          :  dat.tipo[0].nombre === "checkbox"?
                          <div className="mb-2 d-flex flex-wrap">
                            {dat.opciones?.map((opc,idx2) => (
                              <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                                <input 
                                className={`form-check-input ${formData.campos[idx].hasError ? 'is-invalid' : ''}`} 
                                type="checkbox"
                                style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}
                                // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? true : false }
                                disabled = {true}
                                name={opc.label} 
                                checked={formData.campos[idx].opciones[idx2].value?true:false}                            
                                onChange={(event) => handleInputChange(event, idx)}
                                id={`flexRadioLg`+idx2}/>
                                <label className="form-check-label" htmlFor={`flexRadioLg`+idx2}>
                                  { opc.label }
                                </label>
                              </div>
                            ))}
                          </div>
                          : dat.tipo[0].nombre === "radiobutton" ?
                          <div className="mb-2 d-flex flex-wrap">
                            {dat.opciones?.map((opc,idx2) => (
                              <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                                <input className={`form-check-input ${formData.campos[idx].hasError ? 'is-invalid' : ''}`} type="radio"
                                value={opc.label} 
                                style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}
                                // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux)? true : false }
                                disabled = {true}
                                checked={formData.campos[idx].opciones[idx2].value?true:false}                          
                                onChange={(event) => handleInputChange(event, idx)}
                                id="flexRadioSm"  />
                                <label className="form-check-label" htmlFor="flexRadioSm">
                                { opc.label }
                                </label>
                            </div>
                            ))}                    
                          </div>  
                          : dat.tipo[0].nombre === "Lista desplegable BD" ?
                            <div>
                              <Select
                                name={formData.campos[idx]._id}
                                placeholder='Seleccione un campo'
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc', 
                                    minHeight: '34px',
                                    height: '34px',
                                    fontFamily: 'inherit',
                                    fontSize: '0.95rem',
                                    color: '#5e6278'
                                  }),
                                }}
                                options={formData.campos[idx].tablaData[0]?.option?formData.campos[idx].tablaData[0].option:[]}
                                value={formData.campos[idx].selectOpion?formData.campos[idx].selectOpion:null}
                                // isDisabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? true : false }
                                isDisabled = {true}
                                onChange={(selectedOption) => handleInputChangeSelect(selectedOption, idx)}
                              />
                            </div>                    
                          : dat.tipo[0].nombre === "Numero" ?
                            <input
                            type='number'
                            className={`form-control form-control-sm form-control-solid ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}
                            onChange={(event) => handleInputChange(event, idx)}
                            style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}
                            // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? true : false }
                            disabled = {true}
                            value={formData.campos[idx].values}  
                            placeholder='' />                      
                          : dat.tipo[0].nombre === "Fecha" ?  
                          <div >
                            <input
                              type="date"
                              id="dateInput"
                              // disabled = { (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? true : false }
                              disabled = {true}
                              style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc', fontSize:'14px' }}
                              name={dat.tipo[0].nombre}
                              value={formData.campos[idx].values}                            
                              onChange={(event) => handleInputChange(event, idx)}
                              className={`custom-date-input ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}
                            />
                          </div>
                          :dat.tipo[0].nombre === "Imagen" ?  
                            <div className={ (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? 'disabled' : '' } style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '' }}  >
                              <DropzoneImagen key={dat._id} onFilesSelected={(files,ind,infoImagen) => handleFilesSelected(files, idx,infoImagen)}  idx={dat._id}   titulo={dat.titulo} filesImagen={dat.Files?dat.Files:[]} infoImagen={dat.infoFiles?dat.infoFiles:[]} disabled = {true}/>
                            </div >
                          :dat.tipo[0].nombre === "carga de documentos" ?  
                            <div className={ (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? 'disabled' : '' } style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '' }}>
                              <DropzoneArchivo 
                              key={dat._id} 
                              onFilesSelected={(files,ind,infoArchivo) => 
                              handleFilesSelected(files, idx,infoArchivo)}  
                              idx={dat._id}   
                              titulo={dat.titulo} 
                              filesArchivo={dat.Files?dat.Files:[]} 
                              infoArchivo={dat.infoFiles?dat.infoFiles:[]}  disabled = {true}
                              />
                            </div > 
                          :dat.tipo[0].nombre === "Firma" ? 
                              ( formData.campos[idx].values? 
                                <>
                                  <img src={formData.campos[idx].values} style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc' }}  />
                                  {/* <div className={`button-container ${(!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? 'disabled' : ''} ` } >
                                      <button className="btn btn-light-warning btn-sm" onClick={() => removeFirma(idx)}>Modificar</button>
                                  </div> */}
                                </>
                                : 
                                <div className={ (!formData.campos[idx].editable && !formData.campos[idx].observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !formData.campos[idx].observadoAux) ? 'disabled' : '' } style={{ border: formData.campos[idx].observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '' }}> 
                                  <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === 'panel_graficar' ? 'active' : ''}`}
                                        onClick={() => handleTabChange('panel_graficar')}
                                      >
                                        Graficar
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === 'panel_cargar' ? 'active' : ''}`}
                                        onClick={() => handleTabChange('panel_cargar')}
                                      >
                                        Cargar
                                      </button>
                                    </li>
                                    <li className="nav-item">
                                      <button
                                        className={`nav-link ${activeTab === 'panel_generar' ? 'active' : ''}`}
                                        onClick={() => handleTabChange('panel_generar')}
                                      >
                                        Generar
                                      </button>
                                    </li>
                                  </ul>
                                  <div className="tab-content" id="myTabContent">
                                    <div className={`tab-pane fade ${activeTab === 'panel_graficar' ? 'show active' : ''}`} >
                                      <SignaturePad 
                                        key={dat._id} 
                                        onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                        shouldClearFirma={shouldClearFirma}
                                        onClearFirma={handleClearFirmaComplete} />
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'panel_cargar' ? 'show active' : ''}`}>
                                      <DropzoneImagenUnico 
                                        key={dat._id} 
                                        onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                        shouldClearFirma={shouldClearFirma}
                                        onClearFirma={handleClearFirmaComplete}
                                        idx={dat._id} />
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'panel_generar' ? 'show active' : ''}`}>
                                      <TextToStyleGenerator
                                        key={dat._id}
                                        onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                        shouldClearFirma={shouldClearFirma}
                                        onClearFirma={handleClearFirmaComplete} />
                                    </div>
                                  </div>
                                </div>
                              )
                          : ""
                          )}                        
                          <span className='text-muted mt-1 fw-semibold fs-7'>{dat.comentario}</span>
                            {(!formData.campos[idx].values || !formData.campos[idx].valuesFiles) && formData.campos[idx].obligatorio && formData.campos[idx].hasError && (
                              <div className='fv-plugins-message-container'>
                                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                  {formData.campos[idx].titulo } es requerido
                                </div>
                              </div>
                            )} 
                            { formData.campos[idx].observado && (
                              <div className='fv-plugins-message-container'>
                                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                  <h6 className='text-warning' >{ String(formData.campos[idx].observacion?.[Number(formData.campos[idx].observacion?.length) - 1]?.value) }</h6> 
                                </div>
                              </div>
                            )} 
                        </div>
                        :
                        null
                      )
                      
                  ))}                  
                </div>
                {( seccion.dinamico ?
                  <div>
                    {seccion.secciones?.map((secciones, item) => (
                      <div className='bg-white hoverable mt-2'>
                          <div className='card-header border-0 pt-2 bg-white hoverable card-seccion-header'>
                            <h3 className='card-title align-items-start flex-column'>
                            </h3>
                            <div className='card-toolbar'>
                              <ul className='nav'>
                                <li className='nav-item'>
                                <span className="btn btn-light-danger badge badge-square badge-light " onClick={() => handleRemoveSeccion(item)}>
                                    X
                                </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className=' grid-container-seccion'>  
                                          
                            {secciones.data.map((datSec, idx) => (
                              (datSec.visible?
                                <div className='mb-2 grid-item' key={idx} >
                                  <label className={`form-label`}>
                                    <span className={`${datSec.obligatorio ? 'required' : ''}`}>{datSec.titulo}</span> 
                                    {(datSec.comentario?
                                        <i
                                        className='fas fa-exclamation-circle ms-2 fs-7'
                                        data-bs-toggle='tooltip'
                                        title={datSec.comentario}
                                      ></i>
                                    :
                                      ""
                                    )}
                                    
                                  </label>
                                  <br></br>
                                    {(  datSec.tipo[0].nombre === "Caja de Texto"? 
                                    <input
                                    type='text'
                                    className={`form-control form-control-sm form-control-solid ${datSec.hasError ? 'is-invalid' : ''}`}
                                    name={datSec.tipo[0].nombre}
                                    value={datSec.values}
                                    disabled = { !datSec.editable ? true : false }
                                    onChange={(event) => handleInputChange2(event, item, idx)}
                                    placeholder='' />
                                    
                                  
                                  :  datSec.tipo[0].nombre === "Lista Desplegable"?
                                  <select 
                                  className={`form-select form-select-solid ${datSec.hasError ? 'is-invalid' : ''}`} 
                                  aria-label="Select example"
                                  disabled = { !datSec.editable ? true : false }
                                  name={datSec.tipo[0].nombre}        
                                  value={datSec.values}                      
                                  onChange={(event) => handleInputChange2(event,item, idx)}>
                                    <option value="">Seleccionar</option>
                                    {datSec.opciones?.map(opc => (       
                                      <option value={opc.label} > { String(opc.label) }</option>
                                    ))}
                                    
                                  </select>
                                  
                                  :  datSec.tipo[0].nombre === "checkbox"?
                                  <div className="mb-2 d-flex flex-wrap">
                                    {datSec.opciones?.map((opc,idx2) => (
                                      <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                                        <input 
                                        className={`form-check-input ${datSec.hasError ? 'is-invalid' : ''}`} 
                                        type="checkbox"
                                        name={opc.label}
                                        disabled = { !datSec.editable ? true : false }
                                        checked={datSec.opciones[idx2].value?true:false}                            
                                        onChange={(event) => handleInputChange2(event,item, idx)}
                                        id={`flexRadioLg`+idx2}/>
                                        <label className="form-check-label" htmlFor={`flexRadioLg`+idx2}>
                                          { opc.label }
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  : datSec.tipo[0].nombre === "radiobutton" ?
                                  <div className="mb-2 d-flex flex-wrap">
                                    {datSec.opciones?.map((opc,idx2) => (
                                      <div className="form-check form-check-custom form-check-solid form-check-sm m-2">
                                        <input className={`form-check-input ${datSec.hasError ? 'is-invalid' : ''}`} type="radio"
                                        value={opc.label} 
                                        checked={datSec.opciones[idx2].value?true:false}    
                                        disabled = { !datSec.editable ? true : false }                      
                                        onChange={(event) => handleInputChange2(event,item, idx)}
                                        id="flexRadioSm"  />
                                        <label className="form-check-label" htmlFor="flexRadioSm">
                                        { opc.label }
                                        </label>
                                    </div>
                                    ))}                    
                                  </div>  
                                  : datSec.tipo[0].nombre === "Lista desplegable BD" ?
                                    <div>
                                      <Select
                                        name={datSec._id}
                                        placeholder='Seleccione un campo'
                                        isDisabled = { !datSec.editable ? true : false }
                                        styles={{
                                          control: (provided, state) => ({
                                            ...provided,
                                            border: datSec.observado ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '1px solid #ccc', 
                                            minHeight: '34px',
                                            height: '34px',
                                            fontFamily: 'inherit',
                                            fontSize: '0.95rem',
                                            color: '#5e6278'
                                          }),
                                        }}
                                        options={datSec.tablaData[0]?.option ? datSec.tablaData[0].option: []}
                                        value={datSec.selectOpion?datSec.selectOpion:null}
                                        onChange={(selectedOption) => handleInputChangeSelect(selectedOption, idx)}
                                      />
                                    </div>                    
                                  : datSec.tipo[0].nombre === "Numero" ?
                                    <input
                                    type='number'
                                    disabled = { !datSec.editable ? true : false }
                                    className={`form-control form-control-sm form-control-solid ${datSec.hasError ? 'is-invalid' : ''}`}
                                    onChange={(event) => handleInputChange2(event,item, idx)}
                                    value={datSec.values}  
                                    placeholder='' />  

                                  : datSec.tipo[0].nombre === "Fecha" ?  
                                  <div>
                                    <input
                                      type="date"
                                      id="dateInput"
                                      disabled = { !datSec.editable ? true : false }
                                      name={datSec.tipo[0].nombre}
                                      value={datSec.values}      
                                      style={{ fontSize:'14px' }}                      
                                      onChange={(event) => handleInputChange2(event,item, idx)}
                                      className={`custom-date-input ${datSec.hasError ? 'is-invalid' : ''}`}
                                    />
                                  </div>
                                  :datSec.tipo[0].nombre === "Imagen" ?  
                                    <div>
                                      <DropzoneImagen key={datSec._id} onFilesSelected={(files,ind,infoImagen) => handleFilesSelected(files, idx,infoImagen)}  idx={datSec._id}   titulo={datSec.titulo} filesImagen={datSec.Files?datSec.Files:[]} infoImagen={datSec.infoFiles?datSec.infoFiles:[]} />
                                    </div>
                                  :datSec.tipo[0].nombre === "carga de documentos" ?  
                                    <div>
                                      <DropzoneArchivo 
                                      key={datSec._id} 
                                      onFilesSelected={(files,ind,infoArchivo) => 
                                        handleFilesSelected(files, idx,infoArchivo)
                                      }  
                                      idx={datSec._id}   
                                      titulo={datSec.titulo} 
                                      filesArchivo={datSec.Files?datSec.Files:[]} 
                                      infoArchivo={datSec.infoFiles?datSec.infoFiles:[]} />
                                    </div> 
                                  :datSec.tipo[0].nombre === "Firma" ? 
                                    <div> 
                                      <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                        <li className="nav-item">
                                          <button
                                            className={`nav-link ${activeTab === 'panel_graficar' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('panel_graficar')}
                                          >
                                            Graficar
                                          </button>
                                        </li>
                                        <li className="nav-item">
                                          <button
                                            className={`nav-link ${activeTab === 'panel_cargar' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('panel_cargar')}
                                          >
                                            Cargar
                                          </button>
                                        </li>
                                        <li className="nav-item">
                                          <button
                                            className={`nav-link ${activeTab === 'panel_generar' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('panel_generar')}
                                          >
                                            Generar
                                          </button>
                                        </li>
                                      </ul>
                                      <div className="tab-content" id="myTabContent">
                                        <div className={`tab-pane fade ${activeTab === 'panel_graficar' ? 'show active' : ''}`}>
                                          <SignaturePad 
                                            key={datSec._id} 
                                            onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                            shouldClearFirma={shouldClearFirma}
                                            onClearFirma={handleClearFirmaComplete} />
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 'panel_cargar' ? 'show active' : ''}`}>
                                          <DropzoneImagenUnico 
                                            key={datSec._id} 
                                            onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                            shouldClearFirma={shouldClearFirma}
                                            onClearFirma={handleClearFirmaComplete}
                                            idx={datSec._id} />
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 'panel_generar' ? 'show active' : ''}`}>
                                          <TextToStyleGenerator
                                            key={"sec"+datSec._id}
                                            onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                            shouldClearFirma={shouldClearFirma}
                                            onClearFirma={handleClearFirmaComplete} />
                                        </div>
                                      </div>
                                    </div>
                                  : ""
                                  )}                        
                                  <span className='text-muted mt-1 fw-semibold fs-7'>{datSec.comentario}</span>
                                    {(!datSec.values || !datSec.valuesFiles) && datSec.obligatorio && datSec.hasError && (
                                      <div className='fv-plugins-message-container'>
                                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                          {datSec.titulo } es requerido
                                        </div>
                                      </div>
                                    )} 
                                </div>
                                :
                                null
                              )
                            ))}                  
                          </div>
                      </div>
                    ))}
                  </div>
                  :
                  ""
                )}
              </div>
            </div>
          </div>
          {
            (estatusFicha === 'pendientePorColaborador' || estatusFicha ==='fichaSiendoEditada') && (
              <div className="card-footer py-4 ">
                <button ref={btnRef} onClick={onClick} type="button" className="btn btn-primary btn-sm me-10" id="kt_button_1">
                  <span className="indicator-label">Guardar Sección</span>
                  <span className="indicator-progress">Cargando
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
            )
          }
          
        </div>

        
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
      </div> 
  );
}

interface MyProps {
  procesoFicha: ProcesoFichas[];
  historial:Historial[];
  num:number;
  estatusFicha:string;  
  onUpdate: (updatedData: any) => void;
}

export const PartnerSheet : React.FC<MyProps> = ({procesoFicha,num,estatusFicha,historial}) => {
  const location = useLocation()
  const [isLoading, setLoadingStatus] = useState(false)
  const [procesos, setProcesos] = useState<ProcesoFichas[]>(procesoFicha)
  const [historialData, setHistorialData] = useState<Historial[]>(historial)
  const [estadoRegistroFicha, setEstadoRegistroFicha] = useState(estatusFicha)
  const btnGuardarRef = useRef<HTMLButtonElement | null>(null);
  const btnBorradorRef = useRef<HTMLButtonElement | null>(null);
  
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  
  useEffect(() => {
    setLoadingStatus(true)
    setProcesos(procesoFicha)
    setHistorialData(historial)
    setEstadoRegistroFicha(estatusFicha)
    //refetchData()
  }, [procesoFicha])

  const handleUpdateSeccion = (fichaIndex:any, seccionIndex:any, updatedData:Seccion) => {
    const newProcesos = [...procesos];
    newProcesos[num].fichas[fichaIndex].seccion[seccionIndex] = updatedData;
    setProcesos(newProcesos);
    onValidarDependencia()
  };

  const onValidarDependencia = (): boolean => {
    let result = true;

    procesos.map((procesoFicha) => {
      procesoFicha.fichas.map((ficha) => {
        ficha.seccion.map((seccion) => {
          seccion.campos.map((cam) => {
            if (cam.dependiente) {
                let mostrar = false
                let selectOption = null
                procesos.map((procesoFicha2) => {
                  procesoFicha2.fichas.map((ficha2) => {
                    ficha2.seccion.map((seccion2) => {
                      seccion2.campos.map((cam2) => {
                        
                        if(cam2.mostrar && cam.respuestaCampoDependiente === cam2.values && cam2._id === cam.campoDependiente){
                          mostrar = true
                        }

                        if(cam2.mostrar && cam2.tipo[0].nombre === "Lista desplegable BD" && cam2._id === cam.campoDependiente){
                          console.log(cam2.selectOpion)
                          mostrar = true
                          const newOpciones: any = []
                          cam.tablaData[0].value.map(dat => {
                            if(String(cam2.selectOpion?.value) === dat.parent_id ){
                              newOpciones.push({
                                value: dat.id?dat.id:'',
                                label: dat.name?dat.name:''
                              })
                            }
                          })
                          cam.tablaData[0].option=newOpciones
                        }

                      })
                    })
                  })
                })

                cam.tablaData[0]?.option.map(opc => {
                  if(opc.label === cam.selectOpion?.label && opc.value === cam.selectOpion?.value){
                    selectOption = cam.selectOpion
                  }
                })
                cam.mostrar = mostrar
                cam.selectOpion = selectOption
            } 
            return cam;
          })
        })
      })

    });

    const dataWithValuesAndFiles:ProcesoFichas[] = procesos.map((procesoFicha:ProcesoFichas) => ({
      ...procesoFicha,
    }));
    setProcesos(dataWithValuesAndFiles); 
    return result
  };

  const onValidarCampos = (): boolean => {
    let result = true;

    const updatedFormData = procesos.map((procesoFicha) => {
      procesoFicha.fichas.map((ficha) => {
        ficha.seccion.map((seccion) => {
          seccion.campos.map((cam) => {
            if(!seccion.dinamico){
              if (cam.obligatorio && !cam.values && cam.valuesFiles.length === 0 && cam.selectOpion === null) {
                cam.hasError = true;
                result = false;
              } else {
                cam.hasError = false;
              }
            }
            
            return cam;
          })
        })
      })

    });

    const dataWithValuesAndFiles:ProcesoFichas[] = procesos.map((procesoFicha:ProcesoFichas) => ({
      ...procesoFicha,
      fichas:procesoFicha.fichas.map((ficha:Ficha) => ({ 
        ...ficha,
        seccion:ficha.seccion.map((seccion:Seccion) => ({
          ...seccion,
          campos: seccion.campos.map((campo:Campos) => ({
            ...campo,
            hasError: (campo.obligatorio && !campo.values && campo.valuesFiles.length === 0 && campo.selectOpion === null) ? true:false
          }))
        })),
      }))
      
      
    }));
    setProcesos(dataWithValuesAndFiles); 
    return result
  };

  const onGuardar = () => {
    if(onValidarCampos() === true){
      btnGuardarRef.current?.setAttribute('data-kt-indicator', 'on');

      if(estadoRegistroFicha === "fichaObservada"){
        let ultimaObsevación = ''
        historialData.map(his => {
          if(his.motivo === 'OBSERVADO'){
            ultimaObsevación = his.data?.text?his.data?.text:''
          }
        })
        showResponseObservacionMessage(ultimaObsevación).then((result) => {
          if (result.isConfirmed) {
            historialData.push({
              createdAt: new Date(),
              motivo: 'OBSERVADO',
              id_user_creador: String(idusuario),
              id_user_receptor: String('64e3e8423f9119497dac5f3c'),
              data:{
                nameuser: String(nameusuario),
                iduser: String(idusuario),
                text: result.value,
                fecha: new Date()
              }
            })

            const data = {
              id_user_creador:idusuario,
              estado:"completadoPorColaborador",
              procesoficha:procesos,
              historial:historialData
            } 
  
            
            nuevoRegistroFichaTotal(data)
            .then((response) => {
              if (response?.success) {
                setEstadoRegistroFicha(response?.estado)
                btnBorradorRef.current?.removeAttribute("data-kt-indicator");
                showMessage('success', '¡Buen trabajo!', response.message)      
              } else {
                showMessage('error', 'Hubo un error', response.message)
                console.log(response?.message)
              }
            })
            .catch((error) => {
              btnBorradorRef.current?.removeAttribute("data-kt-indicator");
              console.log(error)
            })
  
          }else {
            btnBorradorRef.current?.removeAttribute("data-kt-indicator");          
          }
        }) 
      }else{
        historialData.push({
          createdAt: new Date(),
          motivo: 'completadoPorColaborador',
          id_user_creador: String(idusuario),
          id_user_receptor: String('64e3e8423f9119497dac5f3c')
        })
        const data = {
          id_user_creador:idusuario,
          estado:"completadoPorColaborador",
          procesoficha:procesos,
          historial:historialData    
        }

        nuevoRegistroFichaTotal(data)
          .then((response) => {
            if (response?.success) {
              setEstadoRegistroFicha(response?.estado)
              btnGuardarRef.current?.removeAttribute("data-kt-indicator");
              showMessage('success', '¡Buen trabajo!', response.message)      
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnGuardarRef.current?.removeAttribute("data-kt-indicator");
            console.log(error)
          })
      }
    }
    

  };

  const onBorrador = () => {
      btnBorradorRef.current?.setAttribute('data-kt-indicator', 'on');

        historialData.push({
          createdAt: new Date(),
          motivo: 'fichaSiendoEditada',
          id_user_creador: String(idusuario),
          id_user_receptor: String('64e3e8423f9119497dac5f3c')
        })
        const data = {
          id_user_creador:idusuario,
          estado:"fichaSiendoEditada",
          procesoficha:procesos,  
          historial:historialData      
        }

        nuevoRegistroFichaTotal(data)
        .then((response) => {
          if (response?.success) {
            btnBorradorRef.current?.removeAttribute("data-kt-indicator");
            showMessage('success', '¡Buen trabajo!', response.message)      
          } else {
            showMessage('error', 'Hubo un error', response.message)
            console.log(response?.message)
          }
        })
        .catch((error) => {
          btnBorradorRef.current?.removeAttribute("data-kt-indicator");
          console.log(error)
        })

  };


  return (
    <div>
    {procesos[num]?.fichas[0].seccion.map((item, idx) => (
      <CardComponent 
      key={String(num+"-"+idx)}
      seccion={item}
      estatusFicha={estadoRegistroFicha}
      onUpdate={(updatedData) => handleUpdateSeccion(0, idx, updatedData)}  />  
    ))}

      {/* <pre>{JSON.stringify(procesos[num], null, 2)}</pre> */}
    </div>
  )
}