import {useContext, useState} from 'react'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {Card, Accordion, Badge} from 'react-bootstrap'
import {title} from 'process'
import Collapsible from 'react-collapsible'
import {KTIcon} from '../../../../../_metronic/helpers'
import {SheetEditContext} from '../SheetContexts'
import {DraggableFieldList} from './DraggableFieldList'


const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

const CollapsibleFieldItem = ({item, index, sectionItem, permisos}: any) => {
  const {openFieldModal, setData, sheetData, setFieldData, setSectionData,openAssignmentModal,openAssignmentRequeridoModal} =
    useContext(SheetEditContext)

  const deleteField = () => {
    setData({
      ...sheetData,
      sections: sheetData?.sections?.map((n) =>
        n.id === sectionItem.id ? {...n, fields: n.fields?.filter((f) => f.id !== item.id)} : n
      ),
    })
  }

  const editField = () => {
    setSectionData(sectionItem)
    setFieldData(item)
    openFieldModal()
  }

  const addAssignment = () => {
    setSectionData(sectionItem)
    setFieldData(item)
    openAssignmentModal()
  }

  const addAssignmentRequerido = () => {
    setSectionData(sectionItem)
    setFieldData(item)
    openAssignmentRequeridoModal()
  }

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <div style={{position: 'relative'}}>
            <div style={{marginLeft: '30px', fontWeight: 'normal'}}>{item.name}</div>

            <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
              <Badge
                bg='gray-600'
                className='me-2'
                style={{transform: 'translateY(-2px)', color: 'white'}}
              >
                {index + 1}
              </Badge>
            </div>

            <div style={{position: 'absolute', top: '0', right: '0'}}>
              {permisos.includes('Asignacion Requeridas') && (
                <a
                  href='#!'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{transform: 'translateY(-8px)'}}
                  title='Asignar Datos Requeridos'
                  onClick={() => addAssignmentRequerido()}
                >
                  <KTIcon
                    iconName='disconnect'
                    className='fs-3'
                  />
                </a>
              )}
              {permisos.includes('Asignacion PowerApps') && (
                <a
                  href='#!'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  style={{transform: 'translateY(-8px)'}}
                  title='Asignar a Power Apps'
                  onClick={() => addAssignment()}
                >
                  <KTIcon
                    iconName='disconnect'
                    className='fs-3'
                  />
                </a>
              )}
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Editar Campo'
                onClick={() => editField()}
              >
                <KTIcon iconName='pencil' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                style={{transform: 'translateY(-8px)'}}
                title='Eliminar Campo'
                onClick={() => deleteField()}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export {CollapsibleFieldItem}
