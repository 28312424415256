import { isObject } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUnidadesPorId } from '../../../../services/companies';
import { getDocumentById, saveDocument, showFilePlantillaMatchS3 } from '../../../../services/documentos';
import { getAllFilesCPD, getFileId, getFileInPDF, getFilexIdproceso } from '../../../../services/files';
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu';
import { getProceso } from '../../../../services/procesos';
import { showDeleteMessage, showInfoCopy, showMessage } from '../../../../shared/Alerts';
import { useAuth2 } from '../../../authv2/Auth2Provider';
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface';
import { handleS3Convert64 } from '../../../generales_functions/files';
import { PartnerContext } from '../PartnerContexts';
import { FilesPersonalizado } from '../enviodocumentInterface';
import { ShowDocumentModal } from '../show-document-modal/ShowDocumentModal';
import { ShowDocumentPersonalizadoModal } from '../show-document-personalizado-modal/ShowDocumentPersonalizadoModal';
import { ShowFileModal } from '../show-file-modal/ShowFileModal';
import { ShowUserModal } from '../show-user-modal/ShowUserModal';
import OverlayLoading from './../../../generales_component/OverlayLoading';
import { PartnersTableGeneral } from './../../../generales_component/PartnersTableGeneral';

import Select from 'react-select'

const DocumentUploadForm = () => { 
  const navigate = useNavigate()
  const {id} = useParams()

  const {setShowSelectUserModal,setShowSelectDocumentModal,setShowSelectDocumentPersonalizadoModal,
          idProcess,setIdProcess,listUsers,setListUsers,listDocuments,setShowFileModal,
          setListDocuments, listDocumentsPersonalizado, setListDocumentsPersonalizado, setViewDocumento, setSelectedPlantillaId,unidad,setUnidad, missingTags} = useContext(PartnerContext)
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  const [loading, setLoading] = useState(false)
  const [loadingPendiente, setLoadingPendiente] = useState(false)  
  const [loadingBorrador, setLoadingBorrador] = useState(false)  
  const [processData, setProcessData] = useState([])
  const [selectedDocument, setSelectedDocument] = useState([])
  const [documentData, setDocumentData] = useState([])
  const [formData, setFormData] = useState({_id:"",codigo:"",name:"",idproceso:"", unidad:""});
  const btnGuardarRef = useRef<HTMLButtonElement | null>(null);
  const btnGuardarActivoRef = useRef<HTMLButtonElement | null>(null);
  const btnBorradorRef = useRef<HTMLButtonElement | null>(null);
  const [hasErrorName, setHasErrorName] = useState<boolean>(false)
  const [cargandoFile, setCargandoFile] = useState(false);
  const [checkCPD, setCheckCPD] = useState(false);
  const [unidades, setUnidades] = useState([]); 
  const [optionDocumentCPD,setOptionDocumentCPD] = useState<{label:string,value:string}[]>([])
  const [dataDocumentCPD,setDataDocumentCPD] = useState<any[]>([])
  const [isNivelesAprobation, setIsNivelAprobation] = useState(false)

  const headerDocument = [
    {
      type: 'string',
      field: 'name',
      label: 'Nombre',
    },
    {
      type: 'string',
      field: 'type',
      label: 'Tipo',
    },
    {
      type: 'string',
      field: 'typeSelected',
      label: 'Tipo Seleccion',
    },
    {
      type: 'symbol',
      field: 'number_aprobacion',
      label: 'Numero Aprobación',
      variantTooltip: {
        number_aprobacion: 'success',
      },
      title: {
        number_aprobacion: 'Cantidad de Niveles',
      },
    },
    {
      type: 'tooltip',
      field: 'nivel_motivo_rechazo',
      label: 'Observaciones',
    },
    {
      type: 'button',
      field: 'edit',
      icon: 'pencil',
      label: '',
    },
    {
      type: 'button',
      field: 'show_url',
      icon: 'eye',
      label: '',
    },
    {
      type: 'button',
      field: 'delete',
      icon: 'cross',
      label: '',
    },
  ]
  const headerUser = [
    {
      type: 'string',
      field: 'dni',
      label: 'DNI',
    },
    {
      type: 'string',
      field: 'nombre',
      label: 'Nombre',
    },
    {
      type: 'date',
      field: 'createdAt',
      label: 'F.Inicio',
    },
    {
      type: 'string',
      field: 'countDocument',
      label: 'N° Doc',
    },    
    {
      type: 'button',
      field: 'delete',
      label: "Eliminar",
    }
  ]
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = '/configuracion/enviodocumentos'
        console.log(currentPath)
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          console.log("permissionsForSubMenu")
          console.log(permissionsForSubMenu)
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(()=>{
    if(checkCPD) getDocumentCPD()
  },[checkCPD])

  const getDocumentCPD = () =>{    
    if(formData.unidad !== "")
      getAllFilesCPD(formData.idproceso,formData.unidad)
      .then((response) => {
        console.log(response)
        setDataDocumentCPD(response.data)
        const grupoCPD = response.data.map((x:any) => ({label: `${x.name}`, value: x._id}))
        setOptionDocumentCPD(grupoCPD)
      })
      .catch((error) => console.log(error))
  }

  useEffect(()=>{
    refetchData()
    if (id) {
      refetchDataId(id)
    }
    fetchUnidades(); 
  },[])

  const fetchUnidades = async () => {
    try {
      const response = await getUnidadesPorId(usuario?.userId);
      if (response.success) {
        setUnidades(response.data.map((unidad: any) => ({
          label: unidad.DesUnid.toUpperCase(),
          value: unidad.codUnid,
        })));
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  useEffect(()=>{
    refreshListUsers()
    verifyNivelesAprobation()
    
  },[listDocuments,listDocumentsPersonalizado])

  const refetchData = () => {
    const options = {
      responsables: usuario?.userId
    }
    getProceso(1,100,options).then((response) => {
      if (response.success) {
        setProcessData(response.data.map((x:any) => ({label: x.nombre, value: x._id})))
      } else {
        console.log(response.msg)
      }
    })
  }

  
  const refetchDataId = (idUploadDocumento:string) => {
    getDocumentById(idUploadDocumento).then((response:any) => {
      if (response.success) {    
        const unidad = response.data.unidad && response.data.unidad.length > 0 ? response.data.unidad[0] : { codUnid: "", DesUnid: "" }; 
        setFormData({_id:response.data._id ,codigo:response.data.codigo,name:response.data.nombre,idproceso:response.data.idProceso, unidad: unidad.codUnid})        
        setIdProcess(response.data.idProceso)
        setUnidad(unidad.codUnid)
        setListDocuments(response.data.idFiles)
        setListUsers(response.data.idUsers)
        if(isObject(response.data.filesChildren)){
          const arrayDocPersonalizado : FilesPersonalizado = response.data.filesChildren
          if (Array.isArray(arrayDocPersonalizado) && arrayDocPersonalizado.length > 0) {
            const arrayPersonalizado = response.data.idFiles.filter((item1:any) => item1.typeSelected === "Personalizado")[0];
            const newPersonalizado = {_id:'', name:arrayPersonalizado.name, type:arrayPersonalizado.type, typeSelected:arrayPersonalizado.typeSelected, children: arrayDocPersonalizado, edit: false}
            console.log(newPersonalizado)
            setListDocumentsPersonalizado(newPersonalizado);
          }
        }
      } else {
        console.log(response.msg)
      }
    })
  }

  const refreshListUsers = () => {
    // count Configured Document
    let newListUsers = listUsers.map((user:any) => {
      const ListDocuAux = listDocuments.filter(doc => doc.typeSelected === 'Configurado')      
      return {...user,countDocument:ListDocuAux.length}
    })

    // count Personalizado Document
    if(listDocumentsPersonalizado.children.length>0){
      newListUsers = newListUsers.map(user => {
        let cont = 0 
        listDocumentsPersonalizado.children.forEach(docPer => {
          if(user.dni === docPer.dni){
            cont++
          }
        })
        return {...user,countDocument:user.countDocument + cont}
      })
    }

    setListUsers(newListUsers)
  }

  const verifyNivelesAprobation = () => {
    let numNiveles = 0
    listDocuments.map(doc => {
      numNiveles = numNiveles + doc.number_aprobacion
    })
    if(numNiveles === 0) setIsNivelAprobation(false)
    else setIsNivelAprobation(true)
  }

  const limpiarFiltros = () => {
    setFormData((prevFormData) => ({...prevFormData, idproceso: "", unidad: ""}));
    setListDocuments([]);
    setListUsers([]);
    setListDocumentsPersonalizado({_id:'', name:'', type:'', typeSelected:'', children: [], edit: false});
  };
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      return { ...prevFormData, [name]:value };
    })

    if(formData.name !== ""){
      setHasErrorName(false)
    }

    if (name === "unidad") {
      setUnidad(value);  
    }
    if(name === "idproceso"){      
        setDocumentData([])
        setSelectedDocument([])
        setIdProcess(value)
        getFilexIdproceso(value)
          .then((response) => {
            if (response.success) {
              setDocumentData(response.data.map((x:any) => ({label: x.name, value: x._id})))
            }
          })
          .catch((error) => {
            console.log(error)
          })

      }
    }

  const handleSelectChange = (e: any) => {
    const datValue = dataDocumentCPD.filter(doc => doc._id === e.value)[0]
    setListDocuments([...listDocuments,{...datValue,typeSelected:"CPD",edit:false}])

  }

  const onValidarCamposBorrador  = () : boolean => {    
    if(formData.name === ""){
      setHasErrorName(true)
      return false
    }
    return true
  }

  const onValidarCampos  = () : boolean => {    
    if(formData.name === ""){
      setHasErrorName(true)
      return false
    }else if(listDocuments.length ===  0){
      showInfoCopy("warning","Añadir Documentos","debe seleccionar documentos a la lista")
      return false
    }else if(listUsers.length === 0){
      showInfoCopy("warning","Añadir Usuarios","debe seleccionar usuarios a la lista")
      return false
    }
    return true
  }

  const onBorrador = () => {
    
    if(onValidarCamposBorrador() === true){
      setLoadingBorrador(true)
     
      const data = {
        _id:formData._id,
        nombre:formData.name,
        idProceso:formData.idproceso,
        idFiles:listDocuments,
        idUsers:listUsers,
        filesChildren:listDocumentsPersonalizado.children,
        estadoCarga:'borrador',
        unidad: formData.unidad,
        codigo: formData.codigo
      }

      btnBorradorRef.current?.setAttribute('data-kt-indicator', 'on');             
      saveDocument(data)
      .then((response) => {
        setLoadingBorrador(false)
        if (response?.success) {
          limpiarForm()
          btnBorradorRef.current?.removeAttribute("data-kt-indicator");
          showMessage('success', '¡Buen trabajo!', response.message)      
        } else {
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error:any) => {
        setLoadingBorrador(false)
        btnBorradorRef.current?.removeAttribute("data-kt-indicator");
        console.log(error)
      })
      
    }
    

  };

  const onGuardar = () => {
    if(onValidarCampos() === true){
      setLoading(true)
      //TODO info para envio de docs 
      const data = {
        _id:formData._id,
        nombre:formData.name,
        idProceso:formData.idproceso,
        idFiles:listDocuments,
        idUsers:listUsers,
        filesChildren:listDocumentsPersonalizado.children,
        estadoCarga:'completado',
        unidad: formData.unidad,
        codigo: formData.codigo
      }

      btnGuardarRef.current?.setAttribute('data-kt-indicator', 'on');             
      saveDocument(data)
      .then((response) => {
        setLoading(false)
        if (response?.success) {
          limpiarForm()
          btnGuardarRef.current?.removeAttribute("data-kt-indicator");
          showMessage('success', '¡Buen trabajo!', response.message)      
        } else {
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error:any) => {
        setLoading(false)
        btnGuardarRef.current?.removeAttribute("data-kt-indicator");
        console.log(error)
      }) 
    }    

  };

  
  const onGuardarActivo = () => {
    if(onValidarCampos() === true){
      setLoadingPendiente(true)
      //TODO info para envio de docs 
      const data = {
        _id:formData._id,
        nombre:formData.name,
        idProceso:formData.idproceso,
        idFiles:listDocuments,
        idUsers:listUsers,
        filesChildren:listDocumentsPersonalizado.children,
        estadoCarga:'pendiente',
        unidad: formData.unidad,
        codigo: formData.codigo
      }

      btnGuardarActivoRef.current?.setAttribute('data-kt-indicator', 'on');             
      saveDocument(data)
      .then((response) => {
        setLoadingPendiente(false)
        if (response?.success) {
          limpiarForm()
          btnGuardarActivoRef.current?.removeAttribute("data-kt-indicator");
          showMessage('success', '¡Buen trabajo!', response.message)      
        } else {
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error:any) => {
        setLoadingPendiente(false)
        btnGuardarActivoRef.current?.removeAttribute("data-kt-indicator");
        console.log(error)
      }) 
    }    

  };

  const limpiarForm = () => {
    setFormData({_id:"",codigo:"",name:"",idproceso:"", unidad:""})
    setListDocuments([])
    setListUsers([])
    setListDocumentsPersonalizado({_id:'', name:'', type:'', typeSelected:'', children: [], edit: false})
  }

  const handleBtnSelected = async (item: any, array:any[], accion:string, propList:string ) => {
    const arrayNew : any[] = []
    let TypeSelected = ""
    if(accion === 'delete'){
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          array.forEach((dat:any) => {
            let entro = false
            if(dat['_id'] === item._id){          
              entro = true
            }
            if(!entro){
              arrayNew.push(dat)
            }
          })
          if(propList === "Users"){
            setListUsers(arrayNew)
            setListDocuments(listDocuments.map(doc => {
              if (doc.tags) {
                const tagsFilter = doc.tags?.filter((tag: any) => tag.user !== item.dni)
                return {
                  ...doc,
                  tags: tagsFilter?.length > 0 ? tagsFilter : null
                }
              }

              return doc
            }))
          }else if(propList === "Documents"){
            setListDocuments(arrayNew)
          }
        }
      })
    }else if(accion === 'show_url'){
      if(item.typeSelected){
        TypeSelected = item.typeSelected
      }
      
      if(TypeSelected === "Personalizado"){
        const newViewDocument:any[] = []
        listDocumentsPersonalizado.children.map(async (dat) => {
          console.log(dat)
          if(dat.keyname){
            const result = await handleS3Convert64(dat.keyname,dat.name)
            if(result){
              newViewDocument.push({
                uri:result.uri,
                fileType:result.fileType,
                fileName:dat.name,
                keyName:dat.keyname
              })
              setShowFileModal(true)
              setCargandoFile(false)
            }else{
              setCargandoFile(false)
            }     
          }else{
            newViewDocument.push({
              uri:dat.url,
              fileType:dat.extension,
              fileName:dat.name
            })
            setShowFileModal(true)
            setCargandoFile(false)
          }
        })
        setViewDocumento(newViewDocument)
        
      }else{
        console.log(listUsers)
        if(item.type === "PLANTILLA"){
          const idUser = listUsers[0]._id
          setCargandoFile(true)
          setSelectedPlantillaId(item._id)
          const response = await showFilePlantillaMatchS3({
            idUser,
            idFile: item._id,
            tagsRelated: item.tags?.find(
              (tag: any) => tag.user === listUsers[0].dni
            )?.tags ?? null
          })
          setCargandoFile(false)

          console.log(response)
          setViewDocumento([
            {
              uri: response.data.data,
              fileType: 'pdf',
              fileName: `${listUsers[0].nombre} - ${response.data.filename}`,
              keyName: response.data.keyname,
            },
          ])
          setShowFileModal(true)
        }else{
          setCargandoFile(true)
          const file = await getFileId(item._id)
          const fileInPdf = await getFileInPDF(file.data.keyname)
          console.log({fileInPdf, file})
          setCargandoFile(false)
          setViewDocumento([
            {
              uri: fileInPdf.data,
              fileType: 'pdf',
              fileName: file.data.filename,
              keyName: file.data.keyname
            },
          ])
          setShowFileModal(true)
        }
      }
    }else if(accion === 'edit'){
      if(item.typeSelected){
        TypeSelected = item.typeSelected
      }
      if(TypeSelected === "Personalizado"){
        setShowSelectDocumentPersonalizadoModal(true)
      }else{
      }
    }
  };

  const onChangeCheckCPD = (e:any) => {
    setCheckCPD(e.target.checked)
  }
  
  const handleAdminDocument = () =>{
    navigate(`/seguimiento/documentos`)
  }
  
  const disabledByMissingTags = listDocuments
    .filter(item => item.tags != null)
    .some(item => item.tags.length !== listUsers.length)

  const handleDisabledSelectMouseOver = () => {
    if (listUsers.length > 0) {
      showMessage('error', 'Para cambiar proceso o unidad', 'Debe limpiar los usuarios y documentos seleccionados.');
    }
  };

  const someDocumentIsDenied = listDocuments?.some(doc => doc.nivel_motivo_rechazo != null) ?? false
  
  return (
    <>
      <Container>
        <Row className=' mb-3 pt-3 px-3'>
          <Row className='mb-3'>
            <Col sm>
              <Form.Group controlId='formNombre'>
                <Form.Label style={{paddingBottom: '3px'}}>Nombre de carga</Form.Label>
                <Form.Control
                  type='text'
                  required
                  name='name'
                  value={formData.name}
                  onChange={(event) => handleInputChange(event)}
                  className={`form-control-sm ${hasErrorName ? 'is-invalid' : ''}`}
                  placeholder='Ingrese un nombre'
                />
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group controlId='formNombre'>
                <Form.Label style={{paddingBottom: '3px'}}>Unidad</Form.Label>
                <OverlayTrigger
                  overlay={
                    listUsers.length > 0 ? (
                      <Tooltip>Debe limpiar los usuarios y documentos seleccionados.</Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <select
                    className={`form-select form-select-solid`}
                    aria-label='Select example'
                    value={formData.unidad}
                    name='unidad'
                    onChange={(event) => handleInputChange(event)}
                    disabled={listUsers.length > 0}
                    onMouseOver={handleDisabledSelectMouseOver} 
                  >
                    <option value=''>Seleccionar</option>
                    {unidades.map((unidad: any) => (
                      <option key={unidad.value} value={unidad.value}>
                        {unidad.label}
                      </option>
                    ))}
                  </select>
                </OverlayTrigger>
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group controlId='formNombre'>
                <Form.Label style={{paddingBottom: '3px'}}>Proceso</Form.Label>
                <OverlayTrigger
                  overlay={
                    listUsers.length > 0 ? (
                      <Tooltip>Debe limpiar los usuarios y documentos seleccionados.</Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <select
                    className={`form-select form-select-solid}`}
                    aria-label='Select example'
                    value={formData.idproceso}
                    name='idproceso'
                    onChange={(event) => handleInputChange(event)}
                    disabled={listUsers.length > 0} // Deshabilitar si hay usuarios seleccionados
                    onMouseOver={handleDisabledSelectMouseOver} // Mostrar mensaje si hay usuarios seleccionados
                  >
                    <option value=''>Seleccionar</option>
                    {processData.map((x: any) => (
                      <option value={x.value}> {String(x.label)}</option>
                    ))}
                  </select>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
          {listUsers.length > 0 && (
            <Row className='mb-3'>
              <Col
                sm={{span: 3, offset: 9}}
                className='d-flex align-items-center justify-content-end'
              >
                <Button variant='warning' className='btn-sm' type='button' onClick={limpiarFiltros}>
                  Habilitar Filtros
                </Button>
              </Col>
            </Row>
          )}
          <Row className='bg-secondary my-3 mx-1'>
            <Col sm>
            {permissions.includes('Seleccionar Usuarios') && (
              <Button
                variant='primary'
                className='mx-3 mt-3 btn-sm'
                type='button'
                onClick={() => {
                  idProcess !== ''
                    ? setShowSelectUserModal(true)
                    : showInfoCopy(
                        'info',
                        'Seleccionar el Proceso',
                        'debe seleccionar el proceso antes de buscar a los usuario'
                      )
                }}
              >
                Seleccionar Usuarios
              </Button>)}
              <PartnersTableGeneral
                title={'Usuario Seleccionado'}
                searchVisible={true}
                searchHint={'Buscar Usuarios ...'}
                theaderVisible={true}
                theader={headerUser}
                tbody={listUsers}
                tMaxHeight={'200px'}
                onBtnSelected={(Item: any, accion: string) =>
                  handleBtnSelected(Item, listUsers, accion, 'Users')
                }
                classTable='table-rounded table-striped border'
              />
            </Col>
          </Row>
          <Row className='bg-secondary my-3 mx-1 '>
            <Col sm>
            {permissions.includes('Seleccionar Documentos') && (         
              <Button
                variant='primary'
                className='mx-3 mt-3 btn-sm'
                type='button'
                onClick={() => {
                  listUsers.length > 0
                    ? setShowSelectDocumentModal(true)
                    : showInfoCopy(
                        'info',
                        'Seleccionar un Usuarios',
                        'debe seleccionar al menos 1 usuario'
                      )
                }}
              >
                Seleccionar Doc.
              </Button>)}
              {permissions.includes('Cargar Documentos Personalizados') && (
                <Button
                  variant='primary'
                  className='mx-3 mt-3 btn-sm'
                  type='button'
                  onClick={() => {
                    listUsers.length > 0
                      ? setShowSelectDocumentPersonalizadoModal(true)
                      : showInfoCopy(
                          'info',
                          'Seleccionar un Usuarios',
                          'debe seleccionar al menos 1 usuario'
                        )
                  }}
                >
                  Cargar Doc Personalizados
                </Button>)}
            </Col>
            {/* <Col sm>
              <Row className='mt-4'>
                <Col sm={4} >
                  <Form.Group controlId='formCheckInclude' >
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input className="form-check-input" 
                        type="checkbox"
                        disabled={listUsers.length>0?false:true} 
                        onChange={(e:any) => onChangeCheckCPD(e)} 
                        checked={checkCPD} 
                        id="flexSwitchDefault"/>
                      <label className="form-check-label" >
                          <b>¿INCLUYE CPD?</b>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={8}>
                  { checkCPD && (
                      <Select
                        name='documentCPD'
                        options={optionDocumentCPD}
                        styles={{
                          control: (provided:any) => ({
                            ...provided,
                            fontSize: '12px',
                            minHeight: '34px', // Reducción del alto
                            height: '34px',
                          }),
                        }}
                        onChange={handleSelectChange}
                        placeholder='Seleccione Grupo Aprobación'
                      />
                  )}
                </Col>
              </Row>              
            </Col> */}
            <Row>
              <Col sm>
                <PartnersTableGeneral
                  title={'Documentos Seleccionado'}
                  searchVisible={true}
                  searchHint={'Buscar Usuarios ...'}
                  theaderVisible={false}
                  theader={headerDocument}
                  tbody={listDocuments}
                  tMaxHeight={'200px'}
                  onBtnSelected={(Item: any, accion: string) =>
                    handleBtnSelected(Item, listDocuments, accion, 'Documents')
                  }
                  classTable={'table-rounded table-striped border'}
                  isEdit={!(id == null || id === '')}
                />
              </Col>
            </Row>
          </Row>

          <Row>
            <Col>
              <div className='d-flex justify-content-start'>
                <Button
                  variant='danger'
                  onClick={() => {
                    handleAdminDocument()
                  }}
                >
                  Regresar
                </Button>
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-end'>
                <OverlayTrigger
                  overlay={
                    disabledByMissingTags ? (
                      <Tooltip>
                        Por favor, cargue nuevamente los documentos con los usuarios seleccionados
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div>

                  
                    <Button
                      variant='light'
                      className='mx-3'
                      ref={btnBorradorRef}
                      onClick={onBorrador}
                      type='reset'
                      disabled={disabledByMissingTags}
                    >
                      {!loadingBorrador && 'Borrador'}
                      {loadingBorrador && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Subiendo...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    disabledByMissingTags ? (
                      <Tooltip>
                        Por favor, cargue nuevamente los documentos con los usuarios seleccionados
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div>
                  {permissions.includes('Enviar Documentos') && (
                    listDocuments.length > 0 && !isNivelesAprobation && (
                      <Button
                        variant='primary'
                        ref={btnGuardarRef}
                        onClick={onGuardar}
                        disabled={disabledByMissingTags}
                        type='button'
                      >
                        {!loading && 'Enviar Documentos'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Enviando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </Button>
                    )
                    )}
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    disabledByMissingTags ? (
                      <Tooltip>
                        Por favor, cargue nuevamente los documentos con los usuarios seleccionados
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div>
                  {permissions.includes('Guardar') && (
                    listDocuments.length > 0 && isNivelesAprobation && (
                      <Button
                        variant='primary'
                        ref={btnGuardarActivoRef}
                        onClick={onGuardarActivo}
                        disabled={disabledByMissingTags || someDocumentIsDenied}
                        type='button'
                      >
                        {!loading && 'Guardar'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Guardando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </Button>
                    )
                    )}
                  </div>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        </Row>
        {cargandoFile && <OverlayLoading />}
        <ShowUserModal />
        <ShowDocumentModal />
        <ShowDocumentPersonalizadoModal />
        <ShowFileModal />
      </Container>
    </>
  )
}

export { DocumentUploadForm };

