import React, {useContext} from 'react'
import {PartnerContext} from '../PartnerContexts'
import {BulkAddUserContent} from './BulkAddUserContent'
import {BulkAddUserModalHeader} from './BulkAddUserModalHeader'

const BulkAddUserModal: React.FC = () => {
  const {bulkAddUserModal} = useContext(PartnerContext)

  return (
    <>
      {bulkAddUserModal && (
        <>
          <div>
            <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
              {/* begin::Modal dialog */}
              <div className='modal-dialog modal-dialog-centered modal-xl'>
                {/* begin::Modal content */}
                <div className='modal-content'>
                  <BulkAddUserModalHeader />
                  {/* begin::Modal body */}
                  <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
                    <BulkAddUserContent />
                  </div>
                  {/* end::Modal body */}
                </div>
                {/* end::Modal content */}
              </div>
              {/* end::Modal dialog */}
            </div>
          </div>
          {/* begin::Modal Backdrop */}
          <div className='modal-backdrop fade show'></div>
          {/* end::Modal Backdrop */}
        </>
      )}
    </>
  )
}

export {BulkAddUserModal}
