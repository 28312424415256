import {createContext, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {useAuth2} from '../../modules/authv2/Auth2Provider'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../services/menu'

interface DashboardContextProps {
  permissions: string[]
  setPermissions: (permissions: string[]) => void
}

export const DashboardContext = createContext<DashboardContextProps>({
  permissions: [],
  setPermissions: () => {},
})

const getActionsDashboard = async (pathname: string, role: string) => {
  const response = await fetchMenuActionsByRole(role)
  const subMenuResponse = await fetchSubMenuByUrl(pathname)

  if (subMenuResponse.success) {
    const subMenuId = subMenuResponse.data.id_hijo
    const permissionsForSubMenu =
      response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []

    return permissionsForSubMenu
  }

  return []
}

export const DashboardContextProvider = ({children}: {children: React.ReactNode}) => {
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()
  const currentPath = location.pathname

  const {isLoading} = useQuery({
    queryKey: ['actions', currentPath!, selectedRole!],
    queryFn: ({queryKey}) => {
      const [_, currentPath, selectedRole] = queryKey
      return getActionsDashboard(currentPath, selectedRole)
    },
    onSuccess: (data: any) => {
      setPermissions(data ?? [])
    },
  })

  return (
    <DashboardContext.Provider value={{permissions, setPermissions}}>
      {children}
    </DashboardContext.Provider>
  )
}
