import debounce from 'just-debounce-it'
import React, {useContext, useEffect} from 'react'
import {Col, Form, FormControl, Row, Stack} from 'react-bootstrap'
import {useSearchParams} from 'react-router-dom'
import Select, {SingleValue} from 'react-select'
import {useUnidades} from '../../../shared/hooks/useUnidades'
import {AltaContext} from './AltaContext'
import {Status} from './types/GenTypes'

type SingleValueOption = {value: Status; label: string}

const statusOptions: SingleValueOption[] = [
  {value: 'pendiente', label: 'Pendiente'},
  {value: 'observado', label: 'Observado'},
  {value: 'enviado', label: 'Enviado'},
  {value: 'alta', label: 'En alta'},
]

const AltaHeader: React.FC = () => {
  const {
    procesos,
    setProcessId,
    setUnidadId,
    processId,
    setFieldSelected,
    setSheetId,
    status,
    setStatus,
    searchValue,
    setSearchValue,
  } = useContext(AltaContext)
  const {toOptions} = useUnidades()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('dni')) {
      setSearchValue(searchParams.get('dni') ?? '')
      setProcessId(searchParams.get('idproceso') ?? '')
    }
  }, [searchParams])

  const handleChangeProcess = (e: any) => {
    setProcessId(e.value)
    // setFieldSelected({})
    // setSheetId('')
  }

  const handleChangeUnidad = (e: any) => {
    setUnidadId(e.value)
  }

  const defaultProcess = procesos.find((pr) => pr.value === processId)

  const handleChangeStatus = (newValue: SingleValue<SingleValueOption>): void => {
    setStatus(newValue?.value ?? 'pendiente')
    // setFieldSelected({})
    // setSheetId('')
  }

  const onChangeSearch = debounce((value: string) => {
    setSearchValue(value)
  }, 500)

  return (
    <section>
      <Row>
        <Col>
          <Stack className='h-100'>
            <span className='fw-bold'>Buscar Proceso</span>
            <Select
              options={procesos}
              value={defaultProcess}
              placeholder='Seleccione un proceso'
              onChange={handleChangeProcess}
            />
          </Stack>
        </Col>
        <Col>
          <Stack className='h-100'>
            <span className='fw-bold'>Buscar Unidad</span>
            <Select
              options={toOptions()}
              placeholder='Seleccione una unidad'
              onChange={handleChangeUnidad}
            />
          </Stack>
        </Col>
        <Col>
          <Stack className='h-100'>
            <span className='fw-bold'>Estado</span>
            <Select
              options={statusOptions}
              value={statusOptions.find((x) => x.value === status) ?? statusOptions[0]}
              onChange={handleChangeStatus}
            />
          </Stack>
        </Col>
        <Col>
          <Stack direction='vertical' className='h-100'>
            <span className='fw-bold'>Buscar</span>
            <Form.Group>
              <FormControl
                type='text'
                id='search'
                placeholder='Busca por Nombres y Apellidos | Nro. Documento'
                onChange={(e) => onChangeSearch(e.target.value)}
                className='form-control-sm same-height'
                // value={searchValue}
              />
            </Form.Group>
          </Stack>
        </Col>
      </Row>
    </section>
  )
}

export {AltaHeader}
