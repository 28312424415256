import React, {Fragment, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import styled from 'styled-components'
import {Button, Form} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {ficha} from '../../../../../../services/fichas'
import {ModalEliminar} from '../modals/ModalEliminar'
import {ModalActualizar} from '../modals/ModalActualizar'
import {ListaSecciones} from '../../secciones/ListaSecciones'

const SearchWrapper = styled.div`
  padding: 30px;
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
`

const Ficha = () => {
  const [lista, setLista] = useState('')
  const [selecto, setSelecto] = useState(0)
  const [updated, setUpdated] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')
  const navigate = useNavigate()
  const {id} = useParams()

  useEffect(() => {
    const x = id.slice(1)
    refetchData(x)
  }, [])

  const refetchData = (id) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setLista(response?.data[0])
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSelecto = (opt) => {
    if (opt === selecto) {
      setSelecto(0)
    } else {
      setSelecto(opt)
    }
  }

  /*   const handleSelecto = (opt) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:${opt}`)
  } */

  const handleEliminar = (id) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleActualizar = (id) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setActualizar(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setTimeout(() => {
      setMactualizar(!mactualizar)
    }, 1000)
  }

  const handleNuevaSeccion = (idnuevo) => {
    setMnuevo(!mnuevo)
    setNuevo(idnuevo)
  }

  const handleReturn = () => {
    navigate('/configuracion/fichas')
  }

  return (
    <>
      {lista && (
        <>
          <SearchWrapper>
            <Form className='d-flex w-100'>
              <Button
                variant='primary'
                style={{
                  fontSize: '11px',
                }}
                onClick={() => handleReturn()}
              >
                <i className='fa-solid fa-arrow-left'></i> Regresar
              </Button>
            </Form>
          </SearchWrapper>
          <SearchWrapper>
          <table className='table table-row-bordered table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-50px'></th>
                <th className='p-0 min-w-150px'></th>
                <th className='p-0 min-w-140px'></th>
                <th className='p-0 min-w-110px'></th>
                <th className='p-0 min-w-50px'></th>
              </tr>
            </thead>
            <tbody>
              <Fragment key={lista?._id}>
                <tr>
                  <td>
                    <div className='symbol symbol-45px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/ficha.png')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td onClick={() => handleSelecto(lista?._id)}>
                    <span
                      className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      style={{cursor: 'pointer'}}
                    >
                      {lista?.nombre}
                    </span>
                  </td>
                  <td className='text-end text-muted fw-semibold'></td>
                  <td></td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    {lista?.descripcion}
                  </td>
                  <td></td>
                  <td>
                    <div
                      className='symbol symbol-45px me-2 pr-2'
                      onClick={() => handleNuevaSeccion(lista?._id)}
                    >
                      <span className='symbol-label' style={{cursor: 'pointer'}}>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/nuevo.png')}
                          className='h-50 align-self-center'
                          alt='Agregar'
                          title='Agregar Seccion'
                        />
                      </span>
                    </div>
                    <div
                      className='symbol symbol-45px me-2 pr-2'
                      onClick={() => handleActualizar(lista?._id)}
                    >
                      <span className='symbol-label' style={{cursor: 'pointer'}}>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/editar.png')}
                          className='h-50 align-self-center'
                          alt='Editar'
                          title='Editar'
                        />
                      </span>
                    </div>
                    <div
                      className='symbol symbol-45px me-2'
                      onClick={() => handleEliminar(lista?._id)}
                    >
                      <span className='symbol-label' style={{cursor: 'pointer'}}>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/eliminar.png')}
                          className='h-50 align-self-center'
                          alt='Eliminar'
                          title='Eliminar'
                        />
                      </span>
                    </div>
                  </td>
                </tr>
                <ListaSecciones
                  id={lista?._id}
                  mnuevo={mnuevo}
                  setMnuevo={setMnuevo}
                  nuevo={nuevo}
                />
              </Fragment>
            </tbody>
          </table>
          </SearchWrapper>
        </>
      )}
      {/* Modal Eliminar Ficha */}
      <ModalEliminar
        show={meliminar}
        setShow={setMeliminar}
        data={eliminar}
        setUpdated={setUpdated}
        updated={updated}
        setLimpiar={setEliminar}
      />
      {/* Modal Eliminar Ficha */}
      <ModalActualizar
        show={mactualizar}
        setShow={setMactualizar}
        data={actualizar[0]}
        setUpdated={setUpdated}
        updated={updated}
        setLimpiar={setActualizar}
      />
    </>
  )
}

export default Ficha
