import React from 'react'

interface FieldValueProps {
  children: React.ReactNode
}

const FieldValue: React.FC<FieldValueProps> = ({children}) => {
  return (
    <div>
      <p className='mb-0 fs-7 bg-secondary p-2 rounded d-inline-block shadow-sm'>{children}</p>
    </div>
  )
}

export {FieldValue}
