import { useContext, useEffect } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import { SheetEditContext } from '../SheetContexts'
import { InfoRequerida3 } from '../../../documentacion/MiFicha/components/InfoRequeridav3'
import { Campos,
  DataTabla,
  Ficha,
  Historial,
  ProcesoFichas,
  Seccion,
  Tabla,
  tablaData, } from '../../../documentacion/MiFicha/FIchaInterface'
import { Sheet } from '../Sheet'

const PreviewModalForm = () => {
  const { sheetData, closePreviewModal, previewMode } = useContext(SheetEditContext)

  useEffect(() => {
    console.log('hola', sheetData)
    console.log(transformDataToPreview(sheetData))
  }, [sheetData]);

  const transformDataToPreview = (original: any) => {
    console.log(previewMode)
    const transformed = {
      "_id": original.sections && original.sections.length > 0 ? original.sections[0].id : '',
      "nombre": original.name,
      "seccion": original.sections.map((section: any) => ({
        "_id": section.id,
        "dinamico": section.dynamic,
        "nombre": section.name,
        "descripcion": section.description,
        "orden": 0, // Assuming this is a default value
        "campos": section.fields.map((field: any) => ({
          "valuesFiles": [],
          "observacion": [],
          "_id": field.id,
          "estado": field.type && field.type.length > 0 ?  field.type[0].estado : '',
          "opciones": field.options,
          "id_user_creador": null,
          "id_user_modificador": null,
          "titulo": field.name,
          "tag": field.tag,
          "comentario": field.comment,
          "editable": !field.editable && previewMode === "Colaborador"? field.editable : true,
          "visible": !field.visible && previewMode === "Colaborador" ? field.visible : true,
          "obligatorio": field.required && previewMode === "Colaborador" ? field.required : '',
          "orden": 0, // Assuming this is a default value
          "idseccion": section.id && section.id.length > 0 ? section.id: '',
          "idtipo": field.responseType && field.responseType.length > 0 ? field.responseType : '',
          "dependiente": field.isDependent && previewMode === "Colaborador" ? field.isDependent : '',
          "tabla": "",
          "campoDependiente": field.dependentField && previewMode === "Colaborador" ? field.dependentField : '',
          "respuestaCampoDependiente": field.dependentFieldResponse && previewMode === "Colaborador" ? 
                                       field.dependentFieldResponse : '',
          "tipo": [{
            "_id": field.type ? field.type[0]._id : '',
            "nombre": field.type ? field.type[0].nombre : '',
            "estado": field.type ? field.type[0].estado : ''
          }],
          "tablaData": field.tablaData.map((tablaData: tablaData) => ({
            ...tablaData,
            option: tablaData.value.map((val: any) => ({
              value: val.id ? val.id : '',
              label: val.name ? val.name : ''
            }))
          })),

          "infoFiles": []
        })),
        "secciones": section.secciones ? section.secciones.map((tabla: Tabla) => ({
          ...tabla,
          data: tabla.data.map((dattabla: DataTabla) => ({
            ...dattabla,
            values: dattabla.values ? dattabla.values : '',
            valuesFiles: dattabla.valuesFiles ? dattabla.valuesFiles : [],
            selectOpion: dattabla.selectOpion ? dattabla.selectOpion : null,
            hasError: dattabla.hasError ? dattabla.hasError : false,
            tablaData: dattabla.tablaData ? dattabla.tablaData.map((dat: tablaData) => ({
              ...dat,
              option: dat.value.map((val: any) => ({
                value: val.id ? val.id : '',
                label: val.name ? val.name : ''
              }))
            })) : []
          }))
        })) : []// Assuming no nested sections in the original object
      }))
    };
    return [
      {
        _id: '',
        idproceso: '',
        fichas: [
          transformed
        ]
      } as ProcesoFichas
    ];
  }

  return (
    <>
        <InfoRequerida3 urlRef={"/seguimiento/procesos"} 
        procesoFicha={transformDataToPreview(sheetData)} 
        isInvisible={true} idRegistroFicha={''} 
        historial={[]} 
        vista = {previewMode}
        num={0} estatusFicha={''} 
        onUpdate={function (updatedData: any): void {
          throw new Error('Function not implemented.')
        }} />

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              type='reset'
              onClick={() => {
                closePreviewModal()
              }}
            >
              Salir
            </Button>
          </div>
        </Row>
    </>
  )
}

export { PreviewModalForm }
