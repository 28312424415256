import {useContext, useEffect, useState} from 'react'
import {Col, Form, Row, Stack} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import Select from 'react-select'
import {KTIcon} from '../../../../../_metronic/helpers'
import {
  downloadMultipleDocuments,
  getDocumentsReport,
  getDocumentsReportInExcel,
} from '../../../../services/documentSent'
import {getAllFilesCPD, getSendDocumentFilexIdproceso} from '../../../../services/files'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {getProcessesForDropdown} from '../../../../services/procesos'
import {showMessage} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {DatePickerBusiness} from '../../../generales_component/DatePickerBusiness'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import {DocumentsReportContext} from '../DocumentsReportContext'
import './custom.css'

type ValuePiece = Date | null

type Value = [ValuePiece, ValuePiece]

const TipoDocs = [
  {
    label: 'Todos',
    value: 'all',
  },
  {
    label: 'Documentos Gen.',
    value: 'DOCUMENTOS GENERALES',
  },
  {
    label: 'CPD',
    value: 'CPD',
  },
]

const DocumentsReportTableHeader = () => {
  const [value, onChange] = useState<Value>([null, null])
  const [processList, setProcessList] = useState<any[]>([])
  const [unidad, setUnidad] = useState('')
  const {toOptions, misUnidades} = useUnidades()
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [isDownloading, setDownloadingStatus] = useState<boolean>(false)
  const [documents, setDocuments] = useState<any[]>([])
  const [docsCPD, setDocsCPD] = useState<any[]>([])
  const [tipDoc, setTipDoc] = useState('all')

  const {
    setProcessId,
    setDateRange,
    setDocument,
    setState,
    setData,
    setIndexes,
    processId,
    dateRange,
    documentName,
    state,
    setLoadingStatus,
    setTotalPages,
    currentPage,
    selectedDocuments,
    setSelectedDocuments,
    sortBy,
  } = useContext(DocumentsReportContext)

  const handleSearch = () => {
    if (validateFilters()) {
      getReport({
        processId,
        dateRange: value,
        document: documentName,
        state,
        currentPage,
        limit: 10,
        sortBy: sortBy.columnName,
        sortOrder: sortBy.sortDirection,
        unidad,
        typeDoc: tipDoc,
      })
    }
  }

  const validateFilters = () => {
    if (value === undefined) {
      showMessage('info', 'Importante', 'Seleccione un rango de fechas')
      return false
    }

    if (processId === '') {
      showMessage('info', 'Importante', 'Seleccione un proceso')
      return false
    }

    return true
  }

  const handleDocumentsDownload = () => {
    downloadDocuments({
      processId,
      dateRange: value,
      document: documentName,
      state,
      selectedDocuments,
    })
  }
  const location = useLocation()
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])
  useEffect(() => {
    handleSearch()
  }, [currentPage, sortBy])

  useEffect(() => {
    getProcesses()
  }, [])

  const getReport = (params: any) => {
    getDocumentsReport(params)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          setData(response.data)
          setIndexes(response.indexes)
          setTotalPages(response.totalPages)
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadDocuments = (params: any) => {
    setDownloadingStatus(true)

    downloadMultipleDocuments(params)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = 'multiple_documents'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setDownloadingStatus(false)
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error) => {
        console.log(error)
        setDownloadingStatus(false)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const handleExport = () => {
    if (validateFilters()) {
      setGlobalOverlayLoading(true)
      getDocumentsReportInExcel({
        processId,
        dateRange: value,
        document: documentName,
        state,
        typeDoc: tipDoc,
      })
        .then((response) => console.log(response))
        .catch((error) => console.log(error))
        .finally(() => setGlobalOverlayLoading(false))
    }
  }

  // const handlePaste = (event: any) => {
  //     const pastedDate = event.clipboardData.getData('text');
  //     const parsedDate = new Date(pastedDate);

  //     if (!isNaN(parsedDate.getTime())) {
  //       onChange(parsedDate);
  //     }
  //   };

  const getProcesses = () => {
    getProcessesForDropdown()
      .then((response) => {
        if (response.success) {
          setProcessList(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChangeUnidad = (e: any) => {
    setUnidad(e.value)
  }

  const getDocumentProcess = (idProcess: string, unidad: string) => {
    getSendDocumentFilexIdproceso(idProcess, true, unidad)
      .then((response) => {
        if (response.success) {
          const allDocuments = Object.values(response.data).flat()
          setDocuments(allDocuments)
        } else {
          console.error('Error fetching documents:', response.message)
        }
      })
      .catch((error) => {
        console.error('Error fetching documents:', error)
      })
  }

  const getDocumentCPD = (idProcess: string, unidad: string) => {
    getAllFilesCPD(idProcess, unidad)
      .then((response) => {
        if (response.success) {
          setDocsCPD(response.data)
        } else {
          setDocsCPD([])
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getDocumentProcess(processId, unidad)
    getDocumentCPD(processId, unidad)
  }, [processId, unidad])

  const optionDocumentDefault = [{name: 'Todos', _id: 'all'}]
  const allOptions = [...optionDocumentDefault, ...documents, ...docsCPD]
  const withFilterDocsOptions =
    tipDoc === 'all'
      ? allOptions
      : allOptions.filter(
          (doc) => doc.typeSelected === tipDoc || doc.type_document === tipDoc || doc._id === 'all'
        )
  const documentsOptions = withFilterDocsOptions.map((doc) => ({
    label: doc.name,
    value: doc._id,
  }))

  return (
    <div className='mx-8 mt-8 mb-2'>
      <Row>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Proceso:</span>
            <Select
              options={processList.map(process => ({ label: process.name, value: process.id }))}
              onChange={(e) => setProcessId(e?.value)}
            />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Unidad:</span>
            <Select
              options={toOptions()}
              onChange={handleChangeUnidad}
              isDisabled={misUnidades.length === 0}
            />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Tipo de Documento:</span>
            <Select
              placeholder='Seleccione un tipo de documento'
              defaultValue={{
                label: 'Todos',
                value: 'all',
              }}
              name='tipDoc'
              options={TipoDocs}
              onChange={(e) => setTipDoc(e?.value ?? 'all')}
            />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Documento:</span>
            <Select
              options={documentsOptions}
              placeholder='Ingrese un nombre'
              defaultValue={{
                label: 'Todos',
                value: 'all',
              }}
              name='description'
              onChange={(e) => {
                const docname = e?.label ? e.label : ''
                setDocument(e?.value === 'all' ? '' : docname)
              }}
            />
          </Stack>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col style={{minWidth: '220px'}}>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Rango de Fecha de envio</span>
            <DatePickerBusiness value={value} onChange={onChange} />
          </Stack>
        </Col>
        <Col className='d-flex align-self-end justify-content-start'>
          <Stack direction='vertical' gap={1}>
            <span className='fw-bold'>Estado Documento:</span>
            <Form.Control as='select' onChange={(e) => setState(e.target.value)} className={'form-select form-select-solid}'}            >
              <option value=''>Todos</option>
              <option value='pending'>Pendiente</option>
              <option value='signed'>Firmado</option>
              <option value='rejected'>Rechazado</option>
            </Form.Control>
          </Stack>
        </Col>
        <Col>
          <button type='button' className='btn btn-sm btn-success mt-7' onClick={handleSearch}>
            <KTIcon iconName='magnifier' className='fs-2' />
            Buscar
          </button>
        </Col>
        <Col className='d-flex align-self-end justify-content-end gap-2'>
          
          <div className='d-flex align-self-end justify-content-end'>
            {permissions.includes('Descargar documento') && (
              <button
                type='button'
                className='btn btn-sm btn-primary'
                title='Exportar a Excel'
                onClick={handleExport}
              >
                <KTIcon iconName='file-down' className='fs-2' />
                Exportar
              </button>
            )}
          </div>

          {selectedDocuments.length > 1 && (
            <div className='d-flex align-self-end justify-content-end'>
              <button
                type='button'
                className='btn btn-success'
                title='Descargar documentos seleccionados'
                onClick={handleDocumentsDownload}
              >
                <KTIcon iconName='folder-down' className='fs-2' />
                Descargar
              </button>
            </div>
          )}
        </Col>
      </Row>

      {isDownloading && <OverlayLoading />}
    </div>
  )
}

export {DocumentsReportTableHeader}
