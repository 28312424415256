import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { formatDateToDMY } from '../../../../helpers/dateFunctions'
import { deletePartner, getAllPartnersPag, updatePartner } from '../../../../services/colaboradores'
import { deleteDocument } from '../../../../services/documentos'
import {
  busquedaficha,
  deleteCompleteSheet,
  ficha
} from '../../../../services/fichas'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { changeStatusFicha } from '../../../../services/registroFicha'
import {
  showCancellationMessage,
  showDeleteMessage,
  showMessage,
  showReactivationMessage
} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import SortableTableHeader from '../../../../shared/SortableTableHeader'
import { UsersListLoading } from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { ListaSecciones } from '../../usuario/pages/secciones/ListaSecciones'
import { PartnerContext } from '../PartnerContexts'
import { ModernPagination } from '../../../../shared/ModernPagination'

const HistorialDocumentsTable = () => {
  const users = useQueryResponseData()
  // const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  const [sortBy, setSortBy] = useState({ columnName: '', sortDirection: '' });
  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [fetchStatus, setFechStatus] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')

  const [lista, setLista] = useState<any[]>([]);
  const [selecto, setSelecto] = useState(0)
  const [show, setShow] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [mactualizar, setMactualizar] = useState(false)
  const [actualizar, setActualizar] = useState('')
  const [mnuevo, setMnuevo] = useState(false)
  const [nuevo, setNuevo] = useState('')

  // const {searchTerm} = useContext(SheetContext)
  const { activeOnly, state, searchTerm, id_process, setId_Process, entryDateFrom, entryDateTo, openObservacionModal,unidad} =
    useContext(PartnerContext)

  const navigate = useNavigate()

  const iconsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]
  const location = useLocation()
  const { selectedRole } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  // useEffect(() => {
  //   if(id_process!== ""){
  //     setLoadingStatus(true)
  //     refetchData({
  //       activeOnly: activeOnly,
  //       sheetStates: state,
  //       searchQuery: searchTerm,
  //       entryDateFrom: entryDateFrom,
  //       entryDateTo: entryDateTo,
  //       id_process: id_process,
  //     })
  //   }
  // }, [])

  // useEffect(() => {
  //   if(id_process!== ""){
  //     setLoadingStatus(true)
  //     refetchData({
  //       activeOnly: activeOnly,
  //       sheetStates: state,
  //       searchQuery: searchTerm,
  //       id_process: id_process,
  //     })
  //   }
  // }, [searchTerm])

  useEffect(() => {
    if (id_process !== "") {
      setLoadingStatus(true);
      refetchData({
        activeOnly: activeOnly,
        sheetStates: state,
        searchQuery: searchTerm,
        entryDateFrom: entryDateFrom,
        entryDateTo: entryDateTo,
        id_process: id_process,
        sortBy: sortBy.columnName, 
        sortOrder: sortBy.sortDirection ,
        unidad : unidad
      });
    }
  }, [activeOnly, state, entryDateFrom, entryDateTo, currentPage, id_process, sortBy,unidad, searchTerm])

    const sortData = (data: any[], sortBy: string, sortOrder: 'asc' | 'desc'): any[] => {
      return data.sort((a, b) => {
          if (sortOrder === 'asc') {
              return a[sortBy] - b[sortBy];
          } else {
              return b[sortBy] - a[sortBy];
          }
      });
  };

const refetchData = (options: any) => {
  setLoadingStatus(true);
  getAllPartnersPag(currentPage, 10, options)
      .then((response: any) => {
          if (response.success) {
            console.log(response.length)
              setLoadingStatus(false);
              const auxTotalPage = response.length ? Math.ceil(response.length / 10) : totalPages;
              setTotalPages(response.totalPages ?? 1);

              let sortedData = response?.data;

              if (['totalPending', 'totalSigned', 'totalRejected'].includes(options.sortBy)) {
                console.log("options" +options.sortBy)
                  sortedData = sortData(response?.data, options.sortBy, options.sortOrder);
                  console.log("SORTED DATA", JSON.stringify(sortedData, null, 2));
                }

              setLista(sortedData);
          } else {
              console.log(response);
          }
      })
      .catch((error: any) => {
          console.log(error);
      });
};
 

  /* const handleObservar = (id: any, namecolaborador: any) => {
    navigate(`/seguimiento/colaborador/observacion/${id}/${namecolaborador}`)
  }

  const handleValidation = (id: any, namecolaborador: any) => {
    navigate(`/configuracion/validar/actions/${id}/${namecolaborador}`)
  } */

  /* const valContextProvider = {
    activeOnly,
    state,
    setActiveOnly,
    setState,
    isObservacionModalOpen,
    openObservacionModal,
    closObservacionModal
  } */

  const changePartnerStatus = (id: string, activeState: boolean) => {
    if (activeState === false) {
      showDeleteMessage().then((result) => {
        if (result.isConfirmed) {
          deletePartner(id)
            .then((response) => {
              if (response.success) {
                showMessage('success', '¡Buen trabajo!', response.message)
              } else {
                showMessage('error', 'Hubo un error', response.error)
              }
              refetchData({
                activeOnly: activeOnly,
                sheetStates: state,
                searchQuery: searchTerm,
                entryDateFrom: entryDateFrom,
                entryDateTo: entryDateTo,
                id_process: id_process,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } else {
      updatePartner(id, { estado: true })
        .then((response) => {
          refetchData({
            activeOnly: activeOnly,
            sheetStates: state,
            searchQuery: searchTerm,
            entryDateFrom: entryDateFrom,
            entryDateTo: entryDateTo,
            id_process: id_process,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  // Utility function to generate pagination links based on the current page and totalPages values
  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({ page: currentPage - 1, label: 'Anterior' })
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({ page: i, label: String(i) })
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({ page: currentPage + 1, label: 'Siguiente' })
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    
  }

  const handleSelecto = (opt: any) => {
    navigate(`/crafted/pages/configuracion/usuario/pages/fichas/plantilla/Ficha/:${opt}`)
  }

  const handleEliminar = (id: any) => {
    setMeliminar(!meliminar)
    setEliminar(id)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteCompleteSheet(id)
          .then((response) => {
            if (response.success) {
              console.log(response)
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              console.log(response)
              showMessage('error', 'Hubo un error', response.error)
            }
            refetchData({
              activeOnly: activeOnly,
            })
          })
          .catch((error) => showMessage('error', 'Hubo un error', error.message))
      }
    })
  }

  const handleActualizar = (id: any) => {
    ficha(id)
      .then((response) => {
        if (response.success) {
          setActualizar(response?.data)
        } else {
          console.log(response.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    setTimeout(() => {
      setMactualizar(!mactualizar)
    }, 1000)
  }

  const handleNuevaSeccion = (idnuevo: any) => {
    setMnuevo(!mnuevo)
    setNuevo(idnuevo)
  }

  const handleSearch = (e: any) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      e.preventDefault()
      e.target.blur()
    }
    const valorbuscado = e.target.value
    if (valorbuscado?.length > 2) {
      if (valorbuscado) {
        busquedaficha(valorbuscado).then((response) => {
          if (response.success) {
            setLista(response?.data)
          } else {
            refetchData({
              activeOnly: activeOnly,
            })
          }
        })
      } else {
        refetchData({
          activeOnly: activeOnly,
        })
      }
    } else {
      refetchData({
        activeOnly: activeOnly,
      })
    }
  }

  const handleCancellation = (id: string) => {
    showCancellationMessage().then((result) => {
      if (result.isConfirmed) {
        deleteDocument(id)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error)
          })

      }
    })
  }

  const handleReactivation = (id: string) => {
    showReactivationMessage().then((result) => {
      if (result.isConfirmed) {
        changeStatusRegistroFicha(id, 'completadoPorColaborador')
      }
    })
  }

  const changeStatusRegistroFicha = (id: string, estado: string) => {
    changeStatusFicha(id, { estado })
      .then((response) => {
        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.error)
        }
        refetchData({
          activeOnly: activeOnly,
          sheetStates: state,
          searchQuery: searchTerm,
          entryDateFrom: entryDateFrom,
          entryDateTo: entryDateTo,
          id_process: id_process,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleView = (id: any) => {
    navigate(`/seguimiento/historialdocumentos/detalle/${id}`)
  }



  // Custom Toggle Component
  const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
    <a
      ref={ref}
      href='#'
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      title='Visualizar ficha'
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <KTIcon iconName='dots-square-vertical' className='fs-3' />
    </a>
  ))
  const handleSort = (columnName: string, sortDirection: string) => {
    console.log("Sorting column:", columnName, "Direction:", sortDirection);
    setSortBy({ columnName, sortDirection });
    console.log("SortBy state updated:", sortBy);
};
  
  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered fs-6 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                <th className='text-start p-3'>
                  Unidad
                </th>
                {/* <th className=''>Nro Solicitud</th> */}
                <th className=''>DNI</th>
                <SortableTableHeader
                  label='Nombres y Apellidos'
                  columnName='nombres'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Cargo'
                  columnName='cargo'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Fec. Ingreso'
                  columnName='fechaIngreso'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Doc. Firmados'
                  columnName='totalSigned'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Doc. Pend.'
                  columnName='totalPending'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <SortableTableHeader
                  label='Doc. Obs'
                  columnName='totalRejected'
                  handleSort={handleSort}
                  activeColumn={sortBy.columnName}
                  labelStyle={{color: '#ffffff'}}
                />
                <th className='text-end p-3' style={{paddingBlock: '5px', paddingRight: '5px'}}>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {lista.length > 0 ? (
                lista?.map((item: any, index: any) => (
                  <Fragment key={item._id}>
                    <tr>
                      <td className='text-start min-w-200px p-2'>{item.unidad}</td>
                      {/* <td> Sol{item._id.slice(0, 3)}...{item._id.slice(-3)} </td> */}
                      <td>{item.dni}</td>
                      <td className='text-start min-w-300px p-1'>
                        {item.nombres} {item.apellidos}
                      </td>
                      <td className='text-start min-w-200px p-1'>{item.cargo}</td>
                      <td className='text-start min-w-150px p-1'>
                        {formatDateToDMY(item.fechaIngreso)}
                      </td>
                      <td className='text-center min-w-150px p-1'>{item.totalSigned}</td>
                      <td className='text-center min-w-150px p-1'>{item.totalPending}</td>
                      <td className='text-center min-w-150px p-1'>{item.totalRejected}</td>
                      <td className='text-end d-flex align-self-end justify-content-end p-1'>
                        <Stack direction='horizontal' gap={1}>
                          {permissions.includes('Ver Detalle') && (
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='ver detalle'
                              onClick={() => handleView(item.idUsuario)}
                            >
                              <KTIcon iconName='eye' className='fs-3' />
                            </a>
                          )}
                        </Stack>
                      </td>
                    </tr>
                    {/* {item._id === selecto ? (
                      <ListaSecciones
                        id={selecto}
                        mnuevo={mnuevo}
                        setMnuevo={setMnuevo}
                        nuevo={nuevo}
                      />
                    ) : null} */}
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <UsersListPagination /> */}
        <ModernPagination
          totalPages={totalPages}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export { HistorialDocumentsTable }

