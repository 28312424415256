import {Button, Col,Row, Stack} from 'react-bootstrap'
import {KTCard, KTIcon} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {getProcesoFichas} from '../../../../services/procesos'
import {
  ProcesoFichas,
  Ficha,
  Seccion,
  Campos,
  Tabla,
  DataTabla,
  tablaData,
  Historial,
} from '../../../documentacion/MiFicha/FIchaInterface'
import {PartnerSheet} from '../../../documentacion/MiFicha/components/PartnerSheet'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {approvePartner} from '../../../../services/colaboradores'
import { changeStatusFicha, getRegistroFichaOne } from '../../../../services/registroFicha'
import { HistorialMessageModel } from '../../colaboradores/components/observacion/HistorialMessageModel'
import { ChatInner } from '../../colaboradores/components/ChatInner'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const ValidationActions = () => {
  const validationBreadcrumbs = [
    {
      title: 'Configuración',
      path: '/seguimiento/colaboradores',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const Ruta = [
    {
      path: 'inforequerida',
      link: '/documentos/ficha/inforequerida',
    },
    {
      path: 'documentacionrequerida',
      link: '/documentos/ficha/documentacionrequerida',
    },
  ]

  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setLoadingStatus] = useState(false)
  const [procesos, setProcesos] = useState<ProcesoFichas[]>([])
  const [historialData, setHistorialData] = useState<Historial[]>([])
  const [idUsuarioRegistro, setIdUsuarioRegistro] = useState<string>('')
  const [historialMessage, setHistorialMessage] = useState<HistorialMessageModel[]>([])
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  const {id,namecolaborador} = useParams()
  const navigate = useNavigate()
  const {selectedRole} = useAuth2()

  useEffect(() => {
    setLoadingStatus(true)
    refetchData()
  }, [])

  const refetchData = () => {
    setLoadingStatus(true)
    const idRegistroFicha = id
    /* getProcesoFichas(idProceso, idusuario)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          if (response.message === 'Datos de Ficha') {
            const dataWithValuesAndFiles: ProcesoFichas[] = response?.data.map(
              (procesoFicha: ProcesoFichas, item: any) => ({
                ...procesoFicha,
                fichas: procesoFicha.fichas.map((ficha: Ficha) => ({
                  ...ficha,
                  ruta: Ruta[item],
                  seccion: ficha.seccion.map((seccion: Seccion) => ({
                    ...seccion,
                    secciones: seccion.secciones ? seccion.secciones : [],
                    campos: seccion.campos.map((campo: Campos) => ({
                      ...campo,
                      values: campo.values ? campo.values : '',
                      valuesFiles: campo.valuesFiles ? campo.valuesFiles : [],
                      selectOpion: campo.selectOpion ? campo.selectOpion : null,
                      hasError: campo.hasError ? campo.hasError : false,
                      dependiente: campo.dependiente ? campo.dependiente : false,
                      campoDependiente: campo.campoDependiente ? campo.campoDependiente : '',
                      respuestaCampoDependiente: campo.respuestaCampoDependiente
                        ? campo.respuestaCampoDependiente
                        : '',
                      mostrar: campo.mostrar ? campo.mostrar : campo.dependiente ? false : true,
                      tablaData: campo.tablaData.map((tablaData: tablaData) => ({
                        ...tablaData,
                        option: tablaData.value.map((val: any) => ({
                          value: val.id ? val.id : '',
                          label: val.name ? val.name : '',
                        })),
                      })),
                    })),
                  })),
                })),
              })
            )
            setProcesos(dataWithValuesAndFiles)
          } else {
            const dataWithValuesAndFiles: ProcesoFichas[] = response?.data[0]?.procesoficha.map(
              (procesoFicha: ProcesoFichas, item: any) => ({
                ...procesoFicha,
                fichas: procesoFicha.fichas.map((ficha: Ficha) => ({
                  ...ficha,
                  ruta: Ruta[item],
                  seccion: ficha.seccion.map((seccion: Seccion) => ({
                    ...seccion,
                    campos: seccion.campos.map((campo: Campos) => ({
                      ...campo,
                      values: campo.values ? campo.values : '',
                      valuesFiles: campo.valuesFiles ? campo.valuesFiles : [],
                      selectOpion: campo.selectOpion ? campo.selectOpion : null,
                      hasError: campo.hasError ? campo.hasError : false,
                      dependiente: campo.dependiente ? campo.dependiente : false,
                      campoDependiente: campo.campoDependiente ? campo.campoDependiente : '',
                      respuestaCampoDependiente: campo.respuestaCampoDependiente
                        ? campo.respuestaCampoDependiente
                        : '',
                      mostrar: campo.mostrar ? campo.mostrar : campo.dependiente ? false : true,
                      tablaData: campo.tablaData.map((tablaData: tablaData) => ({
                        ...tablaData,
                        option: tablaData.value.map((val: any) => ({
                          value: val.id ? val.id : '',
                          label: val.name ? val.name : '',
                        })),
                      })),
                    })),

                    secciones: seccion.secciones
                      ? seccion.secciones.map((tabla: Tabla) => ({
                          ...tabla,
                          data: tabla.data.map((dattabla: DataTabla) => ({
                            ...dattabla,
                            values: dattabla.values ? dattabla.values : '',
                            valuesFiles: dattabla.valuesFiles ? dattabla.valuesFiles : [],
                            selectOpion: dattabla.selectOpion ? dattabla.selectOpion : null,
                            hasError: dattabla.hasError ? dattabla.hasError : false,
                            tablaData: dattabla.tablaData
                              ? dattabla.tablaData.map((dat: tablaData) => ({
                                  ...dat,
                                  option: dat.value.map((val: any) => ({
                                    value: val.id ? val.id : '',
                                    label: val.name ? val.name : '',
                                  })),
                                }))
                              : [],
                          })),
                        }))
                      : [],
                  })),
                })),
              })
            )
            setProcesos(dataWithValuesAndFiles)
          }
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      }) */

      getRegistroFichaOne(idRegistroFicha)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          
            const dataWithValuesAndFiles:ProcesoFichas[] = response?.data[0]?.procesoficha.map((procesoFicha:ProcesoFichas, item:any) => ({
              ...procesoFicha,
              fichas:procesoFicha.fichas.map((ficha:Ficha) => ({ 
                ...ficha,
                seccion:ficha.seccion.map((seccion:Seccion) => ({
                  ...seccion,
                  campos: seccion.campos.map((campo:Campos) => ({
                    ...campo,
                    values: campo.values?campo.values:'',
                    observacion: campo.observacion?campo.observacion:{},
                    observado: campo.observado?campo.observado:false,                                     
                    valuesFiles: campo.valuesFiles?campo.valuesFiles:[],
                    selectOpion:campo.selectOpion?campo.selectOpion:null,
                    hasError: campo.hasError?campo.hasError:false,
                    dependiente: campo.dependiente?campo.dependiente:false,
                    campoDependiente: campo.campoDependiente?campo.campoDependiente:"",
                    respuestaCampoDependiente: campo.respuestaCampoDependiente?campo.respuestaCampoDependiente:"",
                    mostrar: campo.mostrar?campo.mostrar:campo.dependiente?false:true,
                    tablaData: campo.tablaData.map((tablaData:tablaData) => ({
                      ...tablaData,
                      option: tablaData.value.map((val:any) => ({
                        value: val.id?val.id:'',
                        label: val.name?val.name:''
                      }))
                    }))
                  })),
                  
                  secciones:seccion.secciones?seccion.secciones.map((tabla:Tabla) => ({
                    ...tabla,
                    data: tabla.data.map((dattabla:DataTabla) => ({
                      ...dattabla,
                      values: dattabla.values?dattabla.values:'',
                      valuesFiles: dattabla.valuesFiles?dattabla.valuesFiles:[],
                      selectOpion:dattabla.selectOpion?dattabla.selectOpion:null,
                      hasError: dattabla.hasError?dattabla.hasError:false,
                      tablaData: dattabla.tablaData? dattabla.tablaData.map((dat:tablaData) => ({
                        ...dat,
                        option: dat.value.map((val:any) => ({
                          value: val.id?val.id:'',
                          label: val.name?val.name:''
                        }))
                      })) : []
                    }))
                  })):[],


                })),
              }))
              
            }));

            setIdUsuarioRegistro(response?.data[0]?.id_user_creador)
            
            const historial:Historial[] = response?.data[0]?.historial
            setHistorialData(historial)
            setProcesos(dataWithValuesAndFiles)

            const historialMensaje : HistorialMessageModel[] = []
            response?.data[0]?.historial?.map((dat:Historial) => {
              if(dat.motivo === 'OBSERVADO'  && dat.data){
                historialMensaje.push(dat.data)
              }
            })
            setHistorialMessage(historialMensaje)
          
        } else {
          console.log(response)
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }

  const handleApproval = () => {
    changeStatusFicha(id, {estado: 'aprobadoPorAdmin'})
      .then((response) => {
        console.log(response)
        if (response.success) {
          navigate(`/seguimiento/procesos`)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handlePending = (observacion:any) => {
    approvePartner({
      partnerId: id,
      isPending: true,
    })
      .then((response) => {
        console.log(response)
        navigate(`/seguimiento/procesos/observacion/${id}/${namecolaborador}/${observacion}`)
        
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleObservar = (observacion: any) => {
    navigate(`/seguimiento/procesos/observacion/${id}/${namecolaborador}/${observacion}`)
  }

  const handleExit = () => {
    navigate(`/seguimiento/procesos`)
  }
  const isValid = (historialData[historialData.length - 1]?.camposModificados?.length ?? 0) > 0;

  const goBack = () => {
    const link = selectedRole === 'POST' ? '/documentos/proceso' : '/seguimiento/procesos'
    navigate(link)
  }

  return (
    <>
      <PageTitle breadcrumbs={validationBreadcrumbs}>Validación</PageTitle>
      <GoBackButton goBack={goBack} />
      <KTCard>
        <Row>
          {isValid && (
            <Col className='d-flex align-self-start justify-content-start m-3'>
              <div className='alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-10'>
                <span className='svg-icon svg-icon-5hx svg-icon-primary me-4 mb-5 mb-sm-0'>
                  <i className='bi bi-info-square fs-4x'></i>
                </span>
                <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
                  <h5 className='mb-1'>Campos Modificados</h5>
                  {historialData[historialData.length - 1]?.camposModificados?.map((cam) => (
                    <span>{cam}</span>
                  ))}
                </div>

                <button
                  type='button'
                  className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                  data-bs-dismiss='alert'
                >
                  <span className='svg-icon svg-icon-3 svg-icon-primary'>
                    <KTIcon iconName='cross' className='fs-3' />
                  </span>
                </button>
              </div>
            </Col>
          )}
          <Col className='d-flex align-self-end justify-content-end m-3'>
            <Stack direction='horizontal' gap={2}>
              <button type='button' className='btn btn-success' onClick={() => handleApproval()}>
                Aprobar
              </button>
              {/* <button type='button' className='btn btn-danger' onClick={() => handlePending()}>
                Aprobar con Pendiente
              </button>  */}
              <Button
                variant='warning'
                type='submit'
                onClick={() => handlePending('false')}
              >Aprobar con Pendiente</Button>

              <button type='button' className='btn btn-primary' onClick={() => handleObservar('true')}>
                Observar
              </button>
              <button type='button' className='btn btn-danger' onClick={() => handleExit()}>
                Salir
              </button>
            </Stack>
          </Col>
        </Row>

        <div className='card mb-5 '>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex overflow-auto h-20px mb-2'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {procesos.map((procesoficha, i) => (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` + (i === activeTab && 'active')
                      }
                      to={``}
                      onClick={() => setActiveTab(i)}
                    >
                      {procesoficha.fichas[0].nombre}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <PartnerSheet
          num={activeTab}
          procesoFicha={procesos}
          estatusFicha={''}
          historial={[]}
          onUpdate={function (updatedData: any): void {
            throw new Error('Function not implemented.')
          }}
        />
      </KTCard>

      <div className='flex-lg-row-fluid mt-2'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header bg-secondary' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a href='#' className='fs-4 fw-bolder  me-1 mb-2 lh-1'>
                  Historial Observación
                </a>

                <div className='mb-0 lh-1'>
                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                  <span className='fs-7 fw-bold text-gray-400'>{namecolaborador}</span>
                </div>
              </div>
            </div>

            <div className='card-toolbar'></div>
          </div>
          <ChatInner
            idUsuario={String(idusuario)}
            invisibleChat={true}
            idNameUsuario={String(nameusuario)}
            historialMessage={historialMessage}
          />
        </div>
      </div>
    </>
  )
}

export {ValidationActions}
