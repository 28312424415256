import React, {useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone';
import './styles.css';
import { KTSVG , KTIcon} from '../../../../../_metronic/helpers';
import DocViewer, { DocViewerRenderers,  } from "@cyntler/react-doc-viewer";
import ModalComponent from './modalPreview';
import { downloadFileS3 } from '../../../../services/files';
import { showMessage } from '../../../../shared/Alerts';
import { Button } from 'react-bootstrap';




interface DropzoneExampleProps {
    onFilesSelected: (files: File[], index: number,infoArchivo: {url:string,name:string,size:string}[]) => void;
    idx: string;
    titulo: string;
    filesArchivo: File[] | [];
    infoArchivo: {url:string,name:string,size:string,base64?:string}[] | [];
    disabled?: boolean;
}

const DropzoneArchivo : React.FC<DropzoneExampleProps> = ({ onFilesSelected,idx,titulo,filesArchivo,infoArchivo,disabled = false }) =>{
    const [filesBackUp, setFilesBackUp] = useState<{url:string,name:string,size:string,base64?:string}[]>(infoArchivo);
    const [files, setFiles] = useState<File[]>(filesArchivo);

    const onDrop = (acceptedFiles: File[]) => {
        const renamedFiles = Array.from(acceptedFiles).map((file, index) => {
            const partes = file.name.split('.');
            const extension = partes[partes.length - 1];            
            const newName = index === 0?`${titulo}.${extension}`:`${titulo}_${index}.${extension}`
            return new File([file], newName, { type: file.type });
        });

        // Update the state with the accepted files
        setFiles([...files, ...renamedFiles]);
        onFilesSelected([...files, ...renamedFiles],0,filesBackUp);
    };

    const removeFile = (file: File) => {
        setFiles(files.filter((f) => f !== file));
        onFilesSelected(files.filter((f) => f !== file),0,filesBackUp);
    };
    
    const removeFileBackUp = (fileBackup:{name: string; size: string;}) => {
        setFilesBackUp(filesBackUp.filter((f) => f !== fileBackup));
        onFilesSelected(files,0,filesBackUp.filter((f) => f !== fileBackup));
    };

    /* const [fileUrl, setFileUrl] = useState('');
    const handlePreviewFile = (file: File) => {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        
    }; */

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
        }
    });

    const descargarArchivos = (archivo:File) => {
        const url = window.URL.createObjectURL(archivo);
        const link = document.createElement('a');
        link.href = url;
        link.download = archivo.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const descargarArchivosS3 = (keyname:string) => {
        const partes = keyname.split('.');
        const extension = partes[partes.length - 1];
        
        const partesKey = keyname.split('/');
        const filename = partesKey[partesKey.length - 1]; 
        downloadFileS3(keyname, extension)
        .then((response) => {
            if (response.success) {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            showMessage('success', '¡Buen trabajo!', response.message)
            } else {
            console.log(response)
            showMessage('error', 'Hubo un error', response.error)
            }
        })
        .catch((error) => {
            console.log(error)
            showMessage('error', 'Hubo un error', error.message)
        })
    };


    return (
        <div>
            {!disabled && (
                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                    <input {...getInputProps()} accept=".doc,.docx,.pdf,.txt" />
                    <p>
                        Arrastra el archivo aquí <br/> o <br/> 
                        <Button type='button' className='btn-sm btn-success'>Seleccionar Archivo</Button>
                    </p>
                </div>
            )}
    
            <div className="dropzone-preview-container">
                {filesBackUp.filter(file=>file.url)
                        .map((file) => (
                    file.url ? (
                        <div key={file.name} className="dropzone-preview2">
                            <div className="file-info2">
                                <p>{file.name}</p>
                                <span 
                                    className="badge badge-square badge-danger remove-btn2" 
                                    onClick={() => removeFileBackUp(file)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button 
                                    className="badge badge-square badge-danger preview-btn2 btn-sm" 
                                    onClick={() => descargarArchivosS3(file.url)}
                                >
                                    <KTIcon iconName='cloud-download' className='fs-3' />
                                </button>
                            </div>
                        </div>
                    ) : ""
                ))}
    
                {files.filter(file => 
                    ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf', 
                    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
                    .includes(file.type)  
                ).map((file) => (
                    <div key={file.name} className="dropzone-preview2">
                        {file.type.startsWith('image/') ? (
                            <>
                                <img src={URL.createObjectURL(file)} alt={file.name} />
                                <span 
                                    className="badge badge-square badge-danger remove-btn2" 
                                    onClick={() => removeFile(file)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-1x"
                                    />
                                </span>
                                <button 
                                    className="btn btn-icon btn-light pulse pulse-info me-10 mb-10 preview-btn2 btn-sm" 
                                    onClick={() => descargarArchivos(file)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen004.svg"
                                        className="svg-icon svg-icon-1"
                                    />
                                    <span className="pulse-ring"></span>
                                </button>
                            </>
                        ) : (
                            <div className="d-flex align-items-center justify-content-between">
                                <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                                <div className="flex-grow-1 text-start">
                                    <span className="d-block text-primary fw-bold">Nombre del archivo: {file.name}</span>
                                    <hr className="m-0 mt-1 text-primary" />
                                </div>
                                <KTIcon iconName="check-circle" className="fs-3 ms-3 text-success" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
    
            {files.length > 0 && (
                <div className="remove-btn-container">
                    <button 
                        className="remove-all-btn bg-danger" 
                        onClick={() => setFiles([])}
                    >
                        Eliminar todos los archivos
                    </button>
                </div>
            )}
    
            {files.length === 0 && filesBackUp.length === 0 && (
                <div className="d-flex align-items-center justify-content-between">
                    <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                    <div className="flex-grow-1 text-start">
                        <span className="d-block text-primary fw-bold">No se ha seleccionado ningún archivo</span>
                        <hr className="m-0 mt-1 text-primary" />
                    </div>
                    <KTIcon iconName="plus-circle" className="fs-3 ms-3" />
                </div>
            )}
    
            {files.some(file => !file.name && !file.type.startsWith('image/')) && (
                <div className="d-flex align-items-center justify-content-between">
                    <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3 text-danger" />
                    <div className="flex-grow-1 text-start">
                        <span className="d-block text-primary fw-bold">Archivo no válido</span>
                        <hr className="m-0 mt-1 text-primary" />
                    </div>
                    <KTIcon iconName="cross-circle" className="fs-3 ms-3 text-danger" />
                </div>
            )}
        </div>
    );

};

export default DropzoneArchivo;
