import {useContext} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {Badge} from 'react-bootstrap'
import Collapsible from 'react-collapsible'
import {KTIcon} from '../../../../../_metronic/helpers'
import {SheetEditContext} from '../SheetContexts'
import {DraggableFieldList} from './DraggableFieldList'
import { v4 } from 'uuid'
import { Type } from '../Sheet'

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

const CollapsibleSectionItem = ({item, index, permisos}: any) => {
  const {openFieldModal, openSectionModal, sheetData, setData, setSectionData, tables, types} =
    useContext(SheetEditContext)

  const deleteSection = () => {
    setData({...sheetData, sections: (sheetData.sections || []).filter((n) => n.id !== item.id)})
  }

  const editSection = () => {
    setSectionData(item)
    openSectionModal()
  }

  const addField = () => {
    setSectionData(item)
    openFieldModal()
  }

  const createUbigeoMacro =  () => {
    const createData = (collection: string, typeField: Type, dependentField: string) => {
      const collectionName = tables.find((table) => table.label.toLowerCase() === collection.toLowerCase())?.label ?? ''
      const tag = '{' + collection.toUpperCase().replace(/\s/g, '_') + '}'

      const existingFields =
        item?.fields?.filter((field: any) =>
          field.name.toLowerCase().startsWith(collection.toLowerCase())
        ) ?? []

      const numberSameName = existingFields.length

      let name = collection
      if (numberSameName !== 0) {
        name += ` ${numberSameName}`
      }

      return {
        id: v4(),
        name,
        tag,
        checkExport: false,
        checkIntegracion: false,
        idseccion: item.id,
        responseType: typeField._id,
        comment: '',
        visible: true,
        editable: true,
        required: true,
        actualizable: false,
        options: [],
        isDependent: !!dependentField,
        assignedTo: '',
        dependentField,
        dependentFieldResponse: '',
        variable: false,
        collectionName, // get_Tables()
        tablaData: [], // get_Tables()
        type: [typeField],
      }
    }

    const typeField = types.find((type) => type.identificador === 'dropdownlist_db')

    const pais = createData('Pais', typeField!, '')
    const departamento = createData('Departamento', typeField!, pais.id)
    const provincia = createData('Provincia', typeField!, departamento.id)
    const distrito = createData('Distrito', typeField!, provincia.id)

    const sectionData = {
      ...item,
      fields: [...(item?.fields ?? []), pais, departamento, provincia, distrito],
    }

    const withThisSection = sheetData?.sections?.filter((section) => section.id !== item.id)
    setData({
      ...sheetData,
      sections: [
        ...(withThisSection ?? []),
        sectionData
      ]
    })
  }

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <div style={{position: 'relative'}}>
            <Collapsible
              transitionTime={200}
              easing='ease'
              trigger={item.name}
              triggerStyle={{marginLeft: 30, fontWeight: 'bolder'}}
            >
              {/* <div>Something</div>
              <div>Another</div>
              <div>Another</div>
              <div>Another</div>
              <div>Another</div> */}
              <div className='mt-6 mx-10'>
                <DraggableFieldList sectionItem={item} permisos={permisos} />
              </div>

              {/* {!isDraggable ? (
                <div>
                  <div>Something</div>
                  <div>Another</div>
                  <div>Another</div>
                  <div>Another</div>
                  <div>Another</div>
                </div>
              ) : (
                <DraggableSeccionList />
              )} */}
            </Collapsible>

            <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
              <Badge
                bg='success-active'
                className='me-2'
                style={{transform: 'translateY(-2px)', color: 'white'}}
              >
                {index + 1}
              </Badge>
            </div>

            <div style={{position: 'absolute', top: '0', right: '0'}}>
              <Badge
                bg='primary-active'
                className='me-2'
                style={{transform: 'translateY(-8px)', color: 'white'}}
              >
                {item.fields?.length || 0}
              </Badge>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Crear ubigeo'
                onClick={() => createUbigeoMacro()}
              >
                <KTIcon iconName='geolocation' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Agregar Campo'
                onClick={() => addField()}
              >
                <KTIcon iconName='plus-circle' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                style={{transform: 'translateY(-8px)'}}
                title='Editar Sección'
                onClick={() => editSection()}
              >
                <KTIcon iconName='pencil' className='fs-3' />
              </a>
              <a
                href='#!'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                style={{transform: 'translateY(-8px)'}}
                title='Eliminar Sección'
                onClick={() => deleteSection()}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export {CollapsibleSectionItem}
