import { useState } from "react"
import { KTCard } from "../../../../_metronic/helpers"
import { PageTitle } from "../../../../_metronic/layout/core"
import { AlertsContext } from "./AlertsContexts"
import { AlertsTable } from "./main/AlertsTable"
import { AlertsTableHeader } from "./main/AlertsTableHeader"

const alertsBreadcrumbs = [
    {
        title: 'Configuración',
        path: '/configuracion/alertas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const AlertsPages = () => {

    const [searchTerm, setSearchTerm] = useState('')
    const [processId, setProcessId] = useState('')

    return (
        <AlertsContext.Provider 
            value={{
                searchTerm,
                setSearchTerm,
                processId,
                setProcessId
            }}
        >
            <PageTitle breadcrumbs={alertsBreadcrumbs}>Alertas</PageTitle>
            <KTCard>
                <AlertsTableHeader />
                <AlertsTable />
            </KTCard>
        </AlertsContext.Provider>
    )
}

export { AlertsPages }