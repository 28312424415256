/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
// import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {logout} from '../../../../app/services/auth'
import { DataUser } from '../../../../app/modules/documentacion/MiFicha/DataUserInterface'
import { useAuth2 } from '../../../../app/modules/authv2/Auth2Provider'

const HeaderUserMenu: FC = () => {
  // const usuarioJSON = localStorage.getItem('userData');
  // let usuario: DataUser|null=null;
  // if (usuarioJSON !== null) {
  //   usuario = JSON.parse(usuarioJSON);
  // }

  const { userData, selectedRole } = useAuth2()
  const [letter, setLetter] = useState("");
  
  useEffect(() => {
    if(userData){
      ActualizarIcon();
    }
  }, []);

  
  useEffect(() => {
    if(userData){
      ActualizarIcon();
    }
  }, [userData]);

  const ActualizarIcon = () => {
    const name = userData.userName.split(" ")
    setLetter(`${name[0].charAt(0)}${name[2]?name[2].charAt(0) : ''}`)
  }

  const handleLogout = () => {
    let urlLogout = localStorage.getItem('urlLogout')
    localStorage.clear();
    window.location.replace(String(urlLogout))
    // window.location.replace('https://workin.grupotawa.com/Seguridad/CerrarSesion');
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
          <div className="symbol-label fs-2 fw-bold bg-primary text-inverse-primary">{letter}</div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userData?.userName} 
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{selectedRole}</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {userData?.userEmail}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          Mi Perfil
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link
          to='#'
          className='menu-link px-5'
          onClick={() => handleLogout()}
        >
          Cerrar Sesión
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Cerrar Sesion
        </a>
      </div> */}
    </div>
  )
}

export {HeaderUserMenu}
