import React, {createContext, useCallback, useEffect, useState} from 'react'
import {getAllCompaniesByPais} from '../../../../services/companies'
import {getAllUsersPag} from '../../../../services/users'
import {WorkingDocs} from '../../../../types-module/WDocsNS'

type OptionsSelected = {
  unidad: string
  empresa: string
  estado: boolean
  searchTerm: string
}

interface Props {
  children: React.ReactNode
}

interface IUsersContext {
  users: WorkingDocs.User[]
  setUsers: React.Dispatch<React.SetStateAction<WorkingDocs.User[]>>
  getAllUsers: () => void
  optionsSelected: OptionsSelected
  setOptionsSelected: React.Dispatch<React.SetStateAction<OptionsSelected>>
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  totalPages: number
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  isLoading: boolean
  setLoadingStatus: React.Dispatch<React.SetStateAction<boolean>>
  userEmpresa: Record<string, string>
  setUserEmpresa: React.Dispatch<React.SetStateAction<Record<string, string>>>
  enterprices: WorkingDocs.Company[]
  setEnterprices: React.Dispatch<React.SetStateAction<WorkingDocs.Company[]>>
  hints: number
  setHints: React.Dispatch<React.SetStateAction<number>>
}

export const UsuariosPageContext = createContext<IUsersContext>({
  users: [],
  setUsers: () => {},
  getAllUsers: () => {},
  optionsSelected: {
    unidad: '',
    empresa: '',
    estado: true,
    searchTerm: '',
  },
  setOptionsSelected: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  totalPages: 1,
  setTotalPages: () => {},
  isLoading: false,
  setLoadingStatus: () => {},
  userEmpresa: {},
  setUserEmpresa: () => {},
  enterprices: [],
  setEnterprices: () => {},
  hints: 0,
  setHints: () => {},
})

const UsuariosPageContextProvider: React.FC<Props> = ({children}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [users, setUsers] = useState<WorkingDocs.User[]>([])
  const [optionsSelected, setOptionsSelected] = useState<OptionsSelected>({
    unidad: '',
    empresa: '',
    estado: true,
    searchTerm: '',
  })
  const [isLoading, setLoadingStatus] = useState(false)
  const [userEmpresa, setUserEmpresa] = useState<Record<string, string>>({})
  const [enterprices, setEnterprices] = useState<WorkingDocs.Company[]>([])
  const [hints, setHints] = useState(0)

  const getAllUsers = useCallback(async () => {
    setLoadingStatus(true)
    const response = await getAllUsersPag(currentPage, 10, {
      activeOnly: optionsSelected.estado,
      sheetStates: '',
      searchQuery: optionsSelected.searchTerm,
      entryDateFrom: null,
      entryDateTo: null,
      cunidad: optionsSelected.unidad,
      cempresa: optionsSelected.empresa,
      onlyPartners: 'true'
    })
    setLoadingStatus(false)
    setUsers(response?.data ?? [])
    const auxTotalPage = Math.ceil(response.length / 10)
    setTotalPages(auxTotalPage)
    setHints(response.length ?? 0)

    const ue = response?.data?.reduce((acc: any, user: any) => {
      acc[user.dni] = user.empresa[0]
      return acc
    }, {})

    setUserEmpresa(ue)
  }, [currentPage, optionsSelected])

  useEffect(() => {
    if (optionsSelected.empresa && optionsSelected.unidad) {
      getAllUsers()
    }
  }, [currentPage, optionsSelected])

  useEffect(() => {
    setLoadingStatus(true)
    getAllCompaniesByPais()
      .then((data) => {
        setEnterprices(data.data)
      })
      .finally(() => {
        setLoadingStatus(false)
      })
  }, [])

  return (
    <UsuariosPageContext.Provider
      value={{
        users,
        setUsers,
        getAllUsers,
        optionsSelected,
        setOptionsSelected,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        isLoading,
        setLoadingStatus,
        userEmpresa,
        setUserEmpresa,
        enterprices,
        setEnterprices,
        hints,
        setHints,
      }}
    >
      {children}
    </UsuariosPageContext.Provider>
  )
}

export {UsuariosPageContextProvider}
