import { fetchConToken } from "../helpers/fetch";

const url = `alert/`

const getAllAlerts = async (options: any) => {
    const queryString = new URLSearchParams(options).toString();

    const resp = await fetchConToken(
        `${url}getAll?${queryString}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const getAlertsState = async (options: any) => {
    const queryString = new URLSearchParams(options).toString();

    const resp = await fetchConToken(
        `${url}state/get?${queryString}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const getAlertById = async (id: string) => {
    const resp = await fetchConToken(
        `${url}getById/${id}`,
        {},
        'GET'
    );

    const body = await resp.json();

    return body;
};

const createAlert = async (data: any) => {

    const resp = await fetchConToken(
        `${url}create`,
        data,
        'POST'
    );

    const body = await resp.json();

    return body;
};

const createAlertProcess = async (data: any) => {

    const resp = await fetchConToken(
        `${url}createprocess`,
        data,
        'POST'
    );

    const body = await resp.json();

    return body;
};

const updateAlert = async (id: string, data: any) => {
    const resp = await fetchConToken(`${url}update/${id}`, data, 'POST')

    const body = await resp.json();

    return body;
};

const updateAlertProcess = async (id: string, data: any) => {
    const resp = await fetchConToken(
        `${url}updateprocess/${id}`,
        data,
        'POST'
    );

    const body = await resp.json();

    return body;
};

const deleteAlert = async (id: string) => {
    const resp = await fetchConToken(
        `${url}delete/${id}`,
        {},
        'DELETE'
    );

    const body = await resp.json();

    return body;
};

export {
    getAllAlerts,
    getAlertsState,
    createAlert,
    createAlertProcess,
    getAlertById,
    updateAlert,
    updateAlertProcess,
    deleteAlert
};