import {useContext, useState,useEffect, FC} from 'react'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {Card, Accordion, Badge} from 'react-bootstrap'
import {title} from 'process'
import Collapsible from 'react-collapsible'
import { getCompleteSheet } from '../../../../../../services/fichas'
import { Field, Section, Sheet } from '../../../../fichas/Sheet'
import { FieldsIngracion, ListFieldsIngracion } from '../../../../documento/documentInterface'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { PartnerContext } from '../../../PartnerContexts'
import { addFieldExternalData } from '../../../../../../services/external'
import { showMessage } from '../../../../../../shared/Alerts'

const grid = 5

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // Some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // Change background color if dragging
  background: isDragging ? '#18c073' : 'white',
  borderRadius: 10,
  // Styles we need to apply on draggables
  ...draggableStyle,
})

interface FieldExternal {label: string, fields: any[]}

interface MyComponentProps {
  item: FieldExternal;
  ind:number;
  index:number;
  onUpdate: (updatedData: any) => void;
}


const CollapsibleFieldExternalItem : FC<MyComponentProps> = (props) => {
  const {setExternalData,externalData,setReloadServiceExternal,setShowExportPlantillaExcel,setSelectGroupName} = useContext(PartnerContext)
  let {item,ind,index,onUpdate} = props
  const [formDataFieldExternal, setFormDataFieldExternal] = useState(item);

  useEffect(() => {
    setFormDataFieldExternal(item)
  }, [item])

  useEffect(() => {
    onUpdate(formDataFieldExternal)
  }, [item, formDataFieldExternal])


const handleInputChange = (event: any, index: any,key:any) => {
  const {value} = event.target
  let fieldValue = value
  setFormDataFieldExternal((formDataFieldExternal) => {
    if(formDataFieldExternal.fields){
      const newField = [...formDataFieldExternal.fields]
      newField[index] = {
        ...newField[index],
        [key]: fieldValue,
      }
      return {...formDataFieldExternal, fields: newField}

    }else{
      return {...formDataFieldExternal}
    }
  })
}


const actualizarGrupo = (nameGrupo:string) => {
  setSelectGroupName(nameGrupo)
  setShowExportPlantillaExcel(true)
}

const deleteGrupo = async(nameGrupo :string) => {
  const newExternalDataFields = externalData.fields.filter(field => String(field.label) !== String(nameGrupo));
  setExternalData({...externalData,fields:newExternalDataFields})

  const option = {
    fields:newExternalDataFields,
    after_field:""
  }
  await addFieldExternalData(
  externalData._id,
  option
  )
  .then((response) => {
  if (response.success) {
  showMessage('success', 'Eliminado Correctamente', response.message)
  } else {
  showMessage('error', 'Hubo un Error', response.message)
  }
  setReloadServiceExternal(true)
  })
  .catch((error) => {
  console.log(error)
  })
}
  

  return (
    <>
      {
        <Draggable draggableId={`She`+formDataFieldExternal.label} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <div style={{position: 'relative'}}>
                <div {...provided.dragHandleProps} style={{position: 'absolute', top: '0', left: '0'}}>
                  <Badge
                    bg='success-active'
                    className='me-2'

                    style={{transform: 'translateY(-2px)', color: 'white'}}
                  >
                    {index + 1}
                  </Badge>
                </div>
                <div style={{position: 'absolute', top: '0', right: '0'}}>
                  <Badge
                    bg='primary-active'
                    className='me-2 mt-2'
                    style={{transform: 'translateY(-8px)', color: 'white'}}
                  >
                    {item.fields?.length || 0}
                  </Badge>
                  {/* <a
                    href=''
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    style={{transform: 'translateY(-8px)'}}
                    title='Agregar Campo'
                    // onClick={() => addField()}
                  >
                    <KTIcon iconName='plus-circle' className='fs-3' />
                  </a> */}
                  <a
                    className='btn btn-icon btn-bg-light btn-light-primary btn-sm mx-3'
                    style={{transform: 'translateY(-8px)'}}
                    title='Actualizar Grupo'
                    onClick={() => actualizarGrupo(formDataFieldExternal.label)}
                  >
                    <KTIcon iconName='pencil' className='fs-4' />
                  </a>
                  <a
                    className='btn btn-icon btn-bg-light btn-light-danger btn-sm mx-3'
                    style={{transform: 'translateY(-8px)'}}
                    title='Eliminar Grupo'
                    onClick={() => deleteGrupo(formDataFieldExternal.label)}
                  >
                    <KTIcon iconName='cross' className='fs-4' />
                  </a>
                </div>
                
                <Collapsible transitionTime={200} open={true} easing='ease' trigger={formDataFieldExternal.label} triggerStyle={{marginLeft: 30, fontWeight:'bolder'}}>
                  <div className='mt-3 mx-10'>
                      { formDataFieldExternal.fields.map((field:any, it:number) => (
                          <>
                              {it !== 0 && <div className="separator my-3"></div>}
                            <div className='grid-container-obs' style={{position: 'relative'}}>
                              {Object.keys(field).map((key:any) => (
                                <div className='grid-item' style={{marginLeft: '10px',marginTop: '8px', fontWeight: 'bold' }}>{key}
                                  <input
                                    type='text'
                                    value={field[key]}
                                    onChange={(event) => handleInputChange(event, it,key)}
                                    className={`form-control form-control-sm form-control-solid`}
                                    placeholder='Ingrese Etiqueta'
                                  />
                                </div>
                              )) } 
                            </div>
                          </>                        
                      ))
                      }
                      
                    
                  </div>
                </Collapsible>

              </div>
            </div>
          )}
        </Draggable>

    }
    </>
    
    
  )
}

export {CollapsibleFieldExternalItem}
