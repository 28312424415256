import React, {useState, FC, useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Link, useLocation} from 'react-router-dom'
import Select from 'react-select'
import SignaturePad from './SignatureCanvas'
import Datetime from 'react-datetime'
import {useDropzone} from 'react-dropzone'
import 'react-datetime/css/react-datetime.css'
import {KTSVG} from '../../../../../_metronic/helpers'
import { JsonTable } from 'react-json-to-html';
import './styles.css'
import {nuevoRegistroFicha, nuevoRegistroFichaTotal} from '../../../../services/registroFicha'
import {getProcesoFichas} from '../../../../services/procesos'
import {Accordion} from 'react-bootstrap'
import {FieldType, ModernField} from '../components/fields/ModernField'
import DropzoneImagen from './Dropzone'
import DropzoneImagenUnico from './DropzoneUnico'
import DropzoneArchivo from './DropzoneArchivo'
import TextToStyleGenerator from './StyleFont'
import Base64FileViewer from './Base64FileViewer'
import {KTIcon} from '../../../../../_metronic/helpers'
import {campos} from '../../../../services/campos'
import {
  Campos,
  DataTabla,
  Ficha,
  ProcesoFichas,
  Seccion,
  Tabla,
  tablaData,
  Observacion,
  Historial,
} from '../FIchaInterface'
import {showInfoHtml, showMessage, showResponseObservacionMessage} from '../../../../shared/Alerts'
import {number} from 'yup'
import {DataUser} from '../DataUserInterface'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {Button, Col, Form, Row, Stack, Dropdown} from 'react-bootstrap'
import {downloadFileS3} from '../../../../services/files'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {transformDependentType} from '../../../../shared/functions'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import { useUnidades } from '../../../../shared/hooks/useUnidades'
import { Border } from 'react-bootstrap-icons'

interface MyComponentProps {
  idRegistroFicha: string
  seccion: Seccion
  procesos: ProcesoFichas[]
  historialData: Historial[]
  estatusFicha: string
  onUpdate: (updatedData: any) => void
  camposUpdate: (updatedData: any) => void
  tab: number
  filtro?: Record<string, any> | null
  unidad?: Record<string, any> | null
  setUnidad?: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
  permissions: string[]
}

const CardComponent: FC<MyComponentProps> = (props) => {
  const {
    setUnidad,
    unidad,
    filtro,
    seccion,
    procesos,
    historialData,
    onUpdate,
    camposUpdate,
    estatusFicha,
    idRegistroFicha,
    tab,
    permissions,
  } = props
  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }
  const {selectedRole, idEmpresaInterna} = useAuth2()
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  const rolusuario = selectedRole

  const [procesos1, setProcesos1] = useState<ProcesoFichas[]>(procesos)
  const [historialData1, setHistorialData1] = useState<Historial[]>(historialData)
  const [formData, setFormData] = useState(seccion)
  const [activeTab, setActiveTab] = useState('panel_graficar')
  const [shouldClearFirma, setShouldClearFirma] = useState(false)
  const {misUnidades} = useUnidades()
  
  useEffect(() => {
    // Esta función se ejecutará después de cada actualización de formData
    onUpdate(formData)
  }, [formData])

  useEffect(() => {
    setProcesos1(procesos)
  }, [procesos])

  useEffect(() => {
    setHistorialData1(historialData)
  }, [historialData])

  const handleClearFirmaComplete = () => {
    setShouldClearFirma(false)
  }

  const removeFirma = (index: any) => {
    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos]
      newCampo[index] = {
        ...newCampo[index],
        values: '',
      }
      return {...prevFormData, campos: newCampo}
    })
    handleTabChange('panel_graficar')
  }

  const handleTabChange = (tabId: any) => {
    setShouldClearFirma(true)
    setActiveTab(tabId)
  }

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'))
      }

      reader.readAsDataURL(file)
    })
  }

  // Función para convertir un array de archivos a Base64
  const filesToBase64 = async (files: any) => {
    const base64Array = []

    for (const file of files) {
      try {
        const base64 = await fileToBase64(file)
        base64Array.push(base64)
      } catch (error) {
        console.error(error)
      }
    }

    return base64Array
  }

  const handleFilesSelected = (
    files: File[],
    index: number,
    infoArchivo: {url: string; name: string; size: string}[]
  ) => {
    const infoFiless: {url: string; name: string; size: string}[] = []
    const newValuesFiles: string[] = []
    let hasObservado = false
    let observacion: Observacion[] = []
    let campoUpdate: string[] = []

    infoArchivo.map((res) => {
      if (res.url !== '') {
        infoFiless.push(res)
        newValuesFiles.push(res.url)
      }
    })

    files.forEach((res) => {
      infoFiless.push({
        url: '',
        name: res.name,
        size: String(res.size),
      })
    })

    filesToBase64(files)
      .then((base64Array) => {
        setFormData((prevFormData) => {
          const newCampo = [...prevFormData.campos]
          const valuesFiles = base64Array.filter((file) => typeof file === 'string') as string[]
          valuesFiles.map((res) => {
            newValuesFiles.push(res)
          })

          if (
            (estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') &&
            permissions.includes('Actualizar')
          ) {
            formData.campos[index].observacion?.map((obs) => {
              observacion.push(obs)
            })
            observacion[Number(observacion?.length) - 1].data_correguida = ''
            observacion[Number(observacion?.length) - 1].estado_abierto = false
            if (
              formData.campos[index].observacion?.[
                Number(formData.campos[index].observacion?.length) - 1
              ]?.data_observada !== newValuesFiles.join(';')
            ) {
              hasObservado = false
              campoUpdate.push(String(formData.campos[index].titulo))
              camposUpdate(campoUpdate)
            } else {
              console.log('no entro')
              hasObservado = true
            }
          }

          if (
            (estatusFicha === 'aprobadoPorAdmin' || estatusFicha === 'historico') &&
            permissions.includes('Actualizar')
          ) {
            formData.campos[index].observacion?.map((obs) => {
              observacion.push(obs)
            })
            if (observacion.length > 0) {
              if (
                observacion[Number(observacion?.length) - 1].observacion === 'Actualizable' &&
                formatDateToDMY(new Date()) ===
                  formatDateToDMY(observacion[Number(observacion?.length) - 1].fecha)
              ) {
                observacion[Number(observacion?.length) - 1].data_correguida = ''
                observacion[Number(observacion?.length) - 1].estado_abierto = false
              } else {
                observacion.push({
                  observacion: 'Actualizable',
                  value: '',
                  fecha: new Date(),
                  data_observada: String(formData.campos[index].valuesAux),
                  data_correguida: '',
                  estado_abierto: true,
                })
              }
            } else {
              observacion.push({
                observacion: 'Actualizable',
                value: '',
                fecha: new Date(),
                data_observada: String(formData.campos[index].valuesAux),
                data_correguida: '',
                estado_abierto: true,
              })
            }
            campoUpdate.push(String(formData.campos[index].titulo))
            camposUpdate(campoUpdate)
          }

          newCampo[index] = {
            ...newCampo[index],
            infoFiles: infoFiless,
            Files: files,
            valuesFiles: newValuesFiles,
            observado: hasObservado,
            observacion: observacion,
          }
          return {...prevFormData, campos: newCampo}
        })
      })
      .catch((error) => console.error(error))
    // console.log(arrayFile)
  }

  const handleFirmaSelected = (firma: string, index: number) => {
    let hasObservado = false
    let observacion: Observacion[] = []
    let campoUpdate: string[] = []
    if (
      (estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = ''
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if (
        formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]
          ?.data_observada !== firma
      ) {
        hasObservado = false
        campoUpdate.push(String(formData.campos[index].titulo))
        camposUpdate(campoUpdate)
      } else {
        hasObservado = true
      }
    }

    if (
      (estatusFicha === 'aprobadoPorAdmin' || estatusFicha === 'historico') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      if (observacion.length > 0) {
        if (
          observacion[Number(observacion?.length) - 1].observacion === 'Actualizable' &&
          formatDateToDMY(new Date()) ===
            formatDateToDMY(observacion[Number(observacion?.length) - 1].fecha)
        ) {
          observacion[Number(observacion?.length) - 1].data_correguida = ''
          observacion[Number(observacion?.length) - 1].estado_abierto = false
        } else {
          observacion.push({
            observacion: 'Actualizable',
            value: '',
            fecha: new Date(),
            data_observada: String(formData.campos[index].valuesAux),
            data_correguida: '',
            estado_abierto: true,
          })
        }
      } else {
        observacion.push({
          observacion: 'Actualizable',
          value: '',
          fecha: new Date(),
          data_observada: String(formData.campos[index].valuesAux),
          data_correguida: '',
          estado_abierto: true,
        })
      }
      campoUpdate.push(String(formData.campos[index].titulo))
      camposUpdate(campoUpdate)
    }

    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos]
      newCampo[index] = {
        ...newCampo[index],
        values: firma,
        observado: hasObservado,
        observacion: observacion,
      }
      return {...prevFormData, campos: newCampo}
    })
  }

  const handleInputChangeSelect = (selectOpion: any, index: any) => {
    let fieldValue = selectOpion
    let hasError = false
    let hasObservado = false
    let observacion: Observacion[] = []
    let campoUpdate: string[] = []

    if (
      formData.campos[index].obligatorio &&
      !formData.campos[index].selectOpion &&
      fieldValue === ''
    )
      hasError = true

    if (
      (estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = fieldValue.label
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if (
        formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]
          ?.data_observada !== fieldValue.label
      ) {
        hasObservado = false
        campoUpdate.push(String(formData.campos[index].titulo))
        camposUpdate(campoUpdate)
      } else {
        hasObservado = true
      }
    }

    if (
      (estatusFicha === 'aprobadoPorAdmin' || estatusFicha === 'historico') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      if (observacion.length > 0) {
        if (
          observacion[Number(observacion?.length) - 1].observacion === 'Actualizable' &&
          formatDateToDMY(new Date()) ===
            formatDateToDMY(observacion[Number(observacion?.length) - 1].fecha)
        ) {
          observacion[Number(observacion?.length) - 1].data_correguida = fieldValue
          observacion[Number(observacion?.length) - 1].estado_abierto = false
        } else {
          observacion.push({
            observacion: 'Actualizable',
            value: '',
            fecha: new Date(),
            data_observada: String(formData.campos[index].valuesAux),
            data_correguida: fieldValue,
            estado_abierto: true,
          })
        }
      } else {
        observacion.push({
          observacion: 'Actualizable',
          value: '',
          fecha: new Date(),
          data_observada: String(formData.campos[index].valuesAux),
          data_correguida: fieldValue,
          estado_abierto: true,
        })
      }
      campoUpdate.push(String(formData.campos[index].titulo))
      camposUpdate(campoUpdate)
    }

    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos]
      newCampo[index] = {
        ...newCampo[index],
        hasError: hasError,
        selectOpion: fieldValue,
        observado: hasObservado,
        observacion: observacion,
        tablaData: newCampo[index].tablaData.map((tab: tablaData) => ({
          ...tab,
        })),
      }
      return {...prevFormData, campos: newCampo}
    })
  }

  const handleInputChange = (event: any, index: any) => {
    const {name, value, type} = event.target
    let fieldValue = value
    let hasError = false
    let hasObservado = false
    let observacion: Observacion[] = []
    let campoUpdate: string[] = []

    if (type === 'checkbox') {
      fieldValue = ''
      formData.campos[index].opciones?.map((opc) => {
        opc.value = opc.label === name ? !opc.value : opc.value
        if (opc.value) fieldValue = fieldValue === '' ? opc.label : fieldValue + ',' + opc.label
      })
    } else if (type === 'select-one') {
      formData.campos[index].opciones?.forEach((opc) => {
        opc.value = opc.label === value
      })
    } else if (type === 'radio') {
      formData.campos[index].opciones?.map((opc) => {
        opc.value = opc.label === value ? true : false
      })
    }

    if (
      (estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = fieldValue
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if (
        formData.campos[index].observacion?.[Number(formData.campos[index].observacion?.length) - 1]
          ?.data_observada !== fieldValue
      ) {
        hasObservado = false
        campoUpdate.push(String(formData.campos[index].titulo))
        camposUpdate(campoUpdate)
      } else {
        hasObservado = true
      }
    }

    if (
      (estatusFicha === 'aprobadoPorAdmin' || estatusFicha === 'historico') &&
      permissions.includes('Actualizar')
    ) {
      formData.campos[index].observacion?.map((obs) => {
        observacion.push(obs)
      })
      if (observacion.length > 0) {
        if (
          observacion[Number(observacion?.length) - 1].observacion === 'Actualizable' &&
          formatDateToDMY(new Date()) ===
            formatDateToDMY(observacion[Number(observacion?.length) - 1].fecha)
        ) {
          observacion[Number(observacion?.length) - 1].data_correguida = fieldValue
          observacion[Number(observacion?.length) - 1].estado_abierto = false
        } else {
          observacion.push({
            observacion: 'Actualizable',
            value: '',
            fecha: new Date(),
            data_observada: String(formData.campos[index].valuesAux),
            data_correguida: fieldValue,
            estado_abierto: true,
          })
        }
      } else {
        observacion.push({
          observacion: 'Actualizable',
          value: '',
          fecha: new Date(),
          data_observada: String(formData.campos[index].valuesAux),
          data_correguida: fieldValue,
          estado_abierto: true,
        })
      }
      campoUpdate.push(String(formData.campos[index].titulo))
      camposUpdate(campoUpdate)
    }

    if (formData.campos[index].obligatorio && !formData.campos[index].values && fieldValue === '')
      hasError = true

    setFormData((prevFormData) => {
      const newCampo = [...prevFormData.campos]
      if (
        (estatusFicha === 'fichaObservada' ||
          estatusFicha === 'aprobadoConPendiente' ||
          estatusFicha === 'historico') &&
        permissions.includes('Actualizar')
      ) {
        newCampo[index] = {
          ...newCampo[index],
          values: fieldValue,
          hasError: hasError,
          observado: hasObservado,
          observacion: observacion,
        }
      } else {
        newCampo[index] = {
          ...newCampo[index],
          values: fieldValue,
          hasError: hasError,
          observado: hasObservado,
        }
      }
      return {...prevFormData, campos: newCampo}
    })
  }

  const handleInputChange2 = (event: any, ind: any, index: any) => {
    const {name, value, type} = event.target
    let fieldValue = value
    let hasError = false
    let hasObservado = false
    let observacion: Observacion[] = []

    if (type === 'checkbox') {
      fieldValue = ''
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map((opc) => {
        opc.value = opc.label === name ? !opc.value : opc.value
        if (opc.value) fieldValue = fieldValue === '' ? opc.label : fieldValue + ',' + opc.label
      })
    } else if (type === 'select-one') {
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map((opc) => {
        opc.value = opc.label === value ? true : false
      })
    } else if (type === 'radio') {
      formData.secciones?.[ind]?.data?.[index]?.opciones?.map((opc) => {
        opc.value = opc.label === value ? true : false
      })
    }

    if (estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') {
      formData.secciones?.[ind]?.data?.[index]?.observacion?.map((obs) => {
        observacion.push(obs)
      })
      observacion[Number(observacion?.length) - 1].data_correguida = fieldValue
      observacion[Number(observacion?.length) - 1].estado_abierto = false
      if (
        formData.secciones?.[ind]?.data?.[index]?.observacion?.[
          Number(formData.secciones?.[ind]?.data?.[index]?.observacion?.length) - 1
        ]?.data_observada !== fieldValue
      ) {
        hasObservado = false
      } else {
        hasObservado = true
      }
    }

    setFormData((prevFormData) => {
      const secciones: Tabla[] = prevFormData.secciones || []
      const seccionInd = secciones[ind] || {data: []}
      const data = seccionInd.data || []
      const newSeccion: DataTabla[] = [...data]
      newSeccion[index] = {
        ...newSeccion[index],
        values: fieldValue,
        hasError: hasError,
        observado: hasObservado,
        observacion: observacion,
      }
      secciones[ind] = {...seccionInd, data: newSeccion}
      return {...prevFormData, secciones: secciones}
    })
  }

  const btnRef = useRef<HTMLButtonElement | null>(null)

  const onClick = () => {
    if (formData.dinamico === false) {
      if (onValidarCampos() === true) {
        btnRef.current?.setAttribute('data-kt-indicator', 'on')
        const data = {
          _id: String(idRegistroFicha),
          id_user_creador: idusuario,
          estado: 'fichaSiendoEditada',
          tipo_envio: 'SECCION',
          procesoficha: procesos1,
          historial: historialData1,
          filtro,
          unidad,
        }

        nuevoRegistroFichaTotal(data)
          .then((response) => {
            if (response?.success) {
              btnRef.current?.removeAttribute('data-kt-indicator')
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnRef.current?.removeAttribute('data-kt-indicator')
            console.log(error)
          })
      }
    } else {
      if (formData.secciones.length > 0) {
        btnRef.current?.setAttribute('data-kt-indicator', 'on')

        const data = {
          _id: String(idRegistroFicha),
          id_user_creador: idusuario,
          estado: 'fichaSiendoEditada',
          tipo_envio: 'SECCION',
          procesoficha: procesos1,
          historial: historialData1,
          filtro,
          unidad,
        }

        nuevoRegistroFichaTotal(data)
          .then((response) => {
            if (response?.success) {
              btnRef.current?.removeAttribute('data-kt-indicator')
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnRef.current?.removeAttribute('data-kt-indicator')
            console.log(error)
          })
      } else {
        showMessage('error', '¡Hubo un error!', 'Debe dar click en el más, para añadir la seccion')
      }
    }
  }

  const onValidarCampos = (): boolean => {
    let result = true
    //const camposObligatorio: { [ficha: string]:{[seccion: string]:any[]}  } = {};
    const camposObligatorio: {[seccion: string]: any[]} = {}

    const updatedFormData = formData.campos.map((cam) => {
      if (!camposObligatorio[formData.nombre]) {
        camposObligatorio[formData.nombre] = []
      }

      if (
        cam.obligatorio &&
        !cam.values &&
        cam.valuesFiles.length === 0 &&
        cam.selectOpion === null
      ) {
        cam.hasError = true
        result = false
        camposObligatorio[formData.nombre].push({titulo: cam.titulo, orden: cam.orden})
      } else {
        cam.hasError = false
      }
      return cam
    })

    let codigoHTML = ''
    let existFieldObsevados = false
    codigoHTML += `<b>${formData.nombre}</b><br/>
                        <div class="centrar-tabla" >
                        <table border="1">
                        <thead>
                            <tr>
                            <th>Orden</th>
                            <th>Campo</th>
                            </tr>
                        </thead>
                        <tbody> `
    for (const key of camposObligatorio[formData.nombre]) {
      existFieldObsevados = true
      codigoHTML += '<tr><td>' + key.orden + '</td><td>' + key.titulo + '</td></tr>'
    }
    codigoHTML += '</tbody> </table> </div>'

    if (existFieldObsevados) {
      showInfoHtml('info', 'Hay Campos Obligatorios en la Sección', codigoHTML)
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      campos: updatedFormData,
    }))
    return result
  }

  const onAñadirTabla = () => {
    if (onValidarCampos() === true) {
      setFormData((prevFormData) => {
        const seccion = prevFormData.secciones
        const newData: DataTabla[] = []
        prevFormData.campos.forEach((cam) => {
          newData.push({
            _id: cam._id,
            values: cam.values,
            valuesAux: cam.values,
            objectValue: cam.objectValue,
            valuesFiles: cam.valuesFiles,
            infoFiles: cam.infoFiles,
            selectOpion: cam.selectOpion,
            Files: cam.Files,
            estado: cam.estado,
            opciones: cam.opciones,
            idseccion: cam.idseccion,
            idtipo: cam.idtipo,
            titulo: cam.titulo,
            obligatorio: cam.obligatorio,
            actualizable: cam.actualizable,
            hasError: cam.hasError,
            observacion: cam.observacion,
            observado: cam.observado,
            observadoAux: cam.observadoAux,
            dependiente: cam.dependiente,
            campoDependiente: cam.campoDependiente,
            respuestaCampoDependiente: cam.respuestaCampoDependiente,
            mostrar: cam.mostrar,
            orden: cam.orden,
            tabla: cam.tabla,
            tablaData: cam.tablaData,
            editable: cam.editable,
            comentario: cam.comentario,
            visible: cam.visible,
            tipo: cam.tipo,
          })
        })
        seccion?.push({indice: seccion.length + 1, data: newData})

        return {...prevFormData, secciones: seccion}
      })

      VaciarCampos()
    }
  }

  const VaciarCampos = () => {
    setFormData((prevFormData) => {
      prevFormData.campos.forEach((cam) => {
        cam.values = ''
        cam.selectOpion = null
      })
      return {...prevFormData}
    })
  }

  const handleRemoveSeccion = (item: any) => {
    setFormData((prevFormData) => {
      const newSeccion: Tabla[] = []
      let con = 0
      prevFormData.secciones.forEach((secc, idx) => {
        if (idx !== item) {
          con++
          newSeccion.push({indice: con, data: secc.data})
        }
      })

      return {...prevFormData, secciones: newSeccion}
    })
  }

  /* const getImgKeyName = (keyname:string) => {
    
    const partes = keyname.split('.');
    const extension = partes[partes.length - 1];
    
    const partesKey = keyname.split('/');
    const filename = partesKey[partesKey.length - 1]; 
    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          return response.data;
        }else{
          return ""
        }
        
      })
      .catch((error) => {
        console.log(error)        
        return ""
      })
  } */

  const ValidateStyle = (ind: number): string => {
    let result = '1px solid #ccc'
    if (formData.campos[ind].observado) {
      if (estatusFicha === 'aprobadoConPendiente') {
        result = '2px solid orange'
      } else {
        result = '2px solid red'
      }
    }
    return result
  }

  const ValidateStyle2 = (ind: number): string => {
    let result = ''
    if (formData.campos[ind].observado) {
      if (estatusFicha === 'aprobadoConPendiente') {
        result = '2px solid orange'
      } else {
        result = '2px solid red'
      }
    }
    return result
  }

  const ValidateDisabled = (ind: number): boolean => {
    let result = false
    /* if (!formData.campos[ind].observadoAux){
      result = true
    } */
    if (
      (!formData.campos[ind].editable &&
        !formData.campos[ind].observadoAux &&
        permissions.includes('Actualizar')) ||
      (estatusFicha === 'fichaObservada' &&
        permissions.includes('Actualizar') &&
        !formData.campos[ind].observadoAux) ||
      (estatusFicha === 'aprobadoConPendiente' &&
        permissions.includes('Actualizar') &&
        !formData.campos[ind].observadoAux) ||
      (estatusFicha === 'historico' &&
        permissions.includes('Actualizar') &&
        !formData.campos[ind].actualizable) ||
      (estatusFicha === 'aprobadoPorAdmin' &&
        permissions.includes('Actualizar') &&
        !formData.campos[ind].actualizable)
    ) {
      result = true
    }
    return result
  }

  const ValidateDisabledSeccion = (): boolean => {
    let result = false
    /* if (!formData.campos[ind].observadoAux){
      result = true
    } */
    if (
      (estatusFicha === 'pendientePorColaborador' || estatusFicha === 'fichaSiendoEditada') &&
      permissions.includes('Actualizar')
    ) {
      result = true
    }
    return result
  }

  const ValidarEstadosBtnSecciones = (): boolean => {
    let result = false
    if (
      (estatusFicha === 'pendientePorColaborador' && permissions.includes('Actualizar')) ||
      (estatusFicha === 'fichaSiendoEditada' && permissions.includes('Actualizar'))
    ) {
      result = true
    }
    return result
  }

  const [isMinimized, setIsMinimized] = useState(false)

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized)
  }

  const onlyMyUnids = (unids: any[]) => {
    const codMisUnids = misUnidades.map(u => u.codUnidad)
    const onlyCompanySelected = unids.filter(u => u.parent_id === idEmpresaInterna)

    return onlyCompanySelected
      .filter((u) => codMisUnids.includes(u.id))
      .map((u) => ({label: u.name, value: u.id}))
  }

  return (
    seccion.campos.some((campo) => campo.visible) ?(
    <div
      className={`row g-5 g-xxl-0
              ${
                (estatusFicha === 'completadoPorColaborador' &&
                  permissions.includes('Actualizar')) ||
                (estatusFicha === 'procesoAnulado' && permissions.includes('Actualizar'))
                  ? 'disabled'
                  : ''
              }
              `}
    >
      
      <div
        className={`card card-xxl-stretch mb-5  mt-5 mb-xxl-8 ${
          seccion.dinamico ? 'bg-opacity-10 bg-primary' : ''
        } ${isMinimized ? 'minimized' : 'overflow-visible'}`}
      >
                {seccion.campos.some((campo) => campo.visible) && (
        <div className="card-header border-0 pt-2">
          <h3
            className={`card-title align-items-start flex-column ${
              seccion.dinamico ? 'text-white' : ''
            }`}
          >
             <span className='card-label fw-bold fs-3 mb-1'>{seccion.nombre?.toUpperCase()}
             </span>

            <span className='text-muted mt-1 fw-semibold fs-7'>
              {seccion.descripcion?.toUpperCase()}
            </span>
          </h3>
          <div>
            {seccion.dinamico && ValidateDisabledSeccion() ? (
              <button
                ref={btnRef}
                title='hacer click para añadir esta Sección'
                onClick={onAñadirTabla}
                type='button'
                className='btn mt-2 add-btn btn-light-success btn-sm mr-10'
              >
                Agregar <KTIcon iconName='plus' className='fs-3' />
              </button>
            ) : (
              ''
            )}
          </div>
          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                {/* <span className="btn btn-warning badge badge-square badge-light w-5px" onClick={handleToggleMinimize}> */}
                {isMinimized ? (
                  <>
                    <button onClick={handleToggleMinimize}  className="btn btn-icon btn-light btn-sm">
                      <i className='bi bi-chevron-down'></i>
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={handleToggleMinimize}  className="btn btn-icon btn-light btn-sm">
                      <i className='bi bi-chevron-up'></i>
                    </button>
                  </>
                )}
                {/* </span> */}
                {/* <a className="btn btn-icon btn-success btn-sm" onClick={handleToggleMinimize} >
                    {isMinimized ? <i className="las la-plus fs-1 me-1"></i> : <i className="las la-minus fs-1 me-1"></i>}                    
                  </a> */}
              </li>
            </ul>
          </div>
        </div>
                )}
                        {seccion.campos.some((campo) => campo.visible) && (
        <div className='card-body py-3 pt-5 '>
          <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
              <div className={` grid-container`}>
                {seccion.campos.map((dat, idx) =>
                  (dat.visible && dat.mostrar) || (permissions.includes('Editar Ficha') && dat.visible )? (
                    <div
                      className={`mb-2 ${
                        dat.tipo[0].nombre === 'carga de documentos'
                          ? 'grid-item-Documento'
                          : 'grid-item'
                      }`}
                      key={idx}
                    >
                      {
                      dat.visible == true &&(
                        <>
                        <label className={`form-label`}>
                        <span className={`${dat.obligatorio ? 'required' : ''}`}>
                          {dat.titulo?.toUpperCase()}
                        </span>
                        {formData.campos[idx].observado ? (
                          <>
                            <i
                              className={`fas fa-exclamation-circle ms-2 fs-7 ${
                                estatusFicha === 'aprobadoConPendiente'
                                  ? 'text-warning'
                                  : 'text-danger'
                              }  jumping`}
                              data-tooltip-id={`my-tooltip-${formData.campos[idx]._id}`}
                            ></i>
                            <ReactTooltip
                              id={`my-tooltip-${formData.campos[idx]._id}`}
                              place='bottom'
                              variant={`${
                                estatusFicha === 'aprobadoConPendiente' ? 'warning' : 'error'
                              }`}
                              content={String(
                                formData.campos[idx].observacion?.[
                                  Number(formData.campos[idx].observacion?.length) - 1
                                ]?.value
                              )}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </label>
                      </>
                      )
                    }
                      
                      <br></br>
                      {dat.tipo[0].nombre === 'Caja de Texto' && dat.visible == true ? (
                        <input
                          type='text'
                          className={`form-control form-control-sm ${
                            ValidateDisabled(idx) ? 'form-control-solid' : ''
                          } 
                                      ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}
                          name={dat.tipo[0].nombre?.toUpperCase()}
                          style={{border: ValidateStyle(idx)}}
                          disabled={!dat.editable}
                          value={formData.campos[idx].values?.toUpperCase()}
                          onChange={(event) => handleInputChange(event, idx)}
                          placeholder=''
                        />
                      ) : dat.tipo[0].nombre === 'Lista Desplegable' && dat.visible == true ? (
                        <select
                          className={`form-select ${
                            ValidateDisabled(idx) ? 'form-control-solid' : ''
                          } ${ValidateDisabled(idx) ? 'disabled' : ''}   ${
                            formData.campos[idx].hasError ? 'is-invalid' : ''
                          }`}
                          aria-label='Select example'
                          style={{border: ValidateStyle(idx)}}
                          disabled={!dat.editable}
                          name={dat.tipo[0].nombre}
                          value={formData.campos[idx].values}
                          onChange={(event) => handleInputChange(event, idx)}
                        >
                          <option value=''>Seleccionar</option>
                          {dat.opciones?.map((opc) => (
                            <option value={opc.label}> {String(opc.label)}</option>
                          ))}
                        </select>
                      ) : dat.tipo[0].nombre === 'checkbox' && dat.visible == true ? (
                        <div className='mb-2 d-flex flex-wrap'>
                          {dat.opciones?.map((opc, idx2) => (
                            <div
                              className={`form-check form-check-custom ${
                                ValidateDisabled(idx) ? 'form-check-solid' : ''
                              } form-check-sm m-2`}
                            >
                              <input
                                className={`form-check-input ${
                                  formData.campos[idx].hasError ? 'is-invalid' : ''
                                }`}
                                type='checkbox'
                                style={{border: ValidateStyle(idx)}}
                                disabled={!dat.editable}
                                name={opc.label}
                                checked={formData.campos[idx].opciones[idx2].value ? true : false}
                                onChange={(event) => handleInputChange(event, idx)}
                                id={`flexRadioLg` + idx2}
                              />
                              <label className='form-check-label' htmlFor={`flexRadioLg` + idx2}>
                                {opc.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : dat.tipo[0].nombre === 'radiobutton' && dat.visible == true ? (
                        <div className='mb-2 d-flex flex-wrap'>
                          {dat.opciones?.map((opc, idx2) => (
                            <div className='form-check form-check-custom form-check-solid form-check-sm m-2'>
                              <input
                                className={`form-check-input ${
                                  formData.campos[idx].hasError ? 'is-invalid' : ''
                                }`}
                                type='radio'
                                value={opc.label}
                                style={{border: ValidateStyle(idx)}}
                                disabled={!dat.editable}
                                checked={formData.campos[idx].opciones[idx2].value ? true : false}
                                onChange={(event) => handleInputChange(event, idx)}
                                id='flexRadioSm'
                              />
                              <label className='form-check-label' htmlFor='flexRadioSm'>
                                {opc.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : dat.tipo[0].nombre === 'Lista desplegable BD' && dat.visible == true ? (
                        <div>
                          <Select
                            name={formData.campos[idx]._id}
                            placeholder='Seleccione un campo'
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                border: ValidateStyle(idx),
                                minHeight: '34px',
                                height: '34px',
                                fontFamily: 'inherit',
                                fontSize: '0.95rem',
                                color: '#5e6278',
                              }),
                            }}
                            options={
                              formData.campos[idx].tablaData[0]?.option
                                ? dat.tabla.toLowerCase() === 'unidades'
                                    ? onlyMyUnids(formData.campos[idx].tablaData[0].value)
                                    : formData.campos[idx].tablaData[0].option
                                : []
                            }
                            value={
                              formData.campos[idx].selectOpion
                                ? formData.campos[idx].selectOpion
                                : null
                            }
                            isDisabled={!dat.editable}
                            onChange={(selectedOption) => {
                              if (dat.tabla.toLowerCase() === 'unidades' && setUnidad) {
                                setUnidad({
                                  ...unidad,
                                  codUnid: selectedOption?.value,
                                  DesUnid: selectedOption?.label,
                                })
                              }

                              handleInputChangeSelect(selectedOption, idx)
                            }}
                          />
                        </div>
                      ) : dat.tipo[0].nombre === 'Numero' && dat.visible == true ? (
                        <input
                          type='number'
                          className={`form-control form-control-sm ${
                            ValidateDisabled(idx) ? 'form-control-solid' : ''
                          } ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}
                          onChange={(event) => handleInputChange(event, idx)}
                          style={{border: ValidateStyle(idx)}}
                          disabled={!dat.editable}
                          value={formData.campos[idx].values}
                          placeholder=''
                        />
                      ) : dat.tipo[0].nombre === 'Fecha' && dat.visible == true ? (
                        <div>
                          <input
                            type='date'
                            id='dateInput'
                            disabled={!dat.editable}
                            style={{border: ValidateStyle(idx), fontSize: '14px'}}
                            name={dat.tipo[0].nombre}
                            value={formData.campos[idx].values}
                            onChange={(event) => handleInputChange(event, idx)}
                            className={`custom-date-input ${
                              ValidateDisabled(idx) ? 'form-control-solid' : ''
                            } ${formData.campos[idx].hasError ? 'is-invalid' : ''}`}
                          />
                        </div>
                      ) : dat.tipo[0].nombre === 'Imagen' && dat.visible == true ? (
                        <div
                          className={!dat.editable ? 'disabled' : ''}
                          style={{border: ValidateStyle2(idx)}}
                        >
                          <DropzoneImagen
                            key={dat._id}
                            onFilesSelected={(files, ind, infoImagen) =>
                              handleFilesSelected(files, idx, infoImagen)
                            }
                            idx={dat._id}
                            titulo={dat.titulo}
                            filesImagen={dat.Files ? dat.Files : []}
                            infoImagen={dat.infoFiles ? dat.infoFiles : []}
                          />
                        </div>
                      ) : dat.tipo[0].nombre === 'carga de documentos' && dat.visible == true  ? (
                        <div
                          className={!dat.editable? 'disabled' : ''}
                          style={{border: ValidateStyle2(idx)}}
                        >
                          <DropzoneArchivo
                            key={dat._id}
                            onFilesSelected={(files, ind, infoArchivo) =>
                              handleFilesSelected(files, idx, infoArchivo)
                            }
                            idx={dat._id}
                            titulo={dat.titulo}
                            filesArchivo={dat.Files ? dat.Files : []}
                            infoArchivo={dat.infoFiles ? dat.infoFiles : []}
                          />
                        </div>
                      ) : dat.tipo[0].nombre === 'Firma' && dat.visible == true ? (
                        formData.campos[idx].values ? (
                          <>
                            <img
                              src={formData.campos[idx].values}
                              style={{border: ValidateStyle(idx)}}
                            />
                            <div
                              className={`button-container ${
                                !dat.editable ? 'disabled' : ''
                              } `}
                            >
                              <button
                                className='btn btn-light-warning btn-sm'
                                onClick={() => removeFirma(idx)}
                              >
                                Modificar
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            className={!dat.editable ? 'disabled' : ''}
                            style={{border: ValidateStyle2(idx)}}
                          >
                            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                              <li className='nav-item'>
                                <button
                                  className={`nav-link ${
                                    activeTab === 'panel_graficar' ? 'active' : ''
                                  }`}
                                  onClick={() => handleTabChange('panel_graficar')}
                                >
                                  Graficar
                                </button>
                              </li>
                              <li className='nav-item'>
                                <button
                                  className={`nav-link ${
                                    activeTab === 'panel_cargar' ? 'active' : ''
                                  }`}
                                  onClick={() => handleTabChange('panel_cargar')}
                                >
                                  Cargar
                                </button>
                              </li>
                              <li className='nav-item'>
                                <button
                                  className={`nav-link ${
                                    activeTab === 'panel_generar' ? 'active' : ''
                                  }`}
                                  onClick={() => handleTabChange('panel_generar')}
                                >
                                  Generar
                                </button>
                              </li>
                            </ul>
                            <div className='tab-content' id='myTabContent'>
                              <div
                                className={`tab-pane fade ${
                                  activeTab === 'panel_graficar' ? 'show active' : ''
                                }`}
                              >
                                <SignaturePad
                                  key={dat._id}
                                  onFirmaSelected={(files) => handleFirmaSelected(files, idx)}
                                  shouldClearFirma={shouldClearFirma}
                                  onClearFirma={handleClearFirmaComplete}
                                />
                              </div>
                              <div
                                className={`tab-pane fade ${
                                  activeTab === 'panel_cargar' ? 'show active' : ''
                                }`}
                              >
                                <DropzoneImagenUnico
                                  key={dat._id}
                                  onFirmaSelected={(files) => handleFirmaSelected(files, idx)}
                                  shouldClearFirma={shouldClearFirma}
                                  onClearFirma={handleClearFirmaComplete}
                                  idx={dat._id}
                                />
                              </div>
                              <div
                                className={`tab-pane fade ${
                                  activeTab === 'panel_generar' ? 'show active' : ''
                                }`}
                              >
                                <TextToStyleGenerator
                                  key={dat._id}
                                  onFirmaSelected={(files) => handleFirmaSelected(files, idx)}
                                  shouldClearFirma={shouldClearFirma}
                                  onClearFirma={handleClearFirmaComplete}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      ) :  dat.tipo[0].nombre === 'Tabla' && dat.visible == true ? (
                        <div>
                          <JsonTable
                            json={{
                              Tabla: [
                                {
                                  Descripcion: dat.titulo,
                                  Estado: dat.tipo[0].estado == 'A' ? 'Activo': 'No activo',
                                  Nombre: dat.tipo[0].nombre, 
                                }
                              ]
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {dat.visible == true ? (
                        <span className='text-muted mt-1 fw-semibold fs-7'>{dat.comentario}</span>
                      ) : ''}


                      {(!formData.campos[idx].values || !formData.campos[idx].valuesFiles) &&
                        formData.campos[idx].obligatorio &&
                        formData.campos[idx].hasError && (
                          <div className='fv-plugins-message-container'>
                            <div
                              data-field='appname'
                              data-validator='notEmpty'
                              className='fv-help-block'
                            >
                              {formData.campos[idx].titulo} es requerido
                            </div>
                          </div>
                        )}
                      {formData.campos[idx].observado && (
                        <div className='fv-plugins-message-container'>
                          <div
                            data-field='appname'
                            data-validator='notEmpty'
                            className='fv-help-block'
                          >
                            <h6
                              className={`${
                                formData.campos[idx].observado
                                  ? estatusFicha === 'aprobadoConPendiente'
                                    ? 'text-warning'
                                    : 'text-danger'
                                  : ''
                              }`}
                            >
                              {String(
                                formData.campos[idx].observacion?.[
                                  Number(formData.campos[idx].observacion?.length) - 1
                                ]?.value
                              )}
                            </h6>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null
                )}
              </div>
              {seccion.dinamico ? (
                <div>
                  {seccion.secciones?.map((secciones, item) => (
                    <div className='bg-white hoverable mt-2'>
                      <div className='card-header border-0 pt-2 bg-white hoverable card-seccion-header'>
                        <h3 className='card-title align-items-start flex-column'></h3>
                        {/* <div className='card-toolbar'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a onClick={()=>handleRemoveSeccion(item)} className="btn btn-danger btn-sm">
                                Eliminar <i className="bi bi-x-square fs-2 mt-1 "></i>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      <div className=' grid-container-seccion'>
                        {secciones.data.map((datSec, idx) =>
                          datSec.visible == true ? (
                            <div className='mb-2 grid-item' key={idx}>
                              {/* <label className={`form-label`}>
                                    <span className={`${datSec.obligatorio ? 'required' : ''}`}>{datSec.titulo}</span> 
                                    {(datSec.comentario?
                                        <i
                                        className='fas fa-exclamation-circle ms-2 fs-7'
                                        data-bs-toggle='tooltip'
                                        title={datSec.comentario}
                                      ></i>
                                    :
                                      ""
                                    )}
                                    
                                  </label> */}
                              <label className={`form-label`}>
                                <span className={`${datSec.obligatorio ? 'required' : ''}`}>
                                  {datSec.titulo}
                                </span>
                                {formData.secciones[item].data[idx].observado ? (
                                  <>
                                    <i
                                      className={`fas fa-exclamation-circle ms-2 fs-7 ${
                                        estatusFicha === 'aprobadoConPendiente'
                                          ? 'text-warning'
                                          : 'text-danger'
                                      }  jumping`}
                                      data-tooltip-id={`my-tooltip-${formData.secciones[item].data[idx]._id}`}
                                    ></i>
                                    <ReactTooltip
                                      id={`my-tooltip-${formData.secciones[item].data[idx]._id}`}
                                      place='bottom'
                                      variant={`${
                                        estatusFicha === 'aprobadoConPendiente'
                                          ? 'warning'
                                          : 'error'
                                      }`}
                                      content={String(
                                        formData.secciones[item].data[idx].observacion?.[
                                          Number(
                                            formData.secciones[item].data[idx].observacion?.length
                                          ) - 1
                                        ]?.value
                                      )}
                                    />
                                  </>
                                ) : (
                                  ''
                                )}
                              </label>

                              <br></br>
                              {datSec.tipo[0].nombre === 'Caja de Texto' ? (
                                <input
                                  type='text'
                                  className={`form-control form-control-sm 
                                                ${datSec.hasError ? 'is-invalid' : ''}`}
                                  name={datSec.tipo[0].nombre}
                                  value={datSec.values}
                                  style={{
                                    border:
                                      datSec.observado && permissions.includes('Actualizar')
                                        ? estatusFicha === 'aprobadoConPendiente'
                                          ? '2px solid orange'
                                          : '2px solid red'
                                        : '1px solid #ccc',
                                  }}
                                  disabled={
                                    (!datSec.editable &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar')) ||
                                    (((estatusFicha === 'fichaObservada' &&
                                      permissions.includes('Actualizar')) ||
                                      (estatusFicha === 'aprobadoConPendiente' &&
                                        permissions.includes('Actualizar'))) &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar'))
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => handleInputChange2(event, item, idx)}
                                  placeholder=''
                                />
                              ) : datSec.tipo[0].nombre === 'Lista Desplegable' ? (
                                <select
                                  className={`form-select ${datSec.hasError ? 'is-invalid' : ''}`}
                                  aria-label='Select example'
                                  style={{
                                    border:
                                      datSec.observado && permissions.includes('Actualizar')
                                        ? estatusFicha === 'aprobadoConPendiente'
                                          ? '2px solid orange'
                                          : '2px solid red'
                                        : '1px solid #ccc',
                                  }}
                                  disabled={
                                    (!datSec.editable &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar')) ||
                                    (((estatusFicha === 'fichaObservada' &&
                                      permissions.includes('Actualizar')) ||
                                      (estatusFicha === 'aprobadoConPendiente' &&
                                        permissions.includes('Actualizar'))) &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar'))
                                      ? true
                                      : false
                                  }
                                  name={datSec.tipo[0].nombre}
                                  value={datSec.values}
                                  onChange={(event) => handleInputChange2(event, item, idx)}
                                >
                                  <option value=''>Seleccionar</option>
                                  {datSec.opciones?.map((opc) => (
                                    <option value={opc.label}> {String(opc.label)}</option>
                                  ))}
                                </select>
                              ) : datSec.tipo[0].nombre === 'checkbox' ? (
                                <div className='mb-2 d-flex flex-wrap'>
                                  {datSec.opciones?.map((opc, idx2) => (
                                    <div className='form-check form-check-custom form-check-sm m-2'>
                                      <input
                                        className={`form-check-input ${
                                          datSec.hasError ? 'is-invalid' : ''
                                        }`}
                                        type='checkbox'
                                        name={opc.label}
                                        style={{
                                          border:
                                            datSec.observado && permissions.includes('Actualizar')
                                              ? estatusFicha === 'aprobadoConPendiente'
                                                ? '2px solid orange'
                                                : '2px solid red'
                                              : '1px solid #ccc',
                                        }}
                                        disabled={
                                          (!datSec.editable &&
                                            !datSec.observadoAux &&
                                            permissions.includes('Actualizar')) ||
                                          (((estatusFicha === 'fichaObservada' &&
                                            permissions.includes('Actualizar')) ||
                                            (estatusFicha === 'aprobadoConPendiente' &&
                                              permissions.includes('Actualizar'))) &&
                                            !datSec.observadoAux &&
                                            permissions.includes('Actualizar'))
                                            ? true
                                            : false
                                        }
                                        checked={datSec.opciones[idx2].value ? true : false}
                                        onChange={(event) => handleInputChange2(event, item, idx)}
                                        id={`flexRadioLg` + idx2}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={`flexRadioLg` + idx2}
                                      >
                                        {opc.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              ) : datSec.tipo[0].nombre === 'radiobutton' ? (
                                <div className='mb-2 d-flex flex-wrap'>
                                  {datSec.opciones?.map((opc, idx2) => (
                                    <div className='form-check form-check-custom form-check-sm m-2'>
                                      <input
                                        className={`form-check-input ${
                                          datSec.hasError ? 'is-invalid' : ''
                                        }`}
                                        type='radio'
                                        value={opc.label}
                                        checked={datSec.opciones[idx2].value ? true : false}
                                        style={{
                                          border:
                                            datSec.observado && permissions.includes('Actualizar')
                                              ? estatusFicha === 'aprobadoConPendiente'
                                                ? '2px solid orange'
                                                : '2px solid red'
                                              : '1px solid #ccc',
                                        }}
                                        onChange={(event) => handleInputChange2(event, item, idx)}
                                        id='flexRadioSm'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioSm'>
                                        {opc.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              ) : datSec.tipo[0].nombre === 'Lista desplegable BD' ? (
                                <div>
                                  <Select
                                    name={datSec._id}
                                    placeholder='Seleccione un campo'
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        border: datSec.observado
                                          ? estatusFicha === 'aprobadoConPendiente'
                                            ? '2px solid orange'
                                            : '2px solid red'
                                          : '1px solid #ccc',
                                        minHeight: '34px',
                                        height: '34px',
                                        fontFamily: 'inherit',
                                        fontSize: '0.95rem',
                                        color: '#5e6278',
                                      }),
                                    }}
                                    isDisabled={
                                      (!datSec.editable &&
                                        !datSec.observadoAux &&
                                        permissions.includes('Actualizar')) ||
                                      (((estatusFicha === 'fichaObservada' &&
                                        permissions.includes('Actualizar')) ||
                                        (estatusFicha === 'aprobadoConPendiente' &&
                                          permissions.includes('Actualizar'))) &&
                                        !datSec.observadoAux &&
                                        permissions.includes('Actualizar'))
                                        ? true
                                        : false
                                    }
                                    options={
                                      datSec.tablaData[0]?.option ? datSec.tablaData[0].option : []
                                    }
                                    value={datSec.selectOpion ? datSec.selectOpion : null}
                                    onChange={(selectedOption) =>
                                      handleInputChangeSelect(selectedOption, idx)
                                    }
                                  />
                                </div>
                              ) : datSec.tipo[0].nombre === 'Numero' ? (
                                <input
                                  type='number'
                                  style={{
                                    border:
                                      datSec.observado && permissions.includes('Actualizar')
                                        ? estatusFicha === 'aprobadoConPendiente'
                                          ? '2px solid orange'
                                          : '2px solid red'
                                        : '1px solid #ccc',
                                  }}
                                  disabled={
                                    (!datSec.editable &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar')) ||
                                    (((estatusFicha === 'fichaObservada' &&
                                      permissions.includes('Actualizar')) ||
                                      (estatusFicha === 'aprobadoConPendiente' &&
                                        permissions.includes('Actualizar'))) &&
                                      !datSec.observadoAux &&
                                      permissions.includes('Actualizar'))
                                      ? true
                                      : false
                                  }
                                  className={`form-control form-control-sm ${
                                    datSec.hasError ? 'is-invalid' : ''
                                  }`}
                                  onChange={(event) => handleInputChange2(event, item, idx)}
                                  value={datSec.values}
                                  placeholder=''
                                />
                              ) : datSec.tipo[0].nombre === 'Fecha' ? (
                                <div>
                                  <input
                                    type='date'
                                    id='dateInput'
                                    style={{
                                      border:
                                        datSec.observado && permissions.includes('Actualizar')
                                          ? estatusFicha === 'aprobadoConPendiente'
                                            ? '2px solid orange'
                                            : '2px solid red'
                                          : '1px solid #ccc',
                                      fontSize: '14px',
                                    }}
                                    disabled={
                                      (!datSec.editable &&
                                        !datSec.observadoAux &&
                                        permissions.includes('Actualizar')) ||
                                      (((estatusFicha === 'fichaObservada' &&
                                        permissions.includes('Actualizar')) ||
                                        (estatusFicha === 'aprobadoConPendiente' &&
                                          permissions.includes('Actualizar'))) &&
                                        !datSec.observadoAux &&
                                        permissions.includes('Actualizar'))
                                        ? true
                                        : false
                                    }
                                    name={datSec.tipo[0].nombre}
                                    value={datSec.values}
                                    onChange={(event) => handleInputChange2(event, item, idx)}
                                    className={`custom-date-input ${
                                      datSec.hasError ? 'is-invalid' : ''
                                    }`}
                                  />
                                </div>
                              ) : (
                                /* :datSec.tipo[0].nombre === "Imagen" ?  
                                              <div className={ (!datSec.editable && !datSec.observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !datSec.observadoAux) ? 'disabled' : '' } style={{ border: (datSec.observado && (permissions.includes('Actualizar'))) ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '' }} >
                                                <DropzoneImagen key={datSec._id} onFilesSelected={(files,ind,infoImagen) => handleFilesSelected(files, idx,infoImagen)}  idx={datSec._id}   titulo={datSec.titulo} filesImagen={datSec.Files?datSec.Files:[]} infoImagen={datSec.infoFiles?datSec.infoFiles:[]} />
                                              </div>
                                            :datSec.tipo[0].nombre === "carga de documentos" ?  
                                              <div className={ (!datSec.editable && !datSec.observadoAux)  || ((estatusFicha === 'fichaObservada' || estatusFicha === 'aprobadoConPendiente') && !datSec.observadoAux) ? 'disabled' : '' } style={{ border: (datSec.observado && (permissions.includes('Actualizar'))) ? estatusFicha === 'aprobadoConPendiente'? '2px solid orange' : '2px solid red' : '' }} >
                                                <DropzoneArchivo key={datSec._id} onFilesSelected={(files,ind,infoArchivo) => handleFilesSelected(files, idx,infoArchivo)}  idx={datSec._id}   titulo={datSec.titulo} filesArchivo={datSec.Files?datSec.Files:[]} infoArchivo={datSec.infoFiles?datSec.infoFiles:[]} />
                                              </div> 
                                            :datSec.tipo[0].nombre === "Firma" ? 
                                              <div> 
                                                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                                                  <li className="nav-item">
                                                    <button
                                                      className={`nav-link ${activeTab === 'panel_graficar' ? 'active' : ''}`}
                                                      onClick={() => handleTabChange('panel_graficar')}
                                                    >
                                                      Graficar
                                                    </button>
                                                  </li>
                                                  <li className="nav-item">
                                                    <button
                                                      className={`nav-link ${activeTab === 'panel_cargar' ? 'active' : ''}`}
                                                      onClick={() => handleTabChange('panel_cargar')}
                                                    >
                                                      Cargar
                                                    </button>
                                                  </li>
                                                  <li className="nav-item">
                                                    <button
                                                      className={`nav-link ${activeTab === 'panel_generar' ? 'active' : ''}`}
                                                      onClick={() => handleTabChange('panel_generar')}
                                                    >
                                                      Generar
                                                    </button>
                                                  </li>
                                                </ul>
                                                <div className="tab-content" id="myTabContent">
                                                  <div className={`tab-pane fade ${activeTab === 'panel_graficar' ? 'show active' : ''}`}>
                                                    <SignaturePad 
                                                      key={datSec._id} 
                                                      onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                                      shouldClearFirma={shouldClearFirma}
                                                      onClearFirma={handleClearFirmaComplete} />
                                                  </div>
                                                  <div className={`tab-pane fade ${activeTab === 'panel_cargar' ? 'show active' : ''}`}>
                                                    <DropzoneImagenUnico 
                                                      key={datSec._id} 
                                                      onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                                      shouldClearFirma={shouldClearFirma}
                                                      onClearFirma={handleClearFirmaComplete}
                                                      idx={datSec._id} />
                                                  </div>
                                                  <div className={`tab-pane fade ${activeTab === 'panel_generar' ? 'show active' : ''}`}>
                                                    <TextToStyleGenerator
                                                      key={"sec"+datSec._id}
                                                      onFirmaSelected={(files) => handleFirmaSelected(files, idx)} 
                                                      shouldClearFirma={shouldClearFirma}
                                                      onClearFirma={handleClearFirmaComplete} />
                                                  </div>
                                                </div>
                                              </div> */
                                ''
                              )}
                              <span className='text-muted mt-1 fw-semibold fs-7'>
                                {datSec.comentario}
                              </span>
                              {(!datSec.values || !datSec.valuesFiles) &&
                                datSec.obligatorio &&
                                datSec.hasError && (
                                  <div className='fv-plugins-message-container'>
                                    <div
                                      data-field='appname'
                                      data-validator='notEmpty'
                                      className='fv-help-block'
                                    >
                                      {datSec.titulo} es requerido
                                    </div>
                                  </div>
                                )}
                            </div>
                          ) : ''
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
                        )}
        {ValidarEstadosBtnSecciones() && tab === 0 && (
          <div className='card-footer py-4'>
            <button
              ref={btnRef}
              onClick={onClick}
              type='button'
              className='btn btn-primary btn-sm me-10" id="kt_button_1'
            >
              <span className='indicator-label'>Guardar Sección</span>
              <span className='indicator-progress'>
                Cargando
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        )}
      </div>
            
      {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
    </div>

      ) :  null 
  )
}

interface MyProps {
  procesoFicha: ProcesoFichas[]
  idRegistroFicha: string
  isInvisible?: boolean
  historial: Historial[]
  urlRef?: string
  vista: string
  num: number
  estatusFicha: string
  onUpdate: (updatedData: any) => void
  filtro?: Record<string, any> | null
  unidad?: Record<string, any> | null
  setUnidad?: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
}

export const InfoRequerida3: React.FC<MyProps> = ({
  procesoFicha,
  idRegistroFicha,
  num,
  estatusFicha,
  urlRef,
  historial,
  isInvisible,
  onUpdate,
  filtro,
  unidad,
  setUnidad,
  vista
}) => {
  const location = useLocation()
  const [isLoading, setLoadingStatus] = useState(false)
  const [procesos, setProcesos] = useState<ProcesoFichas[]>(procesoFicha)
  const [historialData, setHistorialData] = useState<Historial[]>(historial)
  const [camposUpdate, setCamposUpdate] = useState<string[]>([])
  const [estadoRegistroFicha, setEstadoRegistroFicha] = useState(estatusFicha)
  const [activeTab, setActiveTab] = useState(num)
  const [vistaFicha, setvistaFicha] = useState(vista)
  const btnGuardarRef = useRef<HTMLButtonElement | null>(null)
  const btnBorradorRef = useRef<HTMLButtonElement | null>(null)
  const [permissions, setPermissions] = useState<string[]>([])

  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  const {selectedRole} = useAuth2()
  const idusuario = usuario?.userId
  const nameusuario = usuario?.userName
  const rolusuario = selectedRole

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = urlRef
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    setLoadingStatus(true)
    setProcesos(procesoFicha)
    setHistorialData(historial)
    setEstadoRegistroFicha(estatusFicha)
    setActiveTab(num)
  }, [procesoFicha, activeTab, num])

  const handleUpdateSeccion = (fichaIndex: any, seccionIndex: any, updatedData: Seccion) => {
    const newProcesos = [...procesos]
    newProcesos[num].fichas[fichaIndex].seccion[seccionIndex] = updatedData
    setProcesos(newProcesos)
    onValidarDependencia()
  }

  const handleUpdateCampo = (updatedCampo: string[]) => {
    const uniqueValues = new Set(camposUpdate)
    uniqueValues.add(updatedCampo[0])
    const uniqueValuesArray = Array.from(uniqueValues)
    setCamposUpdate(uniqueValuesArray)
  }

  const onValidarDependencia = (): boolean => {
    let result = true

    procesos.forEach((procesoFicha) => {
      procesoFicha.fichas.forEach((ficha) => {
        ficha.seccion.forEach((seccion) => {
          seccion.campos.forEach((cam) => {
            if (cam.dependiente) {
              let mostrar = false
              let selectOption = null
              procesos.forEach((procesoFicha2) => {
                procesoFicha2.fichas.forEach((ficha2) => {
                  ficha2.seccion.forEach((seccion2) => {
                    seccion2.campos.forEach((cam2) => {
                      const transformedValue = transformDependentType(cam.respuestaCampoDependiente)
                      if (
                        cam2.mostrar &&
                        ((Array.isArray(transformedValue) &&
                          transformedValue.includes(cam2.values)) ||
                          (typeof transformedValue === 'string' &&
                            transformedValue === cam2.values)) &&
                        cam2._id === cam.campoDependiente
                      ) {
                        mostrar = true
                      }

                      if (
                        cam2.mostrar &&
                        cam2.tipo[0].nombre === 'Lista desplegable BD' &&
                        cam2._id === cam.campoDependiente
                      ) {
                        mostrar = true
                        const newOpciones: any = []
                        cam.tablaData[0].value.forEach((dat) => {
                          if (String(cam2.selectOpion?.value) === dat.parent_id) {
                            newOpciones.push({
                              value: dat.id ? dat.id : '',
                              label: dat.name ? dat.name : '',
                            })
                          }
                        })
                        cam.tablaData[0].option = newOpciones
                      }
                    })
                  })
                })
              })

              cam.tablaData[0]?.option.forEach((opc) => {
                if (opc.label === cam.selectOpion?.label && opc.value === cam.selectOpion?.value) {
                  selectOption = cam.selectOpion
                }
              })
              cam.mostrar = mostrar
              cam.selectOpion = selectOption
            }
            return cam
          })
        })
      })
    })

    const dataWithValuesAndFiles: ProcesoFichas[] = procesos.map((procesoFicha: ProcesoFichas) => ({
      ...procesoFicha,
    }))
    setProcesos(dataWithValuesAndFiles)
    return result
  }

  const onValidarCampos = (): boolean => {
    let result = true
    if (!(estadoRegistroFicha === 'aprobadoPorAdmin' || estadoRegistroFicha === 'historico')) {
      const camposObligatorio: {[ficha: string]: {[seccion: string]: any[]}} = {}
      const updatedFormData = procesos.map((procesoFicha) => {
        procesoFicha.fichas.map((ficha) => {
          if (!camposObligatorio[ficha.nombre]) {
            camposObligatorio[ficha.nombre] = {}
          }
          ficha.seccion.map((seccion) => {
            if (!camposObligatorio[ficha.nombre][seccion.nombre]) {
              camposObligatorio[ficha.nombre][seccion.nombre] = []
            }
            seccion.campos.map((cam) => {
              if (!seccion.dinamico) {
                if (
                  cam.obligatorio &&
                  !cam.values &&
                  cam.valuesFiles.length === 0 &&
                  cam.selectOpion === null
                ) {
                  cam.hasError = true
                  result = false
                  camposObligatorio[ficha.nombre][seccion.nombre].push({
                    titulo: cam.titulo,
                    orden: cam.orden,
                  })
                } else {
                  cam.hasError = false
                }
              }
              return cam
            })
          })
        })
      })

      let codigoHTML = ''

      for (const key of Object.keys(camposObligatorio)) {
        codigoHTML += `Ficha :<b> ${key}</b><br/>`
        for (const key1 of Object.keys(camposObligatorio[key])) {
          if (camposObligatorio[key][key1].length > 0) {
            codigoHTML += `Sección :<b> ${key1}</b><br/>
                            <div class="centrar-tabla" >
                            <table border="1">
                            <thead>
                                <tr>
                                <th style='width:50px' >Orden</th>
                                <th>Campo</th>
                                </tr>
                            </thead>
                            <tbody> `
            for (const val of camposObligatorio[key][key1]) {
              codigoHTML +=
                '<tr><td style="width:50px" >' + val.orden + '</td><td>' + val.titulo + '</td></tr>'
            }
            codigoHTML += `</tbody> </table> </div>`
          }
        }
      }

      if (!result) {
        showInfoHtml('info', `Hay Campos Obligatorios`, codigoHTML)
      }

      const dataWithValuesAndFiles: ProcesoFichas[] = procesos.map(
        (procesoFicha: ProcesoFichas) => ({
          ...procesoFicha,
          fichas: procesoFicha.fichas.map((ficha: Ficha) => ({
            ...ficha,
            seccion: ficha.seccion.map((seccion: Seccion) => ({
              ...seccion,
              campos: seccion.campos.map((campo: Campos) => ({
                ...campo,
                hasError:
                  campo.obligatorio &&
                  !campo.values &&
                  campo.valuesFiles.length === 0 &&
                  campo.selectOpion === null
                    ? true
                    : false,
              })),
            })),
          })),
        })
      )
      setProcesos(dataWithValuesAndFiles)
    }

    return result
  }

  const onGuardar = () => {
    if (onValidarCampos() === true) {
      let estadoDefault = 'completadoPorColaborador'
      if (permissions.includes('Editar Ficha')) {
        estadoDefault = estadoRegistroFicha
      }

      btnGuardarRef.current?.setAttribute('data-kt-indicator', 'on')
      if (estadoRegistroFicha === 'fichaObservada') {
        let ultimaObsevación = ''
        historialData.map((his) => {
          if (his.motivo === 'OBSERVADO') {
            ultimaObsevación = his.data?.text ? his.data?.text : ''
          }
        })
        showResponseObservacionMessage(ultimaObsevación).then((result) => {
          if (result.isConfirmed) {
            historialData.push({
              createdAt: new Date(),
              motivo: 'OBSERVADO',
              id_user_creador: String(idusuario),
              id_user_receptor: String(procesos[0].proceso?.[0].responsables.join(',')),
              data: {
                nameuser: String(nameusuario),
                iduser: String(idusuario),
                text: result.value,
                fecha: new Date(),
              },
              camposModificados: camposUpdate,
            })

            const data = {
              _id: String(idRegistroFicha),
              id_user_creador: idusuario,
              estado: estadoDefault,
              procesoficha: procesos,
              historial: historialData,
              rol: rolusuario,
              vista: 'preenrolamiento',
              filtro,
              unidad,
            }

            nuevoRegistroFichaTotal(data)
              .then((response) => {
                if (response?.success) {
                  setEstadoRegistroFicha(response?.estado)
                  btnGuardarRef.current?.removeAttribute('data-kt-indicator')
                  showMessage('success', '¡Buen trabajo!', response.message)
                } else {
                  showMessage('error', 'Hubo un error', response.message)
                  console.log(response?.message)
                }
              })
              .catch((error) => {
                btnGuardarRef.current?.removeAttribute('data-kt-indicator')
                console.log(error)
              })
          } else {
            btnGuardarRef.current?.removeAttribute('data-kt-indicator')
          }
        })
      } else if (
        estadoRegistroFicha === 'aprobadoPorAdmin' ||
        estadoRegistroFicha === 'historico'
      ) {
        historialData.push({
          createdAt: new Date(),
          motivo: estadoRegistroFicha,
          id_user_creador: String(idusuario),
          id_user_receptor: String(procesos[0].proceso?.[0].responsables.join(',')),
          camposModificados: camposUpdate,
        })

        const data = {
          _id: String(idRegistroFicha),
          id_user_creador: idusuario,
          estado: estadoRegistroFicha,
          procesoficha: procesos,
          historial: historialData,
          rol: rolusuario,
          vista: 'preenrolamiento',
          filtro,
          unidad,
        }

        nuevoRegistroFichaTotal(data)
          .then((response) => {
            if (response?.success) {
              setEstadoRegistroFicha(response?.estado)
              btnGuardarRef.current?.removeAttribute('data-kt-indicator')
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnGuardarRef.current?.removeAttribute('data-kt-indicator')
            console.log(error)
          })
      } else {
        historialData.push({
          createdAt: new Date(),
          motivo: estadoDefault,
          id_user_creador: String(idusuario),
          id_user_receptor: String(procesos[0].proceso?.[0].responsables.join(',')),
          camposModificados: camposUpdate,
        })
        const data = {
          _id: String(idRegistroFicha),
          id_user_creador: idusuario,
          estado: estadoDefault,
          procesoficha: procesos,
          historial: historialData,
          rol: rolusuario,
          vista: 'preenrolamiento',
          filtro,
          unidad,
        }

        nuevoRegistroFichaTotal(data)
          .then((response) => {
            if (response?.success) {
              setEstadoRegistroFicha(response?.estado)
              btnGuardarRef.current?.removeAttribute('data-kt-indicator')
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.message)
              console.log(response?.message)
            }
          })
          .catch((error) => {
            btnGuardarRef.current?.removeAttribute('data-kt-indicator')
            console.log(error)
          })
      }
    } else {
      // showMessage('error', 'Hubo un inconveniente', "Falta completar campos Requeridos")
    }
  }

  const onBorrador = () => {
    btnBorradorRef.current?.setAttribute('data-kt-indicator', 'on')
    historialData.push({
      createdAt: new Date(),
      motivo: 'fichaSiendoEditada',
      id_user_creador: String(idusuario),
      id_user_receptor: String(procesos[0].proceso?.[0].responsables.join(',')),
    })
    const data = {
      _id: String(idRegistroFicha),
      id_user_creador: idusuario,
      estado: 'fichaSiendoEditada',
      tipo_envio: 'BORRADOR',
      procesoficha: procesos,
      historial: historialData,
      filtro,
      unidad,
    }

    nuevoRegistroFichaTotal(data)
      .then((response) => {
        if (response?.success) {
          btnBorradorRef.current?.removeAttribute('data-kt-indicator')
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.message)
          console.log(response?.message)
        }
      })
      .catch((error) => {
        btnBorradorRef.current?.removeAttribute('data-kt-indicator')
        console.log(error)
      })
  }

  const ValidarEstadosBtnBorrador = (): boolean => {
    let result = false
    if (
      (estadoRegistroFicha === 'pendientePorColaborador' && permissions.includes(vistaFicha)) ||
      (estadoRegistroFicha === 'fichaSiendoEditada' && permissions.includes(vistaFicha))
    ) {
      result = true
    }
    return result
  }

  const ValidarEstadosBtnGuardar = (): boolean => {
    let result = false
    if (
      (!(
        estadoRegistroFicha === 'completadoPorColaborador' ||
        estadoRegistroFicha === 'procesoAnulado'
      ) &&
        permissions.includes(vistaFicha)) ||
      (estadoRegistroFicha === 'completadoPorColaborador' &&
        permissions.includes('Editar Ficha')) ||
      (estadoRegistroFicha === 'aprobadoConPendiente' && permissions.includes('Editar Ficha')) ||
      (estadoRegistroFicha === 'aprobadoPorAdmin' && permissions.includes('Editar Ficha')) ||
      (estadoRegistroFicha === 'historico' && permissions.includes('Editar Ficha'))
    ) {
      result = true
    }
    return result
  }

  return (
    <div>
      {procesos[num]?.fichas[0].seccion.map(
        (item, idx) =>
          ((item.visible && permissions.includes(vistaFicha)) ||
            permissions.includes('Editar Ficha')) && (
            <CardComponent
              idRegistroFicha={idRegistroFicha}
              key={String(num + '-' + idx)}
              tab={activeTab}
              seccion={item}
              procesos={procesos}
              historialData={historialData}
              estatusFicha={estadoRegistroFicha}
              onUpdate={(updatedData) => handleUpdateSeccion(0, idx, updatedData)}
              camposUpdate={(updatedCampo) => handleUpdateCampo(updatedCampo)}
              filtro={filtro}
              unidad={unidad}
              setUnidad={setUnidad}
              permissions={permissions}
            />
          )
      )}

      {!isInvisible && (
        <div className='card-footer'>
          <Row>
            {/* <Col className='my-1' >
            
          </Col> */}
            <Col className='d-flex align-self-end justify-content-end py-2'>
              {num + 1 === 1 && procesos.length > 1 ? (
                <button
                  type='button'
                  onClick={() => onUpdate(num + 1)}
                  className='btn btn-primary btn-sm me-10'
                >
                  <Link
                    className={`nav-link text-active-primary me-6 `}
                    to={``}
                    onClick={() => onUpdate(num + 1)}
                  >
                    Siguiente
                  </Link>
                </button>
              ) : num + 1 === procesos.length && procesos.length > 1 ? (
                <button
                  type='button'
                  onClick={() => onUpdate(num - 1)}
                  className='btn btn-primary btn-sm me-10'
                >
                  <Link
                    className={`nav-link text-active-primary me-6 `}
                    to={``}
                    onClick={() => onUpdate(num - 1)}
                  >
                    Anterior
                  </Link>
                </button>
              ) : procesos.length > 1 ? (
                <>
                  <button
                    type='button'
                    onClick={() => onUpdate(num - 1)}
                    className='btn btn-primary btn-sm me-10'
                  >
                    <Link
                      className={`nav-link text-active-primary me-6 `}
                      to={``}
                      onClick={() => onUpdate(num - 1)}
                    >
                      Anterior
                    </Link>
                  </button>
                  <button
                    type='button'
                    onClick={() => onUpdate(num + 1)}
                    className='btn btn-primary btn-sm me-10'
                  >
                    <Link
                      className={`nav-link text-active-primary me-6 `}
                      to={``}
                      onClick={() => onUpdate(num + 1)}
                    >
                      Siguiente
                    </Link>
                  </button>
                </>
              ) : (
                <></>
              )}
              {ValidarEstadosBtnBorrador() && (
                <button
                  ref={btnBorradorRef}
                  onClick={onBorrador}
                  type='button'
                  className='btn btn-primary btn-sm me-10'
                  id='kt_button_Borrador'
                >
                  <span className='indicator-label'>Guardar Borrador</span>
                  <span className='indicator-progress'>
                    Cargando
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              )}
              {num + 1 === procesos.length && ValidarEstadosBtnGuardar() && (
                <button
                  ref={btnGuardarRef}
                  onClick={onGuardar}
                  type='button'
                  className='btn btn-primary btn-sm me-10'
                  id='kt_button_Guardar'
                >
                  <span className='indicator-label'>Guardar Ficha</span>
                  <span className='indicator-progress'>
                    Cargando
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              )}
            </Col>
          </Row>
        </div>
      )}
      {/* <pre>{num}</pre>
      <pre>{JSON.stringify(procesos[num], null, 2)}</pre> */}
    </div>
  )
}
