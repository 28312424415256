import {FC, lazy, Suspense, useEffect} from 'react'
import {useAuth2} from '../../modules/authv2/Auth2Provider'
import './Banner.css'
import Skeleton from './components/Skeleton'
import {DashboardContextProvider} from './DashboardContextProvider'

const PrincipalBanner = lazy(() => import('./components/PrincipalBanner'))
const SolicitudesSection = lazy(() => import('./components/SolicitudesSection'))
const UsersFilterSection = lazy(() => import('./components/UsersFilterSection'))
const PendingDocsSection = lazy(() => import('./components/PendingDocsSection'))
const PrincipalBannerColab = lazy(() => import('./components/PrincipalBannerColab'))
const DocumentsColab = lazy(() => import('./components/DocumentsColab'))
const ProcessColab = lazy(() => import('./components/ProcessColab'))
const LegajoSection = lazy(() => import('./components/LegajoSection'))

const dashboardViewRoles = ['SUPER', 'ADMIN', 'SUPERADMIN']

const DashboardWrapper: FC = () => {
  const {selectedRole, selectedCompanyId} = useAuth2()

  // useEffect(() => {
  //   queryClient.invalidateQueries()
  // }, [selectedCompanyId, selectedRole])

  useEffect(() => {}, [])

  return (
    <DashboardContextProvider>
      <>
        <div className='row g-5 g-xxl-8'>
          <div className='mb-5 mb-xxl-8 card'>
            <Suspense fallback={<Skeleton height={50} count={3} />}>
              {selectedRole !== 'POST' ? <PrincipalBanner /> : <PrincipalBannerColab />}
            </Suspense>
          </div>
        </div>

        <div className='row g-5 g-xxl-8'>
          <div className='col-xl-6'>
            <Suspense fallback={<Skeleton height={50} count={3} />}>
              {dashboardViewRoles.includes(selectedRole as string) ? <SolicitudesSection /> : null}

              {selectedRole === 'POST' ? <DocumentsColab /> : null}
            </Suspense>
          </div>
          <div className='col-xl-6'>
            <Suspense fallback={<Skeleton height={50} count={3} />}>
              {dashboardViewRoles.includes(selectedRole as string) ? <UsersFilterSection /> : null}

              {selectedRole === 'POST' ? <ProcessColab /> : null}
            </Suspense>
          </div>
        </div>

        <div className='row g-5 g-xxl-8'>
          <Suspense fallback={<Skeleton height={50} count={3} />}>
            {dashboardViewRoles.includes(selectedRole as string) ? <PendingDocsSection /> : null}

            {selectedRole === 'POST' ? <LegajoSection /> : null}
          </Suspense>
        </div>
      </>
    </DashboardContextProvider>
  )
}

export {DashboardWrapper}
