import {createContext} from 'react'

export interface Enterprice {
  _id: string
  nombre: string
  descripcion: string
  estado: string
  createdAt: string
  updatedAt: string
}

interface PartnerContextProps {
  activeOnly: boolean
  state: string[]
  searchTerm: string
  entryDateFrom: any
  entryDateTo: any
  setActiveOnly: (value: boolean) => void
  setState: (value: string[]) => void
  setSearchTerm: (value: string) => void
  setEntryDateFrom: (value: any) => void
  setEntryDateTo: (value: any) => void
  isEditUserModalOpen: boolean
  openEditUserModal: () => void
  closeEditUserModal: () => void
  userData: Record<string, any>
  setUserData: (value: {}) => void
  updateUser: boolean
  setUpdateUser: (value: boolean) => void
  enterprices: Enterprice[]
  setEnterprices: (values: Enterprice[]) => void
  addUserModal: boolean
  openAddUserModal: () => void
  closeAddUserModal: () => void
  fetchAllUsers: () => Promise<any>
  currentPage: number
  setCurrentPage: (value: number) => void
  userEmpresa: Record<string, string>
  setUserEmpresa: React.Dispatch<React.SetStateAction<Record<string, string>>>
  lista: any[]
  setLista: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setLoadingStatus: React.Dispatch<React.SetStateAction<boolean>>
  totalPages: number
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  bulkAddUserModal: boolean
  openBulkAddUserModal: () => void
  closeBulkAddUserModal: () => void
}

const PartnerContext = createContext<PartnerContextProps>({
  activeOnly: true,
  state: [],
  searchTerm: '',
  entryDateFrom: null,
  entryDateTo: null,
  setActiveOnly: (value) => {},
  setState: (value) => {},
  setSearchTerm: (value) => {},
  setEntryDateFrom: (value: any) => {},
  setEntryDateTo: (value: any) => {},
  isEditUserModalOpen: false,
  openEditUserModal: () => {},
  closeEditUserModal: () => {},
  userData: {},
  setUserData: (value) => {},
  updateUser: false,
  setUpdateUser: (value: boolean) => {},
  enterprices: [],
  setEnterprices: () => {},
  addUserModal: false,
  openAddUserModal: () => {},
  closeAddUserModal: () => {},
  async fetchAllUsers() {},
  currentPage: 1,
  setCurrentPage: () => {},
  userEmpresa: {},
  setUserEmpresa: () => {},
  lista: [],
  setLista: () => {},
  setLoadingStatus: () => {},
  isLoading: false,
  totalPages: 1,
  setTotalPages: () => {},
  bulkAddUserModal: false,
  openBulkAddUserModal: () => {},
  closeBulkAddUserModal: () => {},
})

export {PartnerContext}
