import { useContext, useEffect, useState } from 'react'
import { Col, Form, Row, Stack } from 'react-bootstrap'
import { useLocation, useSearchParams } from 'react-router-dom'
import { KTIcon } from '../../../../../_metronic/helpers'
import { getUnidades, getUnidadesEmpresas } from '../../../../services/companies'
import { generateLoadExcel } from '../../../../services/documentos'
import { fetchMenuActionsByRole, fetchSubMenuByUrl } from '../../../../services/menu'
import { getProceso, getProcessesForDropdown } from '../../../../services/procesos'
import { TableSearch } from '../../../../shared/TableSearch'
import { useGlobalOverlayLoading } from '../../../../shared/hooks/useGlobalOverlayLoading'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import { PartnerContext } from '../PartnerContexts'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import { DatePickerBusiness } from '../../../generales_component/DatePickerBusiness'

const DocumentsListHeader = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Borrador',
      stateName: 'borrador',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'verify',
      title: 'Pendiente',
      stateName: 'pendiente',
      color: '#3c50e7', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Enviado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const buttonsDataSigned = [
    {
      iconName: 'verify',
      title: 'Pendiente',
      stateName: 'pendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Completado',
      stateName: 'completado',
      color: '#2ecc71', // Green
    }
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const [processList, setProcessList] = useState<any[]>([])
  const [unitList, setUnitList] = useState<any[]>([])
  const { setGlobalOverlayLoading } = useGlobalOverlayLoading()
  const location = useLocation()
  const { selectedRole, selectedCompanyId } = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const usuarioJSON = localStorage.getItem('userData')
    const [searchParams ] = useSearchParams()

  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        console.log("currentpath"+ JSON.stringify(currentPath))
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          console.log("PERMISOS"+ JSON.stringify(subMenuResponse))
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu = response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  const { setActiveOnly, setIdProceso, setState,setStateSigned, setSearchTerm, setEntryDateFrom, setEntryDateTo, setUnidad, setDates, Dates } =
    useContext(PartnerContext)

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleButtonClick = (index: number) => {
    const newActiveButtons = [...activeButtons]
    newActiveButtons[index] = !newActiveButtons[index]
    setActiveButtons(newActiveButtons)
    setState(
      buttonsData
        .map((x, i) => (newActiveButtons[i] ? x.stateName : ''))
        .filter((n) => n.length > 0)
    )
  }

  const handleProcessChange = (e: any) => {
    setIdProceso(e.target.value)
  }

  const handleUnitChange = (e: any) => {
    setUnidad(e.target.value)
  }

  const handleStateChange = (e: any) => {
    setState(e.target.value)
  }
  
  const handleStateSignedChange = (e: any) => {
    setStateSigned(e.target.value)
  }

  const handleClearFilters = () => {
    setState([''])
    setStateSigned('')    
    setSearchTerm('')
    setEntryDateFrom(null)
    setEntryDateTo(null)
  }

  const handleExport = (id: any) => {
    setGlobalOverlayLoading(true)
    generateLoadExcel({})
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
      .finally(() => setGlobalOverlayLoading(false))
  }

  /* const getProcesses = () => {
    getProcessesForDropdown()
      .then((response) => {
        if (response.success) {
          setProcessList(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchUnidades = async () => {
    try {
      const response = await getUnidades()
      if (response.success) {
        setUnitList(response.data.map((unidad: any) => ({
          label: unidad.DesUnid.toUpperCase(),
          value: unidad.codUnid
        })))
      } else {
        console.log(response.message)
      }
    } catch (error) {
      console.error(error)
    }
  } */

  useEffect(() => {
    // getProcesses()
    // fetchUnidades() // Llama a la función para obtener las unidades
    const options = {
      responsables: usuario?.userId,
      estado: "A"
    }

    Promise.all([
      getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId)),
      getProceso(1, 100, options),
    ]).then((responses) => {
      if (responses[0].success && responses[1].success && responses[1].data.length>0) {
        const misUnids = responses[0].data[0].unidad.filter((u: any) => u.selected)
        setUnitList(misUnids.map((unidad: any) => ({
          label: unidad.DesUnid.toUpperCase(),
          value: unidad.codUnidad
        })))
        setProcessList(responses[1].data.map((x: any) => ({label: x.nombre, value: x._id})))
      }
    })
  }, [])

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          {/* <Col>
            <TableSearch onSearch={handleSearch} design={true} />
          </Col> */}
          <Col>
            <Stack direction='vertical' gap={3} className='h-100'>
              <span className='fw-bold me-2'>Proceso:</span>
                <Form.Control as='select' onChange={handleProcessChange} className={'form-select form-select-solid}'} style={{ minWidth: '200px' }}>
                  <option value=''>Seleccione un proceso</option>
                  {processList.map(process => (
                    <option value={process.value} key={process.value}>
                      {process.label}
                    </option>))
                  }
                </Form.Control>
            </Stack>
          </Col>
          <Col>
                <Stack direction='vertical' gap={3} className='h-100'>
                <span className='fw-bold me-2'>Unidad:</span>
                <Form.Control as='select' onChange={handleUnitChange} className={'form-select form-select-solid}'} style={{ minWidth: '200px' }}>
                  <option value=''>Seleccione una unidad</option>
                  {unitList.map(unit => (
                    <option value={unit.value} key={unit.value}>
                      {unit.label}
                    </option>))
                  }
                </Form.Control>
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical' gap={3} >
              <span className='fw-bold'>Buscar</span>
              <TableSearch className='same-height' placeholderSearch="Buscar Nombre de Carga" onSearch={handleSearch} design={true} searchTermDef={searchParams.get('nombre') ?? ''} />
            </Stack>
          </Col>
        </Row>
        <Row className='p-1'>
          <Col>
            <Stack direction='vertical' gap={3} className='h-100'>
              <span className='fw-bold'>Rangos de Fecha de Carga</span>
              <DatePickerBusiness value={Dates} onChange={setDates} />
              {/* <Stack direction='horizontal' gap={3}>
                <Form.Control
                  type='date'
                  placeholder='Inicio'
                  onChange={(e) => setEntryDateFrom(e.target.value)}
                />
                <Form.Control
                  type='date'
                  placeholder='Fin'
                  onChange={(e) => setEntryDateTo(e.target.value)}
                />
              </Stack> */}
            </Stack>
          </Col>
          <Col>
                <Stack direction='vertical' gap={3} className='h-100'>
                  <span className='fw-bold'>Estado Carga:</span>
                  <Form.Control as='select' style={{minWidth:"220px"}} onChange={handleStateChange} className={'form-select form-select-solid}'}>
                    <option value=''>Seleccione Estado</option>
                    {buttonsData.map((option) => (
                      <option key={option.iconName} value={option.stateName} style={{ color: option.color }}>
                        {option.title}
                      </option>
                    ))}
                  </Form.Control>
                </Stack>
          </Col>
          <Col>
                <Stack direction='vertical' gap={3} className='h-100'>
                  <span className='fw-bold'>Estado Firma:</span>
                  <Form.Control as='select' style={{minWidth:"220px"}} onChange={handleStateSignedChange} className={'form-select form-select-solid}'}>
                    <option value=''>Seleccione Estado</option>
                    {buttonsDataSigned.map((option) => (
                      <option key={option.iconName} value={option.stateName} style={{ color: option.color }}>
                        {option.title}
                      </option>
                    ))}
                  </Form.Control>
                </Stack>
          </Col>
          {/* <Col className='d-flex align-self-end justify-content-end'> */}
            {/* <Stack direction='horizontal' gap={2}>
            {permissions.includes('Exportar') && (
              <button type='button' className='btn btn-primary' onClick={handleExport}>
                <KTIcon iconName='file-down' className='fs-2' />
                Exportar
              </button>
            )}
            </Stack> */}
          {/* </Col> */}
        </Row>
      </div>
    </>
  )
}

export { DocumentsListHeader }

