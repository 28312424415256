/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState,useContext,useEffect} from 'react'
import clsx from 'clsx'
import { ColaboradorObservacionContext } from '../components/ColaboradorObservacionContexts'
import {
    toAbsoluteUrl,
    defaultUserInfos,
    MessageModel,
    UserInfoModel,
    messageFromClient,
} from '../../../../../_metronic/helpers'

import {
    HistorialMessageModel,
    defaultMessages
} from '../components/HistorialMessageModel'
import { parseISO, format } from 'date-fns';

type Props = {
    isDrawer?: boolean,
    historialMessage: HistorialMessageModel[],
    idUsuario:string,
    invisibleChat?:boolean,
    idNameUsuario:string
    disabled?: boolean
}

/* const bufferMessages : HistorialMessageModel[] = defaultMessages */

const ChatInner: FC<Props> = ({isDrawer = false,historialMessage,invisibleChat,idUsuario,idNameUsuario, disabled = false}) => {
    const {message, setMessage} = useContext(ColaboradorObservacionContext)
    const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
    //const [message, setMessage] = useState<string>('')
    const [messages, setMessages] = useState<HistorialMessageModel[]>(historialMessage)
    const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)

    
    useEffect(() => {
        setMessages(historialMessage)
    }, [historialMessage])

/* 
    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault()
        sendMessage()
        }
    } */

    return (
        <div
        className='card-body bg-light'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
        >
        <div
            className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies={
            isDrawer
                ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            }
            data-kt-scroll-wrappers={
            isDrawer
                ? '#kt_drawer_chat_messenger_body'
                : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
            }
            data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        >
            {messages.map((message, index) => {
            const state = message.iduser !== idUsuario ? 'dark' : 'primary'
            const templateAttr = {}
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                message.iduser !== idUsuario ? 'start' : 'end'
            } mb-10`
            return (
                <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': false})}
                {...templateAttr}
                >
                    <div
                        className={clsx(
                        'd-flex flex-column align-items',
                        `align-items-${message.iduser !== idUsuario ? 'start' : 'end'}`
                        )}
                    >
                        <div className='d-flex align-items-center mb-2'>
                        {message.iduser !== idUsuario ? (
                            <>
                            <div className='ms-3'>
                                <a
                                href='#'
                                className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                >
                                {message.nameuser}
                                </a>
                                <span className='text-muted fs-7 mb-1'>{format(parseISO(String(message.fecha)), 'dd/MM/yyyy hh:mm a') }</span>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className='me-3'>
                                <span className='text-muted fs-7 mb-1'>{format(parseISO(String(message.fecha)), 'dd/MM/yyyy hh:mm a') }</span>
                                <a
                                href='#'
                                className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                >
                                You
                                </a>
                            </div>
                            </>
                        )}
                        </div>

                        <div
                        className={clsx(
                            'p-5 rounded',
                            `bg-light-${state}`,
                            'text-dark fw-bold mw-lg-400px',
                            `text-${message.iduser !== idUsuario ? 'start' : 'end'}`
                        )}
                        data-kt-element='message-text'
                        dangerouslySetInnerHTML={{__html: message.text}}
                        ></div>
                    </div>
                    </div>
                )
            })}
        </div>
        { !invisibleChat && 
            <div
                className='card-footer pt-4'
                id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            >
                <textarea
                className='form-control form-control-flush mb-3- bg-white'
                rows={2}
                data-kt-element='input'
                placeholder='Escribir Observación'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={disabled}
                /* onKeyDown={onEnterPress} */
                ></textarea>
            </div>
        }
        </div>
    )
}

export {ChatInner}
