import {fetchConToken} from '../helpers/fetch'

const url = 'documentSent/'

const getAllDocumentsSent = async (options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchConToken(`${url}getAll?${queryString}`, {}, 'GET')

  const body = await resp.json()

  return body
}

const getDocumentSentById = async (id: string) => {
  const resp = await fetchConToken(`${url}getById/${id}`, {}, 'GET')

  const body = await resp.json()

  return body
}

const signDocument = async (id: string) => {
  const resp = await fetchConToken(`${url}signDocument/${id}`, {}, 'PUT')

  const body = await resp.json()

  return body
}

const signDocumentCPD = async (id: string) => {
  const resp = await fetchConToken(`${url}signDocumentCPD/${id}`, {}, 'PUT')

  const body = await resp.json()

  return body
}

const signMultipleDocuments = async (documentSentIds: string[]) => {
  const resp = await fetchConToken(`${url}signMultipleDocuments`, {documentSentIds}, 'PUT')

  const body = await resp.json()

  return body
}

const signMultipleDocumentsCPD = async (documentSentIds: string[]) => {
  const resp = await fetchConToken(`${url}signMultipleDocumentsCPD`, {documentSentIds}, 'PUT')

  const body = await resp.json()

  return body
}

const rejectDocument = async (id: string, rejectionMessage: string) => {
  const resp = await fetchConToken(`${url}rejectDocument/${id}`, {rejectionMessage}, 'PUT')

  const body = await resp.json()

  return body
}

const generateDocumentsExcel = async () => {
  try {
    const resp = await fetchConToken(`${url}generate_documents_excel`, {}, 'GET')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const excelBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const excelUrl = URL.createObjectURL(excelBlob)

    window.open(excelUrl, '_blank')
  } catch (error) {
    console.error('Error generating Excel:', error)
  }
}

const viewDocument = async (id: string) => {
  const resp = await fetchConToken(`${url}viewDocument/${id}`, {}, 'PUT')

  const body = await resp.json()

  return body
}

const getDocumentsByFile = async (params: any) => {
  const resp = await fetchConToken(`${url}getDocumentsByFile`, params, 'POST')

  const body = await resp.json()

  return body
}

const getDocumentsReport = async (params: any) => {
  const resp = await fetchConToken(`${url}getDocumentsReport`, params, 'POST')

  const body = await resp.json()

  return body
}

const getDocumentsReportInspector = async (params: any) => {
  const resp = await fetchConToken(`${url}getDocumentsReportInspector`, params, 'POST')

  const body = await resp.json()

  return body
}

//get_documents_report_excel
const getDocumentsReportInExcel = async (params: any) => {
  try {
    const resp = await fetchConToken(`${url}get_documents_report_excel`, params, 'POST')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const excelBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const anchor = document.createElement('a')

    anchor.href = URL.createObjectURL(excelBlob)

    const currentDate = new Date()
    const dd = String(currentDate.getDate()).padStart(2, '0')
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0')
    const yyyy = currentDate.getFullYear()
    anchor.download = `reporte_documentos_${dd}-${mm}-${yyyy}.xlsx`

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (error) {
    console.error('Error generating Excel:', error)
  }
}

const getDocumentsReportInspectorInExcel = async (params: any) => {
  try {
    const resp = await fetchConToken(`${url}get_documents_report_inspector_excel`, params, 'POST')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const excelBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const anchor = document.createElement('a')

    anchor.href = URL.createObjectURL(excelBlob)

    const currentDate = new Date()
    const dd = String(currentDate.getDate()).padStart(2, '0')
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0')
    const yyyy = currentDate.getFullYear()
    anchor.download = `reporte_documentos_${dd}-${mm}-${yyyy}.xlsx`

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (error) {
    console.error('Error generating Excel:', error)
  }
}

const downloadMultipleDocuments = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocuments`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadMultipleDocumentsIds = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocumentsyIds`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadMultipleDocumentsKeyName = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocumentsyKeyName`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadUnicoDocumentsKeyName = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadUnicoDocumentsyKeyName`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadMultipleDocumentsByUser = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocumentsUser`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadMultipleDocumentsByState = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocumentsState`, params, 'POST')

  const body = await resp.json()

  return body
}

const downloadMultipleDocumentsByUserSelected = async (params: any) => {
  const resp = await fetchConToken(`${url}downloadMultipleDocumentsState/selected`, params, 'POST')

  const body = await resp.json()

  return body
}

const getDocumentDetail = async (params: any) => {
  const resp = await fetchConToken(`${url}getDocumentDetail`, params, 'POST')

  const body = await resp.json()

  return body
}

const getMiLegajo = async (options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchConToken(`${url}get_mi_legajo?${queryString}`, {}, 'GET')

  const body = await resp.json()

  return body
}

const cancelDocumentByUser = async (params: {
  userIdSent: string
  fileId: string
  loadId: string
}) => {
  const response = await fetchConToken(`${url}cancel/document/user`, params, 'DELETE')

  return await response.json()
}

export {
  cancelDocumentByUser,
  downloadMultipleDocuments,
  downloadMultipleDocumentsByState,
  downloadMultipleDocumentsByUser,
  downloadMultipleDocumentsByUserSelected,
  downloadMultipleDocumentsIds,
  downloadMultipleDocumentsKeyName,
  downloadUnicoDocumentsKeyName,
  generateDocumentsExcel,
  getAllDocumentsSent,
  getDocumentDetail,
  getDocumentsByFile,
  getDocumentSentById,
  getDocumentsReport,
  getDocumentsReportInExcel,
  getDocumentsReportInspector,
  getDocumentsReportInspectorInExcel,
  getMiLegajo,
  rejectDocument,
  signDocument,
  signDocumentCPD,
  signMultipleDocuments,
  signMultipleDocumentsCPD,
  viewDocument,
}
