import {Col, Container, Dropdown, Form, Row, Stack} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useContext, useEffect, useState} from 'react'
import {PartnerContext} from '../BandejaProcesoContexts'
import {useDebounce} from 'use-debounce'
import {TableSearch} from '../../../../shared/TableSearch'
import {generateExcel, generateSimplifiedExcel} from '../../../../services/fichas'
import { getProceso } from '../../../../services/procesos'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'

const PartnersListHeader = () => {
  const buttonsData = [
    {
      iconName: 'time',
      title: 'Pendiente por Colaborador',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'Completado por Colaborador',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'Aprobado con Pendiente',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Aprobado por Administrador',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'Ficha Siendo Editada',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    // {
    //   iconName: 'exclamation-triangle', // Adjust the icon name
    //   title: 'Proceso Anulado',
    //   stateName: 'procesoAnulado',
    //   color: '#e74c3c', // Red
    // },
    {
      iconName: 'eye',
      title: 'Ficha Observada',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const [processData, setProcessData] = useState([])
  const [idproceso, setIdproceso] = useState("");
  const usuarioJSON = localStorage.getItem('userData');
  let usuario: DataUser|null=null;
  
  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON);
  }

  const {setActiveOnly, setState, setSearchTerm, setSheetId, setEntryDateFrom, setEntryDateTo, openFichaPlantillaModal,setShowCargaExcelModal} =
    useContext(PartnerContext)

    useEffect(() => {
      const options = {
        responsables: usuario?.userId
      }
      getProceso(1,100,options).then((response) => {
        if (response.success) {
          // setIdproceso(response.data[0]._id)
          setProcessData(response.data.map((x:any) => ({label: x.nombre, value: x._id})))
        } else {
          console.log(response.msg)
        }
      })
    },[])
    
  
  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleInputChange = (event: any) => {
    setIdproceso(event.target.value)
  }

  const handleButtonClick = (index: number) => {
    const newActiveButtons = [...activeButtons]
    newActiveButtons[index] = !newActiveButtons[index]
    setActiveButtons(newActiveButtons)
    setState(
      buttonsData
        .map((x, i) => (newActiveButtons[i] ? x.stateName : ''))
        .filter((n) => n.length > 0)
    )
  }

  const handleChange = (e: any) => {
    // setFormData({...formData, [e.target.name]: e.target.value})
    setActiveOnly(e.target.value)
  }

  const handleStateChange = (e: any) => {
    setState(e.target.value)
  }

  const handleClearFilters = () => {

    // setActiveOnly(true) 
    // setState(['']) 
    // setSearchTerm('')
    // setEntryDateFrom(null)
    // setEntryDateTo(null)
  }

  const handleExport = (id: any) => {
    generateExcel()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  const handleSimplifiedExport = (id: any) => {
    generateSimplifiedExcel()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
  }

  
  const handleShowFicha = () => {
    setSheetId("647c0e1f0b0325fc1a8215f5")
    openFichaPlantillaModal()
  }
  const handleShowCargaExcel = () => {
    setSheetId("647c0e1f0b0325fc1a8215f5")
    setShowCargaExcelModal(true)
  }


  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row className='mb-4'>
          <Col>
          <Stack>
            <span className='fw-bold'>Buscar Proceso</span>
            <TableSearch className='same-height' onSearch={handleSearch} design={true} />
          </Stack>
          </Col>
        </Row>
      </div>
    </>
  )
}

export {PartnersListHeader}
