import { fetchConToken } from "../helpers/fetch"

const addWSeleccion = async (data,rol,idproceso,idunidad,tipo,estado) => {
  
    const resp = await fetchConToken(
      `wseleccion/add_data_json/${rol}/${idproceso}/${idunidad}/${tipo}/${estado?estado:null}`,
      data,
      'POST'
    )
  
    const body = await resp.json()
  
    return body
  }

  const updateWSeleccion = async (data,idproceso,idunidad) => {
  
    const resp = await fetchConToken(
      `wseleccion/update_data_json/${idproceso}/${idunidad}}`,
      data,
      'PUT'
    )
  
    const body = await resp.json()
  
    return body
  }

  /* const validateUserUpdateWSekeccuib = async (data,idproceso,idunidad) => {
  
    const resp = await fetchConToken(
      `wseleccion/validate_update_data_json/${idproceso}/${idunidad}}`,
      data,
      'PUT'
    )
  
    const body = await resp.json()
  
    return body
  } */


  const addWSeleccionAsignacionProceso = async (data,rol,idproceso,tipo,estado) => {
  
    const resp = await fetchConToken(
      `wseleccion/add_data_asignacionproceso/${rol}/${idproceso}/${tipo}/${estado?estado:null}`,
      data,
      'POST'
    )
  
    const body = await resp.json()
  
    return body
  }


  const getAllWSeleccion = async (currentPage,numberData,options) => {
    const queryString = new URLSearchParams(options).toString()
  
    const resp = await fetchConToken(
      `wseleccion/get/${currentPage}/${numberData}?${queryString}`,
      {},
      'GET'
    )
  
    const body = await resp.json()
  
    return body
  }

  const addWSeleccionId = async (data) => {
    const resp = await fetchConToken(
      `wseleccion/add_ids`,
      data,
      'POST'
    )
  
    const body = await resp.json()
  
    return body
  }
  
export {
  addWSeleccion,
  updateWSeleccion,
  getAllWSeleccion,
  addWSeleccionAsignacionProceso,
  addWSeleccionId
}