import { fetchConToken } from '../helpers/fetch'

const fichas = async () => {
  const resp = await fetchConToken(`ficha/getfichaproceso`)
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const getSheets = async (query, page, limit) => {
  const resp = await fetchConToken(`ficha/get_sheets?page=${page}&limit=${limit}&query=${query}`)
  const body = await resp.json()

  return body
}

const ficha = async (id) => {
  const resp = await fetchConToken(`ficha/ficha/`, { idficha: id }, 'POST')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const fichasxid = async (id) => {
  const resp = await fetchConToken(`proceso/fichasxproceso/`, { idproceso: id }, 'POST')
  const body = await resp.json()

  /* if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  } */

  return body
}

const busquedaficha = async (busqueda) => {
  const resp = await fetchConToken(`ficha/?nombre=${busqueda}`)
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const procesoficha = async (idproceso, idficha) => {
  const resp = await fetchConToken(`proceso/fichaxproceso/`, { idproceso, idficha }, 'POST')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const nuevaFichaProceso = async (idproceso, idficha) => {
  const resp = await fetchConToken(`proceso/nuevafichaproceso`, { idproceso, idficha }, 'POST')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const eliminarFichaProceso = async (ideliminar) => {
  const resp = await fetchConToken(`proceso/eliminarfichaproceso`, { ideliminar }, 'POST')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const nuevaFicha = async (data) => {
  const resp = await fetchConToken(`ficha/add`, data, 'POST')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const createCompleteSheet = async (data) => {
  const resp = await fetchConToken('ficha/add_complete', data, 'POST')

  const body = await resp.json()
  return body
}

const getCompleteSheet = async (id) => {
  const resp = await fetchConToken(`ficha/get_complete/${id}`, {}, 'GET')

  const body = await resp.json()
  return body
}

const updateCompleteSheet = async (id, data) => {
  const resp = await fetchConToken(`ficha/update_complete/${id}`, data, 'PUT')

  const body = await resp.json()
  return body
}

const deleteCompleteSheet = async (id) => {
  const resp = await fetchConToken(`ficha/delete_complete/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const cloneCompleteSheet = async (id, name = '') => {
  const resp = await fetchConToken(`ficha/clone_complete/${id}?name=${name}`, {}, 'POST')

  const body = await resp.json()
  return body
}

const eliminarficha = async (id) => {
  const resp = await fetchConToken(`ficha/${id}`, {}, 'DELETE')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const actualizarficha = async (data, id) => {
  const resp = await fetchConToken(`ficha/editar/${id}`, data, 'PUT')
  const body = await resp.json()

  if (body.success) {
    localStorage.setItem('token', body.token)
    localStorage.setItem('token-init-date', new Date().getTime())
  }

  return body
}

const generatePDF = async (id) => {
  try {
    const resp = await fetchConToken(`ficha/generate_pdf/${id}`, {}, 'GET')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const pdfBlob = new Blob([uint8Array], { type: 'application/pdf' })

    const anchor = document.createElement('a')

    anchor.href = URL.createObjectURL(pdfBlob)

    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    anchor.download = `ficha_colaborador_${dd}-${mm}-${yyyy}.pdf`;


    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (error) {
    console.error('Error generating PDF:', error)
  }
}

const generateExcel = async (params) => {
  try {
    const resp = await fetchConToken(`ficha/generate_excel`, params, 'POST')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const excelBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const anchor = document.createElement('a')

    anchor.href = URL.createObjectURL(excelBlob)

    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    anchor.download = `reporte_detallado_colaboradores_${dd}-${mm}-${yyyy}.xlsx`;


    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (error) {
    console.error('Error generating Excel:', error)
  }
}

const generateSimplifiedExcel = async (opts = {}) => {
  try {
    const resp = await fetchConToken('ficha/generate_simplified_excel', opts, 'POST')
    const responseData = await resp.json()

    const uint8Array = new Uint8Array(responseData.data)

    const excelBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const anchor = document.createElement('a')

    anchor.href = URL.createObjectURL(excelBlob)

    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    anchor.download = `reporte_simple_colaboradores_${dd}-${mm}-${yyyy}.xlsx`;


    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

  } catch (error) {
    console.error('Error generating Excel:', error)
  }
}

const getSheetsByProcessForDropdown = async (id) => {
  const resp = await fetchConToken(`ficha/getSheetsByProcessDropdown/${id}`)
  const body = await resp.json()

  return body
}

const getSheetsAllByProcessForDropdown = async (id) => {
  const resp = await fetchConToken(`ficha/getSheetsAllByProcessDropdown/${id}`)
  const body = await resp.json()

  return body
}

export {
  fichas,
  nuevaFicha,
  fichasxid,
  ficha,
  eliminarficha,
  actualizarficha,
  procesoficha,
  nuevaFichaProceso,
  eliminarFichaProceso,
  busquedaficha,
  createCompleteSheet,
  getCompleteSheet,
  updateCompleteSheet,
  deleteCompleteSheet,
  cloneCompleteSheet,
  getSheets,
  generatePDF,
  generateExcel,
  generateSimplifiedExcel,
  getSheetsByProcessForDropdown,
  getSheetsAllByProcessForDropdown
}
