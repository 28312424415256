import moment from 'moment'
import React, {createContext, useEffect, useState} from 'react'
import {getMiLegajo} from '../../../services/documentSent'
import {getProcessLikePartner} from '../../../services/procesos'
import {useAuth2} from '../../authv2/Auth2Provider'
import {PreviewDocumentModal} from '../../generales_component/preview-document-modal/PreviewDocumentModal'
import {DocumentSent, Value} from './legajo.types'

export interface Doc {
  uri: string
  fileType: string
  fileName: string
  keyName?: string
}

export interface ExtraFilter {
  proceso: string
  unidad: string
}

interface MiLegajoContextType {
  loadingDocs: boolean
  setLoadingDocs: React.Dispatch<React.SetStateAction<boolean>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  documents: Record<string, Record<string, DocumentSent[]>>
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  previewDoc: Doc[]
  setPreviewDoc: React.Dispatch<React.SetStateAction<Doc[]>>
  openPreviewModal: boolean
  setOpenPreviewModal: React.Dispatch<React.SetStateAction<boolean>>
  dateRange: [Date | null, Date | null]
  setDateRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>
  extraFilter: ExtraFilter
  setExtraFilter: React.Dispatch<React.SetStateAction<ExtraFilter>>
  procesos: any[]
}

export const MiLegajoContext = createContext<MiLegajoContextType>({
  searchTerm: '',
  setSearchTerm: () => {},
  documents: {},
  currentPage: 1,
  setCurrentPage: () => {},
  previewDoc: [],
  setPreviewDoc: () => {},
  openPreviewModal: false,
  setOpenPreviewModal: () => {},
  loadingDocs: false,
  setLoadingDocs: () => {},
  dateRange: [null, null],
  setDateRange: () => {},
  extraFilter: {
    proceso: '',
    unidad: '',
  },
  setExtraFilter: () => {},
  procesos: [],
})

interface MiLegajoContextProps {
  children: React.ReactNode
}

export const MiLegajoContextProvider: React.FC<MiLegajoContextProps> = ({children}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [documents, setDocuments] = useState<Record<string, Record<string, DocumentSent[]>>>({})
  const {userData} = useAuth2()
  const [previewDoc, setPreviewDoc] = useState<Doc[]>([])
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [loadingDocs, setLoadingDocs] = useState(false)
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [extraFilter, setExtraFilter] = useState<ExtraFilter>({
    proceso: '',
    unidad: '',
  })
  const [procesos, setProcesos] = useState<any[]>([])

  useEffect(() => {
    setLoadingDocs(true)

    let startDate = null
    let endDate = null
    if (dateRange) {
      startDate = moment((dateRange as any)?.at(0)).toISOString()
      endDate = moment((dateRange as any)?.at(1)).toISOString()
    }

    getMiLegajo({
      userId: userData?.userId,
      name: searchTerm,
      startDate,
      endDate,
      processId: extraFilter.proceso,
      unidad: extraFilter.unidad,
    })
      .then((response) => {
        if (response.success) {
          setDocuments(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoadingDocs(false)
      })
  }, [searchTerm, dateRange, extraFilter])

  useEffect(() => {
    getProcessLikePartner().then((response) => {
      if (response.success) {
        setProcesos(response.data)
      }
    })
  }, [])

  return (
    <MiLegajoContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        documents,
        currentPage,
        setCurrentPage,
        previewDoc,
        setPreviewDoc,
        openPreviewModal,
        setOpenPreviewModal,
        loadingDocs,
        setLoadingDocs,
        dateRange,
        setDateRange,
        extraFilter,
        setExtraFilter,
        procesos,
      }}
    >
      {children}
      <PreviewDocumentModal
        documentos={previewDoc}
        setIsPreviewDocumentModalOpen={setOpenPreviewModal}
        isPreviewDocumentModalOpen={openPreviewModal}
        title='Previsualizar documento'
      />
    </MiLegajoContext.Provider>
  )
}
