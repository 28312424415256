import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTable } from 'react-table'
import { KTCard, KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { ConverDocx, addFileProcess, deleteFile, downloadFileS3 } from '../../../../services/files'
import { getProcesoDocumentByClient } from '../../../../services/procesos'
import { showDeleteMessage, showInfoHtml, showMessage } from '../../../../shared/Alerts'
import { useQueryResponseData } from '../../../apps/user-management/users-list/core/QueryResponseProvider'
import { usersColumns } from '../../../apps/user-management/users-list/table/columns/_columns'
import { useAuth2 } from '../../../authv2/Auth2Provider'
import DropzoneArchivoUnico from '../components/DropzoneArchivoUnico'
import { Proceso, ProcessFiles, ProcessSheet } from '../documentInterface'
import OverlayLoading from './../components/OverlayLoading'
import { PartnerEditContext } from './PartnerEditContext'
import { TagsEditModal } from './show-search-tag-modal/TagsEditModal'
import './styles.css'
import { LeyendaModal } from './show-leyenda-modal/LeyendaModal'
import { DetailAprobacionModal } from './show-detail-aprobacion-modal/DetailAprobacionModal'
import { GoBackButton } from '../../../generales_component/GoBackButton'

const fichasBreadcrumbs = [
  {
    title: 'Configuración',
    path: '/configuracion/documentos_por_cliente',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DocumentClienteEdit = () => {
  const navigate = useNavigate()
  const users = useQueryResponseData()
  const {id, idCliente} = useParams()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps} = useTable({columns, data})
  const {userData} = useAuth2()

  const [process_id, setProcessId] = useState('')
  const [showLeyenda, setShowLeyenda] = useState(false)
  const [showDetailAprobacion, setShowDetailAprobacion] = useState(false)
  const [isTagsModalOpen, setTagsModalStatus] = useState(false)
  const [processSheetData, setProcessSheetData] = useState<ProcessSheet[]>([])
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [title, setTitle] = useState('Nueva Carpeta')
  const [procesData, setProcesData] = useState<Proceso>({
    _id: '',
    nombre: '',
    codigo: '',
    descripcion: '',
    estado: '',
    codUnid: '',
  })
  const [processFile, setProcessFile] = useState<ProcessFiles>({
    _id: '',
    idproceso: '',
    name: '',
    file: '',
    filename: '',
    keyname: '',
    number_aprobacion:0 ,
    type: 'PLANTILLA',
    type_document: 'KIT DE INGRESO',
    codUnid: '',
    detalle_niveles:[]
  })
  const [processFiles, setProcessFiles] = useState<ProcessFiles[]>([])
  const [showForm, setShowForm] = useState(true)
  const [editButton, setEditButton] = useState(false)
  const [acceptExt, setAcceptExt] = useState('.docx')
  const [shouldClear, setShouldClear] = useState(false)
  const [cargandoFile, setCargandoFile] = useState(false)
  const [unidad, setUnidad] = useState<any>(null)

  useEffect(() => {
    if (id && idCliente) {
      setTitle('Proceso Documento')
      setProcessFile({...processFile, idproceso: id, codUnid: idCliente})
      handleGetDocument(id)
    }
  }, [])

  const handleClearComplete = () => {
    setShouldClear(false)
  }

  const openTagsModal = () => {
    setTagsModalStatus(true)
  }

  const closeTagsModal = () => {
    setTagsModalStatus(false)
  }

  const valueContext = {
    process_id,
    setProcessId,
    processSheetData,
    setProcessSheetData,
    processFile, 
    setProcessFile,
    isTagsModalOpen,
    openTagsModal,
    closeTagsModal,
    showLeyenda,
    setShowLeyenda,
    showDetailAprobacion,
    setShowDetailAprobacion
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if(ValidarNiveles()){
      if (id) {
        if(processFile.file !== ''){
          handleSaveProcessFile(processFile)
        }else{
          showMessage('error', 'Hubo un error', "Debe Seleccionar un Archivo")
        }
      }
    }else{
      showMessage('info', 'Faltan Datos', "Debe Completar los niveles de aprobacion")
    }
    
  }

  const ValidarNiveles = (): boolean => {
    let result = false;
    if (processFile.number_aprobacion == processFile.detalle_niveles.length){
      result = true
    }
    return result
  }

  const handleSaveProcessFile = (options: any) => {
    console.log(options)
    setLoading(true)
    addFileProcess(options)
      .then((response) => {
        if (response.success) {
          setProcessFiles(response.data)
          limpiarCampos()
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        limpiarCampos()
        setLoading(false)
        showMessage('error', 'Hubo un error', error.message)
      })
  }

  const limpiarCampos = () => {
    setProcessFile({
      _id: '',
      idproceso: id ? id : '',
      name: '',
      file: '',
      filename: '',
      keyname: '',
      number_aprobacion:0 ,
      type: 'PLANTILLA',
      type_document: 'KIT DE INGRESO',
      codUnid: idCliente ? idCliente : '',
      detalle_niveles:[]
    })
    setAcceptExt('.docx')
    setShouldClear(true)
    setEditButton(false)
  }

  const handleGetDocument = (id: string) => {
    setFormLoading(true)
    getProcesoDocumentByClient(id, idCliente, userData.userId)
      .then((response) => {
        setUnidad(response.data.unidad)
        setProcesData(response.data.proceso[0])
        setProcessFiles(response.data.documentos)
        setFormLoading(false)
      })
      .catch((error) => console.log(error))
  }

  const handleChange = (e: any) => {
    //setProcesData({...procesData, [e.target.name]: e.target.value})
  }

  const handleChangeDocument = (e: any) => {
    setProcessFile({...processFile, [e.target.name]: e.target.value})
  }

  const handleDocumentSelect = (e: any) => {
    const val = e.target.value
    if (val === 'PLANTILLA') {
      setAcceptExt('.docx')
      setShowForm(true)
    } else {
      setAcceptExt('.pdf')
      setShowForm(false)
    }

    setProcessFile({...processFile, type: val})
  }

  const handleDocumentTypeSelect = (e: any) => {
    const val = e.target.value
    setProcessFile({...processFile, type_document: val})
  }

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo.'))
      }

      reader.readAsDataURL(file)
    })
  }

  /* const handleFileChange = async (e:any)  => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase();
      const base64 = await fileToBase64(selectedFile);
      
      if (fileName.endsWith('.pdf')) {        
        setSelectedFile(selectedFile);
      }else if(fileName.endsWith('.docx') || fileName.endsWith('.doc')){
        setSelectedFile(selectedFile);
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await ConverDocx(formData)
        if (response.status === 200) {
          const buffer = await response.arrayBuffer();
          const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = "fileName.docx"; // Nombre del archivo
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Error al descargar el archivo');
        }
        console.log('Archivo Word seleccionado:', selectedFile);
      } else {
        alert('Por favor, seleccione un archivo PDF válido.');
        e.target.value = null;
      }
      
      setProcessFile({...processFile,file:String(base64),filename:fileName})
    }
    
  };
 */

  const handleFileUnicoChange = async (file: File | null) => {
    const selectedFile = file
    setCargandoFile(true)
    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase()
      const base64 = await fileToBase64(selectedFile)

      if (fileName.endsWith('.pdf')) {
        setCargandoFile(true)
        //setSelectedFile(selectedFile);
      } else if (fileName.endsWith('.docx') || fileName.endsWith('.doc')) {
        //setSelectedFile(selectedFile);
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('idproceso', processFile.idproceso)
        const response = await ConverDocx(formData)
        if (response.success) {
          console.log(response)
          let codigoHTML = ''
          if (!Object.keys(response.data).length) {
            codigoHTML += 'No se encontraron <b>Etiquetas</b> en la plantilla '
          } else {
            codigoHTML += `Se encontraron en la plantilla lo siguiente <b>Etiquetas</b><br/>
                                <div class="centrar-tabla" >
                                <table border="1">
                                <thead>
                                    <tr>
                                        <th>Etiqueta</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody> `
            for (const key of Object.keys(response.data)) {
              codigoHTML +=
                '<tr><td>' +
                key.replace(/\\/g, '') +
                '</td><td>' +
                response.data[key] +
                '</td></tr>'
            }
            codigoHTML += '</tbody> </table> </div>'
          }
          showInfoHtml('info', response.message, codigoHTML)
          setCargandoFile(false)
        } else {
          setCargandoFile(false)
          console.error('Error al descargar el archivo')
        }
        console.log('Archivo Word seleccionado:', selectedFile)
      } else {
        setCargandoFile(false)
        alert('Por favor, seleccione un archivo PDF válido.')
        //e.target.value = null;
      }

      setProcessFile({...processFile, file: String(base64), filename: fileName})
    }
    setCargandoFile(false)
  }

  const handleDownload = async (keyname: string, filename: string) => {
    const partes = filename.split('.')
    const extension = partes[partes.length - 1]
    setCargandoFile(true)

    downloadFileS3(keyname, extension)
      .then((response) => {
        if (response.success) {
          const link = document.createElement('a')
          link.href = response.data
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
        setCargandoFile(false)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        showMessage('error', 'Hubo un error', error.message)
        setCargandoFile(false)
        setLoading(false)
      })
  }

  const handleView = (id: any, keyname: string, filename: string) => {
    // if(extension==="pdf"){
    const url = `/configuracion/documentos/view/${id}`
    window.open(url, '_blank')
    /* }else{
      downloadFileS3(keyname,extension)
        .then((response) => {
            if (response.success) {
                const link = document.createElement('a');
                link.style.display = 'none'; 
                link.href = response.data;
                link.download = filename;
                document.body.appendChild(link);
                link.click();                
                document.body.removeChild(link);
                link.addEventListener('load', () => {
                  window.open(response.data, '_blank');
                });
            }

        })
        .catch((error) => {
            console.log(error)
        })
    } */
  }

  const handleUpdate = (dat: any) => {
    setProcessFile({
      ...dat,
      type_document: dat.type_document ? dat.type_document : 'KIT DE INGRESO',
    })
    if (dat.type === 'PLANTILLA') {
      setAcceptExt('.docx')
      setShowForm(true)
    } else {
      setAcceptExt('.pdf')
      setShowForm(false)
    }
    setEditButton(true)
  }

  const handleDelete = (id: any, idproceso: any) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteFile(id)
          .then((response) => {
            if (response.success) {
              handleGetDocument(idproceso)
              showMessage('success', '¡Buen trabajo!', response.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
  }

  const handleShowEtiquetas = (id: any) => {
    setProcessId(id)
    openTagsModal()
  }

  const handleListDocument = () => {
    navigate('/configuracion/documentos_por_cliente')
  }

  return (
    <>
      <PartnerEditContext.Provider value={valueContext}>
        <PageTitle breadcrumbs={fichasBreadcrumbs}>{title}</PageTitle>
        <GoBackButton goBack={handleListDocument} />
        <KTCard>
          {formLoading ? (
            <div className='pt-12 pb-6'>
              <Container>
                <Row className='mb-3'>
                  <Col sm>
                    <Stack gap={2}>
                      <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                      <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                    </Stack>
                  </Col>
                  <Col sm>
                    <Stack gap={2}>
                      <Skeleton height={20} width={52} duration={1} style={{borderRadius: '5px'}} />
                      <Skeleton height={44} duration={1} style={{borderRadius: '10px'}} />
                    </Stack>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col sm>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-end'>
                        <Skeleton
                          height={20}
                          width={139}
                          duration={1}
                          style={{borderRadius: '5px'}}
                        />
                      </div>
                      <Skeleton
                        height={44}
                        width={120}
                        duration={1}
                        style={{borderRadius: '10px'}}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className='mb-6'>
                  <Col sm>
                    <Skeleton height={195} duration={1} style={{borderRadius: '10px'}} />
                  </Col>
                </Row>

                <Row>
                  <div className='d-flex justify-content-end'>
                    <Skeleton
                      height={44}
                      width={102}
                      duration={1}
                      className='mx-3'
                      style={{borderRadius: '10px'}}
                    />
                    <Skeleton height={44} width={96} duration={1} style={{borderRadius: '10px'}} />
                  </div>
                </Row>
              </Container>
            </div>
          ) : (
            <Container>
              <Row className='mb-3 mt-5'>
                <Row>
                  <Col sm>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-end'>
                        <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>
                          Información de Proceso
                        </Form.Label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Col sm>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      required
                      placeholder='Ingrese un nombre'
                      name='name'
                      value={procesData.nombre}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col sm>
                  <Form.Group controlId='formCliente'>
                    <Form.Label>Unidad</Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      required
                      placeholder='Ingrese un nombre'
                      name='unidad'
                      value={unidad?.DesUnid}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col sm>
                  <Form.Group controlId='formDescripcion'>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      placeholder='Ingrese una descripción'
                      name='description'
                      value={procesData.descripcion}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='custom-row mb-3 pt-3 px-3'>
                <Form onSubmit={handleSubmit}>
                  <Col sm>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-end'>
                        <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>
                          Agregar de Documento
                        </Form.Label>
                      </div>
                    </div>
                  </Col>
                  <Row className='mb-3'>
                    <Col sm>
                      <Form.Group controlId='formNombre'>
                        <Form.Label>Ingresa nombre de documento</Form.Label>
                        <Form.Control
                          type='text'
                          required
                          placeholder='Ingrese un nombre'
                          name='name'
                          value={processFile.name}
                          onChange={handleChangeDocument}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col sm>
                    <Stack direction='vertical' gap={2}>  
                      <Form.Group controlId='formNombre'>
                        <Form.Label>
                          Tipo de flujo del documento
                          <i
                            onClick={()=>{setShowLeyenda(true)}}
                            className={`fas fa-exclamation-circle ms-2 fs-5 text-primary`}
                            data-tooltip-id={`my-tooltip-1`}
                          >
                          </i>
                          <ReactTooltip
                            id={`my-tooltip-1`}
                            place="bottom"
                            variant={`info`}
                            content={`Ver leyenda, dar click aqui`}
                          />
                        </Form.Label>
                        <Form.Control
                          as='select'
                          name='type'
                          className={'form-select form-select-solid}'}
                          value={processFile.type_document}
                          onChange={handleDocumentTypeSelect}
                          required
                        >
                          {/* <option value=''>Seleccione un campo</option>   */}
                          <option key={1} value={'KIT DE INGRESO'}>{'KIT DE INGRESO'}</option>
                          <option key={2} value={'DOCUMENTOS GENERALES'}>{'DOCUMENTOS GENERALES'}</option>
                          <option key={3} value={`CPD`}>{`CPD`}</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId='formCantidad'>
                        <Form.Label>
                          Cantidad de aprobaciones
                          <i
                            className={`fas fa-exclamation-circle ms-2 fs-5 text-primary`}
                            data-tooltip-id={`my-tooltip-2`}
                          >
                          </i>
                          <ReactTooltip
                            id={`my-tooltip-2`}
                            place="bottom"
                            variant={`info`}
                            content={`Ingrese cantidad de niveles requeridos para que el documento se aprobado`}
                          />
                        </Form.Label>
                        <Stack direction='horizontal' gap={2}> 
                            <Form.Control
                              type='number'
                              required
                              placeholder='Ingrese un cantidad'
                              name='number_aprobacion'
                              value={processFile.number_aprobacion}
                              onChange={handleChangeDocument}
                            />
                            
                            { processFile.detalle_niveles.length > 0 ? 
                                <>
                                  <Button
                                    type='button'
                                        disabled={processFile.number_aprobacion>0?false:true}
                                    data-tooltip-id={`my-tooltip-3`}
                                    onClick={() => setShowDetailAprobacion(true)}
                                    className="btn btn-primary">                                  
                                      <KTIcon iconName='pencil' className='fs-3' /> 
                                  </Button>
                                  <ReactTooltip
                                    id={`my-tooltip-3`}
                                    place="bottom"
                                    variant={`info`}
                                    content={`Click para añadir detalle de los niveles de aprobación`}
                                  />
                                </>
                              :  
                                <>
                                  <Button
                                    type='button'
                                    disabled={processFile.number_aprobacion>0?false:true}
                                    data-tooltip-id={`my-tooltip-3`}
                                    onClick={() => setShowDetailAprobacion(true)}
                                    className="btn btn-primary">                                  
                                      <KTIcon iconName='plus' className='fs-3' /> 
                                  </Button>
                                  <ReactTooltip
                                    id={`my-tooltip-3`}
                                    place="bottom"
                                    variant={`info`}
                                    content={`Click para añadir detalle de los niveles de aprobación`}
                                  />
                                </>
                            }
                            
                            
                          </Stack>
                      </Form.Group>
                    </Stack>
                      
                    </Col>
                    <Col sm>
                      <Row>
                        <Col sm>
                          <Form.Group controlId='formNombre'>
                            <Form.Label>Formato de documento</Form.Label>
                            <Form.Control
                              as='select'
                              name='type'
                              className={'form-select form-select-solid}'}
                              value={processFile.type}
                              onChange={handleDocumentSelect}
                              required
                            >
                              {/* <option value=''>Seleccione un campo</option>   */}
                              <option key={1} value={'PLANTILLA'}>
                                {'PLANTILLA'}
                              </option>
                              <option key={2} value={'PDF'}>
                                {'PDF'}
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        {showForm && (
                          <Col sm>
                            <Form.Group>
                              <Form.Label>Ver Etiquetas</Form.Label>
                            </Form.Group>
                            <Button
                              type='button'
                              onClick={() => handleShowEtiquetas(procesData._id)}
                              className='btn btn-primary'
                            >
                              <KTIcon iconName='eye' className='fs-3' /> Mostrar
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>

                    <Col sm>
                      <DropzoneArchivoUnico
                        key={processFile._id}
                        onSelected={(files) => handleFileUnicoChange(files ? files : null)}
                        shouldClear={shouldClear}
                        onClear={handleClearComplete}
                        acceptType={acceptExt}
                        infoArchivo={{url: processFile.file, name: processFile.filename}}
                        idx={processFile._id}
                      />
                      {cargandoFile && <OverlayLoading />}

                      {/* <Form.Group controlId="fileUpload">
                          <Form.Label>Seleccionar archivo:</Form.Label>
                          <Form.Control type="file" value={processFile.filename ? processFile.filename : ''} accept={acceptExt} onChange={handleFileChange} />
                        </Form.Group> */}
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <div className='d-flex justify-content-start'>
                        <Button
                          variant='danger'
                          onClick={() => {
                            handleListDocument()
                          }}
                        >
                          Regresar
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className='d-flex justify-content-end'>
                        <Button variant='primary' type='submit'>
                          {!loading && !editButton && 'Subir Archivo'}
                          {!loading && editButton && 'Actualizar Archivo'}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Subiendo...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </Col>

                    {/* <Col sm>
                        <Button variant="primary" type="submit">
                          Subir Archivo
                        </Button>                    
                      </Col> */}
                  </Row>
                </Form>
              </Row>
              <Row className='mb-3 mt-5'>
                <Col sm>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-end'>
                      <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>
                        Listado de Documentos
                      </Form.Label>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className='mb-6'>
                <Col sm>
                  <div>
                    <div className='table-responsive'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='text-start'>Nombre</th>
                            <th className='text-start'>Formato</th>
                            <th className='text-start'>Tipo</th>
                            <th className='text-end'>Accioness</th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                          {processFiles.length > 0 ? (
                            processFiles.map((item: any) => (
                              <Fragment key={item._id}>
                                <tr>
                                  <td className='text-start min-w-200px'>
                                    {item.type === 'PLANTILLA' ? (
                                      <img
                                        alt={item.filename}
                                        src={toAbsoluteUrl('/media/svg/files/doc.svg')}
                                        className='h-20px mx-10'
                                      />
                                    ) : (
                                      <img
                                        alt={item.filename}
                                        src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                                        className='h-20px mx-10'
                                      />
                                    )}
                                    {item.name}
                                  </td>
                                  <td className='text-start min-w-50px'>{item.type}</td>
                                  <td className='text-start min-w-50px'>{item.type_document}</td>
                                  <td className='text-end'>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                      <a
                                        href='#d'
                                        onClick={() => handleDownload(item.keyname, item.filename)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTIcon iconName='cloud-download' className='fs-3' />
                                      </a>
                                      <a
                                        href='#v'
                                        onClick={() =>
                                          handleView(item._id, item.keyname, item.filename)
                                        }
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTIcon iconName='eye' className='fs-3' />
                                      </a>
                                      <a
                                        href='#u'
                                        onClick={() => handleUpdate(item)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      >
                                        <KTIcon iconName='pencil' className='fs-3' />
                                      </a>
                                      <a
                                        href='#del'
                                        onClick={() => handleDelete(item._id, item.idproceso)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      >
                                        <KTIcon iconName='trash' className='fs-3' />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  No matching records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </KTCard>
        <TagsEditModal />
        <LeyendaModal />
        <DetailAprobacionModal />
      </PartnerEditContext.Provider>
    </>
  )
}

export { DocumentClienteEdit }

