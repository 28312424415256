import ExcelJS from 'exceljs'
import React, {useContext, useEffect, useState} from 'react'
import {Col, Container, Form, Row, Stack} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useDropzone} from 'react-dropzone'
import {showInfoHtml, showMessage, showValidatePassword} from '../../../../../../shared/Alerts'
import {DataUser} from '../../../../../documentacion/MiFicha/DataUserInterface'
import OverlayLoading from '../../../../../generales_component/OverlayLoading'

import './styles.css'
import { PartnerContext } from '../../../PartnerContexts'
import { addFieldExternalData } from '../../../../../../services/external'


function quitarTildes(texto: string) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const ShowCargaExcelModalForm: React.FC = () => {
  const buttonsData = [
    {
      iconName: 'time',
      title: 'Pendiente por Colaborador',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'Completado por Colaborador',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'Aprobado con Pendiente',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Aprobado por Administrador',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'Historico',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'Ficha Siendo Editada',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    // {
    //   iconName: 'exclamation-triangle', // Adjust the icon name
    //   title: 'Proceso Anulado',
    //   stateName: 'procesoAnulado',
    //   color: '#e74c3c', // Red
    // },
    {
      iconName: 'eye',
      title: 'Ficha Observada',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]
  const {externalData,setShowCargarPlantillaExcel,setReloadServiceExternal} = useContext(PartnerContext)
  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const [file, setFile] = useState<File | null>(null)
  const [rol, setRol] = useState('POST')
  const [estado, setEstado] = useState('')
  const [afterField, setAfterField] = useState('-')
  const [idproceso, setIdproceso] = useState('')
  const [idunidad, setIdunidad] = useState('')
  
  const [cargandoData, setCargandoData] = useState(false)
  const [processData, setProcessData] = useState([])
  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  useEffect(() => {
    readFile()
  }, [file])

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0] || null)
  }

  const readFile = async () => {
    if (file ) {
      setCargandoData(true)
      const datErroneo: {celda: string; value: string}[] = []
      let LabelGrupo = ""
      const arrayHeader: string[] = []
      const arrayObjectCampos: any[] = []
      const workbook = new ExcelJS.Workbook()
      const reader = new FileReader()

      reader.onload = async (e: any) => {
        const arrayBuffer = e.target.result
        await workbook.xlsx.load(arrayBuffer)
        // Supongamos que el archivo Excel tiene una sola hoja
        const worksheet = workbook.getWorksheet(1)
        // Accede a los datos de la hoja y procesa según tus necesidades
        worksheet.eachRow((row, rowNumber) => {
          const obj: {[key: string]: string} = {}
          const lastColumnNumber = row.cellCount
          row.eachCell((cell, colNumber) => {
            let exist = false
            if (rowNumber === 1) {
              LabelGrupo = cell.text
            }
            if (rowNumber === 2) {
              arrayHeader.push(cell.text)
            }
            const isLastCell = colNumber === lastColumnNumber
            // Validando Campos
            if (rowNumber > 2) {
              
              obj[arrayHeader[colNumber - 1]] = cell.text
            }
          })
          if (rowNumber > 2) {
            arrayObjectCampos.push(obj)
          }
        })

        if (datErroneo.length > 0) {
          setCargandoData(false)
          let codigoHTML = ''
          codigoHTML += `Se encontraron campos que no son admisibles, Verificar los campos que sean correctos <br/>
                              <div class="centrar-tabla" >
                              <table border="1">
                              <thead>
                                  <tr>
                                    <th>Celda</th>
                                    <th>Campos</th>
                                  </tr>
                              </thead>
                              <tbody> `
          for (const dat of datErroneo) {
            codigoHTML += '<tr><td>' + dat.celda + '</td><td>' + dat.value + '</td></tr>'
          }
          codigoHTML += '</tbody> </table> </div>'
          showInfoHtml('info', 'Campos no admisible', codigoHTML)
        } else {
          const option = {
                          fields:{label:LabelGrupo,fields:arrayObjectCampos},
                          after_field:afterField
                        }
          // console.log(option)
          await addFieldExternalData(
              externalData._id,
              option
            )
            .then((response) => {
              if (response.success) {
                showMessage('success', 'Registro Correctamente', response.message)
              } else {
                showMessage('error', 'Hubo un Error', response.message)
              }
              setCargandoData(false)
              setReloadServiceExternal(true)
            })
            .catch((error) => {
              console.log(error)
              setCargandoData(false)
            })
            
            setShowCargarPlantillaExcel(false)
        }
      }
      reader.readAsArrayBuffer(file)
    }
    
  }

  const handleInputChange = (event: any) => {
    setIdproceso(event.target.value)
  }

  const handleInputChangeUnidad = (event: any) => {
    setIdunidad(event.target.value)
  }

  const handleStateChange = (e: any) => {
    setEstado(e.target.value)
  }

  const handleChangeAfterField = (e: any) => {
    setAfterField(e.target.value)
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'application/xlsx': ['.xlsx'],
    },
    maxFiles: 1,
  })

  return (
    <>
      <Container className='mt-1'>
        <Row>
          {externalData.fields.length ===0?
            <Col className='mb-6'>
              <span className='fw-bold'>Cargar Nuevo Grupo de Campos :</span>
            </Col>
          :
            <Col className='mb-6'>
              <span className='fw-bold'>Cargar grupo de campos despues de :</span>
              <Stack className=' mt-3' direction='horizontal' gap={3}>
                <Form.Select onChange={handleChangeAfterField}>
                <option value="-">Seleccion grupo de campos</option>
                  {externalData.fields.map(field => (                    
                    <option value={field.label}>{field.label}</option>
                  ))}
                </Form.Select>
              </Stack>
            </Col>
          }
          <Col sm={12}>
            <div className=''>
              {
                <div
                  {...getRootProps()}
                  className={`elevator-with-shadow dropzone ${isDragActive ? 'active' : ''}`}
                >
                  <input {...getInputProps()} />
                  <i className='bi bi-cloud-upload fs-4x'></i>
                  {isDragActive ? (
                    <p>Arrastra y suelta los archivos aquí...</p>
                  ) : (
                    <p>Arrastra archivos aquí o haz clic para seleccionar</p>
                  )}
                  <button className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'>
                    Seleccionar
                  </button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
      {cargandoData && <OverlayLoading />}
    </>
  )
}

export {ShowCargaExcelModalForm}
