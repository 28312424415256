import {Fragment, useContext, useEffect, useState} from 'react'
import {Stack} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {deleteAlert, getAllAlerts} from '../../../../services/alerts'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {showDeleteMessage, showMessage} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {AlertsContext} from '../AlertsContexts'
import {WeekDaysRow} from '../component/WeekDay'
import { TableBusiness } from '../../../generales_component/table/TableBusiness'

const AlertsTable = () => {
  const navigate = useNavigate()
  const [alertList, setAlertList] = useState([])
  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const {searchTerm, processId} = useContext(AlertsContext)

  useEffect(() => {
    getAlerts(currentPage)
  }, [searchTerm, processId])

  const getAlerts = (page: number) => {
    const params = {
      page,
      limit: 10,
      searchTerm,
      processId
    }

    setLoadingStatus(true)

    getAllAlerts(params)
      .then((response) => {
        if (response.success) {
          console.log(response.data)
          setLoadingStatus(false)
          setAlertList(response.data)
          setTotalPages(response.totalPages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleUpdate = (id: string) => {
    navigate(`/configuracion/alertas/edit/${id}`)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteAlert(id)
          .then((response) => {
            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.error)
            }
            getAlerts(currentPage)
          })
          .catch((error) => {
            showMessage('error', 'Hubo un error', error.message)
          })
      }
    })
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    getAlerts(page)
  }
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  const getFrequency = (frequency: string) => {
    if (frequency === 'daily') {
      return 'Diaria'
    }

    if (frequency === 'custom') {
      return ''
    }
  }

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  return (
    <>
      <KTCardBody>
        <div className='table-responsive'>
          <TableBusiness
            checkbox={{include: false}}
            headers={[
              {key: 'description', value: 'Nombre'},
              {key: 'DesUnid', value: 'Unidad'},
              {key: 'processName', value: 'Proceso'},
              {key: 'sheetName', value: 'Hoja'},
              {key: 'frequency', value: 'Frecuencia'},
              {key: 'hour', value: 'Hora/Minuto'},
              {key: 'actions', value: 'Opciones'},
            ]}
          >
            {alertList?.length > 0 ? (
              alertList?.map((item: any, index: number) => (
                <Fragment key={item._id}>
                  <tr>
                    {/* <td className='text-start min-w-100px'>{item._id}</td> */}
                    <td className='text-start min-w-250px p-2'>{item.description}</td>
                    <td className='text-start min-w-250px p-1'>{item.unidad?.DesUnid}</td>
                    <td className='text-start min-w-200px p-1'>{item.processName}</td>
                    <td className='text-start min-w-250px'>{item.sheetName}</td>
                    <td className='text-start min-w-150px'>
                      {
                        <>
                          <WeekDaysRow
                            enabledDays={
                              item.frequency === 'custom'
                                ? item.days.map((day: any) => day.charAt(0))
                                : item.frequency === 'daily'
                                ? ['L', 'M', 'X', 'J', 'V', 'S', 'D']
                                : []
                            }
                          />
                        </>
                      }
                    </td>
                    <td className='text-start min-w-100px'>
                      {item.hour}:{item.minute}
                    </td>
                    <td className='min-w-100px text-end d-flex align-self-end justify-content-end'>
                      <Stack direction='horizontal' gap={1}>
                        {permissions.includes('Editar Alerta') && (
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            title='Editar Alerta'
                            onClick={() => handleUpdate(item._id)}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {permissions.includes('Eliminar Alerta') && (
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            title='Eliminar Alerta'
                            onClick={() => handleDelete(item._id)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </Stack>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </TableBusiness>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {AlertsTable}
