import ExcelJS from 'exceljs'
import React, {FC, Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {Col, Dropdown, Form, Row, Stack} from 'react-bootstrap'
import {useTable} from 'react-table'
import {Tooltip as ReactTooltip, VariantType} from 'react-tooltip'
import {KTCardBody, KTIcon} from '../../../_metronic/helpers'
import {formatDateToDMY, formatISODateToDMYHM} from '../../helpers/dateFunctions'
import {getMissingTags} from '../../services/documentos'
import {showMessage} from '../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../shared/hooks/useGlobalOverlayLoading'
import Pagination from '../../shared/Pagination'
import {UsersListLoading} from '../apps/user-management/users-list/components/loading/UsersListLoading'
import {useQueryResponseData} from '../apps/user-management/users-list/core/QueryResponseProvider'
import {usersColumns} from '../apps/user-management/users-list/table/columns/_columns'
import {DataUser} from '../documentacion/MiFicha/DataUserInterface'
import {CustomTooltip} from './CustomTooltip'
import './styles_general.css'

interface MissingTags {
  idFile: string
  missingTags: string[]
  keyname: string
  filename: string
}

interface MyComponentProps {
  theader: {type: string; field: string; label: string}[]
  tbody: {[key: string]: any}[]
  tMaxHeight?: string
  title: string
  pagination?: boolean
  checksAll?: boolean
  theaderVisible?: boolean
  searchVisible?: boolean
  searchHint?: string
  searchCol?: number
  rangeDateVisible?: boolean
  rangeDateCol?: number
  filterKeyDate?: string[]
  rangeDateTitulo?: string
  filterSelect?: boolean
  dataSelect?: any[]
  dataSelectTitulo?: string
  filterSelectCol?: number
  filterKeySelect?: string[]
  classTable?: string
  setValueSearch?: (value:string) => void
  valueSearch?: string
  onCheckSelected?: (Ids: string[], checked?: boolean) => void
  onBtnSelected?: (Item: any, Accion: string) => void
  onBtnExport?: (Accion: string) => void
  processId?: string
  fetchTags?: boolean
  usersSelected?: any[]
  setGlobalMisingTags?: (missingTags: MissingTags[]) => void
  setDocs?: (docs: Map<string, Record<string, any>[]>) => void
  downloadTags?: () => void
  setLoadingTags?: (loading: boolean) => void
  isEdit?: boolean
}

const PartnersTableGeneral: FC<MyComponentProps> = (props) => {
  const {
    theader,
    tbody,
    tMaxHeight,
    title,
    pagination,
    checksAll,
    theaderVisible,
    searchVisible,
    searchCol,
    rangeDateVisible,
    rangeDateCol,
    filterKeyDate,
    searchHint,
    rangeDateTitulo,
    filterSelect,
    dataSelect,
    filterSelectCol,
    filterKeySelect,
    dataSelectTitulo,
    classTable,
    processId = '',
    setValueSearch,
    valueSearch,
    onCheckSelected,
    onBtnSelected,
    onBtnExport,
    fetchTags = false,
    usersSelected = [],
    setDocs = () => {},
    setGlobalMisingTags = () => {},
    downloadTags = () => {},
    setLoadingTags = () => {},
    isEdit = false,
  } = props
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const usuarioJSON = localStorage.getItem('userData')
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setLoadingStatus] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [show, setShow] = useState(false)
  const [selecto, setSelecto] = useState(0)
  const [typeSelect, setTypeSelect] = useState(0)
  const [tbodyData, setTbodyData] = useState<{[key: string]: any}[]>(tbody)
  const [tbodyDataAux, setTbodyDataAux] = useState<{[key: string]: any}[]>(tbody)
  const [updated, setUpdated] = useState(false)
  const [meliminar, setMeliminar] = useState(false)
  const [eliminar, setEliminar] = useState('')
  const [dateInit, setDataInit] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [missingTags, setMissingTags] = useState<MissingTags[]>(
    fetchTags
      ? []
      : tbody.map((item) => ({
          idFile: item._id,
          missingTags: [],
          keyname: item.keyname,
          filename: item.filename,
        }))
  )
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const excelRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setTbodyData(tbody)
    setTbodyDataAux(tbody)
    handleChangeSelectChildren(tbody)
    if(valueSearch){
      if(valueSearch.length>0){
        handleSearchChangeDefault(valueSearch)
      }
    }
  }, [tbody])

  useEffect(() => {
    handleRangeDate()
  }, [dateInit, dateEnd])
  useEffect(() => {
    orderData(selecto, typeSelect)
  }, [selecto, typeSelect])

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []
    // Generate "Previous" link if not on the first page
    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    // Generate numbered pages
    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    // Generate "Next" link if not on the last page
    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleCheckChange = (selectOpion: any, id: string, all?: boolean) => {
    let fieldValue = selectOpion
    if (all) {
      /* if(fieldValue.target.checked){
        const newBody:any[] = tbodyData.map(item => {
          return {...item,check_selected :true}
        });
        setTbodyData(newBody)

      }else{
        const newBody :any[]  = tbodyData.map(item => {
          return {...item,check_selected :false}
        });
        setTbodyData(newBody)
      } */
      if (onCheckSelected) {
        const arrayIds = tbodyData.map((item) => item._id)
        onCheckSelected(arrayIds, fieldValue.target.checked)
      }
    } else {
      if (onCheckSelected) {
        onCheckSelected([id])
      }
    }
  }

  const handleBtnChange = (item: any, accion: string) => {
    if (onBtnSelected) {
      onBtnSelected(item, accion)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    const search = event.target.value
    if(setValueSearch){
      setValueSearch(search)
    }

    if (search) {
      const resutl_tbody: {[key: string]: any}[] = []
      tbody.map((item) => {
        let entro = false
        theader.map((key) => {
          if (item[key.field]) {
            if (String(item[key.field]).toLowerCase().includes(String(search).toLowerCase())) {
              entro = true
            }
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })
      setTbodyData(resutl_tbody)
      handleRangeDateChildren(resutl_tbody, 'search')
    } else {
      setTbodyData(tbody)
      handleRangeDateChildren(tbody, 'search')
    }
  }

  

  const handleSearchChangeDefault = (searchAux:string) => {
    const search = searchAux

    if (search) {
      const resutl_tbody: {[key: string]: any}[] = []
      tbody.map((item) => {
        let entro = false
        theader.map((key) => {
          if (item[key.field]) {
            if (String(item[key.field]).toLowerCase().includes(String(search).toLowerCase())) {
              entro = true
            }
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })
      setTbodyData(resutl_tbody)
      handleRangeDateChildren(resutl_tbody, 'search')
    } else {
      setTbodyData(tbody)
      handleRangeDateChildren(tbody, 'search')
    }
  }

  const handleSearchChangeChildren = (arraynew: any, type: string) => {
    const search = searchQuery

    if (search) {
      const resutl_tbody: {[key: string]: any}[] = []
      arraynew.map((item: any) => {
        let entro = false
        theader.map((key) => {
          if (item[key.field]) {
            if (String(item[key.field]).toLowerCase().includes(String(search).toLowerCase())) {
              entro = true
            }
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })

      setTbodyData(resutl_tbody)
      if (type === 'rangedate') {
        handleChangeSelectChildren(resutl_tbody)
      }
      if (type === 'filterSelect') {
        handleRangeDateChildren(resutl_tbody, '')
      }
    } else {
      setTbodyData(arraynew)
      if (type === 'rangedate') {
        handleChangeSelectChildren(arraynew)
      }
      if (type === 'filterSelect') {
        handleRangeDateChildren(arraynew, '')
      }
    }
  }

  const handleRangeDate = () => {
    if (dateInit !== '' && dateEnd !== '') {
      const fecIni = new Date(dateInit)
      const fecFin = new Date(dateEnd)

      const resutl_tbody: {[key: string]: any}[] = []
      tbody.map((item) => {
        let entro = false
        filterKeyDate?.map((key) => {
          if (new Date(item[key]) >= fecIni && new Date(item[key]) <= fecFin) {
            entro = true
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })
      setTbodyData(resutl_tbody)
      handleSearchChangeChildren(resutl_tbody, 'rangedate')
    } else {
      setTbodyData(tbody)
      handleSearchChangeChildren(tbody, 'rangedate')
    }
  }

  const handleRangeDateChildren = (arraynew: any, type: string) => {
    if (dateInit !== '' && dateEnd !== '') {
      const fecIni = new Date(dateInit)
      const fecFin = new Date(dateEnd)

      const resutl_tbody: {[key: string]: any}[] = []
      arraynew.map((item: any) => {
        let entro = false
        filterKeyDate?.map((key) => {
          if (new Date(item[key]) >= fecIni && new Date(item[key]) <= fecFin) {
            entro = true
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })
      setTbodyData(resutl_tbody)
      if (type === 'search') {
        handleChangeSelectChildren(resutl_tbody)
      }
    } else {
      setTbodyData(arraynew)
      if (type === 'search') {
        handleChangeSelectChildren(arraynew)
      }
    }
  }

  const handleChangeSelect = (e: any) => {
    setFilterQuery(e.target.value)
    const filter = e.target.value
    if (filter) {
      const resutl_tbody: {[key: string]: any}[] = []
      tbody.map((item) => {
        let entro = false
        filterKeySelect?.map((key: any) => {
          if (Array.isArray(item[key])) {
            item[key].map((e: any) => {
              if (String(e).toLowerCase().includes(String(filter).toLowerCase())) {
                entro = true
              }
            })
          } else if (String(item[key]) === String(filter)) {
            entro = true
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })

      setTbodyData(resutl_tbody)
      handleSearchChangeChildren(resutl_tbody, 'filterSelect')
    } else {
      setTbodyData(tbody)
      handleSearchChangeChildren(tbody, 'filterSelect')
    }
  }

  const handleChangeSelectChildren = (arraynew: any) => {
    const filter = filterQuery
    if (filter) {
      const resutl_tbody: {[key: string]: any}[] = []
      arraynew.map((item: any) => {
        let entro = false
        filterKeySelect?.map((key: any) => {
          if (Array.isArray(item[key])) {
            item[key].map((e: any) => {
              if (String(e).toLowerCase().includes(String(filter).toLowerCase())) {
                entro = true
              }
            })
          } else if (String(item[key]) === String(filter)) {
            entro = true
          }
        })
        if (entro) {
          resutl_tbody.push(item)
        }
      })
      setTbodyData(resutl_tbody)
    } else {
      setTbodyData(arraynew)
    }
  }

  const setEntryDateFrom = (val: any) => {
    setDataInit(val)
  }

  const setEntryDateTo = (val: any) => {
    setDateEnd(val)
  }

  const handleCheckSelectAll = () => {
    const arrayIds = tbodyData.map((item) => item._id)
    if (onCheckSelected) {
      onCheckSelected(arrayIds)
    }
  }

  const handleSelecto = (opt: any) => {
    if (opt === selecto) {
      let typeSelected = typeSelect + 1
      if (typeSelected === 3) {
        setSelecto(0)
        setTypeSelect(0)
      } else {
        setTypeSelect(typeSelected)
      }
    } else {
      setTypeSelect(1)
      setSelecto(opt)
    }
  }

  const orderData = (key: number, type: number) => {
    if (type !== 0) {
      const key1 = theader[key].field
      tbodyData.sort((a, b) => compararPorAtributo(a, b, type === 1 ? 'asc' : 'desc', key1))
      setTbodyData(tbodyData)
    } else {
      setTbodyData(tbodyDataAux)
    }
  }

  const compararPorAtributo = (a: any, b: any, orden: 'asc' | 'desc' = 'asc', idx: string) => {
    const comparador = orden === 'asc' ? 1 : -1

    if (a[idx] < b[idx]) {
      return -1 * comparador
    }
    if (a[idx] > b[idx]) {
      return 1 * comparador
    }
    return 0
  }

  const checkFilesIfMissingTags = async (files: Record<string, any>[]) => {
    try {
      setLoadingTags(true)
      const responses = await Promise.all(
        files.map(async (file) => {
          const data = (
            await getMissingTags({
              idFile: file._id,
              iduser: '4124ll-asd20-asd20-asd20',
              idproceso: processId,
              filename: file.filename,
            })
          ).data

          return {
            ...data,
            filename: file.filename,
            keyname: file.keyname,
          }
        })
      )

      const isSubsaned = responses.every((file) =>
        file.missingTags.every((tagR: any) =>
          files.some((doc) =>
            doc.tags?.some((tag: any) => {
              const tagKeys = Object.keys(tag.tags)
              return tagKeys.includes(`{${tagR}}`)
            })
          )
        )
      )

      const haveTags = files.every((doc) => doc.tags != null && doc.tags.length > 0)
      if (responses.some((item) => item.missingTags.length > 0) && (!isSubsaned || !haveTags)) {
        showMessage(
          'error',
          'Faltan etiquetas',
          'Se encontraron documentos con etiquetas faltantes'
        )
      }

      setMissingTags(responses)
      setGlobalMisingTags(responses)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTags(false)
    }
  }

  const readExcelTags = async (excelFile: File, tags: string[]) => {
    try {
      const buffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(excelFile)

        reader.onload = () => {
          resolve(reader.result as ArrayBuffer)
        }

        reader.onerror = reject
      })

      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(buffer)

      const headers = new Map<number, string>()
      const values: {user: string; tags: Record<string, any>}[] = []
      workbook
        .getWorksheet(1)
        ?.getRow(1)
        .eachCell((cell, colNumber) => {
          headers.set(colNumber, cell.value?.toString() ?? '')
        })

      workbook.getWorksheet(1).eachRow((row, rowNumber) => {
        if (rowNumber > 1) {
          let user = ''
          row.eachCell({includeEmpty: true}, (cell, colNumber) => {
            if (colNumber === 1) {
              if (cell.value === null || cell.value === '')
                throw new Error(
                  'Asegurese que la primera columna tenga el numero de documento del usuario'
                )

              user = cell.value?.toString() as string
              values.push({user, tags: {}})
            } else {
              const value =
                cell.value instanceof Date ? formatDateToDMY(cell.value) : cell.value ?? ''
              values.find((v) => v.user === user)!.tags[headers.get(colNumber) as string] = value
            }
          })
        }
      })

      const isEqualLenthTags = tags.length === headers.size - 1

      if (!isEqualLenthTags) {
        throw Error(
          'El numero de etiquetas no coincide con el numero de columnas, asegurese de llenar todas las columnas'
        )
      }

      const equalLengthUsers = usersSelected.length === values.length
      if (!equalLengthUsers) {
        throw Error('Aseguese que todos los usuarios esten en el archivo excel')
      }

      // const equalTags = values.every((item) => {
      //   const tags = Object.keys(item.tags)
      //   return tags.every((tag) => tags.includes(`{${tag}}`))
      // })
      // console.log(equalTags)

      // if (!equalTags) {
      //   throw Error('Las etiquetas no coinciden con las columnas')
      // }

      const dnis = usersSelected.map((user) => user.dni)
      const sameUsers = values.every((item) => dnis.includes(item.user))
      if (!sameUsers) {
        throw Error('Los usuarios no coinciden con los valores del excel')
      }

      const plantillas = tbody.filter((item) => item.type?.toLowerCase() === 'plantilla')

      const returnedValues = plantillas
        .map((plantilla) =>
          values.map((value) => ({
            idFile: plantilla._id,
            user: value.user,
            tags: value.tags,
          }))
        )
        .flat()

      return returnedValues
    } catch (error) {
      throw Error((error as Error).message)
    }
  }

  const onLoadExcel = async () => {
    try {
      if (excelRef.current != null) {
        excelRef.current.click()

        const selectedFile = await new Promise<File | null>((resolve) => {
          const handleFileChange: EventListener = (event) => {
            const target = event.target as HTMLInputElement
            excelRef.current?.removeEventListener('change', handleFileChange)
            const file = target.files?.[0] ?? null
            resolve(file)
          }

          excelRef.current?.addEventListener('change', handleFileChange)
        })

        setGlobalOverlayLoading(true)

        const uniqueTags = new Set(missingTags.flatMap((item) => item.missingTags))
        const tagsByFile = await readExcelTags(selectedFile as File, Array.from(uniqueTags))

        const docs = tagsByFile.flat()
        const groupedDocs = new Map<string, Record<string, any>[]>()

        docs.forEach((doc) => {
          if (groupedDocs.has(doc.idFile)) {
            const arrTags = groupedDocs.get(doc.idFile) ?? []
            groupedDocs.set(doc.idFile, [...arrTags, doc])
          } else {
            groupedDocs.set(doc.idFile, [doc])
          }
        })

        setDocs(groupedDocs)
      }
    } catch (error) {
      showMessage('error', 'Error', (error as Error).message)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  useEffect(() => {
    if (tbody.length > 0 && fetchTags) {
      const usersCount = usersSelected.length
      checkFilesIfMissingTags(tbody.filter((item) => item.type?.toLowerCase() === 'plantilla'))
    }
  }, [tbody])

  return (
    <>
      <KTCardBody className='py-2 px-0'>
        <div className='d-flex align-items-end mb-3'>
          <label style={{fontSize: '16px', fontWeight: 'bold'}}>{title}</label>
        </div>
        <Form.Group>
          <Row>
            {searchVisible && (
              <Col xs={searchCol ? searchCol : 7} className='my-1'>
                <span className='fw-bold'>Buscar</span>
                <Form.Control
                  type='text'
                  placeholder={searchHint}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className='form-control-sm '
                />
              </Col>
            )}
            {checksAll && (
              <Col xs={5}>
                <div>
                  <button className='btn btn-primary btn-sm mt-2' onClick={handleCheckSelectAll}>
                    <i className='fa-solid fa-square-check'></i> Seleccionar Todo
                  </button>
                </div>
              </Col>
            )}
            {fetchTags && tbody.length > 0 && (
              <Col xs={5} className='my-3'>
                <div className='d-flex align-items-center gap-1'>
                  <CustomTooltip
                    className='btn btn-primary btn-icon'
                    icon='cloud-download'
                    onClick={downloadTags}
                  >
                    Descargar etiquetas
                  </CustomTooltip>
                  <CustomTooltip
                    onClick={onLoadExcel}
                    className='btn btn-primary btn-icon'
                    icon='user-edit'
                  >
                    <input
                      type='file'
                      ref={excelRef}
                      style={{display: 'none'}}
                      accept='.xlsx, .xls'
                    />
                    Subir plantilla
                  </CustomTooltip>
                </div>
              </Col>
            )}
            {rangeDateVisible && (
              <Col xs={rangeDateCol ? rangeDateCol : 5} className='mb-3'>
                <Stack direction='vertical'>
                  <span className='fw-bold'>{rangeDateTitulo}</span>
                  <Stack direction='horizontal' gap={3}>
                    <span className='fw-bold'>Inicio </span>
                    <Form.Control
                      type='date'
                      placeholder='Inicio'
                      onChange={(e) => setEntryDateFrom(e.target.value)}
                    />
                    <span className='fw-bold'>Fin </span>
                    <Form.Control
                      type='date'
                      placeholder='Fin'
                      onChange={(e) => setEntryDateTo(e.target.value)}
                    />
                  </Stack>
                </Stack>
              </Col>
            )}

            {filterSelect && (
              <Col xs={filterSelectCol ? filterSelectCol : 5} className='mb-3'>
                <Stack direction='vertical' gap={1}>
                  <span className='fw-bold'>{dataSelectTitulo}:</span>
                  <Form.Control size='sm' as='select' onChange={handleChangeSelect} className={'form-select form-select-solid}'}>
                    <option value=''>Todos</option>
                    {dataSelect?.map((option: any) => (
                      <option
                        key={option.iconName}
                        value={option.stateName}
                        style={{color: option.color}}
                      >
                        {option.title}
                      </option>
                    ))}
                  </Form.Control>
                </Stack>
              </Col>
            )}
          </Row>
          <Row>
            {onBtnExport && (
              <Col className='d-flex align-self-end justify-content-end py-2'>
                <Stack direction='horizontal' gap={2}>
                  <Dropdown>
                    <Dropdown.Toggle variant='primary' id='dropdown-basic' size='sm'>
                      <KTIcon iconName='file-down' className='fs-2' />
                      Exportar
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href='#' onClick={() => onBtnExport('Report')}>
                        Reporte
                      </Dropdown.Item>
                      <Dropdown.Item href='#' onClick={() => onBtnExport('File')}>
                        Archivos
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
              </Col>
            )}
          </Row>
        </Form.Group>
        <div
          className='table-responsive'
          style={{maxHeight: tMaxHeight ? tMaxHeight : '300px', overflowY: 'auto'}}
        >
          <table
            id='kt_table_users'
            className={`table align-middle table-row-bordered fs-6 table-hover ${classTable}`}
            {...getTableProps()}
          >
            {theaderVisible && (
              <thead style={{padding: '5px'}}>
                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                  {theader.map((item: any, idx) => (
                    <th
                      className={`${item.type === 'button' ? 'text-end px-2' : 'text-start'}`}
                      onClick={() => handleSelecto(idx)}
                      style={{paddingBlock: '5px', paddingLeft: '5px'}}
                    >
                      {item.type === 'check' ? (
                        <>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className={'form-check-input  mx-2'}
                              onChange={(event) => handleCheckChange(event, '', true)}
                              type='checkbox'
                              id={'flexRadioLg' + item.label}
                            />
                          </div>
                        </>
                      ) : (
                        <a href='# ' >
                          {selecto === idx && typeSelect === 1 /* asc */ ? (
                            <>
                              <i className='fa-solid fa-arrow-up-short-wide p-1'></i>
                              <span className='text-start text-white'>{item.label}</span>
                            </>
                          ) : selecto === idx && typeSelect === 2 /* asc */ ? (
                            <>
                              <i className='fa-solid fa-arrow-up-wide-short p-1'></i>
                              <span className='text-start text-white'>{item.label}</span>
                            </>
                          ) : (
                            <>
                              <i className='fa-solid fa-arrows-up-down p-1'></i>
                              <span className='text-start text-white'>{item.label}</span>
                            </>
                          )}
                        </a>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
            )}

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {tbodyData.length > 0 ? (
                tbodyData.map((item: any, idx) => {
                  const misingTagsByFile = missingTags.find((tag) => tag?.idFile === item._id)
                  const includes = misingTagsByFile?.missingTags.every((tag) =>
                    item.tags?.some((obj: any) => `{${tag}}` in obj.tags)
                  )

                  const someRejected = item.detalle_niveles?.some((l: any) => l.detalle.estado === 'rechazado') ?? false
                  const hasHistory = item.historial_niveles?.length > 0
                  const hasAprroveds = item.number_aprobacion === 0
                  const conditionsToDelete = (isEdit && !someRejected && hasHistory) || (isEdit && hasAprroveds)
                  const countMissingTags = misingTagsByFile?.missingTags.length ?? 0

                  return (
                    <Fragment key={`${item._id ? item._id : idx}`}>
                      <tr>
                        {fetchTags && (
                          <>
                            <td
                              style={{paddingBlock: '5px', paddingLeft: '5px'}}
                              className='text-start px-2'
                            >
                              {item.type?.toLowerCase() === 'plantilla' && misingTagsByFile ? (
                                <div className='d-flex align-items-center gap-1'>
                                  <CustomTooltip
                                    className='btn btn-icon btn-light-dark'
                                    icon={
                                      !includes && countMissingTags > 0 ? 'information-3' : 'check'
                                    }
                                    iconClass={`fs-3 text-danger ${
                                      !includes && countMissingTags > 0
                                        ? 'text-hover-danger text-danger'
                                        : 'text-hover-success text-success'
                                    }`}
                                  >
                                    <article className='d-flex flex-column'>
                                      {!includes && countMissingTags > 0 ? (
                                        <>
                                          <p>Faltan {countMissingTags} etiquetas</p>
                                          {misingTagsByFile?.missingTags.map((tag) => (
                                            <span
                                              className='small text-start fw-light mb-1'
                                              key={tag}
                                            >
                                              {tag}
                                            </span>
                                          ))}
                                        </>
                                      ) : (
                                        <p>Documento correcto</p>
                                      )}
                                    </article>
                                  </CustomTooltip>
                                </div>
                              ) : null}
                            </td>
                          </>
                        )}
                        {theader.map((key: any,idx:any) => (
                          <td
                            className={`${key.type === 'button' ? 'text-end px-2' : 'text-start'}`}
                            style={{paddingBlock: '5px', paddingLeft: '5px'}}
                          >
                            {item[key.field] ? (
                              key.type === 'check' ? (
                                <div className='form-check form-check-custom form-check-solid form-check-sm m-2'>
                                  <input
                                    className={'form-check-input'}
                                    checked={item[key.field]}
                                    type='checkbox'
                                    onChange={(event) => handleCheckChange(event, item._id, false)}
                                    id={'flexRadioLg' + item._id}
                                  />
                                  {/* <label className="form-check-label" htmlFor={`flexRadioLg`+item._id}>
                                    <pre>{JSON.stringify(item[key.field], null, 2)}</pre>
                                  </label> */}
                                </div>
                              ) : key.type === 'tooltip' ? (
                                <CustomTooltip
                                  icon='information-3'
                                  iconClass='text-hover-warning text-warning'
                                  children={item[key.field]}
                                />
                              ) : key.type === 'button' ? (
                                <div>
                                  <button
                                    data-tooltip-id={`my-tooltip-${item._id ? item._id : idx}`}
                                    onClick={() => handleBtnChange(item, key.field)}
                                    title={key.label}
                                    className={`btn btn-icon ${
                                      typeof key.btn === 'object'
                                        ? key.btn[item[key.field]]
                                        : key.btn
                                        ? key.btn
                                        : 'btn-light-dark'
                                    } btn-sm me-1`}
                                  >
                                    <KTIcon
                                      iconName={key.icon ? key.icon : 'cross'}
                                      className='fs-3'
                                    />
                                  </button>
                                  {typeof key.variantTooltip === 'object' && (
                                    <ReactTooltip
                                      id={`my-tooltip-${item._id ? item._id : idx}`}
                                      place='bottom'
                                      variant={`${
                                        key.variantTooltip[item[key.field]]
                                          ? (key.variantTooltip[item[key.field]] as VariantType)
                                          : 'info'
                                      }`}
                                      content={`${key.title[item[key.field]]} ${
                                        item[key.descripcion]
                                      }`}
                                    />
                                  )}
                                </div>
                              ) : key.type === 'array' ? (
                                item[key.field].length > 1 ? (
                                  <Form.Select>
                                    {item[key.field].map((r: any) => (
                                      <option>{r}</option>
                                    ))}
                                  </Form.Select>
                                ) : (
                                  item[key.field][0]
                                )
                              ) : key.type === 'date' ? (
                                formatDateToDMY(item[key.field])
                              ) : key.type === 'datetime' ? (
                                formatISODateToDMYHM(item[key.field])
                              ) : key.type === 'boolean' ? (
                                <div>
                                  <button
                                    data-tooltip-id={`my-tooltip-${item._id ? item._id : idx}`}
                                    onClick={() => handleBtnChange(item, key.field)}
                                    title={key.label}
                                    className={`btn btn-icon ${
                                      typeof key.btn === 'object'
                                        ? key.btn[item[key.field]]
                                        : key.btn
                                        ? key.btn
                                        : 'btn-light-dark'
                                    } btn-sm me-1`}
                                  >
                                    <KTIcon
                                      iconName={key.icon ? key.icon : 'cross'}
                                      className='fs-3'
                                    />
                                  </button>
                                  {typeof key.variantTooltip === 'object' && (
                                    <ReactTooltip
                                      id={`my-tooltip-${item._id ? item._id : idx}`}
                                      place='left'
                                      variant={`${
                                        key.variantTooltip[item[key.field]]
                                          ? (key.variantTooltip[item[key.field]] as VariantType)
                                          : 'info'
                                      }`}
                                      content={`${key.title[item[key.field]]}`}
                                    />
                                  )}
                                </div>
                              ) : key.type === 'symbol' ? (
                                <div className="symbol symbol-25px symbol-circle" style={{marginRight:"5px"}}>
                                  <div data-tooltip-id={`my-tooltip-symbol-${item._id ? item._id : idx}`} className="symbol-label fs-2 bg-success text-white">
                                    { String(item[key.field]) }
                                  </div>
                                  {typeof key.variantTooltip === 'object' && (
                                    <ReactTooltip
                                      id={`my-tooltip-symbol-${item._id ? item._id : idx}`}
                                      place='right'
                                      variant={`${
                                        key.variantTooltip[key.field]
                                          ? (key.variantTooltip[key.field] as VariantType)
                                          : 'info'
                                      }`}
                                      content={`${String(key.title[key.field])}`}
                                    />
                                  )}
                                </div>
                              ) : (
                                String(item[key.field])
                              )
                            ) : (key.type === 'check' && item.typeSelected !== 'CPD') ? (
                              <div className='form-check form-check-custom form-check-solid form-check-sm m-2'>
                                <input
                                  className={'form-check-input'}
                                  checked={item[key.field]}
                                  type='checkbox'
                                  onChange={(event) => handleCheckChange(event, item._id)}
                                  id={'flexRadioLg' + item._id}
                                />
                                {/* <label className="form-check-label" htmlFor={`flexRadioLg`+item._id}>
                                    <pre>{JSON.stringify(item[key.field], null, 2)}</pre>
                                  </label> */}
                              </div>
                            ) : key.type === 'button' ? (
                              (conditionsToDelete && key.field === 'delete') || item[key.field] === false ? (
                                ''
                              ) : (
                                <div>
                                  <button
                                    onClick={() => handleBtnChange(item, key.field)}
                                    title={key.label}
                                    className={`btn btn-icon ${
                                      key.btn ? key.btn : 'btn-light-dark'
                                    } btn-sm me-1`}
                                  >
                                    <KTIcon
                                      iconName={key.icon ? key.icon : 'cross'}
                                      className='fs-3 '
                                    />
                                  </button>
                                </div>
                              )
                            ) : key.type === 'symbol' ? (
                              item[key.field] === false ? (
                                ''
                              ) : (
                                <div className="symbol symbol-25px symbol-circle" style={{marginRight:"5px"}}>
                                  <div data-tooltip-id={`my-tooltip-symbol-${item._id ? item._id : idx}`} className="symbol-label fs-2 bg-success text-white">
                                    { String(item[key.field]) }
                                  </div>
                                  {typeof key.variantTooltip === 'object' && (
                                    <ReactTooltip
                                      id={`my-tooltip-symbol-${item._id ? item._id : idx}`}
                                      place='right'
                                      variant={`${
                                        key.variantTooltip[key.field]
                                          ? (key.variantTooltip[key.field] as VariantType)
                                          : 'info'
                                      }`}
                                      content={`${String(key.title[key.field])}`}
                                    />
                                  )}
                                </div>
                              )
                            ) : key.type === 'boolean' ? (
                              <div>
                                <button
                                  data-tooltip-id={`my-tooltip-${item._id ? item._id : idx}`}
                                  onClick={() => handleBtnChange(item, key.field)}
                                  title={key.label}
                                  className={`btn btn-icon ${
                                    typeof key.btn === 'object'
                                      ? key.btn[item[key.field]]
                                      : key.btn
                                      ? key.btn
                                      : 'btn-light-dark'
                                  } btn-sm me-1`}
                                >
                                  <KTIcon iconName={'cross'} className='fs-3' />
                                </button>
                                {typeof key.variantTooltip === 'object' && (
                                  <ReactTooltip
                                    id={`my-tooltip-${item._id ? item._id : idx}`}
                                    place='left'
                                    variant={`${
                                      key.variantTooltip[item[key.field]]
                                        ? (key.variantTooltip[item[key.field]] as VariantType)
                                        : 'info'
                                    }`}
                                    content={`${key.title[item[key.field]]}`}
                                  />
                                )}
                              </div>
                            ) : (
                              item[key.field] ===0 ?
                                ( key.type === 'symbol' ? 
                                  (
                                    <div className="symbol symbol-25px symbol-circle" style={{marginRight:"5px"}}>
                                      <div data-tooltip-id={`my-tooltip-symbol-${item._id ? item._id : idx}`} className="symbol-label fs-2 bg-success text-white">
                                        { String(item[key.field]) }
                                      </div>
                                      {typeof key.variantTooltip === 'object' && (
                                        <ReactTooltip
                                          id={`my-tooltip-symbol-${item._id ? item._id : idx}`}
                                          place='right'
                                          variant={`${
                                            key.variantTooltip[key.field]
                                              ? (key.variantTooltip[key.field] as VariantType)
                                              : 'info'
                                          }`}
                                          content={`${String(key.title[key.field])}`}
                                        />
                                      )}
                                    </div>
                                  ) 
                                  : 
                                  ('')
                                ) 
                                : 
                                ('')
                            )}
                          </td>
                        ))}
                      </tr>
                    </Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={theader.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No se encontraron registros
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pagination && (
          <Pagination
            links={generatePaginationLinks(currentPage, totalPages)}
            currentPage={currentPage}
            isLoading={isLoading}
            onPageChange={handlePageChange}
          />
        )}

        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {PartnersTableGeneral}
