import {useContext, useEffect, useState} from 'react'
import {Row, Form, Button} from 'react-bootstrap'
import {SheetEditContext} from '../SheetContexts'
import {Section} from '../Sheet'
import {v4} from 'uuid'

const SectionEditModalForm = () => {
  const {sheetData, setData, closeSectionModal, sectionData, setSectionData} =
    useContext(SheetEditContext)
  const [formData, setFormData] = useState<Section>({
    id: v4(),
    name: '',
    description: '',
    dynamic: false,
    filter: false
  })

  const [validated, setValidated] = useState(false)

  useEffect(() => {
    if (sectionData.id.length > 0) {
      setFormData(sectionData)
    } else {
      setFormData({
        id: v4(),
        name: '',
        description: '',
        dynamic: false,
        filter: false
      })
    }
  }, [])

  const handleChange = (e: any) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const validateForm = (e: any) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      handleSubmit(e)
    }

    setValidated(true)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (sectionData.id.length > 0) {
      setData({
        ...sheetData,
        sections: (sheetData.sections || []).map((x) => (x.id === sectionData.id ? formData : x)),
      })
    } else {
      setData({...sheetData, sections: [...(sheetData.sections || []), formData]})
    }

    closeSectionModal()
  }
  return (
    <>
      <Form validated={validated} onSubmit={validateForm} noValidate>
        <Form.Group className='mb-3' controlId='formNombre'>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type='text'
            required
            placeholder='Ingrese un nombre'
            name='name'
            value={formData.name}
            onChange={handleChange}
            className='same-height'
          />
          <Form.Control.Feedback type='invalid'>
            Escriba un nombre para la seccion.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formDescripcion'>
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            type='text'
            placeholder='Ingrese una descripción'
            name='description'
            value={formData.description}
            onChange={handleChange}
            className='same-height'
          />
        </Form.Group>

        <Form.Check // prettier-ignore
          type='checkbox'
          id='dynamicCheckbox'
          label='Dinámico'
          checked={formData.dynamic}
          onChange={(event) => setFormData({...formData, dynamic: event.target.checked})}
        />

        <Form.Check
          type='checkbox'
          id='filterCheckbox'
          label='Filtro'
          checked={formData.filter}
          className='mt-3'
          onChange={(event) => setFormData({...formData, filter: event.target.checked})}
        />

        <Row>
          <div className='d-flex justify-content-end'>
            <Button
              variant='light'
              className='mx-3'
              type='reset'
              onClick={() => {
                closeSectionModal()
              }}
            >
              Cancelar
            </Button>
            <Button variant='primary' type='submit'>
              Guardar
            </Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export {SectionEditModalForm}
