import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PageTitle } from "../../../../../_metronic/layout/core"
import { Button, Col, Container, Form, Row, Stack, Tab, Tabs } from "react-bootstrap"
import { KTCard, KTIcon } from "../../../../../_metronic/helpers"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Select from 'react-select';
import ReactQuill from "react-quill";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-quill/dist/quill.snow.css';
import './custom.css'
import { FilterList } from "./FilterList"
import { getProcessesForDropdown } from "../../../../services/procesos"
import { getSheetsByProcessForDropdown } from "../../../../services/fichas"
import { getFieldsBySheetDropdown } from "../../../../services/campos"
import { AlertDropdown } from "./Alert.interface"
import { getAdminUsers } from "../../../../services/users"
import { createAlert, getAlertById, updateAlert } from "../../../../services/alerts"
import { showMessage } from "../../../../shared/Alerts"
import { AlertFichaEdit } from "./AlertFichaEdit"
import { AlertEstadoEdit } from "./AlertEstadoEdit"

const fichasBreadcrumbs = [
    {
        title: 'Configuración',
        path: '/configuracion/alertas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AlertEdit = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [title, setTitle] = useState('Nueva Alerta')
    const [value, onChange] = useState<Value>([new Date(), new Date()]);
    const [selectedDays, setSelectedDays] = useState<any>([]);
    const [selectedEmails, setSelectedEmails] = useState<any>([]);
    const [listProcessValidationError, setListProcessValidationError] = useState(false);
    const [bodyValue, setBodyValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [processList, setProcessList] = useState<AlertDropdown[]>([])
    const [sheetList, setSheetList] = useState<AlertDropdown[]>([])
    const [fieldList, setFieldList] = useState<AlertDropdown[]>([])
    const [processValue, setProcessValue] = useState()
    const [sheetValue, setSheetValue] = useState()
    const [typeAlert, setTypeAlert] = useState('')
    const [processOwners, setProcessOwners] = useState([])
    const [description, setDescription] = useState<string>('')
    const [filters, setFilters] = useState<any>([])
    const [validationMessage, setValidationMessage] = useState('')
    const [isNewEmailButtonEnable, setNewEmailButtonStatus] = useState(false)
    const [newEmail, setNewEmail] = useState('')

    const dayList = [
        { value: 'lunes', label: 'Lunes' },
        { value: 'martes', label: 'Martes' },
        { value: 'miércoles', label: 'Miércoles' },
        { value: 'jueves', label: 'Jueves' },
        { value: 'viernes', label: 'Viernes' },
        { value: 'sábado', label: 'Sábado' },
        { value: 'domingo', label: 'Domingo' },
    ];

    useEffect(() => {
        if (id) {
            setTitle('Editar Alerta')
            getAlert(id)
        }

        getProcesses()
        getAdministrativeUsers()
    }, [])

    const getAlert = (id: string) => {
        getAlertById(id)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    const data = response.data
                    setDescription(data.description)
                    setProcessValue(data.processId)
                    setSheetValue(data.sheetId)
                    setFilters(data.filters)
                    setBodyValue(data.bodyMessage)
                    setSelectedDays(data.days)
                    setSelectedEmails(data.emails)
                    onChange(data.dateRange)
                    getSheetsByProcess(data.processId)
                    getFieldsBySheet(data.sheetId)
                }
            })
            .catch((error) => console.log(error))
    }

    const goBack = () => {
        navigate('/configuracion/alertas')
    }

    const handleDaysChange = (selectedOptions: any) => {
        setSelectedDays(selectedOptions);
        setListProcessValidationError(selectedOptions.length === 0);
    };

    const handleEmailsChange = (selectedOptions: any) => {
        setSelectedEmails(selectedOptions);
    };

    const getProcesses = () => {
        setLoading(true)
        getProcessesForDropdown()
            .then((response) => {
                if (response.success) {
                    setProcessList(response.data)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getAdministrativeUsers = () => {
        getAdminUsers()
            .then((response) => {
                setProcessOwners(response.data.map((x: any) => ({ label: x.nombre, value: x._id })))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getSheetsByProcess = (id: string) => {
        getSheetsByProcessForDropdown(id)
            .then((response) => {
                if (response.success) {
                    setSheetList(response.data)
                }
            })
            .catch((error) => { console.log(error) })
    }

    const getFieldsBySheet = (id: string) => {
        getFieldsBySheetDropdown(id)
            .then((response) => {
                if (response.success) {
                    setFieldList(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleProcessChange = (e: any) => {
        setProcessValue(e.target.value)
        getSheetsByProcess(e.target.value)
    }

    const handleSheetChange = (e: any) => {
        setSheetValue(e.target.value)
        getFieldsBySheet(e.target.value)
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    const handleSubmit = (e: any) => {

        e.preventDefault()

        const data = {
            description: description,
            processId: processValue,
            sheetId: sheetValue,
            filters: filters,
            bodyMessage: bodyValue,
            days: selectedDays,
            emails: selectedEmails,
            dateRange: value
        }

        if (id) {
            updateAlert(id, data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        } else {
            createAlert(data)
                .then((response) => {
                    if (response.success) {
                        showMessage('success', '¡Buen trabajo!', response.message)
                        goBack()
                    } else {
                        showMessage('error', 'Hubo un error', response.error)
                    }
                })
                .catch((error) => {
                    showMessage('error', 'Hubo un error', error.message)
                })
        }

    }

    const handleFilterListChange = (e: any) => {
        setFilters(e)
    }

    const handleNewEmailInputChange = (e: any) => {
        const enteredEmail = e.target.value
        setNewEmail(enteredEmail)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (enteredEmail.trim() === '') {
            setValidationMessage('')
            setNewEmailButtonStatus(false)
        } else if (!emailRegex.test(enteredEmail)) {
            setValidationMessage('El email no es válido')
            setNewEmailButtonStatus(false)
        } else {
            setValidationMessage('')
            setNewEmailButtonStatus(true)
        }
    };

    const handleNewEmail = () => {
        setSelectedEmails([...selectedEmails, { value: newEmail, label: newEmail }]);
        setNewEmailButtonStatus(false)
        setNewEmail('')
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <Tabs
                defaultActiveKey="sheet"
                className="mb-3"
            >
                <Tab eventKey="sheet" title="Alerta por Ficha">
                    <AlertFichaEdit />
                </Tab>
                {/* <Tab eventKey="Status" title="Alerta por Estado">
                    <AlertEstadoEdit />
                </Tab> */}

            </Tabs>
        </>
    )
}

export { AlertEdit }