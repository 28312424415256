import ExcelJS from 'exceljs'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Form, Row, Stack } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useDropzone } from 'react-dropzone'
import { getCompleteSheet } from '../../../../services/fichas'
import { getProceso } from '../../../../services/procesos'
import { addWSeleccion } from '../../../../services/wseleccion'
import { showInfoHtml, showMessage, showValidatePassword } from '../../../../shared/Alerts'
import { useUnidades } from '../../../../shared/hooks/useUnidades'
import { DataUser } from '../../../documentacion/MiFicha/DataUserInterface'
import OverlayLoading from '../../../generales_component/OverlayLoading'
import { Field, Section } from '../../fichas/Sheet'
import { PartnerContext } from '../PartnerContexts'
import { Button } from 'react-bootstrap';
import { KTIcon } from '../../../../../_metronic/helpers';
import './styles.css'

function findEmailAndIDsDuplicates(elements: Record<string, any>[]): [string[], string[]] {
  const seenIDs = new Set<string>()
  const IDsduplicates = new Set<string>()
  const seenEmail = new Set<string>()
  const emailduplicates = new Set<string>()

  elements.forEach((item) => {
    if (seenEmail.has(item.CS_EmailPersonal)) {
      emailduplicates.add(item.CS_EmailPersonal)
    } else {
      seenEmail.add(item.CS_EmailPersonal)
    }

    if (seenIDs.has(item.CS_Documento)) {
      IDsduplicates.add(item.CS_Documento)
    } else {
      seenIDs.add(item.CS_Documento)
    }
  })

  return [Array.from(IDsduplicates), Array.from(emailduplicates)]
}

function quitarTildes(texto: string) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const ShowCargaExcelModalForm: React.FC = () => {
  const buttonsData = [
    {
      iconName: 'time',
      title: 'Pendiente por Colaborador',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'Completado por Colaborador',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'Aprobado con Pendiente',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Aprobado por Administrador',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'Historico',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'Ficha Siendo Editada',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    // {
    //   iconName: 'exclamation-triangle', // Adjust the icon name
    //   title: 'Proceso Anulado',
    //   stateName: 'procesoAnulado',
    //   color: '#e74c3c', // Red
    // },
    {
      iconName: 'eye',
      title: 'Ficha Observada',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]
  const asignacionTypeNew = [
    "CS_ApPaterno",
    "CS_ApMaterno",
    "CS_Nombres",
    "CS_Documento",
    "CS_EmailPersonal",
  ];
  const asignacionTypeExistente = [
    "CS_Documento",
  ];

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  const { setShowCargaExcelModal, sheet_id, sheet, setSheet, setRefresh } = useContext(PartnerContext)
  const [file, setFile] = useState<File | null>(null)
  const [fileValido, setFileValido] = useState(false)
  const [rol, setRol] = useState('POST')
  const [estado, setEstado] = useState('')
  const [tipo, setTipo] = useState('')
  const [idproceso, setIdproceso] = useState('')
  const [idunidad, setIdunidad] = useState('')
  const [arrayObjectCampos, setArrayObjectCampos] = useState<any[]>([])
  const [arrayObjectAssing, setArrayObjectAssing] = useState<any[]>([])
  const [cargandoData, setCargandoData] = useState(false)
  const [processData, setProcessData] = useState([])
  const usuarioJSON = localStorage.getItem('userData')
  const { misUnidades } = useUnidades()
  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  useEffect(() => {
    if (sheet_id) {
      getProcessSheet(sheet_id)
      refetchDataProcesos()
    }
  }, [])

  useEffect(() => {
    console.log("file")
    console.log(file)
    readFile()
  }, [file])

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0] || null)
  }

  const saveExcel = async () => {
    setCargandoData(true)
    if (tipo === 'existing') {
      await addWSeleccion(
        {
          Campos: arrayObjectCampos,
          Assing: arrayObjectAssing,
          password: '',
        },
        rol,
        idproceso,
        idunidad,
        tipo,
        estado
      )
        .then((response) => {
          if (response.success) {
            showInfoHtml(
              'success',
              'Registro Correctamente',
              `
            <p>${response.message}</p>
            ${response?.reincorpored?.length > 0
                ? `
                  <p>Los siguientes usuarios se estan reincorporando: </p>
                  <p>${response?.reincorpored?.join('<br/>')}</p>
                `
                : ''
              }

            `
            )
          } else {
            if (response.data && response.data.length > 0) {
              let codigoHTML = ''
              codigoHTML += `${response.message} <br/>
                                <div class="centrar-tabla" >
                                <table style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif; text-align: left; border: 1px solid #ddd;">
                                <thead>
                                    <tr style="background-color: #000064; color: white;" >
                                      <th style="padding: 10px; border: 1px solid #ddd; width:70px" >Num Documento</th>
                                    </tr>
                                </thead>
                                <tbody> `
              for (const dat of response.data) {
                codigoHTML += '<tr style="background-color:rgb(246, 245, 250);"><td style="padding: 5px; border: 1px solid #ccc;">' + dat + '</td></tr>'
              }

              codigoHTML += '</tbody> </table> </div>'
              showInfoHtml('info', 'Hubo un inconveniente', codigoHTML)
            } else {
              showMessage('error', 'Hubo un Error', response.message)
            }
          }
          setCargandoData(false)
          setRefresh(true)
        })
        .catch((error) => {
          console.log(error)
          setCargandoData(false)
        })
    } else {
      showValidatePassword()
        .then(async (result) => {
          if (result.isConfirmed) {
            await addWSeleccion(
              {
                Campos: arrayObjectCampos,
                Assing: arrayObjectAssing,
                password: result.value,
              },
              rol,
              idproceso,
              idunidad,
              tipo,
              estado
            )
              .then((response) => {
                if (response.success) {
                  showInfoHtml(
                    'success',
                    'Registro Correctamente',
                    `
                  <p>${response.message}</p>
                  ${response?.reincorpored?.length > 0
                      ? `
                        <p>Los siguientes usuarios se estan reincorporando: </p>
                        <p>${response?.reincorpored?.join('<br/>')}</p>
                      `
                      : ''
                    }

                  `
                  )
                } else {
                  showMessage('error', 'Hubo un Error', response.message)
                }
                setCargandoData(false)
                setRefresh(true)
              })
              .catch((error) => {
                console.log(error)
                setCargandoData(false)
              })
            setShowCargaExcelModal(false)
          } else {
            setCargandoData(false)
          }
        })
        .catch((error) => {
          console.log(error)
          setShowCargaExcelModal(false)
        })
    }
  }

  const validarDatos = (data: string[], tipoRegistro: string) => {
    const setData = new Set(data); // Convertimos data a un Set para búsqueda rápida
    if (tipoRegistro == "new") {
      const faltantes = asignacionTypeNew.filter((campo) => !setData.has(campo));
      if (faltantes.length === 0) {
        console.log("Todos los campos están presentes.");
        return {
          success: true,
          respuesta: "Todos los campos están presentes.",
          data: faltantes
        }
      } else {
        return {
          success: false,
          respuesta: "Faltan los siguientes campos: ",
          data: faltantes
        }
      }
    } else {
      const faltantes = asignacionTypeExistente.filter((campo) => !setData.has(campo));
      if (faltantes.length === 0) {
        console.log("Todos los campos están presentes.");
        return {
          success: true,
          respuesta: "Todos los campos están presentes.",
          data: faltantes
        }
      } else {
        return {
          success: false,
          respuesta: "Faltan los siguientes campos: ",
          data: faltantes
        }
      }

    }


  };

  const readFile = async () => {
    setFileValido(false)
    if (file && idproceso !== '' && idunidad !== '') {
      setCargandoData(true)
      const datErroneo: { celda: string; value: string }[] = []
      const arraySeccion: string[] = []
      const arrayHeader: string[] = []
      const arrayHeaderAssing: string[] = []
      const arrayHeaderType: string[] = []
      const arrayHeaderOption: any[] = []
      const arrayHeaderOptionBD: any[] = []
      const arrayObjectCamposAux: any[] = []
      const arrayObjectAssingAux: any[] = []
      const arrayObjectIdTag: any[] = []
      const workbook = new ExcelJS.Workbook()
      const reader = new FileReader()

      reader.onload = async (e: any) => {        
        const arrayBuffer = e.target.result
        await workbook.xlsx.load(arrayBuffer)
        // Supongamos que el archivo Excel tiene una sola hoja
        const worksheet = workbook.getWorksheet(1)
        // Accede a los datos de la hoja y procesa según tus necesidades
        worksheet.eachRow((row, rowNumber) => {
          const obj: { [key: string]: string } = {}
          const obj2: { [key: string]: string } = {}
          const lastColumnNumber = row.cellCount
          row.eachCell((cell, colNumber) => {
            let exist = false
            if (rowNumber === 2) {
              arraySeccion.push(cell.text)
            }
            if (rowNumber === 3) {
              sheet.sections?.forEach((section: Section) => {
                section.fields?.forEach((field: Field) => {
                  if (
                    String(field.name) === String(cell.text) &&
                    String(section.name) === String(arraySeccion[colNumber - 1])
                  ) {
                    arrayHeader.push(cell.text)
                    String(field.type?.[0].identificador) === 'dropdownlist'
                      ? arrayHeaderOption.push(field.options?.map((e) => e.label))
                      : arrayHeaderOption.push([])

                    if (field.type?.[0].identificador === 'dropdownlist_db') {
                      console.log(field.tablaData)
                      if (field.collectionName?.toLowerCase() === 'unidades') {
                        console.log(misUnidades)
                        arrayHeaderOptionBD.push(misUnidades.map((e) => e.codUnidad))
                      } else if (field.collectionName?.toLowerCase() === 'nominas') {
                        arrayHeaderOptionBD.push(field.tablaData[0]?.value.map((e) => e.id))
                      } else {
                        arrayHeaderOptionBD.push(
                          field.tablaData[0]?.value.map((e) => e.id)
                        )
                      }
                    } else {
                      arrayHeaderOptionBD.push([])
                    }
                    //TODO caso sea de unidades solo pushear las opciones que tiene asignado el admin
                    // String(field.type?.[0].identificador) === 'dropdownlist_db'
                    //   ? arrayHeaderOptionBD.push(
                    //       field.tablaData[0]?.value.map(
                    //         e => quitarTildes(e.name).toUpperCase()
                    //       )
                    //     )
                    //   : arrayHeaderOptionBD.push([])

                    arrayHeaderType.push(
                      field.type?.[0].identificador
                        ? String(field.type?.[0].identificador)
                        : 'textbox'
                    )
                    arrayHeaderAssing.push(field.assignedTo ? field.assignedTo : String(colNumber))

                    exist = true
                  }
                })
              })
              if (!exist) {
                if (cell.text !== 'Fecha Solicitud') {
                  datErroneo.push({
                    celda: getExcelLetter(colNumber) + ':' + rowNumber,
                    value: cell.text,
                  })
                }
              }
            }
            const isLastCell = colNumber === lastColumnNumber
            // Validando Campos
            if (rowNumber > 3) {
              if (isLastCell) {
                if (cell.type === ExcelJS.ValueType.Date) {
                  const fechaExcel = cell.value as Date
                  const fechaFormateada = fechaExcel.toISOString().split('T')[0]
                  obj2['fechaRegistro'] = fechaFormateada
                } else {
                  const fechaIngresoString = cell.value
                  if (
                    fechaIngresoString != null &&
                    fechaIngresoString !== undefined &&
                    typeof fechaIngresoString === 'string'
                  ) {
                    const partesFecha = fechaIngresoString.split('/')
                    const dia = Number(partesFecha[0])
                    const mes = Number(partesFecha[1])
                    const año = Number(partesFecha[2])
                    const fechaIngreso = new Date(año, mes - 1, dia)
                    const fechaFormateada = fechaIngreso.toISOString().split('T')[0]
                    // Verifica si la fecha es válida
                    if (!isNaN(fechaIngreso.getTime())) {
                      obj2['fechaRegistro'] = fechaFormateada
                    } else {
                      datErroneo.push({
                        celda: getExcelLetter(colNumber) + ':' + rowNumber,
                        value: cell.text,
                      })
                      console.log('Fecha no válida')
                    }
                  } else {
                    datErroneo.push({
                      celda: getExcelLetter(colNumber) + ':' + rowNumber,
                      value: cell.text,
                    })
                    console.log('El valor de la celda es null o undefined')
                  }
                }
              } else {
                if (cell.type === ExcelJS.ValueType.Date) {
                  const fechaExcel = cell.value as Date
                  const fechaFormateada = fechaExcel.toISOString().split('T')[0]
                  obj[arraySeccion[colNumber - 1] + '_' + arrayHeader[colNumber - 1]] =
                    fechaFormateada
                  obj2[arrayHeaderAssing[colNumber - 1]] = fechaFormateada
                } else {
                  if (arrayHeaderType[colNumber - 1] === 'date') {
                    const fechaIngresoString = cell.value
                    // Verifica si fechaIngresoString no es null ni undefined
                    if (
                      fechaIngresoString != null &&
                      fechaIngresoString !== undefined &&
                      typeof fechaIngresoString === 'string'
                    ) {
                      const partesFecha = fechaIngresoString.split('/')
                      const dia = Number(partesFecha[0])
                      const mes = Number(partesFecha[1])
                      const año = Number(partesFecha[2])
                      const fechaIngreso = new Date(año, mes - 1, dia)
                      const fechaFormateada = fechaIngreso.toISOString().split('T')[0]

                      // Verifica si la fecha es válida
                      if (!isNaN(fechaIngreso.getTime())) {
                        obj[arraySeccion[colNumber - 1] + '_' + arrayHeader[colNumber - 1]] =
                          fechaFormateada
                        obj2[arrayHeaderAssing[colNumber - 1]] = fechaFormateada
                      } else {
                        datErroneo.push({
                          celda: getExcelLetter(colNumber) + ':' + rowNumber,
                          value: cell.text,
                        })
                        console.log('Fecha no válida')
                      }
                    } else {
                      datErroneo.push({
                        celda: getExcelLetter(colNumber) + ':' + rowNumber,
                        value: cell.text,
                      })
                      console.log('El valor de la celda es null o undefined')
                    }
                  } else if (arrayHeaderType[colNumber - 1] === 'dropdownlist') {
                    obj[arraySeccion[colNumber - 1] + '_' + arrayHeader[colNumber - 1]] = cell.text
                    obj2[arrayHeaderAssing[colNumber - 1]] = cell.text
                    const exist = arrayHeaderOption[colNumber - 1].includes(cell.text)
                    if (!exist) {
                      datErroneo.push({
                        celda: getExcelLetter(colNumber) + ':' + rowNumber,
                        value: cell.text,
                      })
                    }
                  } else if (arrayHeaderType[colNumber - 1] === 'dropdownlist_db') {
                    obj[arraySeccion[colNumber - 1] + '_' + arrayHeader[colNumber - 1]] = cell.text
                    obj2[arrayHeaderAssing[colNumber - 1]] = cell.text
                    const exist = arrayHeaderOptionBD[colNumber - 1].includes(
                      quitarTildes(String(cell.text)).toUpperCase()
                    )
                    if (!exist) {
                      datErroneo.push({
                        celda: getExcelLetter(colNumber) + ':' + rowNumber,
                        value: cell.text,
                      })
                    }
                  } else {
                    obj[arraySeccion[colNumber - 1] + '_' + arrayHeader[colNumber - 1]] = cell.text
                    obj2[arrayHeaderAssing[colNumber - 1]] = cell.text
                  }
                }
              }
            }
          })
          if (rowNumber > 3) {
            arrayObjectCamposAux.push(obj)

            const stringProps: { [key: string]: string } = {}
            for (const key in obj2) {
              if (isNaN(parseInt(key))) {
                // Verifica si el nombre de la propiedad es un número en formato de cadena.
                stringProps[key] = obj2[key] // Almacena la propiedad en el nuevo objeto.
              }
            }
            arrayObjectAssingAux.push(stringProps)
          }
        })

        const validateData = validarDatos(arrayHeaderAssing, tipo)
        if (!validateData.success) {
          let codigoHTML = ''
          codigoHTML += `Los siguientes campos se necesitan cargar <br/>
                              <div class="centrar-tabla" >
                              <table style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif; text-align: left; border: 1px solid #ddd;">
                              <thead>
                                  <tr style="background-color: #000064; color: white;" >
                                    <th style="padding: 10px; border: 1px solid #ddd;" >Campos</th>
                                  </tr>
                              </thead>
                              <tbody> `
          for (const dat of validateData.data) {
            codigoHTML += '<tr style="background-color:rgb(246, 245, 250);" ><td style="padding: 5px; border: 1px solid #ccc;" >' + dat.replace(new RegExp(`^CS_`), "") + '</td></tr>'
          }
          codigoHTML += '</tbody> </table> </div>'
          showInfoHtml('error', 'Campos minimos requeridos', codigoHTML)
          setCargandoData(false)
          return
        }

        const [duplicatesIDs, duplicatesEmail] = findEmailAndIDsDuplicates(arrayObjectAssingAux)
        if (datErroneo.length === 0 && (duplicatesIDs.length > 0 || duplicatesEmail.length > 0) && tipo === "new") {
          const titleIDs = duplicatesIDs.length > 0 ? 'Documentos duplicados' : ''
          const titleEmail = duplicatesEmail.length > 0 ? 'Emails duplicados' : ''
          const both = duplicatesIDs.length > 0 && duplicatesEmail.length > 0
          const title = both ? `${titleIDs} y ${titleEmail}` : titleIDs || titleEmail

          const IDsHTML =
            duplicatesIDs.length > 0
              ? `
            Se encontraron ${duplicatesIDs.length} documentos duplicados,
            por favor verifique los campos que sean correctos <br/><br/>
            ${duplicatesIDs
                .map((id) => `<p style="margin:6px;">Nro. Documento: ${id}</p>`)
                .join('')}
          `
              : ''
          const emailsHTML =
            duplicatesEmail.length > 0
              ? `
            Se encontraron ${duplicatesEmail.length} emails duplicados,
            por favor verifique los campos que sean correctos <br/><br/>
            ${duplicatesEmail.map((email) => `<p style="margin:6px;">Email: ${email}</p>`).join('')}
          `
              : ''

          const msg = '<br/> Corrija los datos y vuelve a cargar el excel'

          const html = both ? `${IDsHTML} <br/><br/> ${emailsHTML} ${msg}` : (IDsHTML || emailsHTML) + msg

          showInfoHtml('error', title, html)
          setCargandoData(false)
          return
        }

        if (datErroneo.length > 0) {
          setCargandoData(false)
          let codigoHTML = ''
          codigoHTML += `Se encontraron campos que no son admisibles, Verificar los campos que sean correctos <br/>
                              <div class="centrar-tabla" >
                              <table style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif; text-align: left; border: 1px solid #ddd;">
                              <thead>
                                  <tr style="background-color: #000064; color: white;" >
                                    <th style="padding: 10px; border: 1px solid #ddd; width:70px" >Celda</th>
                                    <th style="padding: 10px; border: 1px solid #ddd;" >Campos</th>
                                  </tr>
                              </thead>
                              <tbody> `
          for (const dat of datErroneo) {
            codigoHTML += '<tr style="background-color:rgb(246, 245, 250);"><td style="padding: 5px; border: 1px solid #ccc;">' + dat.celda + '</td><td style="padding: 5px; border: 1px solid #ccc;">' + dat.value + '</td></tr>'
          }
          codigoHTML += '</tbody> </table> </div>'
          showInfoHtml('info', 'Campos no admisible', codigoHTML)
        } else {
          if (rol !== '' && idunidad !== '' && idproceso !== '' && tipo !== '' && validateData.success) {
            if (tipo === 'existing' && estado === '') {
              setCargandoData(false)
              showMessage('info', 'Seleccionar Campo', 'debe seleccionar estado')
            } else {
              setCargandoData(false)
              setFileValido(true)
              setArrayObjectAssing(arrayObjectAssingAux)
              setArrayObjectCampos(arrayObjectCamposAux)
            }
          } else {
            setCargandoData(false)
            if (rol === '') {
              showMessage('info', 'Seleccionar Campo', 'debe seleccionar rol')
            }
            if (tipo === '') {
              showMessage('info', 'Seleccionar Campo', 'debe seleccionar tipo')
            }
            if (!validateData.success) {
              let codigoHTML = ''
              codigoHTML += `Los siguientes campos se necesitan cargar <br/>
                                  <div class="centrar-tabla" >
                                  <table style="border-collapse: collapse; width: 100%; font-family: Arial, sans-serif; text-align: left; border: 1px solid #ddd;">
                                  <thead>
                                      <tr style="background-color: #000064; color: white;" >
                                        <th style="padding: 10px; border: 1px solid #ddd;" >Campos</th>
                                      </tr>
                                  </thead>
                                  <tbody> `
              for (const dat of validateData.data) {
                codigoHTML += '<tr style="background-color:rgb(246, 245, 250);" ><td style="padding: 5px; border: 1px solid #ccc;" >' + dat.replace(new RegExp(`^CS_`), "") + '</td></tr>'
              }
              codigoHTML += '</tbody> </table> </div>'
              showInfoHtml('error', 'Campos minimos requeridos', codigoHTML)
              setCargandoData(false)
            }
          }
        }
      }
      reader.readAsArrayBuffer(file)
    } else if (idproceso === '') {
      showMessage('info', 'Seleccionar Campo', 'debe seleccionar Proceso')
    } else if (idunidad === '') {
      showMessage('info', 'Seleccionar Campo', 'debe seleccionar Unidad')
    }

  }

  const getExcelLetter = (number: number) => {
    let result = ''
    while (number > 0) {
      const remainder = (number - 1) % 26
      result = String.fromCharCode(65 + remainder) + result
      number = Math.floor((number - 1) / 26)
    }
    return result
  }

  const getProcessSheet = (id: string) => {
    getCompleteSheet(id)
      .then((response) => {
        setSheet(response.data)
      })
      .catch((error) => console.log(error))
  }

  const refetchDataProcesos = () => {
    const options = {
      responsables: usuario?.userId,
    }
    getProceso(1, 100, options).then((response) => {
      if (response.success) {
        setProcessData(response.data.map((x: any) => ({ label: x.nombre, value: x._id })))
      } else {
        console.log(response.msg)
      }
    })
  }

  const handleInputChange = (event: any) => {
    setIdproceso(event.target.value)
  }

  const handleInputChangeUnidad = (event: any) => {
    setIdunidad(event.target.value)
  }

  const handleStateChange = (e: any) => {
    setEstado(e.target.value)
  }
  const handleChangeTipo = (e: any) => {
    setTipo(e.target.value)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/xlsx': ['.xlsx'],
    },
    maxFiles: 1,
  })

  return (
    <>
      <Container className='mt-1'>
        <Row>
          <Row>
            <Col className='mb-6'>
              <span className='fw-bold'>Proceso</span>
              <Stack className=' mt-3' direction='horizontal' gap={3}>
                <select
                  className={'form-select form-select-solid}'}
                  aria-label='Select example'
                  value={idproceso}
                  name='idproceso'
                  onChange={(event) => handleInputChange(event)}
                >
                  <option value=''>Seleccionar</option>
                  {processData.map((x: any) => (
                    <option value={x.value}> {String(x.label)}</option>
                  ))}
                </select>
              </Stack>
            </Col>
            <Col className='mb-6'>
              <span className='fw-bold'>Unidad</span>
              <Stack className=' mt-3' direction='horizontal' gap={3}>
                <select
                  className={'form-select form-select-solid}'}
                  aria-label='Select example'
                  value={idunidad}
                  name='idunidad'
                  onChange={(event) => handleInputChangeUnidad(event)}
                >
                  <option value=''>Seleccionar</option>
                  {misUnidades.map((x: any) => (
                    <option value={x.codUnidad} key={x.codUnidad}>
                      {' '}
                      {String(x.DesUnid)}
                    </option>
                  ))}
                </select>
              </Stack>
            </Col>
          </Row>

          {/* <Col className='mb-6' >
                  <span className='fw-bold'>Ficha</span>
                  <Stack className=' mt-3' direction='horizontal' gap={3}>
                  <Form.Select style={{marginLeft:'50px'}} >
                    <option value='1'>Ficha Empleado</option>
                  </Form.Select>
                  </Stack>
                </Col> */}
        </Row>
        <Row>
          <Col className='mb-6'>
            <span className='fw-bold'>Tipo Usuario</span>
            <Stack className=' mt-3' direction='horizontal' gap={3}>
              <Form.Select onChange={handleChangeTipo}>
                <option value=''>Seleccionar</option>
                <option value='new'>Nuevo</option>
                <option value='existing'>Existente</option>
              </Form.Select>
            </Stack>
          </Col>
          <Col className='mb-6'>
            {tipo === 'existing' && (
              <>
                <span className='fw-bold'>Estado</span>
                <Stack className=' mt-3' direction='horizontal' gap={3}>
                  <Form.Control
                    as='select'
                    className='custom-select'
                    size='sm'
                    onChange={handleStateChange}
                  >
                    <option value=''>Seleccionar</option>
                    {buttonsData.map((option) => (
                      <option
                        key={option.iconName}
                        value={option.stateName}
                        style={{ color: option.color }}
                      >
                        {option.title}
                      </option>
                    ))}
                  </Form.Control>
                </Stack>
              </>
            )}
          </Col>
          <Col sm={12}>
            <div className=''>
              {
                <div>
                  <div
                    {...getRootProps()}
                    className={`dropzone ${isDragActive ? 'active' : ''}`}>
                    <input {...getInputProps()} />
                    <p>Arrastra el archivo aquí <br /> o <br /> <Button type='button' className='btn-sm btn-success'> Seleccionar Archivo</Button></p>
                  </div>
                  <div className='dropzone-preview-container'>

                    <div className='dropzone-preview2'>
                      {
                        file?.name && fileValido ? 
                          <div className="d-flex align-items-center justify-content-between">
                            <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                            <div className="flex-grow-1 text-start">
                                <span className="d-block text-primary fw-bold">Nombre del archivo: {file.name}</span>
                                <hr className="m-0 mt-1 text-primary" />
                            </div>
                            <KTIcon iconName="check-circle" className="fs-3 ms-3 text-success" />
                          </div>
                        :
                          <div className="d-flex align-items-center justify-content-between">
                            <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
    
                            <div className="flex-grow-1 text-start">
                              <span className="d-block text-primary fw-bold">No se ha seleccionado ningun archivo</span>
                              <hr className="m-0 mt-1 text-primary" />
                            </div>
    
                            <KTIcon iconName="plus-circle" className="fs-3 ms-3" />
                          </div>
                      }


                    </div>
                  </div>
                </div>
              }
            </div>
          </Col>
          <Col xs='auto' className='mt-4 ms-auto'>
            <button type='button' className='btn btn-success' onClick={() => saveExcel()} >
              Guardar
            </button>
          </Col>
        </Row>
      </Container>
      {cargandoData && <OverlayLoading />}
    </>
  )
}

export { ShowCargaExcelModalForm }
