import clsx from 'clsx'
import {Fragment, useContext, useEffect, useState} from 'react'
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {cancelDocumentByUser, getDocumentDetail} from '../../../../../services/documentSent'
import {showCancellationMessage, showMessage} from '../../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../../shared/hooks/useGlobalOverlayLoading'
import {handleDownload} from '../../../../generales_functions/files'
import {DocumentLoadDetailContext} from '../DocumentLoadDetailContext'
import OverlayLoading from './../../../../generales_component/OverlayLoading'

const DocumentDetailModalForm = () => {
  const buttonsData = [
    {
      iconName: 'verify',
      title: 'Pendiente',
      stateName: 'pending',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'Firmado',
      stateName: 'signed',
      color: '#2ecc71', // Green
    },

    {
      iconName: 'exclamation-triangle', // Adjust the icon name
      title: 'Rechazado',
      stateName: 'rejected',
      color: '#e74c3c', // Red
    },
  ]

  const {
    closeDocumentDetailModal,
    openDocumentQuickViewModal,
    openDocumentDetailModal,
    quickViewModalData,
    data,
    setViewDocumento,
    setShowFileModal,
  } = useContext(DocumentLoadDetailContext)
  const [users, setUsers] = useState([])
  const [cargandoFile, setCargandoFile] = useState(false)
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const [documentDetail, setDocumentDetail] = useState<any>([])
  useEffect(() => {
    getDetail({
      loadId: data._id,
      processId: quickViewModalData.idproceso,
      fileId: quickViewModalData._id,
    })
    getUsers()
  }, [])

  const getUsers = () => {
    if (quickViewModalData.typeSelected === 'Personalizado') {
      const fileView = data?.filesChildren?.find((x: any) => {
        return x._id === quickViewModalData._id
      })
      setUsers(data?.idUsers?.filter((user: any) => user.dni === fileView.dni))
      // setUsers(data?.idUsers)
    } else {
      setUsers(data?.idUsers)
    }
  }

  const getDetail = async (params: any) => {
    try {
      setGlobalOverlayLoading(true)
      const response = await getDocumentDetail(params)

      if (response.success) {
        setDocumentDetail(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const getDocumentByUser = (userId: string) => {
    return documentDetail.find((x: any) => x.userId === userId)
  }

  const showPreview = async (item: any) => {
    setCargandoFile(true)
    console.log('item')
    console.log(item)
    setViewDocumento({
      uri: '',
      fileType: 'PDF',
      fileName: item.name,
      keyName: item.keyname,
      showSignature: item.state === 'pending',
      signCeo: item.includeSign,
      idUserSign: item?.signMetadata?.signFrom,
    })
    setShowFileModal(true)
    setCargandoFile(false)

    /* const result = await handleS3Convert64(item.keyname, quickViewModalData.name)
      if (result) {
        setViewDocumento(result)
        setShowFileModal(true)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      } */
    /* if (quickViewModalData.type === "PLANTILLA") {
      const result = await handleS3PlantillaMatch(idUser, quickViewModalData._id, quickViewModalData.idproceso, quickViewModalData.keyname, quickViewModalData.filename)
      if (result) {
        handleDownloadBase64(result.uri, quickViewModalData.filename)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      }
    } else {
      const result = await handleS3Convert64(quickViewModalData.keyname, quickViewModalData.filename)
      if (result) {
        setViewDocumento(result)
        setShowFileModal(true)
        setCargandoFile(false)
      } else {
        setCargandoFile(false)
      }
    } */
  }

  const manejarDescarga = (userId: string) => {
    const document = getDocumentByUser(userId)

    handleDownload(
      document.keyname,
      document.keyname.split('/')[document.keyname.split('/').length - 1]
    )
  }

  const getStateByUserId = (userId: string) => {
    const document = getDocumentByUser(userId)

    if (document) {
      const color = buttonsData.find((n) => n.stateName === document.state)?.color

      return (
        <span
          style={{
            backgroundColor: color,
            color: 'white',
            padding: '0.2em 0.5em',
            borderRadius: '0.25rem',
            marginRight: '0.5em',
            fontSize: '0.8em',
          }}
        >
          {getFriendlyState(document.state)}
        </span>
      )
    } else {
      return (
        <span
          style={{
            backgroundColor: '#f39c12',
            color: 'white',
            padding: '0.2em 0.5em',
            borderRadius: '0.25rem',
            marginRight: '0.5em',
            fontSize: '0.8em',
          }}
        >
          {getFriendlyState('pending')}
        </span>
      )
    }
  }

  const getFriendlyState = (value: string): string => {
    switch (value) {
      case 'pending':
        return 'Pendiente'
      case 'signed':
        return 'Firmado'
      case 'rejected':
        return 'Rechazado'
      default:
        return value
    }
  }

  const cancelFile = async (userIdSent: string, loadId: string, fileId: string) => {
    try {
      const cancelResponse = await showCancellationMessage()

      if (cancelResponse.isConfirmed) {
        setGlobalOverlayLoading(true)
        const response = await cancelDocumentByUser({fileId, loadId, userIdSent})
        await getDetail({
          loadId: data._id,
          processId: quickViewModalData.idproceso,
          fileId: quickViewModalData._id,
        })

        if (response.success) {
          showMessage('success', '¡Buen trabajo!', response.message)
        } else {
          showMessage('error', 'Hubo un error', response.message)
        }
      }
    } catch (error) {
      console.log(error)
      showMessage('error', 'Hubo un error', (error as Error).message)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  return (
    <>
      <div>
        <Container>
          <Row className='mb-5'>
            <Col sm>
              <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-end'>
                  <h5>{quickViewModalData.name}</h5>
                </div>
                <Form.Control as='select' className='w-50'>
                  <option value=''>Todos</option>
                  {buttonsData.map((option) => (
                    <option
                      key={option.iconName}
                      value={option.stateName}
                      style={{color: option.color}}
                    >
                      {option.title}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col sm>
              <Table bordered size='sm'>
                <thead>
                  <tr>
                    <th className='text-start'>DNI</th>
                    <th className='text-start'>Colaborador</th>
                    <th className='text-start'>Estado de firma</th>
                    <th className='text-start'>Estado documento</th>
                    <th className='text-end'>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item: any) => (
                    <Fragment key={item._id}>
                      <tr>
                        <td className='text-start fw-bold'>{item.dni}</td>
                        <td className='text-start fw-bold'>{item.nombre}</td>
                        {getDocumentByUser(item._id)?.documentSentState ? (
                          <td className='text-start fw-bold'>{getStateByUserId(item._id)}</td>
                        ) : (
                          <td className='text-start fw-bold'>
                            <Badge bg='warning' className='text-white'>
                              No aplica
                            </Badge>
                          </td>
                        )}
                        {getDocumentByUser(item._id) != null ? (
                          <td className='text-start fw-bold'>
                            <Badge
                              className={clsx(
                                {
                                  'bg-success text-white': getDocumentByUser(item._id)
                                    ?.documentSentState,
                                },
                                {
                                  'bg-danger text-white': !getDocumentByUser(item._id)
                                    ?.documentSentState,
                                }
                              )}
                            >
                              {getDocumentByUser(item._id)?.documentSentState
                                ? 'Enviado'
                                : 'Eliminado'}
                            </Badge>
                          </td>
                        ) : (
                          <td className='text-start fw-bold'>
                            <Badge bg='warning' className='text-white'>
                              No enviado
                            </Badge>
                          </td>
                        )}
                        <td className='text-end'>
                          {getDocumentByUser(item._id)?.state === 'rejected' && (
                            <OverlayTrigger
                              trigger='click'
                              placement='left'
                              overlay={
                                <Popover id='rejected-message-popober'>
                                  <Popover.Header as='h3'>Motivo de Rechazo</Popover.Header>
                                  <Popover.Body>
                                    {getDocumentByUser(item._id).rejectionMessage}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                title='Motivo de Rechazo'
                              >
                                <KTIcon iconName='message-text' className='fs-3' />
                              </button>
                            </OverlayTrigger>
                          )}
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title='Visualización'
                            onClick={() => showPreview(getDocumentByUser(item._id))}
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title='Descargar'
                            onClick={() => manejarDescarga(item._id)}
                          >
                            <KTIcon iconName='arrow-down' className='fs-3' />
                          </button>

                          {getDocumentByUser(item._id)?.documentSentState &&
                            getDocumentByUser(item._id)?.state === 'pending' && (
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                title='Eliminar documento para este usuario'
                                onClick={() => {
                                  const doc = getDocumentByUser(item._id)
                                  cancelFile(doc?.userId, doc?.loadId, doc?.fileId)
                                }}
                              >
                                <KTIcon iconName='cross-square' className='fs-3' />
                              </button>
                            )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>

              {cargandoFile && <OverlayLoading />}
              {/* <pre>{JSON.stringify(quickViewModalData, null, 2)}</pre>
              <pre>{JSON.stringify(users, null, 2)}</pre> */}
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-end'>
              <Button
                variant='light'
                className='mx-3'
                type='reset'
                onClick={() => {
                  closeDocumentDetailModal()
                }}
              >
                Cancelar
              </Button>
              {/* <Button variant='primary' type='submit'>
                {!false && 'Guardar'}
                {false && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Guardando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button> */}
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}

export {DocumentDetailModalForm}
