import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import {getUserById} from '../../../../services/users'
import {useMyCompanies} from '../../../../shared/hooks/useMyCompanies'
import {WorkingDocs} from '../../../../types-module/WDocsNS'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {UsuariosPageContext} from '../context/UsersPageContext'

const stateOptions = [
  {value: true, label: 'Activos'},
  {value: false, label: 'Inactivos'},
]

const UsersHeader: React.FC = () => {
  const {myCompaniesOptions, rolsByCompany} = useMyCompanies()
  const [empresasUnidades, setEmpresasUnidades] = useState<Record<string, any>>({})
  const {optionsSelected, setOptionsSelected, setUsers} = useContext(UsuariosPageContext)
  const {userData} = useAuth2()
  const unidadRef = useRef<any | null>(null)

  const misUnidades = useMemo(() => {
    return empresasUnidades[optionsSelected.empresa] ?? []
  }, [optionsSelected, empresasUnidades])

  const fullCompaniesOptions = myCompaniesOptions.filter((company: any) => {
    const rols: string[] = rolsByCompany.find((x) => x.empresa === company.value)?.rol ?? []

    return rols.includes('SUPER')
  })

  useEffect(() => {
    getUserById(userData?.userId).then((res) => {
      if (res.success) {
        const empresasUnidades = (res?.data as WorkingDocs.User)?.unidad?.reduce(
          (acc: any, unidad) => {
            if (acc[unidad.codEmpresa]) {
              acc[unidad.codEmpresa].push({
                value: unidad.codUnid,
                label: unidad.DesUnid,
              })
            } else {
              acc[unidad.codEmpresa] = [
                {
                  value: unidad.codUnid,
                  label: unidad.DesUnid,
                },
              ]
            }

            return acc
          },
          {}
        )

        setEmpresasUnidades(empresasUnidades)
      }
    })
  }, [])

  useEffect(() => {
    if (myCompaniesOptions.length > 0) {
      setOptionsSelected({
        ...optionsSelected,
        empresa: myCompaniesOptions[0]?.value,
      })
    }

    if (misUnidades.length > 0) {
      setOptionsSelected({
        ...optionsSelected,
        unidad: misUnidades[0]?.value,
      })
    }
  }, [myCompaniesOptions, misUnidades])

  return (
    <Row>
      <Col>
        <Form.Label>Empresa</Form.Label>
        <Select
          options={fullCompaniesOptions}
          placeholder='Seleccione una empresa'
          // menuPortalTarget={document.body}
          value={fullCompaniesOptions.find((x: any) => x.value === optionsSelected.empresa)}
          onChange={(e) => {
            setOptionsSelected({
              ...optionsSelected,
              empresa: e?.value ?? '',
              unidad: '',
            })
          }}
          className='same-height'
        />
      </Col>

      <Col>
        <Form.Label>Unidad</Form.Label>
        <Select
          options={misUnidades}
          placeholder='Seleccione una unidad'
          ref={unidadRef}
          // menuPortalTarget={document.body}
          value={misUnidades.find((x: any) => x.value === optionsSelected.unidad)}
          onChange={(e) => {
            setOptionsSelected({
              ...optionsSelected,
              unidad: e?.value ?? '',
            })
          }}
        />
      </Col>

      <Col>
        <Form.Label>Estado</Form.Label>
        <Select
          options={stateOptions}
          defaultValue={stateOptions[0]}
          placeholder='Seleccione un estado'
          // menuPortalTarget={document.body}
          value={stateOptions.find((x) => x.value === optionsSelected.estado)}
          onChange={(e) => {
            setOptionsSelected({
              ...optionsSelected,
              estado: e?.value ?? true,
            })
          }}
        />
      </Col>
      
      <Col>
        <Form.Label>Buscar</Form.Label>
        <Form.Control
          type='text'
          placeholder='Busca por Nombre | Apellidos | Correo | Nro Documento '
          onChange={(e) => setOptionsSelected({...optionsSelected, searchTerm: e.target.value})}
          className='form-control-sm same-height'
        />
      </Col>
    </Row>
  )
}

export {UsersHeader}
