import { useEffect, useState } from 'react'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {External, initExternalData, initFieldData, initUpdateFieldMigration, PartnerContext, UpdateFieldMigration} from './PartnerContexts'
import { WNomina } from './WNominaInterface'
import { WNominaTrayTabs } from './components/WNominaTrayTabs'
import { Sheet,Field } from '../fichas/Sheet'


const Breadcrumbs = [
  {
    title: 'Seguridad',
    path: '/seguridad/asignaciontabla',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const DataWNomina:WNomina = {
  _id:'', 
  nombre: '',
  url: '',
  contenido: '',
  id_empresa: '',
  estado: true
}


const WNominaPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [wNominaData, setWNominaData] = useState<WNomina>(DataWNomina)
  const [edit,setEdit] = useState(false)
  const [reloadTable,setReloadTable] = useState(false)
  const [sheet_id, setSheetId] = useState('')
  const [idficha, setIdFicha] = useState('')
  const [sheet, setSheet] = useState<Sheet>({name: "",type: "",description: "",sections: []})
  const [fichaData, setFichaData] = useState<{label: string, value: string}[]>([])
  const [loadingFichaContent, setLoadingFichaContent] = useState(false)
  const [updateFieldMigration, setUpdateFieldMigration] = useState<UpdateFieldMigration>(initUpdateFieldMigration)
  const [openAssignmentModal,setOpenAssignmentModal] = useState(false)
  const [fieldData, setFieldData] = useState<Field>(initFieldData)
  const [externalData, setExternalData] = useState<External>(initExternalData)
  const [updateFieldAssing,setUpdateFieldAssing] = useState(false)
  const [reloadServiceExternal,setReloadServiceExternal] = useState(false)
  const [showExportPlantillaExcel,setShowExportPlantillaExcel] = useState(false)
  const [showCargarPlantillaExcel,setShowCargarPlantillaExcel] = useState(false)
  const [selectGroupName, setSelectGroupName] = useState('')
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false)
  const [infoData, setInfoData] = useState<{}>({})

  

  return (
    <>
      <PartnerContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        wNominaData, 
        setWNominaData,
        edit,
        setEdit,
        reloadTable,
        setReloadTable,
        sheet_id, 
        setSheetId,
        idficha, 
        setIdFicha,
        sheet, 
        setSheet,
        fichaData,
        setFichaData,
        loadingFichaContent, 
        setLoadingFichaContent,
        updateFieldMigration,
        setUpdateFieldMigration,
        openAssignmentModal,
        setOpenAssignmentModal,
        fieldData,
        setFieldData,
        externalData,
        setExternalData,
        updateFieldAssing,
        setUpdateFieldAssing,
        reloadServiceExternal,
        setReloadServiceExternal,
        showExportPlantillaExcel,
        setShowExportPlantillaExcel,
        showCargarPlantillaExcel,
        setShowCargarPlantillaExcel,
        selectGroupName, 
        setSelectGroupName,
        isInfoModalOpen,
        setIsInfoModalOpen,
        infoData, 
        setInfoData,
      }}>
        <PageTitle breadcrumbs={Breadcrumbs}>WNomina</PageTitle>
          <WNominaTrayTabs />          
      </PartnerContext.Provider>
    </>
  )
}

export {WNominaPage}
