import { fetchConToken } from '../helpers/fetch'
import { WorkingDocs } from '../types-module/WDocsNS'

const getAdminUsers = async () => {
  const resp = await fetchConToken('user/admin', {}, 'GET')

  const body = await resp.json()

  return body
}

const getAllUsersPag = async (currentPage: any, numberData: any, options: any) => {
  const queryString = new URLSearchParams(options).toString()

  const resp = await fetchConToken(`user/${currentPage}/${numberData}?${queryString}`, {}, 'GET')

  const body = await resp.json()

  return body
}

const getAllUsersSinPag = async () => {
  const resp = await fetchConToken('user', {}, 'GET')

  const body = await resp.json()

  return body
}

const getAllUsersAsignacionSinPag = async () => {
  const resp = await fetchConToken('user/asignaciongrupo', {}, 'GET')

  const body = await resp.json()

  return body
}

const updateUserData = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(`user/roles/${id}`, updatedData, 'PUT')
  const body = await resp.json()

  return body
}

const updateUserEnterpricesAndRols = async (
  id: string,
  updateData: {rol?: string[]; empresa: string[]; contraseña: string}
) => {
  const resp = await fetchConToken(`user/enterprices/rols/${id}`, updateData, 'PUT')

  return await resp.json()
}

const acceptTerminosCondicionesUser = async (id: string, updatedData: any) => {
  const resp = await fetchConToken(`user/accept/terminosycondiciones/${id}`, updatedData, 'PUT')
  const body = await resp.json()

  return body
}

const getRoles = async (): Promise<{
  success: boolean
  data?: WorkingDocs.Role[]
  error?: string
}> => {
  const resp = await fetchConToken('user/getroles/', {}, 'GET')
  const body = await resp.json()

  return body
}

const getTerminosUserId = async (id: string) => {
  const resp = await fetchConToken(`user/getterminos/${id}`, {}, 'GET')
  const body = await resp.json()

  return body
}

const deleteUser = async (id: string) => {
  const resp = await fetchConToken(`user/${id}`, {}, 'DELETE')

  const body = await resp.json()
  return body
}

const activarUser = async (id: string) => {
  const resp = await fetchConToken(`user/activar/${id}`, {}, 'PUT')

  const body = await resp.json()
  return body
}
const updateUser = async (
  id: string,
  updateData: {unidades: {empresaId: string; codUnidad: string; DesUnid: string}[]}
) => {
  const resp = await fetchConToken(`user/updateuser/${id}`, updateData, 'PUT')

  return await resp.json()
}

const getRolsByCompany = async () => {
  const resp = await fetchConToken('user/rols_by_enterprice', {}, 'GET')
  return await resp.json()
}

const createUser = async (data: {
  nombres: string
  apellido_paterno: string
  apellido_materno: string
  email: string
  empresa: string[]
  dni: string
  roles: {rol: string[]; empresa: string}[]
}) => {
  const resp = await fetchConToken('user/create', data, 'POST')
  const body = await resp.json()

  return body
}

const getUserById = async (id: string) => {
  const resp = await fetchConToken(`user/list/get/${id}`, {}, 'POST')
  const body = await resp.json()

  return body
}

export {
  acceptTerminosCondicionesUser,
  activarUser, createUser, deleteUser,
  getAdminUsers,
  getAllUsersAsignacionSinPag,
  getAllUsersPag,
  getAllUsersSinPag,
  getRoles,
  getRolsByCompany,
  getTerminosUserId, getUserById, updateUser,
  updateUserData,
  updateUserEnterpricesAndRols
}

