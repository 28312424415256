import ExcelJS from 'exceljs'
import React, {useContext, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {z} from 'zod'
import {KTIcon} from '../../../../../_metronic/helpers'
import {getAllCompaniesByPais} from '../../../../services/companies'
import {createUser, getRoles} from '../../../../services/users'
import {showInfoHtml, showMessage, showValidatePassword} from '../../../../shared/Alerts'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useMyCompanies} from '../../../../shared/hooks/useMyCompanies'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PartnerContext} from '../PartnerContexts'

const userSchema = z.object({
  dni: z.string().nonempty(),
  nombres: z.string().nonempty(),
  apellido_paterno: z.string().nonempty(),
  apellido_materno: z.string().nonempty(),
  email: z.string().email().nonempty(),
  empresa: z.string().nonempty(),
  perfil: z.string().nonempty(),
})

const ROLE_FILTER = 'ADMIN'
const ROLE_TOP = 'SUPERADMIN'

const BulkAddUserContent: React.FC = () => {
  const {selectedRole} = useAuth2()
  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const {rolsByCompany, myCompaniesOptions} = useMyCompanies()
  const {closeBulkAddUserModal} = useContext(PartnerContext)
  const [allRoles, setAllRoles] = useState<Record<string, any>[]>([])
  const [allCompanies, setAllCompanies] = useState<Record<string, any>[]>([])

  useEffect(() => {
    Promise.all([getRoles(), getAllCompaniesByPais()]).then((responses) => {
      const [responseRoles, responseCompanies] = responses

      if (responseRoles.success) {
        setAllRoles(
          responseRoles?.data?.map((x) => ({
            label: x.nombre_perfil,
            value: x.rol,
          })) ?? []
        )
      }

      if (responseCompanies.success) {
        setAllCompanies(responseCompanies?.data ?? [])
      }
    })
  }, [])

  const companiesOptions =
    selectedRole === ROLE_TOP
      ? allCompanies.map((e) => ({value: e?._id, label: e?.nombre}))
      : myCompaniesOptions.filter((company: any) => {
          const rols: string[] = rolsByCompany.find((x) => x.empresa === company.value)?.rol ?? []

          return rols.includes(ROLE_FILTER)
        })

  const listRolesFilter =
    selectedRole === ROLE_TOP
      ? allRoles
      : allRoles?.filter((roles: any) => ![ROLE_FILTER, ROLE_TOP].includes(roles.value))

  const createExcel = async () => {
    try {
      setGlobalOverlayLoading(true)
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet()

      const enterpricesWS = workbook.addWorksheet('Empresas')
      const rolesWS = workbook.addWorksheet('Perfiles')

      enterpricesWS.columns = [
        {
          header: 'Empresa',
          key: 'Empresa',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'Id Empresa',
          key: 'Id Empresa',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
      ]

      rolesWS.columns = [
        {
          header: 'Perfil',
          key: 'Perfil',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'Id Perfil',
          key: 'Id Perfil',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
      ]

      enterpricesWS.getRow(1).eachCell((cell) => {
        cell.font = {
          name: 'Calibri',
          size: 14,
          bold: true,
          color: {argb: 'FFFFFF'},
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '0A58CA'},
        }
      })

      rolesWS.getRow(1).eachCell((cell) => {
        cell.font = {
          name: 'Calibri',
          size: 14,
          bold: true,
          color: {argb: 'FFFFFF'},
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '0A58CA'},
        }
      })

      companiesOptions.forEach((company: any) => {
        enterpricesWS.addRow([company.label, company.value])
      })

      listRolesFilter.forEach((role: any) => {
        rolesWS.addRow([role.label, role.value])
      })

      worksheet.columns = [
        {
          header: 'dni',
          key: 'dni',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'nombres',
          key: 'nombres',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'apellido_paterno',
          key: 'apellido_paterno',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'apellido_materno',
          key: 'apellido_materno',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'email',
          key: 'email',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'empresa',
          key: 'empresa',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
        {
          header: 'perfil',
          key: 'perfil',
          width: 30,
          style: {
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          },
        },
      ]

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = {
          name: 'Calibri',
          size: 14,
          bold: true,
          color: {argb: 'FFFFFF'},
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '0A58CA'},
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'Plantilla carga de usuarios.xlsx'
      a.click()
    } catch (error) {
      console.log(error)
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const readExcel = async (excel: File, password: string) => {
    try {
      setGlobalOverlayLoading(true)

      const data: any[] = await new Promise((resolve, reject) => {
        const workbook = new ExcelJS.Workbook()
        const reader = new FileReader()

        const headers: any[] = []
        const data: any[] = []

        reader.onload = async (e: any) => {
          const arrayBuffer = e.target.result
          await workbook.xlsx.load(arrayBuffer)
          const worksheet = workbook.getWorksheet(1)
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              row.eachCell((cell, colNumber) => {
                headers.push(cell.value?.toString())
              })
            } else {
              const rowData: any = {}
              row.eachCell((cell, colNumber) => {
                rowData[headers[colNumber - 1]] = cell.value?.toString()
              })
              data.push(rowData)
            }
          })

          resolve(data)
        }

        reader.onerror = reject

        reader.readAsArrayBuffer(excel as File)
      })

      const dataFormat = data.map((item: any) => {
        const existEmpresa = companiesOptions.find(
          (company: any) => company.value === item['empresa']
        )
        const existPerfil = listRolesFilter.find((role: any) => role.value === item['perfil'])

        if (!existEmpresa) {
          throw new Error('Empresa no encontrada, por favor use el identificador de la empresa')
        }

        if (!existPerfil) {
          throw new Error('Perfil no encontrado, por favor use el identificador del perfil')
        }

        if (!userSchema.safeParse(item).success) {
          throw new Error(
            'Formato de datos no válido, asegúrese de que los datos estén en el formato correcto del excel proporcionado'
          )
        }

        const empresa = item['empresa']
        item['empresa'] = [empresa]

        item['roles'] = [
          {
            rol: [item['perfil']],
            empresa: empresa,
          },
        ]
        item['type_user'] = 'USER'
        item['password'] = password
        delete item['perfil']

        return item
      })

      const unsuccessful: any[] = []
      const successful: any[] = []

      for await (const dataUser of dataFormat) {
        const response = await createUser(dataUser)

        if (response.success) {
          successful.push(response)
        } else {
          unsuccessful.push({...response, dni: dataUser.dni})
        }
      }

      const hasUnsuccessful = unsuccessful.length > 0
      const icon = hasUnsuccessful ? 'info' : 'success'
      const title = hasUnsuccessful
        ? 'Hubieron algunos inconvenientes al crear algunos usuarios'
        : 'Se crearon correctamente los usuarios'

      showInfoHtml(
        icon,
        title,
        `
				<p>Se crearon ${successful.length} usuarios correctamente</p>
				<p>Se encontro ${unsuccessful.length} usuarios con errores</p>
				<p>Los siguientes usuarios con Nro documento no se crearon correctamente</p>
				<p>${unsuccessful.map((u) => u?.dni).join('</br>')}</p>
			`
      ).finally(() => closeBulkAddUserModal())
    } catch (error) {
      console.log(error)
      showMessage('error', 'Hubo un error', (error as Error)?.message ?? '')
    } finally {
      setGlobalOverlayLoading(false)
    }
  }

  const onDrop = (acceptedFiles: File[]) => {
    showValidatePassword().then(async (result) => {
      if (result.isConfirmed) {
        const password = result.value
        readExcel(acceptedFiles?.[0], password)
      }
    })
  }

  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'application/xlsx': ['.xlsx'],
    },
    maxFiles: 1,
  })

  return (
    <section>
      <div className='mb-4'>
        <Button type='button' className='btn-sm btn-primary' onClick={createExcel}>
          Exportar Plantilla
        </Button>
      </div>

      <div>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          <p>
            Arrastra el archivo aquí <br /> o <br />{' '}
            <Button type='button' className='btn-sm btn-success'>
              {' '}
              Seleccionar Archivo
            </Button>
          </p>
        </div>
        <div className='dropzone-preview-container'>
          <div className='dropzone-preview2'>
            <div className='d-flex align-items-center justify-content-between'>
              <KTIcon iconName='file-sheet' className='fs-3 text-primary me-3' />

              <div className='flex-grow-1 text-start'>
                <span className='d-block text-primary fw-bold'>
                  {acceptedFiles?.[0]
                    ? acceptedFiles[0].name
                    : 'No se ha seleccionado ningun archivo'}
                </span>
                <hr className='m-0 mt-1 text-primary' />
              </div>

              <KTIcon iconName='plus-circle' className='fs-3 ms-3' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {BulkAddUserContent}
