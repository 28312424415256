import { Switch } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { AiOutlineRight } from 'react-icons/ai'; // Importar el icono de flecha de react-icons
import { fetchMenuActionsByRoleMenu, fetchMenuDataWithActions, updateActionRole } from '../../../../services/menu';
import { PartnerContext } from '../PartnerContexts';
import { getRoles } from '../../../../services/rol';
import { WorkingDocs } from '../../../../types-module/WDocsNS';
import { getAllCompanies, getUnidadesEmpresas } from '../../../../services/companies';
import {DataUser} from '../../../documentacion/MiFicha/DataUserInterface'
import {useAuth2} from '../../../authv2/Auth2Provider'

interface SubMenu {
  id_padre: string;
  id_hijo: string;
  titulo: string;
  url: string;
  fontIcon: string;
  order: number;
  acciones: string[];
  hasRole: boolean;
}

interface Menu {
  _id: string;  
  titulo: string;
  icono: string;
  order: number;
  hijos: SubMenu[];
  hasRole: boolean;
}

interface RoleAction {
  subMenuId: string;
  acciones: string[];
  parent_id: string;
  parent_id2: string
}

const ActionAsignacionForm = () => {
  const { role, setRole, reloadTable } = useContext(PartnerContext);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [roleActions, setRoleActions] = useState<RoleAction[]>([]);
  const [visibleActions, setVisibleActions] = useState<{ [key: string]: boolean }>({});
  const [checkStates, setCheckStates] = useState<{ [subMenuId: string]: { [accion: string]: boolean } }>({});
  const [empresa, setEmpresa] = useState('')
  const [unidad, setUnidad] = useState('')
  const [empresaId, setEmpresaId] = useState('')
  const [unidadId, setUnidadId] = useState('')

  const [allRoles, setAllRoles] = useState<WorkingDocs.Role[]>([])
  const [allEmpresas, setAllEmpresas] = useState<WorkingDocs.Company[]>([])
  const [allUnidades, setAllUnidades] = useState<WorkingDocs.UnidadCompany[]>([])

  const {selectedCompanyId, userData} = useAuth2()
  const userId = userData.userId


  useEffect(() => {
    fetchMenus()
    getRoles().then(res => {
      if (res.success) {
        setAllRoles(res?.data ?? [])
      }
      getAllCompanies('',1,10).then((res)=>
        {
          if(res.success){
            setAllEmpresas(res?.data??[])
          }
        })
    })
  }, [role, reloadTable]);

  useEffect(() => {
    fetchMenus()
    if(empresaId){
      getUnidadesEmpresas([String(empresaId)], userId).then((res) => {
        if (res.success) {
          const unidades = res.data.flatMap((empresa: any) => empresa.unidad ?? []);
          setAllUnidades(unidades);
        }
      });
    }
      
    }, [empresaId, reloadTable]);

    useEffect(() =>{
      fetchMenus()
    },[unidad, reloadTable]);
  
    
    useEffect(() => {
      const newCheckStates: Record<string, Record<string, boolean>> = {};
  
      roleActions.forEach((ra) => {
          newCheckStates[ra.subMenuId] = {};
  
          ra.acciones.forEach((accion) => {
              newCheckStates[ra.subMenuId][accion] = true;
          });
      });
  
      setCheckStates(newCheckStates);
  }, [roleActions]);
  
  
  const fetchMenus = async () => {
    try {
      const menuResponse = await fetchMenuDataWithActions();
      const roleActionResponse = await fetchMenuActionsByRoleMenu(role, empresaId, unidad);
      if (menuResponse.success && roleActionResponse.success) {
        setMenus(menuResponse.data);
        const formattedRoleActions = roleActionResponse.data.map((action: any) =>
        ({
          subMenuId:action.subMenuId,
          acciones:action.acciones,
          parent_id:roleActionResponse.parent_id,
          parent_id2:roleActionResponse.parent_id2
        }));
        setRoleActions(formattedRoleActions);
      }else{
        setMenus([])
      }
    } catch (error) {
      console.error('Error fetching menus with actions:', error);
    }
  };

  const hasRole = (subMenuId: string, action: string): boolean => {

    const roleAction = roleActions.find((ra) => 
        ra.subMenuId === subMenuId &&
        (empresaId ? ra.parent_id === empresaId : true) &&
        (unidad ? ra.parent_id2 === unidad : true)
    );

    return roleAction ? roleAction.acciones.includes(action) : false;
};

const handlePermissionToggle = async (
  menuId: string,
  subMenuId: string,
  actionTitle: string,
  isPermitted: boolean
) => {
  try {
      setRoleActions((prevRoleActions) =>
          prevRoleActions.map((roleAction) =>
              roleAction.subMenuId === subMenuId &&
              (!empresaId || roleAction.parent_id === empresaId) &&
              (!unidad || roleAction.parent_id2 === unidad)
                  ? {
                        ...roleAction,
                        acciones: isPermitted
                            ? [...roleAction.acciones, actionTitle]
                            : roleAction.acciones.filter((accion) => accion !== actionTitle),
                            parent_id: roleAction.parent_id, 
                            parent_id2: roleAction.parent_id2,
                          }
                  : roleAction
          )
      );

      setCheckStates((prevCheckStates) => ({
          ...prevCheckStates,
          [subMenuId]: {
              ...prevCheckStates[subMenuId],
              [actionTitle]: isPermitted,
          },
      }));

      const payload = {
          role,
          empresaId,
          unidad,
          subMenuId,
          actionTitle,
          isPermitted,
      };

      const response = await updateActionRole(payload);
      if (!response.success) {
          setRoleActions((prevRoleActions) =>
              prevRoleActions.map((roleAction) =>
                  roleAction.subMenuId === subMenuId &&
                  (!empresaId || roleAction.parent_id === empresaId) &&
                  (!unidad || roleAction.parent_id2 === unidad)
                      ? {
                            ...roleAction,
                            acciones: isPermitted
                                ? roleAction.acciones.filter((accion) => accion !== actionTitle)
                                : [...roleAction.acciones, actionTitle],
                            parent_id: roleAction.parent_id, 
                            parent_id2: roleAction.parent_id2,
                        }
                      : roleAction
              )
          );

          setCheckStates((prevCheckStates) => ({
              ...prevCheckStates,
              [subMenuId]: {
                  ...prevCheckStates[subMenuId],
                  [actionTitle]: !isPermitted,
              },
          }));

          console.error('Error updating action role:', response.message);
      }
  } catch (error) {
      console.error('Error updating action role:', error);
  }
};
  
  

  const toggleActionsVisibility = (subMenuId: string) => {
    setVisibleActions((prev) => ({
      ...prev,
      [subMenuId]: !prev[subMenuId],
    }));
  };

  return (
    <Container>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Gestión de Permisos de Acciones</h3>
        </div>
        <div className="card-body">
          <Form.Group controlId="roleSelect" style={{ width: '200px', marginBottom: '20px' }}>
            <Form.Label>Seleccione el Rol</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={{ fontSize: '14px' }}
            >
              {
                allRoles.map((rol: any) => (
                  <option key={rol.codigo_perfil} value={rol.codigo_perfil}>{rol.nombre_perfil}</option>
                ))
              }
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='empresaSelect' style={{width: '200px', marginBottom: '20px'}}>
            <Form.Label>Seleccione la Empresa</Form.Label>
            <Form.Control
              as='select'
              value={empresaId}
              onChange={(e) => setEmpresaId(e.target.value)}
              style={{fontSize: '14px'}}
            >
              <option value = ''>
              Predeterminado
            </option>
              {allEmpresas.map((empresa: any) => (
                <option key={empresa._id} value={empresa._id}>
                  {empresa.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='unidadSelect' style={{width: '200px', marginBottom: '20px'}}>
            <Form.Label>Seleccione la Unidad</Form.Label>
            <Form.Control
              as='select'
              value={unidad}
              onChange={(e) => setUnidad(e.target.value)}
              style={{fontSize: '14px'}}
            >
              <option value = ''>
              Predeterminado
            </option>
                {allUnidades.map((unidad: any) => (
                  <option key={unidad.codUnidad} value={unidad.codUnidad}>
                    {unidad.DesUnid}
                  </option>
                ))}
            </Form.Control> 
          </Form.Group> 

          <div style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
          {menus.length > 0 ? (
            menus.map((menu) => (
              <div key={menu._id} style={{ marginBottom: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
                  <h5>{menu.titulo}</h5>
                </div>
                {menu.hijos && menu.hijos.length > 0 ? (
                  menu.hijos.map((subMenu) => (
                    <div key={subMenu.id_hijo} style={{ marginLeft: '30px', marginBottom: '20px' }}>
                      <h6
                        style={{ marginBottom: '10px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        onClick={() => toggleActionsVisibility(subMenu.id_hijo)}
                      >
                        {subMenu.acciones.length > 0 && (
                          <AiOutlineRight
                            style={{
                              marginRight: '5px',
                              transition: 'transform 0.3s',
                              transform: visibleActions[subMenu.id_hijo] ? 'rotate(90deg)' : 'rotate(0deg)',
                            }}
                          />
                        )}
                        {subMenu.titulo}
                      </h6>
                      {visibleActions[subMenu.id_hijo] &&
                        subMenu.acciones.map((accion) => (
                          <div key={accion} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '30px' }}>
                            <span>{accion}</span>
                            <Switch
                              checked={!!checkStates[subMenu.id_hijo]?.[accion]}
                              onChange={(e:any) => handlePermissionToggle(menu._id, subMenu.id_hijo, accion, e.target.checked)}
                              color="primary"
                            />
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p style={{ color: 'red', marginLeft: '30px' }}>No se puede traer el listado de acciones</p>
                )}
              </div>
            ))
          ) : (
            <p style={{ marginLeft: '30px' }}>No existe listado de acciones para este perfil.</p>
          )}

          </div>
        </div>
      </div>
    </Container>
  );
};

export default ActionAsignacionForm;
