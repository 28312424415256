import React, { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import '../../../documentacion/MiFicha/components/styles.css';
import { KTSVG } from '../../../../../_metronic/helpers';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button } from 'react-bootstrap';
import { KTIcon } from '../../../../../_metronic/helpers';


interface DropzoneExampleProps {
    onSelected: (Image:File|null) => void;
    shouldClear: boolean;
    onClear: () => void;
    idx: string;
    acceptType: string;
    infoArchivo: {url:string,name:string} | null;
}

const DropzoneArchivoUnico: React.FC<DropzoneExampleProps> = ({ onSelected, shouldClear, onClear,idx,acceptType,infoArchivo }) => {
    const [fileBackUp, setFileBackUp] = useState<{url:string,name:string}|null>(infoArchivo);
    const [file, setFile] = useState<File | null>(null);
    const onDrop = async (acceptedFiles: File[]) => {
        // Update the state with the accepted file (only the first file will be considered)
        setFile(acceptedFiles[0] || null);
        // const base64 = await fileToBase64(acceptedFiles[0]);
        onSelected(acceptedFiles[0]);
        removeFileBackUp()
    };

    const removeFile = () => {
        setFile(null);
        onSelected(null);
        onClear();
    };
    
    const removeFileBackUp = () => {
        setFileBackUp(null);
        onSelected(null);
        onClear();
    };

    React.useEffect(() => {
        if (shouldClear) {
            removeFile();
        }
    }, [shouldClear]);

    const fileToBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
        
            reader.onloadend = () => {
                resolve(reader.result);
            };
        
            reader.onerror = () => {
                reject(new Error('Error al leer el archivo.'));
            };
        
            reader.readAsDataURL(file);
        });
    };

    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const handlePreviewFile = (file: File) => {
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
    };


    function getAcceptFromExtension(extension: string): Accept {
        const accept: Accept = {};      
        switch (extension) {
            case '.jpg':
            case '.jpeg':
                accept['image/jpeg'] = [extension];
                break;
            case '.png':
                accept['image/png'] = [extension];
                break;
            case '.pdf':
                accept['application/pdf'] = [extension];
                break;
            case '.docx':
                accept['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = [extension];
                break;
            case '.doc':
                accept['application/msword'] = [extension];
                break;
            case '.xlsx':
                accept['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = [extension];
                break;
            case '.xls':
                accept['application/vnd.ms-excel'] = [extension];
                break;
            // Agrega más extensiones y tipos MIME según tus necesidades
            default:
            // Manejo de casos para extensiones desconocidas o no compatibles
            break;
        }

        return accept;
    }

    const acceptAux = getAcceptFromExtension(acceptType) 
    

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptAux?acceptAux:{'image/jpeg':[]},
        maxFiles: 1, // Limitar a una sola imagen
    });

    

    return (
        <div>
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} accept=".doc,.docx,.pdf,.txt" />
        <p>Arrastra el archivo aquí<br/> o <br/> <Button type='button' className='btn-sm btn-success'>Seleccionar Archivo</Button></p>
    </div>
    <div className="dropzone-preview-container">
        {fileBackUp?.url && !file ? (
            <div className="d-flex align-items-center justify-content-between">
                <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                <div className="flex-grow-1 text-start">
                    <span className="d-block text-primary fw-bold">Nombre del archivo: {fileBackUp.name}</span>
                    <hr className="m-0 mt-1 text-primary" />
                </div>
                <KTIcon iconName="check-circle" className="fs-3 ms-3 text-success" />
            </div>
        ) : file === null && !fileBackUp?.url ? (
            <div className="d-flex align-items-center justify-content-between">
                <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                <div className="flex-grow-1 text-start">
                    <span className="d-block text-primary fw-bold">No se ha subido ningún archivo valido</span>
                    <hr className="m-0 mt-1 text-primary" />
                </div>
                <KTIcon iconName="cross-circle" className="fs-3 ms-3" />
            </div>
        ) : !file && !fileBackUp?.url ? (
            <div className="d-flex align-items-center justify-content-between">
                <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                <div className="flex-grow-1 text-start">
                    <span className="d-block text-primary fw-bold">No se ha seleccionado ningún archivo</span>
                    <hr className="m-0 mt-1 text-primary" />
                </div>
                <KTIcon iconName="plus-circle" className="fs-3 ms-3" />
            </div>
        ) : null}

        {file && (
            <div key={file.name} className="dropzone-preview2">
                <div className="d-flex align-items-center justify-content-between">
                    <KTIcon iconName="file-sheet" className="fs-3 text-primary me-3" />
                    <div className="flex-grow-1 text-start">
                        <span className="d-block text-primary fw-bold">Nombre del archivo: {file.name}</span>
                        <hr className="m-0 mt-1 text-primary" />
                    </div>
                    <KTIcon iconName="check-circle" className="fs-3 ms-3 text-success" />
                </div>
            </div>
        )}
    </div>
</div>


    );
};

export default DropzoneArchivoUnico;
