import {useContext, useRef} from 'react'
import {Button, Col, Row, Stack} from 'react-bootstrap'
import Select, {SelectInstance} from 'react-select'
import {useUnidades} from '../../../../shared/hooks/useUnidades'
import {TableSearch} from '../../../../shared/TableSearch'
import {DatePickerBusiness} from '../../../generales_component/DatePickerBusiness'
import {MiLegajoContext} from '../MiLegajoPageContext'

interface ISelectOption {
  value: string
  label: string
}

const MiLegajoHeader = () => {
  const {setSearchTerm, setDateRange, dateRange, setExtraFilter, extraFilter, procesos} =
    useContext(MiLegajoContext)

  const processRef = useRef<SelectInstance<ISelectOption | null>>(null)
  const unidsRef = useRef<SelectInstance<ISelectOption | null>>(null)

  const handleSearch = (e: string) => {
    setSearchTerm(e)
  }

  const {toOptionsPartner} = useUnidades()

  const processOptions = [{value: '', label: 'Seleccione un proceso'}].concat(
    procesos.map((p) => ({value: p._id, label: p.nombre}))
  ) as any[]

  return (
    <>
      <div className='mx-8 mt-8 mb-2'>
        <Row className='mb-4'>
          <Col>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Buscar documento</span>
              <TableSearch
                onSearch={handleSearch}
                design={true}
                placeholderSearch={'Buscar por nombre documento'}
                className='h-40px'
              />
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Unidad</span>
              <Select
                ref={unidsRef}
                options={toOptionsPartner()}
                onChange={(e) => setExtraFilter({...extraFilter, unidad: e ? e.value : ''})}
                placeholder='Seleccione una unidad'
                className='h-40px'
              />
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Proceso</span>
              <Select
                ref={processRef}
                options={processOptions}
                onChange={(e) => setExtraFilter({...extraFilter, proceso: e ? e.value : ''})}
                placeholder='Seleccione un proceso'
              />
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Rango de Fecha Emitida</span>
              <DatePickerBusiness value={dateRange} onChange={setDateRange} className='same-height' />
            </Stack>
          </Col>
          <Col xs='auto' className='mt-4 ms-auto'> 
            <Button
              className='btn btn-primary'
              onClick={() => {
                processRef.current?.clearValue()
                unidsRef.current?.clearValue()
                setExtraFilter({
                  unidad: '',
                  proceso: '',
                })
                setDateRange([null, null])
                setSearchTerm('')
              }}
            >
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export {MiLegajoHeader}
