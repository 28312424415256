import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Widget} from '../Widget'

interface Props {
  count?: number
  height?: number
}

const SkeletonPlaceholder: React.FC<Props> = ({count = 1, height}) => {
  return (
    <Widget title='' showHeader={false}>
      <Skeleton count={count} height={height} />
    </Widget>
  )
}

export default SkeletonPlaceholder
