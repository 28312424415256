import {useContext, useEffect, useMemo, useState} from 'react'
import {Accordion, Button, Col, Container, Form, Row} from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Select from 'react-select'
import {getRoles, updateUserEnterpricesAndRols} from '../../../../services/users'
import {showMessage, showValidatePassword} from '../../../../shared/Alerts'
import {useMyCompanies} from '../../../../shared/hooks/useMyCompanies'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {PartnerContext} from '../PartnerContexts'
import { getAllCompaniesByPais } from '../../../../services/companies'

const ROLE_FILTER = 'ADMIN'
const ROLE_TOP = 'SUPERADMIN'

const enterpricesOptionsStyles = {
  multiValueRemove: (base: any, state: any) => {
    return state.data.disabled ? {...base, display: 'none'} : base
  },
}

const EditUserModalForm = () => {
  const {closeEditUserModal, setUpdateUser, userData, enterprices, setSearchTerm} =
    useContext(PartnerContext)

  const getDefaultEnterprices = (ids: string[]): {label: string; value: string}[] => {
    return enterprices
      .filter((enterprice) => ids.includes(enterprice._id))
      .map((e) => ({value: e._id, label: e.nombre}))
  }

  const [allEnterprices, setAllEnterprices] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [dataUser, setDataUser] = useState<any>(userData)
  const [shouldClear, setShouldClear] = useState(false)
  const [listRoles, setListRoles] = useState<any[]>()
  // const [selected, setSelected] = useState<any[]>()
  const [listProcessValidationError, setListProcessValidationError] = useState(false)
  const [enterpricesSelected, setEnterpricesSelected] = useState<{label: string; value: string}[]>(
    getDefaultEnterprices(userData.empresa)
  )

  const defaultValue = useMemo(() => {
    return dataUser.roles?.reduce((acc: any, item: any) => {
      acc[item.empresa] = item?.rol?.map((rols: any) => rols.codigo_perfil)
      return acc
    }, {})
  }, [])

  const [rolsByEnterprice, setRolsByEnterprice] = useState<Record<string, string[]>>(
    defaultValue ?? {}
  )

  const [rolsByEnterpriceSelected, setRolsByEnterpriceSelected] = useState<
    Record<string, Record<string, any>[]>
  >({})

  const {myCompaniesOptions, rolsByCompany} = useMyCompanies()
  const {selectedRole} = useAuth2()

  const companiesOptions =
    selectedRole === ROLE_TOP
      ? enterprices.map((e) => ({value: e._id, label: e.nombre}))
      : myCompaniesOptions.filter((company: any) => {
          const rols: string[] = rolsByCompany.find((x) => x.empresa === company.value)?.rol ?? []

          return rols.includes(ROLE_FILTER)
        })

  const listRolesFilter =
    selectedRole === ROLE_TOP
      ? listRoles
      : listRoles?.filter((roles: any) => ![ROLE_FILTER, ROLE_TOP].includes(roles.value))

  useEffect(() => {
    setDataUser(dataUser)
  }, [dataUser])

  useEffect(() => {
    getAllCompaniesByPais().then((data) => {
      if (data.success) {
        setAllEnterprices(data.data)
      }
    })
    obtenerRoles()
  }, [])

  useEffect(() => {
    if (listRoles != null) {
      setRolsByEnterpriceSelected(
        dataUser.roles?.reduce((acc: any, item: any) => {
          acc[item.empresa] =
            listRoles?.filter((x: any) =>
              item.rol.map((x: any) => x.codigo_perfil).includes(x.value)
            ) ?? []
          return acc
        }, {}) ?? {}
      )
    }
  }, [listRoles])

  const handleActualizarUser = () => {
    setLoading(true)
    const data = {
      // rol: userRoleSelected,
      empresa: enterpricesSelected.map((x) => x.value),

      rols: rolsByEnterprice,
    }

    showValidatePassword()
      .then(async (result) => {
        if (result.isConfirmed) {
          updateUserEnterpricesAndRols(dataUser._id, {...data, contraseña: result.value})
            .then((response) => {
              if (response.success) {
                limpiarCampos()
                showMessage('success', '¡Buen trabajo!', response.message)
                setUpdateUser(true)
                setSearchTerm('')
                closeEditUserModal()
              } else {
                setSearchTerm('')
                showMessage('error', 'Hubo un Error', response.message)
              }
              setLoading(false)
            })
            .catch((error) => {
              console.log(error)
              limpiarCampos()
              setLoading(false)
              setSearchTerm('')
              showMessage('error', 'Hubo un error', error.message)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const obtenerRoles = () => {
    getRoles()
      .then((response: any) => {
        if (response.success) {
          const rolesAll = response.data?.map((x: any) => ({label: x.nombre_perfil, value: x.rol}))
          // const select: any[] = rolesAll?.filter((x: any) => dataUser.rol.includes(x.label))
          // setSelected(select)
          setListRoles(rolesAll)
          // setUserRoleSelected(select.map((s) => s.value))
        } else {
          console.log(response)
          showMessage('error', 'Hubo un error', response.error)
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const limpiarCampos = () => {
    setDataUser({
      _id: '',
      idproceso: '',
      name: '',
      file: '',
      filename: '',
      keyname: '',
      type: 'PLANTILLA',
    })
    setShouldClear(true)
  }

  const handleSelectChange = (e: any, empresa: string) => {
    setRolsByEnterprice((prev) => ({
      ...prev,
      [empresa]: e.map((x: any) => x.value),
    }))

    setRolsByEnterpriceSelected((prev) => ({
      ...prev,
      [empresa]: e,
    }))

    // setSelected(e)
    // setUserRoleSelected(e.map((x: any) => x.value))
    processOwnersValidation(e.map((x: any) => x.value))
  }

  const handleEnterpriceChange = (e: any) => {
    const empresaIds = e.map((x: any) => x.value)

    setRolsByEnterpriceSelected((prev) => {
      return empresaIds.reduce((acc: any, empresaId: string) => {
        acc[empresaId] = prev[empresaId] ?? []
        return acc
      }, {})
    })
    setRolsByEnterprice((prev) => {
      return empresaIds.reduce((acc: any, empresaId: string) => {
        acc[empresaId] = prev[empresaId] ?? []
        return acc
      }, {})
    })
    setEnterpricesSelected(e)
  }

  const processOwnersValidation = (owners: string[]) => {
    if (owners.length === 0) {
      setListProcessValidationError(true)
    } else {
      setListProcessValidationError(false)
    }
  }

  const disabledByKeys = Object.keys(rolsByEnterpriceSelected).length !== enterpricesSelected.length
  const disabled = Object.values(rolsByEnterpriceSelected)?.some((e) => e.length === 0)

  const canUpdate = (company: string) => {
    return companiesOptions.some((c: any) => c.value === company)
  }

  const enterpricesSelectedOptions = enterpricesSelected.map((e: any) => ({
    value: e.value,
    label: e.label,
    disabled: !canUpdate(e.value),
  }))

  return (
    <>
      <Form>
        <Container>
          <Row className='custom-row mb-3 pt-3 px-3'>
            <Form>
              <Col sm>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex align-items-end'>
                    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>
                      Modificar Roles
                    </Form.Label>
                  </div>
                </div>
              </Col>
              <Row className='mb-3'>
                <Col sm>
                  <Form.Group controlId='formNombre'>
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      placeholder='Ingrese un nombre'
                      name='name'
                      value={dataUser.nombre}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <>
                <Row>
                  <Col sm>
                    <Col>
                      <Form.Group className='mb-3' controlId='formEmpresas'>
                        <Form.Label>Empresas</Form.Label>
                        <Select
                          isMulti
                          name='enterprices'
                          className='basic-multi-select'
                          classNamePrefix='select'
                          placeholder='Seleccione'
                          value={enterpricesSelectedOptions}
                          onChange={handleEnterpriceChange}
                          options={companiesOptions}
                          styles={enterpricesOptionsStyles}
                        />
                        {enterpricesSelected.length === 0 ? (
                          <p style={{color: '#B73A47', marginTop: '10px'}}>
                            Seleccione al menos una empresa.
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Col>
                </Row>

                <Row>
                  <Col sm>
                    <Accordion>
                      {enterpricesSelected.map((enterprice) => (
                        <Accordion.Item eventKey={enterprice.value} key={enterprice.value}>
                          <Accordion.Header>{enterprice.label}</Accordion.Header>
                          <Accordion.Body>
                            <Form.Group className='mb-3'>
                              <Form.Label>Roles</Form.Label>
                              <Select
                                value={rolsByEnterpriceSelected[enterprice.value] ?? []}
                                isMulti
                                name='managers'
                                options={listRolesFilter}
                                className='basic-multi-select'
                                classNamePrefix='select'
                                placeholder='Seleccione'
                                onChange={(e: any) => handleSelectChange(e, enterprice.value)}
                                isDisabled={!canUpdate(enterprice.value)}
                              />
                              {rolsByEnterpriceSelected[enterprice.value]?.length === 0 && (
                                <p style={{color: '#B73A47', marginTop: '10px'}}>
                                  Seleccione al menos un rol.
                                </p>
                              )}
                            </Form.Group>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Col>
                </Row>
              </>

              <Row>
                <div className='d-flex justify-content-end my-3'>
                  <Button
                    variant='light'
                    className='mx-3'
                    type='reset'
                    onClick={() => {
                      closeEditUserModal()
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='primary'
                    type='button'
                    disabled={disabledByKeys || disabled || enterpricesSelected.length === 0}
                    onClick={() => {
                      handleActualizarUser()
                    }}
                  >
                    {!loading && 'Actualizar'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Subiendo...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export {EditUserModalForm}
