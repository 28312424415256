import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";
import { downloadMultipleDocuments, getDocumentsReport, getDocumentsReportInExcel, getDocumentsReportInspector } from "../../../../services/documentSent";
import { getProcessesForDropdown } from "../../../../services/procesos";
import { showMessage } from "../../../../shared/Alerts";
import OverlayLoading from "../../../generales_component/OverlayLoading";
import { DocumentsReportContext } from "../DocumentsReportContext";
import './custom.css';
import Select from 'react-select'
import { useUnidades } from "../../../../shared/hooks/useUnidades"
import { useGlobalOverlayLoading } from "../../../../shared/hooks/useGlobalOverlayLoading";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const DocumentsReportTableHeader = () => {

    const [value, onChange] = useState<Value>();
    const [processList, setProcessList] = useState<any[]>([])
    const [unidad, setUnidad] = useState('')
    const {toOptionsInspector, misUnidadesInspector} = useUnidades()
    const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
    const [isDownloading, setDownloadingStatus] = useState<boolean>(false)

    const {
        setDateRange,
        setDocument,
        setState,
        setData,
        setIndexes,
        dateRange,
        documentName,
        state,
        setLoadingStatus,
        setTotalPages,
        currentPage,
        selectedDocuments,
        setSelectedDocuments,
        sortBy 
    } = useContext(DocumentsReportContext)

    const handleSearch = () => {

        if (validateFilters()) {
            getReport({
                dateRange: value,
                document: documentName,
                state,
                currentPage,
                limit: 10,
                sortBy: sortBy.columnName, 
                sortOrder: sortBy.sortDirection,
                unidad
            })
        }

    }

    const validateFilters = () => {
        if (value === undefined) {
            showMessage('info', 'Importante', 'Seleccione un rango de fechas')
            return false
        }

        return true

    }

    const handleDocumentsDownload = () => {
        downloadDocuments({
            dateRange: value,
            document: documentName,
            state,
            selectedDocuments
        })
    }

    useEffect(() => {
        handleSearch()
    }, [currentPage,sortBy])

    useEffect(() => {
        getProcesses()
    }, [])

    const getReport = (params: any) => {

        getDocumentsReportInspector(params)
            .then((response) => {
                if (response.success) {
                    setLoadingStatus(false)
                    setData(response.data)
                    setIndexes(response.indexes)
                    setTotalPages(response.totalPages)
                } else {
                    console.log(response)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const downloadDocuments = (params: any) => {

        setDownloadingStatus(true)

        downloadMultipleDocuments(params)
            .then((response) => {
                if (response.success) {
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.download = 'multiple_documents';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    setDownloadingStatus(false)
                    showMessage('success', '¡Buen trabajo!', response.message)
                } else {
                    console.log(response)
                    showMessage('error', 'Hubo un error', response.error)
                }
            })
            .catch((error) => {
                console.log(error)
                setDownloadingStatus(false)
                showMessage('error', 'Hubo un error', error.message)
            })
    }

    const handleExport = () => {
        if (validateFilters()) {
            setGlobalOverlayLoading(true)
            getDocumentsReportInExcel({
                dateRange: value,
                document: documentName,
                state
            })
                .then((response) => console.log(response))
                .catch((error) => console.log(error))
                .finally(() => setGlobalOverlayLoading(false))
        }
    }

    // const handlePaste = (event: any) => {
    //     const pastedDate = event.clipboardData.getData('text');
    //     const parsedDate = new Date(pastedDate);

    //     if (!isNaN(parsedDate.getTime())) {
    //       onChange(parsedDate);
    //     }
    //   };

    const getProcesses = () => {
        getProcessesForDropdown()
            .then((response) => {
                if (response.success) {
                    setProcessList(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleChangeUnidad = (e: any) => {
      setUnidad(e.value)
    }

    return (
      <div className='mx-8 mt-8 mb-2'>
        <Row>
          
        <Col className='d-flex align-self-end justify-content-start'>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Rango de Fecha de envio</span>
              <DateRangePicker
                className={'custom-date-picker'}
                value={value}
                onChange={onChange}
                locale='es-ES'
              />
            </Stack>
          </Col>
            {
              misUnidadesInspector.length > 0 &&
              <Col className='d-flex align-self-end justify-content-start'>
                  <Stack direction='vertical' gap={1}>
                  <span className='fw-bold'>Unidad:</span>
                  <Select
                      styles={{
                          control: (provided) => ({
                          ...provided,
                          fontSize: '13px',
                          minHeight: '42px', // Reducción del alto
                          height: '42px',
                          }),
                      }}
                      options={toOptionsInspector()}
                      onChange={handleChangeUnidad}
                  />
                  </Stack>
              </Col>
            }

          <Col className='d-flex align-self-end justify-content-start'>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Documento:</span>
              <Form.Control
                type='text'
                // value={description}
                required
                placeholder='Ingrese un nombre'
                name='description'
                onChange={(e) => setDocument(e.target.value)}
              />
            </Stack>
          </Col>
          <Col className='d-flex align-self-end justify-content-start'>
            <Stack direction='vertical' gap={1}>
              <span className='fw-bold'>Estado Documento:</span>
              <Form.Control as='select' onChange={(e) => setState(e.target.value)}>
                <option value=''>Todos</option>
                <option value='pending'>Pendiente</option>
                <option value='signed'>Firmado</option>
                <option value='rejected'>Rechazado</option>
              </Form.Control>
            </Stack>
          </Col>
          <Col sm='auto' className='d-flex align-self-end justify-content-start'>
            <button type='button' className='btn btn-success' onClick={handleSearch}>
              <KTIcon iconName='magnifier' className='fs-2' />
              Buscar
            </button>
          </Col>

          <Col sm='auto' className='d-flex align-self-end justify-content-end'>
            <Stack direction='vertical' gap={2}>
              <div className='d-flex align-self-end justify-content-end'>
                <button
                  type='button'
                  className='btn btn-primary'
                  title='Exportar a Excel'
                  onClick={handleExport}
                >
                  <KTIcon iconName='file-down' className='fs-2' />
                  Exportar
                </button>
              </div>

              {selectedDocuments.length > 1 && (
                <div className='d-flex align-self-end justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-success'
                    title='Descargar documentos seleccionados'
                    onClick={handleDocumentsDownload}
                  >
                    <KTIcon iconName='folder-down' className='fs-2' />
                    Descargar
                  </button>
                </div>
              )}
            </Stack>
          </Col>
        </Row>

        {isDownloading && <OverlayLoading />}
      </div>
    )
}

export { DocumentsReportTableHeader };

