import debounce from 'just-debounce-it'
import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Col, Dropdown, Form, Row, Stack} from 'react-bootstrap'
import {useLocation, useSearchParams} from 'react-router-dom'
import Select, {SelectInstance, SingleValue, components} from 'react-select'
import {KTIcon} from '../../../../../_metronic/helpers'
import {getUnidadesEmpresas} from '../../../../services/companies'
import {fichasxid, generateExcel, generateSimplifiedExcel} from '../../../../services/fichas'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {getProceso} from '../../../../services/procesos'
import {TableSearch} from '../../../../shared/TableSearch'
import {useGlobalOverlayLoading} from '../../../../shared/hooks/useGlobalOverlayLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {DataUser} from '../../../documentacion/MiFicha/DataUserInterface'
import {DatePickerBusiness} from '../../../generales_component/DatePickerBusiness'
import {DocumentStates, PartnerContext} from '../PartnerContexts'

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    minHeight: '20px',
    height: '34.5px',
    backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
    color: state.isSelected ? 'inherit' : provided.color,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'inherit',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'inherit',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'inherit',
    },
  }),
}

const CustomOption = (props: any) => {
  return (
    <components.Option {...props} className='d-flex align-items-center gap-2'>
      <input type='checkbox' checked={props.isSelected} onChange={() => null} />
      <label>{props.data.label}</label>
    </components.Option>
  )
}

const CustomValueContainer = ({children, ...props}: any) => {
  const length = props.getValue().length

  if (length > 1) {
    return <div className='value-container mx-2'>{length} opciones seleccionadas</div>
  }

  return (
    <div className='value-container mx-2'>
      {children[0] !== null && children[0]?.key === 'placeholder'
        ? 'Seleccione'
        : children[0]?.key !== 'placeholder'
        ? children[0]
        : ''}
      {children[1]}
    </div>
  )
}

const allStates = [
  // {
  //   value: 'all',
  //   label: 'Todos',
  //   state: 'process',
  // },
  {
    value: 'pendientePorColaborador',
    label: 'Fichas Pendientes',
    state: 'sheet',
  },
  {
    value: 'fichaSiendoEditada',
    label: 'Fichas en Progreso',
    state: 'sheet',
  },
  {
    value: 'fichaObservada',
    label: 'Fichas Observadas',
    state: 'sheet',
  },
  {
    value: 'completadoPorColaborador',
    label: 'Fichas Completadas',
    state: 'sheet',
  },
  {
    value: 'aprobadoConPendiente',
    label: 'Fichas Aprobadas con Pendientes',
    state: 'sheet',
  },
  {
    value: 'aprobadoPorAdmin',
    label: 'Fichas Aprobadas',
    state: 'sheet',
  },
  {
    value: 'historico',
    label: 'Fichas Historicas',
    state: 'sheet',
  },
  {
    value: 'documentosPendientesDeFirma', // before "pending"
    label: 'Documentos Pendientes de Firma',
    state: 'sheet', // before "document"
  },
  {
    value: 'documentosEnProcesoDeFirma', // before "viewed"
    label: 'Documentos en Proceso de Firma',
    state: 'sheet', // before "document"
  },
  {
    value: 'documentosFirmados', // before "signed"
    label: 'Documentos Firmados',
    state: 'sheet', // before "document"
  },
  {
    value: 'procesoCerrado',
    label: 'Procesos Cerrados',
    state: 'process',
  },
  {
    value: 'procesoCerradoConPendiente',
    label: 'Procesos Cerrados con Pendientes',
    state: 'process',
  },
  {
    value: 'procesoAnulado',
    label: 'Procesos Anulados',
    state: 'process',
  },
]

interface Campo {
  _id: string
  titulo: string
  tabla: any
  idtipo: Record<string, string>
  opciones?: {id: string; name: string; parent_id: string}[]
}
interface Filtro {
  idseccion: string
  campos: Campo[]
}

interface FiltroState {
  titulo: string
  tipo: Record<string, string>
  opciones?: {id: string; name: string; parent_id: string}[]
}

interface FiltroProceso {
  idficha: string
  idproceso: string
  filtros: Filtro[]
}

const PartnersListHeader = () => {
  const buttonsData = [
    {
      iconName: 'time',
      title: 'PENDIENTE',
      stateName: 'pendientePorColaborador',
      color: '#3498db', // Blue
    },
    {
      iconName: 'check-square',
      title: 'COMPLETADO',
      stateName: 'completadoPorColaborador',
      color: '#9b59b6', // Purple
    },
    {
      iconName: 'verify',
      title: 'APROBADO CON PENDIENTE',
      stateName: 'aprobadoConPendiente',
      color: '#f39c12', // Orange
    },
    {
      iconName: 'security-user',
      title: 'APROBADO',
      stateName: 'aprobadoPorAdmin',
      color: '#2ecc71', // Green
    },
    {
      iconName: 'security-user',
      title: 'HISTORICO',
      stateName: 'historico',
      color: '#2ecccc', // Green
    },
    {
      iconName: 'notepad-edit',
      title: 'FICHA EN PROGRESO',
      stateName: 'fichaSiendoEditada',
      color: '#000000', // Purple
    },
    // {
    //   iconName: 'exclamation-triangle', // Adjust the icon name
    //   title: 'Proceso Anulado',
    //   stateName: 'procesoAnulado',
    //   color: '#e74c3c', // Red
    // },
    {
      iconName: 'eye',
      title: 'FICHA OBSERVADA',
      stateName: 'fichaObservada',
      color: '#d35400',
    },
  ]

  const [activeButtons, setActiveButtons] = useState(buttonsData.map(() => false))
  // const [processData, setProcessData] = useState([])
  const [unidadData, setUnidadData] = useState([])
  // const [fichaData, setFichaData] = useState([])
  const [idproceso, setIdproceso] = useState('')
  const [idunidad, setIdunidad] = useState('')
  const [idfichas, setIdfichas] = useState('')
  const [filtros, setFiltros] = useState<FiltroProceso[]>([])
  const usuarioJSON = localStorage.getItem('userData')
  const [options, setOptions] = useState<'equal' | 'distinct'>('equal')
  const [selectedOption, setSelectedOption] = useState('')
  const [filtro, setFiltro] = useState<FiltroState | null>(null)
  const selectRef = useRef<SelectInstance>(null)
  const allStatesRef = useRef<SelectInstance>(null)
  const {selectedCompanyId} = useAuth2()
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [searchParams] = useSearchParams()

  let usuario: DataUser | null = null

  if (usuarioJSON !== null) {
    usuario = JSON.parse(usuarioJSON)
  }

  const {
    activeOnly,
    setActiveOnly,
    state,
    setState,
    searchTerm,
    setSearchTerm,
    setSheetId,
    entryDateFrom,
    setEntryDateFrom,
    entryDateTo,
    setEntryDateTo,
    openFichaPlantillaModal,
    setTypeUpdate,
    setShowCargaExcelModal,
    setShowCargaEdicionExcelModal,
    setProcessId,
    processId,
    setUnidadId,
    unidadId,
    setQuery,
    setFiltrosKeys,
    setFiltroData,
    filtroData,
    misUnidades,
    setMisUnidades,
    setCampoUnidad,
    campoUnidad,
    setFichaData,
    Dates,
    setDates,
    documentState,
    setDocumentState,
    hints,
    processData,
    setProcessData
  } = useContext(PartnerContext)

  const {setGlobalOverlayLoading} = useGlobalOverlayLoading()
  const location = useLocation()
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  useEffect(() => {
    const options = {
      responsables: usuario?.userId,
      estado: 'A',
    }

    Promise.all([
      getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId)),
      getProceso(1, 100, options),
    ]).then((responses) => {
      if (responses[0].success && responses[1].success && responses[1].data.length > 0) {
        const misUnids = responses[0].data[0].unidad.filter((u: any) => u.selected)
        setMisUnidades(misUnids ?? [])

        if (!searchParams.get('idproceso')) {
          setIdproceso(responses[1].data[0]._id)
          setProcessId(responses[1].data[0]._id)
        }

        if (!searchParams.get('unidad')) {
          setIdunidad(misUnids[0]?.codUnidad)
          setUnidadId(misUnids[0]?.codUnidad)
        }

        setProcessData(responses[1].data.map((x: any) => ({
          label: x.nombre,
          value: x._id,
          isOnboarding: x.isOnboarding,
          isPostulacion: x.isPostulacion
        })))
      }
    })

    // getUnidadesEmpresas([String(selectedCompanyId)], String(usuario?.userId))
    //   .then((response) => {
    //     if (response.success) {
    //       const misUnids = response.data[0].unidad.filter((u: any) => u.selected)
    //       setMisUnidades(misUnids)
    //     }
    //   })

    // getProceso(1, 100, options).then((response) => {
    //   if (response.success) {
    //     setIdproceso(response.data[0]._id)
    //     setProcessId(response.data[0]._id)
    //     setProcessData(response.data.map((x: any) => ({label: x.nombre, value: x._id})))
    //   } else {
    //     console.log(response.msg)
    //   }
    // })
  }, [])

  useEffect(() => {
    if (idproceso !== '') {
      getFichasxProceso(idproceso)
    }
  }, [idproceso])

  useEffect(() => {
    const filtrosByFicha = filtros.filter(
      (filtro) => filtro.idficha === idfichas && filtro.idproceso === idproceso
    )

    const misFiltros =
      filtrosByFicha.length > 0 ? filtrosByFicha[0].filtros[0].campos : ([] as Campo[])

    const filtrosKeys = misFiltros.map((f) => f.titulo.toLowerCase())

    const filtroData = misFiltros.map((f) => ({
      campo: f.titulo.toLowerCase(),
      isUnidad: f.tabla?.toLowerCase() === 'unidades' && f.idtipo.nombre === 'Lista desplegable BD',
      values:
        f.tabla !== 'Unidades' && f.idtipo.nombre === 'Lista desplegable BD'
          ? f.opciones
          : undefined,
    }))

    const campoUnidad = misFiltros.find((f) => f.tabla === 'Unidades') ?? null

    setCampoUnidad(campoUnidad?.titulo ?? '')
    setFiltrosKeys(filtrosKeys)
    setFiltroData(filtroData)
    setFiltro(
      misFiltros[0] != null
        ? {
            titulo: misFiltros[0].titulo,
            tipo: misFiltros[0].idtipo,
            opciones: misFiltros[0].opciones,
          }
        : null
    )
  }, [filtros])

  useEffect(() => {
    if (filtro != null && options != null) {
      console.log(filtro, options, selectedOption)
      setQuery({
        match: options,
        campo: filtro.titulo,
        values: selectedOption,
      })
    }
  }, [filtro, options, selectedOption])

  useEffect(() => {
    if (searchParams.get('idproceso')) {
      setIdproceso(searchParams.get('idproceso') ?? '')
      setProcessId(searchParams.get('idproceso') ?? '')
    }

    if (searchParams.get('unidad')) {
      setUnidadId(searchParams.get('unidad') ?? '')
      setIdunidad(searchParams.get('unidad') ?? '')
    }

    if (searchParams.get('dni')) {
      setSearchTerm(searchParams.get('dni') ?? '')
    }
  }, [searchParams])

  const getFichasxProceso = (value: string) => {
    fichasxid(value).then((response) => {
      console.log(response)
      if (response.success) {
        setIdfichas(response.data[0]?.idficha?._id)
        setSheetId(response.data[0]?.idficha?._id)
        setFichaData(
          response.data.map((x: any) => ({label: x.idficha?.nombre, value: x.idficha?._id}))
        )
        setFiltros(response.procesoFiltros ?? [])
      } else {
        console.log(response.msg)
      }
    })
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleInputChange = (event: any) => {
    setIdproceso(event.target.value)
    setProcessId(event.target.value)
    setQuery(null)
    setSelectedOption('')
    selectRef.current?.clearValue()
  }

  const handleInputChangeUnidad = (event: any) => {
    setIdunidad(event.target.value)
    setUnidadId(event.target.value)
    setQuery(null)
    setSelectedOption('')
    selectRef.current?.clearValue()
  }

  // const handleInputChangeFichas = (event: any) => {
  //   setIdfichas(event.target.value)
  //   setSheetId(event.target.value)
  //   setQuery(null)
  //   selectRef.current?.clearValue()
  // }

  const handleButtonClick = (index: number) => {
    const newActiveButtons = [...activeButtons]
    newActiveButtons[index] = !newActiveButtons[index]
    setActiveButtons(newActiveButtons)
    // setState(
    //   buttonsData
    //     .map((x, i) => (newActiveButtons[i] ? x.stateName : ''))
    //     .filter((n) => n.length > 0)
    // )
  }

  const handleChange = (e: any) => {
    const groupedStates = e.reduce(
      (acc: any, option: any) => {
        if (acc[option.state]) {
          acc[option.state] = acc[option.state].concat(option.value)
        } else {
          acc[option.state] = [option.value]
        }

        return acc
      },
      {sheet: [], document: [], process: []}
    )

    setSelectedOptions(e || [])
    setActiveOnly(groupedStates.process.length > 0 ? groupedStates.process.join(',') : 'all')
    setDocumentState(groupedStates.document.length > 0 ? groupedStates.document.join(',') : 'all')
    setState(groupedStates.sheet.length > 0 ? groupedStates.sheet.join(',') : '')

    // if (groupedStates?.sheet?.length > 0) {
    //   const isPositive = groupedStates.sheet.includes('aprobadoConPendiente', 'aprobadoPorAdmin')
    //   setActiveOnly('all')
    //   setDocumentState(isPositive ? documentState : 'all')
    //   setState(groupedStates.sheet.join(','))
    // }

    // if (groupedStates?.document?.length > 0) {
    //   setActiveOnly('all')
    //   setDocumentState(groupedStates.document.join(','))
    //   setState('')
    // }

    // if (groupedStates?.process?.length > 0) {
    //   setActiveOnly(groupedStates.process.join(','))

    //   setDocumentState('all')
    //   setState('')
    // }

    // setDocumentState('all')
    // setState('')
    // setActiveOnly(e.target.value)
  }

  const handleStateChange = (e: any) => {
    const isPositive = ['aprobadoConPendiente', 'aprobadoPorAdmin'].includes(e.target.value)
    setDocumentState(isPositive ? documentState : 'all')
    setState(e.target.value)
  }

  const handleClearFilters = () => {
    // setActiveOnly(true)
    // setState([''])
    // setSearchTerm('')
    // setEntryDateFrom(null)
    // setEntryDateTo(null)
  }

  const handleExport = (id: any) => {
    const params = {
      activeOnly: activeOnly,
      sheetStates: state,
      searchQuery: searchTerm,
      entryDateFrom: entryDateFrom,
      entryDateTo: entryDateTo,
      processId: processId,
    }

    setGlobalOverlayLoading(true)
    generateExcel(params)
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
      .finally(() => setGlobalOverlayLoading(false))
  }

  const handleSimplifiedExport = (id: any) => {
    setGlobalOverlayLoading(true)
    generateSimplifiedExcel()
      .then((response) => console.log(response))
      .catch((error) => console.log(error))
      .finally(() => setGlobalOverlayLoading(false))
  }

  const handleShowFicha = () => {
    setTypeUpdate(false)
    openFichaPlantillaModal()
  }

  const handleShowFichaEdicion = () => {
    setTypeUpdate(true)
    openFichaPlantillaModal()
  }

  const handleShowCargaExcel = () => {
    setShowCargaExcelModal(true)
  }

  const handleShowCargaEdicionExcel = () => {
    setShowCargaEdicionExcelModal(true)
  }

  const handleChangeOptions = (e: any) => {
    setOptions(e.target.value)
  }

  const handleChangeFilterType = (e: any) => {
    filtros.forEach((filtro) => {
      filtro.filtros.forEach((f) => {
        f.campos.forEach((c) => {
          if (c._id === e.target.value) {
            setFiltro({
              titulo: c.titulo,
              tipo: c.idtipo,
              opciones: c.opciones,
            })
            selectRef.current?.clearValue()
          }
        })
      })
    })
  }

  const handleChangeOption = (e: any) => {
    console.log(e)
    if (Array.isArray(e)) {
      setSelectedOption(e.map((e) => e.value).join('@'))
    } else {
      setSelectedOption(e.value)
    }
  }

  const onChageText = useCallback(
    debounce((value: string) => {
      setSelectedOption(value)
    }, 500),
    []
  )

  const handleChangeDocumentState = (e: SingleValue<{value: DocumentStates; label: string}>) => {
    setDocumentState(e?.value ?? 'all')
  }

  const filtrosByFicha = filtros.filter(
    (filtro) => filtro.idficha === idfichas && filtro.idproceso === idproceso
  )

  const misFiltros =
    filtrosByFicha.length > 0 ? filtrosByFicha[0].filtros[0].campos : ([] as Campo[])

  const optionsSelect =
    filtro != null && filtro.opciones != null
      ? filtroData.find((f) => f.campo === filtro.titulo.toLowerCase())?.isUnidad
        ? misUnidades.map((u: any) => ({
            label: u.DesUnid,
            value: u.codUnidad,
          }))
        : filtroData
            .find((f) => f.campo === filtro.titulo.toLowerCase())
            ?.values?.map((o: Record<string, string>) => ({
              label: o.name,
              value: o.id,
            }))
      : []

  return (
    <>
      <div className='mx-8 mt-8'>
        <Row className='mb-4'>
          <Col>
            <Stack>
              <span className='fw-bold'>Proceso:</span>
              <select
                className={'form-select form-select-solid}'}
                aria-label='Select example'
                value={idproceso}
                name='idproceso'
                onChange={(event) => handleInputChange(event)}
              >
                {processData.map((x: any) => (
                  <option value={x.value} key={x.value}>
                    {' '}
                    {String(x.label)}
                  </option>
                ))}
              </select>
            </Stack>
          </Col>
          <Col>
            <Stack>
              <span className='fw-bold'>Unidad:</span>
              <select
                className={'form-select form-select-solid}'}
                aria-label='Select example'
                value={idunidad}
                name='idunidad'
                onChange={(event) => handleInputChangeUnidad(event)}
              >
                {misUnidades.map((x: any) => (
                  <option value={x.codUnidad} key={x.codUnidad}>
                    {' '}
                    {String(x.DesUnid)}
                  </option>
                ))}
              </select>
            </Stack>
          </Col>
          <Col>
            <Stack direction='vertical' className='h-100'>
              <span className='fw-bold'>Buscar</span>
              <TableSearch className='same-height' placeholderSearch="Busca por DNI | Nombres | Apellidos | Cargo" onSearch={handleSearch} design={true} searchTermDef={searchParams.get('dni') ?? ''} />
            </Stack>
          </Col>
        </Row>

        <Row className=''>
          <Col>
            <Stack direction='vertical' className='h-100'>
              <span className='fw-bold'>Rangos de Fecha de ingreso</span>
              <DatePickerBusiness value={Dates} onChange={setDates} />
            </Stack>
          </Col>
          <Col>
            <Row>
              <Col>
                <Stack direction='vertical' className='h-100'>
                  <span className='fw-bold'>Estado del proceso:</span>
                  <Select
                    options={allStates}
                    value={selectedOptions}
                    onChange={handleChange}
                    isMulti
                    components={{ValueContainer: CustomValueContainer, Option: CustomOption}}
                    placeholder='Seleccione estados'
                    hideSelectedOptions={false}
                    ref={allStatesRef}
                    isClearable
                    closeMenuOnSelect={false}
                    isOptionDisabled={(option: any) => option.value === 'all'}
                    styles={customStyles}
                  />
                </Stack>
              </Col>
              {misFiltros.length > 0 && (
                <>
                  <Col className='mt-3'>
                    <Dropdown autoClose={false} drop='end'>
                      <Dropdown.Toggle variant='primary' id='dropdown-autoclose-false'>
                        Filtros
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Stack direction='horizontal' gap={2}>
                          <Dropdown.Item></Dropdown.Item>
                        </Stack>

                        <Stack direction='horizontal' gap={2}>
                          <Dropdown.Item>
                            <span className='fw-bold'>Personalizado:</span>
                            <Form.Select onChange={handleChangeFilterType}>
                              {misFiltros.map((filtro) => (
                                <option key={filtro._id} value={filtro._id} label={filtro.titulo} />
                              ))}
                            </Form.Select>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <Form.Select onChange={handleChangeOptions}>
                              <option value='equal'>Es</option>
                              <option value='distinct'>No es</option>
                            </Form.Select>
                          </Dropdown.Item>
                        </Stack>

                        <Dropdown.Item>
                          {filtro != null && filtro?.tipo.nombre === 'Lista desplegable BD' ? (
                            <>
                              <Select
                                ref={selectRef}
                                onChange={handleChangeOption}
                                options={optionsSelect}
                                isMulti
                              />
                            </>
                          ) : (
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Buscar'
                              onChange={(e) => {
                                onChageText(e.target.value)
                              }}
                              // onChange={(e) => {setSelectedOption(e.target.value)}}
                            />
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col>
            <Stack className='mt-4' direction='horizontal' gap={2}>
              <Stack direction='horizontal' gap={2}>
                {/* <button type='button' className='btn btn-light' onClick={handleClearFilters}>
                <KTIcon iconName='tablet-delete' className='fs-2' />
                Limpiar
              </button> */}
                {/* <button type='button' className='btn btn-primary' onClick={handleExport}>
                <KTIcon iconName='file-down' className='fs-2' />
                Exportar
              </button> */}
                <Dropdown>
                  {permissions.includes('Exportar') && (
                    <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                      <KTIcon iconName='file-down' className='fs-2' />
                      Exportar
                    </Dropdown.Toggle>
                  )}
                  <Dropdown.Menu>
                    <Dropdown.Item href='#' onClick={handleSimplifiedExport}>
                      Reporte simple
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleExport}>
                      Reporte detallado
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>

              <Stack direction='horizontal' gap={2}>
                <Dropdown>
                  {permissions.includes('Carga Masiva de Procesos') && (
                    <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                      <KTIcon iconName='file-down' className='fs-2' />
                      Carga Masiva de Procesos
                    </Dropdown.Toggle>
                  )}
                  <Dropdown.Menu>
                    <Dropdown.Item href='#' onClick={handleShowFicha}>
                      Exportar Plantilla
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleShowCargaExcel}>
                      Cargar Plantilla de creación
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleShowFichaEdicion}>
                      Exportar Plantilla de Edicion
                    </Dropdown.Item>
                    <Dropdown.Item href='#' onClick={handleShowCargaEdicionExcel}>
                      Cargar Plantilla de edicion
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            </Stack>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <div className='d-flex justify-content-end'>
              <p className='d-inline-block m-0 fw-bold border border-dark p-2 rounded-2'>
                {hints} registros
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export {PartnersListHeader}
