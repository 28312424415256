import {Fragment, useContext, useEffect, useState} from 'react'
import {Stack} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTCardBody, KTIcon} from '../../../../../_metronic/helpers'
import {formatDateToDMY} from '../../../../helpers/dateFunctions'
import {deleteAlert, getAlertsState, getAllAlerts} from '../../../../services/alerts'
import {fetchMenuActionsByRole, fetchSubMenuByUrl} from '../../../../services/menu'
import {showDeleteMessage, showMessage} from '../../../../shared/Alerts'
import Pagination from '../../../../shared/Pagination'
import {UsersListLoading} from '../../../apps/user-management/users-list/components/loading/UsersListLoading'
import {useAuth2} from '../../../authv2/Auth2Provider'
import {Alerts2Context} from '../Alerts2Contexts'

const AlertsTable = () => {
  const navigate = useNavigate()
  const [alertList, setAlertList] = useState([])
  const [isLoading, setLoadingStatus] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const {searchTerm} = useContext(Alerts2Context)

  useEffect(() => {
    getAlerts(currentPage)
  }, [searchTerm])

  const getAlerts = (page: number) => {
    const params = {
      page,
      limit: 10,
      searchTerm,
    }

    setLoadingStatus(true)

    getAlertsState(params)
      .then((response) => {
        if (response.success) {
          setLoadingStatus(false)
          setAlertList(response.data)
          setTotalPages(response.totalPages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleUpdate = (id: string) => {
    navigate(`/configuracion/alerta2/edit/${id}`)
  }

  const handleDelete = (id: string) => {
    showDeleteMessage().then((result) => {
      if (result.isConfirmed) {
        deleteAlert(id)
          .then((response) => {
            if (response.success) {
              showMessage('success', '¡Buen trabajo!', response.message)
            } else {
              showMessage('error', 'Hubo un error', response.error)
            }
            getAlerts(currentPage)
          })
          .catch((error) => {
            showMessage('error', 'Hubo un error', error.message)
          })
      }
    })
  }

  const generatePaginationLinks = (currentPage: number, totalPages: number) => {
    const links = []

    if (currentPage > 1) {
      links.push({page: currentPage - 1, label: 'Anterior'})
    }

    for (let i = 1; i <= totalPages; i++) {
      links.push({page: i, label: String(i)})
    }

    if (currentPage < totalPages) {
      links.push({page: currentPage + 1, label: 'Siguiente'})
    }

    return links
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    getAlerts(page)
  }
  const {selectedRole} = useAuth2()
  const [permissions, setPermissions] = useState<string[]>([])
  const location = useLocation()

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetchMenuActionsByRole(selectedRole)
      if (response.success) {
        const currentPath = location.pathname
        const subMenuResponse = await fetchSubMenuByUrl(currentPath)
        if (subMenuResponse.success) {
          const subMenuId = subMenuResponse.data.id_hijo
          const permissionsForSubMenu =
            response.data.find((item: any) => item.subMenuId === subMenuId)?.acciones || []
          setPermissions(permissionsForSubMenu)
        }
      }
    }

    fetchPermissions()
  }, [selectedRole])

  return (
    <>
      <KTCardBody className='py-4 min-vh-100'>
        <div className='table-responsive min-vh-100'>
          <table className='table align-middle table-row-bordered fs-6 table-hover'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary text-white'>
                {/* <th className='text-start'>Código</th> */}
                
                
                <th className='text-start p-3'>Nombre</th>
                <th className='text-start'>Proceso</th>
                <th className='text-start'>Usuario/Responsable</th>
                <th className='text-start'>Estado</th>
                <th className='text-start'>Fec. Inicio</th>
                <th className='text-start'>Fec. Fin</th>
                <th className='text-end p-3'>Acciones</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {alertList?.length > 0 ? (
                alertList?.map((item: any, index: number) => (
                  <Fragment key={item._id}>
                    <tr>
                      {/* <td className='text-start min-w-100px'>{item._id}</td> */}
                      <td className='text-start min-w-100px min-h-57px p-3'>{item.description}</td>
                      <td className='text-start min-w-100px'>{item.processName}</td>
                      <td className='text-start min-w-100px'>{item.typeSend}</td>
                      <td className='text-start min-w-100px'>{item.sheetState}</td>
                      <td className='text-start min-w-100px'>
                        {formatDateToDMY(new Date())}
                      </td>
                      <td className='text-start min-w-100px'>
                        {formatDateToDMY(new Date())}
                      </td>
                      <td className='text-end p-1'>
                      <div className='d-flex justify-content-end flex-shrink-0'>                          {permissions.includes('Editar Alerta') && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='Editar Alerta'
                              onClick={() => handleUpdate(item._id)}
                            >
                              <KTIcon iconName='pencil' className='fs-3' />
                            </button>
                          )}
                          {permissions.includes('Eliminar Alerta') && (
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              title='Eliminar Alerta'
                              onClick={() => handleDelete(item._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          links={generatePaginationLinks(currentPage, totalPages)}
          currentPage={currentPage}
          isLoading={isLoading}
          onPageChange={handlePageChange}
        />
        {isLoading && <UsersListLoading />}
      </KTCardBody>
    </>
  )
}

export {AlertsTable}
